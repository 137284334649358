import React from 'react';
import {cl} from '../../components/utils/utils';
import { ResponsiveScatterPlot } from '@nivo/scatterplot'

class C18ScatterPlot extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    this.state={
    }
    this.testData =
    [
  {
    "id": "group A",
    "data": [
      {
        "x": 25,
        "y": 116
      },
      {
        "x": 89,
        "y": 67
      },
      {
        "x": 41,
        "y": 27
      },
      {
        "x": 33,
        "y": 65
      },
      {
        "x": 90,
        "y": 53
      },
      {
        "x": 47,
        "y": 73
      },
      {
        "x": 66,
        "y": 47
      },
      {
        "x": 45,
        "y": 106
      },
      {
        "x": 27,
        "y": 105
      },
      {
        "x": 37,
        "y": 75
      },
      {
        "x": 8,
        "y": 88
      },
      {
        "x": 68,
        "y": 53
      },
      {
        "x": 4,
        "y": 8
      },
      {
        "x": 43,
        "y": 67
      },
      {
        "x": 83,
        "y": 20
      },
      {
        "x": 25,
        "y": 7
      },
      {
        "x": 44,
        "y": 81
      },
      {
        "x": 91,
        "y": 8
      },
      {
        "x": 49,
        "y": 109
      },
      {
        "x": 2,
        "y": 79
      },
      {
        "x": 0,
        "y": 25
      },
      {
        "x": 85,
        "y": 109
      },
      {
        "x": 98,
        "y": 13
      },
      {
        "x": 78,
        "y": 27
      },
      {
        "x": 0,
        "y": 111
      },
      {
        "x": 1,
        "y": 31
      },
      {
        "x": 73,
        "y": 119
      },
      {
        "x": 21,
        "y": 94
      },
      {
        "x": 78,
        "y": 99
      },
      {
        "x": 86,
        "y": 32
      },
      {
        "x": 80,
        "y": 14
      },
      {
        "x": 80,
        "y": 32
      },
      {
        "x": 69,
        "y": 43
      },
      {
        "x": 62,
        "y": 25
      },
      {
        "x": 67,
        "y": 104
      },
      {
        "x": 62,
        "y": 58
      },
      {
        "x": 100,
        "y": 102
      },
      {
        "x": 40,
        "y": 62
      },
      {
        "x": 8,
        "y": 82
      },
      {
        "x": 70,
        "y": 0
      },
      {
        "x": 99,
        "y": 61
      },
      {
        "x": 22,
        "y": 89
      },
      {
        "x": 57,
        "y": 56
      },
      {
        "x": 8,
        "y": 87
      },
      {
        "x": 25,
        "y": 23
      },
      {
        "x": 13,
        "y": 7
      },
      {
        "x": 49,
        "y": 61
      },
      {
        "x": 15,
        "y": 101
      },
      {
        "x": 43,
        "y": 89
      },
      {
        "x": 50,
        "y": 25
      }
    ]
  },
  {
    "id": "group B",
    "data": [
      {
        "x": 3,
        "y": 60
      },
      {
        "x": 9,
        "y": 21
      },
      {
        "x": 11,
        "y": 96
      },
      {
        "x": 47,
        "y": 75
      },
      {
        "x": 69,
        "y": 32
      },
      {
        "x": 8,
        "y": 55
      },
      {
        "x": 87,
        "y": 62
      },
      {
        "x": 12,
        "y": 80
      },
      {
        "x": 80,
        "y": 108
      },
      {
        "x": 33,
        "y": 7
      },
      {
        "x": 45,
        "y": 75
      },
      {
        "x": 30,
        "y": 56
      },
      {
        "x": 95,
        "y": 16
      },
      {
        "x": 51,
        "y": 55
      },
      {
        "x": 37,
        "y": 54
      },
      {
        "x": 88,
        "y": 104
      },
      {
        "x": 41,
        "y": 27
      },
      {
        "x": 29,
        "y": 111
      },
      {
        "x": 19,
        "y": 22
      },
      {
        "x": 46,
        "y": 88
      },
      {
        "x": 52,
        "y": 9
      },
      {
        "x": 96,
        "y": 21
      },
      {
        "x": 47,
        "y": 116
      },
      {
        "x": 53,
        "y": 60
      },
      {
        "x": 95,
        "y": 118
      },
      {
        "x": 92,
        "y": 41
      },
      {
        "x": 50,
        "y": 102
      },
      {
        "x": 69,
        "y": 49
      },
      {
        "x": 80,
        "y": 82
      },
      {
        "x": 32,
        "y": 29
      },
      {
        "x": 0,
        "y": 67
      },
      {
        "x": 23,
        "y": 104
      },
      {
        "x": 90,
        "y": 114
      },
      {
        "x": 82,
        "y": 98
      },
      {
        "x": 59,
        "y": 117
      },
      {
        "x": 72,
        "y": 42
      },
      {
        "x": 7,
        "y": 15
      },
      {
        "x": 52,
        "y": 22
      },
      {
        "x": 87,
        "y": 85
      },
      {
        "x": 66,
        "y": 8
      },
      {
        "x": 51,
        "y": 116
      },
      {
        "x": 37,
        "y": 33
      },
      {
        "x": 32,
        "y": 103
      },
      {
        "x": 62,
        "y": 81
      },
      {
        "x": 40,
        "y": 2
      },
      {
        "x": 37,
        "y": 114
      },
      {
        "x": 29,
        "y": 94
      },
      {
        "x": 96,
        "y": 111
      },
      {
        "x": 82,
        "y": 83
      },
      {
        "x": 71,
        "y": 110
      }
    ]
  },
  {
    "id": "group C",
    "data": [
      {
        "x": 10,
        "y": 71
      },
      {
        "x": 17,
        "y": 64
      },
      {
        "x": 26,
        "y": 85
      },
      {
        "x": 66,
        "y": 44
      },
      {
        "x": 82,
        "y": 35
      },
      {
        "x": 34,
        "y": 107
      },
      {
        "x": 76,
        "y": 40
      },
      {
        "x": 83,
        "y": 78
      },
      {
        "x": 33,
        "y": 91
      },
      {
        "x": 4,
        "y": 5
      },
      {
        "x": 89,
        "y": 79
      },
      {
        "x": 29,
        "y": 82
      },
      {
        "x": 34,
        "y": 117
      },
      {
        "x": 9,
        "y": 101
      },
      {
        "x": 66,
        "y": 12
      },
      {
        "x": 49,
        "y": 50
      },
      {
        "x": 4,
        "y": 74
      },
      {
        "x": 73,
        "y": 95
      },
      {
        "x": 9,
        "y": 16
      },
      {
        "x": 86,
        "y": 12
      },
      {
        "x": 69,
        "y": 36
      },
      {
        "x": 19,
        "y": 76
      },
      {
        "x": 87,
        "y": 34
      },
      {
        "x": 5,
        "y": 1
      },
      {
        "x": 44,
        "y": 75
      },
      {
        "x": 65,
        "y": 71
      },
      {
        "x": 90,
        "y": 55
      },
      {
        "x": 34,
        "y": 70
      },
      {
        "x": 68,
        "y": 1
      },
      {
        "x": 90,
        "y": 93
      },
      {
        "x": 99,
        "y": 16
      },
      {
        "x": 76,
        "y": 36
      },
      {
        "x": 59,
        "y": 7
      },
      {
        "x": 80,
        "y": 57
      },
      {
        "x": 40,
        "y": 84
      },
      {
        "x": 96,
        "y": 3
      },
      {
        "x": 0,
        "y": 6
      },
      {
        "x": 38,
        "y": 85
      },
      {
        "x": 50,
        "y": 110
      },
      {
        "x": 22,
        "y": 69
      },
      {
        "x": 0,
        "y": 95
      },
      {
        "x": 20,
        "y": 43
      },
      {
        "x": 11,
        "y": 57
      },
      {
        "x": 32,
        "y": 41
      },
      {
        "x": 39,
        "y": 88
      },
      {
        "x": 42,
        "y": 19
      },
      {
        "x": 22,
        "y": 8
      },
      {
        "x": 42,
        "y": 23
      },
      {
        "x": 26,
        "y": 85
      },
      {
        "x": 59,
        "y": 90
      }
    ]
  },
  {
    "id": "group D",
    "data": [
      {
        "x": 6,
        "y": 81
      },
      {
        "x": 100,
        "y": 68
      },
      {
        "x": 92,
        "y": 94
      },
      {
        "x": 83,
        "y": 39
      },
      {
        "x": 77,
        "y": 60
      },
      {
        "x": 62,
        "y": 85
      },
      {
        "x": 65,
        "y": 108
      },
      {
        "x": 43,
        "y": 20
      },
      {
        "x": 22,
        "y": 39
      },
      {
        "x": 25,
        "y": 85
      },
      {
        "x": 86,
        "y": 9
      },
      {
        "x": 57,
        "y": 100
      },
      {
        "x": 76,
        "y": 33
      },
      {
        "x": 9,
        "y": 11
      },
      {
        "x": 0,
        "y": 87
      },
      {
        "x": 74,
        "y": 118
      },
      {
        "x": 84,
        "y": 18
      },
      {
        "x": 18,
        "y": 80
      },
      {
        "x": 26,
        "y": 71
      },
      {
        "x": 67,
        "y": 2
      },
      {
        "x": 68,
        "y": 84
      },
      {
        "x": 22,
        "y": 105
      },
      {
        "x": 61,
        "y": 96
      },
      {
        "x": 82,
        "y": 38
      },
      {
        "x": 92,
        "y": 43
      },
      {
        "x": 68,
        "y": 112
      },
      {
        "x": 94,
        "y": 103
      },
      {
        "x": 22,
        "y": 15
      },
      {
        "x": 32,
        "y": 1
      },
      {
        "x": 35,
        "y": 110
      },
      {
        "x": 8,
        "y": 71
      },
      {
        "x": 58,
        "y": 106
      },
      {
        "x": 70,
        "y": 12
      },
      {
        "x": 75,
        "y": 21
      },
      {
        "x": 72,
        "y": 85
      },
      {
        "x": 76,
        "y": 100
      },
      {
        "x": 15,
        "y": 0
      },
      {
        "x": 15,
        "y": 55
      },
      {
        "x": 62,
        "y": 15
      },
      {
        "x": 52,
        "y": 117
      },
      {
        "x": 84,
        "y": 20
      },
      {
        "x": 11,
        "y": 9
      },
      {
        "x": 29,
        "y": 81
      },
      {
        "x": 11,
        "y": 75
      },
      {
        "x": 5,
        "y": 113
      },
      {
        "x": 63,
        "y": 76
      },
      {
        "x": 98,
        "y": 61
      },
      {
        "x": 69,
        "y": 61
      },
      {
        "x": 4,
        "y": 71
      },
      {
        "x": 32,
        "y": 89
      }
    ]
  },
  {
    "id": "group E",
    "data": [
      {
        "x": 21,
        "y": 72
      },
      {
        "x": 59,
        "y": 37
      },
      {
        "x": 5,
        "y": 119
      },
      {
        "x": 32,
        "y": 65
      },
      {
        "x": 49,
        "y": 11
      },
      {
        "x": 96,
        "y": 11
      },
      {
        "x": 38,
        "y": 98
      },
      {
        "x": 58,
        "y": 99
      },
      {
        "x": 64,
        "y": 84
      },
      {
        "x": 98,
        "y": 85
      },
      {
        "x": 63,
        "y": 14
      },
      {
        "x": 37,
        "y": 114
      },
      {
        "x": 82,
        "y": 98
      },
      {
        "x": 59,
        "y": 58
      },
      {
        "x": 34,
        "y": 93
      },
      {
        "x": 25,
        "y": 101
      },
      {
        "x": 10,
        "y": 10
      },
      {
        "x": 81,
        "y": 105
      },
      {
        "x": 50,
        "y": 100
      },
      {
        "x": 51,
        "y": 11
      },
      {
        "x": 50,
        "y": 13
      },
      {
        "x": 69,
        "y": 72
      },
      {
        "x": 19,
        "y": 10
      },
      {
        "x": 35,
        "y": 78
      },
      {
        "x": 16,
        "y": 46
      },
      {
        "x": 3,
        "y": 1
      },
      {
        "x": 38,
        "y": 15
      },
      {
        "x": 74,
        "y": 70
      },
      {
        "x": 92,
        "y": 108
      },
      {
        "x": 56,
        "y": 59
      },
      {
        "x": 70,
        "y": 43
      },
      {
        "x": 66,
        "y": 36
      },
      {
        "x": 6,
        "y": 17
      },
      {
        "x": 50,
        "y": 12
      },
      {
        "x": 47,
        "y": 0
      },
      {
        "x": 79,
        "y": 40
      },
      {
        "x": 96,
        "y": 29
      },
      {
        "x": 100,
        "y": 104
      },
      {
        "x": 7,
        "y": 112
      },
      {
        "x": 25,
        "y": 54
      },
      {
        "x": 7,
        "y": 102
      },
      {
        "x": 46,
        "y": 46
      },
      {
        "x": 86,
        "y": 86
      },
      {
        "x": 67,
        "y": 53
      },
      {
        "x": 29,
        "y": 81
      },
      {
        "x": 14,
        "y": 10
      },
      {
        "x": 27,
        "y": 33
      },
      {
        "x": 60,
        "y": 103
      },
      {
        "x": 20,
        "y": 106
      },
      {
        "x": 14,
        "y": 104
      }
    ]
  }
]
  }

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
 	showScatterPlot = ( data /* see data tab */ ) => {
  		data = this.testData
  		return (
	    <ResponsiveScatterPlot
        data={data}
        margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
        xScale={{ type: 'linear', min: 0, max: 'auto' }}
        xFormat=">-.2f"
        yScale={{ type: 'linear', min: 0, max: 'auto' }}
        yFormat=">-.2f"
        blendMode="multiply"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'weight',
            legendPosition: 'middle',
            legendOffset: 46
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'size',
            legendPosition: 'middle',
            legendOffset: -60
        }}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 130,
                translateY: 0,
                itemWidth: 100,
                itemHeight: 12,
                itemsSpacing: 5,
                itemDirection: 'left-to-right',
                symbolSize: 12,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
		)
	}
  
  render(){
    return  (
		<div style={{height:"500px"}}>
    		{this.showScatterPlot(this.props.data)}
    	</div>
	)
  }
}

export default C18ScatterPlot;
 
