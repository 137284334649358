import React from 'react';
import C18Anchor00 from './C18Anchor00'
import {cl} from '../../components/utils/utils';

class C18SubMenuHeader00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
    }
  }
  
  onChange=(e,type,vals)=>{
    e.preventDefault()
    this.props.parms.onChange({pageType:type})//cmd:"idoserPage",
//     cl(type)
    
  }
  
  showItems=()=>{
//     cl(this.props)
    return this.props.parms.items.map((item,i)=>{
      let active=(item.v==this.props.parms.pageType || item.e==this.props.parms.pageType || item.l==this.props.parms.pageType)?"active":""
      if(item.d){}else{
        return(
          <C18Anchor00 to="" key={i} className={active} disabled
            onClick={ev=>{this.onChange(ev,item.v)}}
          >{item.t}</C18Anchor00>
        )
      }
    })
  }
  
  
  render(){
    
    return(
        <nav className="subnav floatleft">
          {this.showItems()}
        </nav>
    )
  }
}
      
export default C18SubMenuHeader00;
