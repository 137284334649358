import React from 'react';
import C18Input00 from './C18Input00'
import C18Select00 from './C18Select00'
import C18Button00 from './C18Button00'
import IconButton from '@material-ui/core/IconButton';
import Camera from '@material-ui/icons/Camera';
import {loadSitesInfo,getSiteInfo,getSiteIndex,getZoneIndex,loadZonesInfo,addToAdminLog} from './C18utils'
import {wsTrans, doGetPostBasic} from '../utils/utils'
import {cl, globs, constant, getRandomString,getTime} from '../../components/utils/utils';
import history from "../../history"
import C18Confirm00 from './C18Confirm00'

class C18ManageSites extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      siteAddName:"",
      update:0,
      popup:{
        opacity:0,
        text:"Are you sure?",
        buttons:["Close"],
        resolve:null,
      },
    }
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveSites)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.loadInfo()
    this.setBreadCrumbs()
  }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
  }
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Sites", url:`/usa/c18/admin/manageSites2`},
            ]},
        },
      )
    }
  }
  
  loadInfo=async()=>{
    await loadSitesInfo()
    this.saveSitesInfo(false)
    await loadZonesInfo()
    let gsi=this.sitesInfo
    let siteSel=(gsi[0]||{}).siteId
    let site=gsi[0]||{}
//     cl(site)
    this.setState({loaded:true, siteSel:siteSel, weatherStation: site.weatherStation, siteImage:site.siteImage})
  }
  
  saveSiteInfoToDb=async(siteId,siteInfo)=>{
    await wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "update", 
      sessionId: globs.userData.session.sessionId, body: {
        accountId:siteInfo.accountId,
        siteId:siteId,
        name:siteInfo.name,
        weatherStation:siteInfo.weatherStation,
        siteImage:siteInfo.siteImage,
      }})
    globs.events.publish("saveOK",true)
    
//     cl(siteInfo)
  }
  
  saveToAdminLog=(adds,o,n)=>{
//     cl(o)
//     cl(n)
    let addObj={
      userId:globs.userData.session.userId,
      siteId:o.siteId,
      time:Math.floor(getTime())
    }
    if (o.type == "addSite" || o.type == "deleteSite") {
      adds.push(
        Object.assign({},addObj,
        {
        action: o.type,
        oldVal: "",
        newVal: n,
      }))
      return
    }
    if(o.name!=n.name){
      adds.push(
        Object.assign({},addObj,
        {
        action:"siteName",
        oldVal:o.name,
        newVal:n.name,
      }))
    }
    if(o.weatherStation!=n.weatherStation){
      cl([o.weatherStation, n.weatherStation])
      adds.push(
        Object.assign({},addObj,
        {
        action:"wStation",
        oldVal:globs.zonesInfo.info[getZoneIndex(o.weatherStation)].zoneName,
        newVal:globs.zonesInfo.info[getZoneIndex(n.weatherStation)].zoneName,
      }))
      // adds.push(
      //   Object.assign({},addObj,
      //   {
      //   action:"wStation",
      //   oldVal:o.weatherStation,
      //   zoneId:n.weatherStation,
      // }))
    }
    if(o.siteImage!=n.siteImage){
      adds.push(
        Object.assign({},addObj,
        {
        action:"siteImage",
        oldVal:o.siteImage,
        newVal:n.siteImage,
      }))
    }
  }
  
  saveSitesInfo=(doSave)=>{// do Save, means save to db, not means copy current settings
    if(!doSave){this.sitesInfo=[]}
    let gsi=globs.sitesInfo.info
    let adminAdds=[]
    for(let i=0;i<gsi.length;i++){
      let s=this.sitesInfo[i]
      if(doSave){
//         cl(s.weatherStation)
//         cl(gsi[i])
//         cl(globs.zonesInfo.info[getZoneIndex(s.weatherStation)])
        if((gsi[i].name!=s.name)||
          (gsi[i].weatherStation!=s.weatherStation)||
          (gsi[i].siteImage!=s.siteImage)){
            this.saveSiteInfoToDb(gsi[i].siteId,s)
            this.saveToAdminLog(adminAdds,gsi[i],s)
            gsi[i].name=s.name
            gsi[i].weatherStation=s.weatherStation
            gsi[i].siteImage=s.siteImage
//             cl(`update ${gsi[i].name}`)
          }
      }else{
        let s=gsi[i]
        let ws = s.weatherStation
        if (!ws) {
          // set weather station to first matching zone if none assigned
          for (let j = 0; j < globs.zonesInfo.info.length; j++) {
            if(globs.zonesInfo.info[j].siteId==s.siteId){
              ws = globs.zonesInfo.info[j].zoneId
              gsi[i].weatherStation = ws
              break
            }
          }
        }
        this.sitesInfo.push({siteId:s.siteId,name:s.name,weatherStation:ws,siteImage:s.siteImage})
      }
    }
//     cl(adminAdds)
    if(doSave){addToAdminLog(adminAdds)}
  }
  
  saveSites=(cmd)=>{
    if(cmd=="save"){
      this.saveSitesInfo(true)
    }
//     history.goBack()
  }
  
  showSitesOpts=()=>{
    return this.sitesInfo.map((s,i)=>{
      return(
        <option key={i} value={s.siteId}>{s.name}</option>
      )
    })
  }
  
  showZoneOpts=()=>{
//     cl(globs.zonesInfo.info)
//     cl(this.state.siteSel)
    return globs.zonesInfo.info.map((z,i)=>{
      if(z.siteId==this.state.siteSel){
        return(
          <option key={i} value={z.zoneId}>{z.zoneName}</option>
        )
      }
    })
  }
  
  setSiteName=(siteId, siteName)=>{
    this.sitesInfo[getSiteIndex(siteId)].name=siteName
//     let gsi=this.sitesInfo
//     for(let i=0;i<gsi.length;i++){
//       if(gsi[i].siteId==siteId){
//         gsi[i].name=siteName
//         return
//       }
//     }
  }
  
  addSite=()=>{
    if(this.state.siteAddName){
      cl(this.state.siteAddName)
      let siteId=getRandomString(16)
      let s={
        accountId:globs.userData.session.accountId,
        siteId:siteId,
        name:this.state.siteAddName,
      }
      globs.sitesInfo.info.push(s)
      this.sitesInfo.push(s)
//       cl(s)
      this.saveSiteInfoToDb(s.siteId,s)
      this.mySetState({siteSel:siteId,update:this.state.update+1})

      let adminAdds = []
      this.saveToAdminLog(adminAdds, {siteID: siteId, type: "addSite"}, this.state.siteAddName)
      cl(adminAdds)
      addToAdminLog(adminAdds)
    }
  }
  
  deleteSite=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this site?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let gsi=this.sitesInfo
      let siteId=this.state.siteSel
      let index=getSiteIndex(siteId)
      let name = gsi[index].name
      globs.sitesInfo.info.splice(index,1)
      gsi.splice(index,1)
      cl([siteId, name])
      
      wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "delete", 
        sessionId: globs.userData.session.sessionId, body: {
          siteId: siteId,
        }})

      let adminAdds = []
      this.saveToAdminLog(adminAdds, {siteId: siteId, type: "deleteSite"}, name)
      addToAdminLog(adminAdds)

      let siteSel=gsi[0].siteId
      this.setState({siteSel:siteSel})
    }
  }
  
  mySetState=(vals)=>{
    this.setState(vals)
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "siteSel":
        this.mySetState(vals)
        break
      case "siteName":
        globs.events.publish("savePageEnable",true)
        this.setSiteName(this.state.siteSel,vals.siteName)
        this.mySetState(vals)
        break
      case "weatherStation":
        cl(vals)
        globs.events.publish("savePageEnable",true)
//         this.setSiteName(this.state.siteSel,vals.siteName)
        Object.assign(this.sitesInfo[getSiteIndex(this.state.siteSel)],vals)
//         cl(this.sitesInfo)
        this.mySetState(vals)
        break
      case "siteImage":
        globs.events.publish("savePageEnable",true)
//         this.setSiteName(this.state.siteSel,vals.siteName)
        Object.assign(this.sitesInfo[getSiteIndex(this.state.siteSel)],vals)
        cl(this.sitesInfo)
        this.mySetState(vals)
        break
      case "siteAddName":
        this.mySetState(vals)
        break
      case "addSite":
        this.addSite()
        break
      case "deleteSite":
        this.deleteSite()
        break
      default:
        break
    }
  }
  
  showSelectSite=()=>{
    return(
      <div className="custom-select">
        <label htmlFor="">Select Site</label>
        
        <C18Select00 id=""
          parms={{list:true}}
          value={this.state.siteSel}
          onChange={e=>this.onChange("siteSel",{siteSel: e.currentTarget.value})}
        >
          {this.showSitesOpts()}
        </C18Select00>
        {false&&
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        }
      </div> 
    )
  }
  
  showSelectWeatherStation=()=>{
//     cl(this.sitesInfo)
    let index=getSiteIndex(this.state.siteSel)
    let gsi=globs.sitesInfo.info
    let siteInfo=this.sitesInfo[index]||{}
    let weatherStation=siteInfo.weatherStation
//     cl(weatherStation)
    return(
      <div className="custom-select">
        <label htmlFor="">Weather Station</label>
        <C18Select00 id=""
          value={weatherStation}
          onChange={e=>this.onChange("weatherStation",{weatherStation: e.currentTarget.value})}
        >
          {this.showZoneOpts()}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div>
      
    )
  }

  resolvePopup=(e,resolve)=>{
    //     cl(e)
        let popup=this.state.popup
        popup.opacity=0
        this.setState({popup:popup})
        if(resolve){resolve(e)}
        cl(this.state.popup)
  }
  
  markImage=async(e)=>{
    
    cl(e.target.files)
    let files=e.target.files[0]
    
    if ((e.target.files[e.target.files.length - 1].size > (1e6 * 2) ) || (!e.target.files[e.target.files.length - 1].type.startsWith("image/"))) {
      cl(this.state.popup)
      return new Promise((r,e)=>{
        let popup={
          opacity:1,
          text:"File must be an image and smaller than 2MB",
          buttons:["Close"],
          resolve:e=>this.resolvePopup(e,r),
        }
      this.setState({popup:popup})
      })
    } 

    let imageId= getRandomString(16)
    let ext=files.name.substr(files.name.lastIndexOf('.')+1)
    let data = new FormData()
    data.append("type", "site")
    data.append("sessionId", globs.userData.session.sessionId)
    data.append('file', files)
    data.append('id', imageId)
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
    cl("post image")
    let ret=await doGetPostBasic(url, method, data, type)
    let ret2 = await ret.json()
    this.onChange("siteImage",{siteImage: `${imageId}.${ext}`})
    
    this.setState({siteImage: `${imageId}.${ext}`})
  }
  

  
  showSiteImage=(siteImage)=>{
//     cl(this.state.avatar)
    // let av=this.state.avatar
//       <div>
//         <IconButton
//           variant="contained"
//           component="label"
//           title="Upload Image"
//         >
//           <Camera/>
//           <C18Input00 hidden multiple type="file" onChange={this.markImage}/>
//         </IconButton>
//       </div>
    var path
//     cl(siteImage);
    if(siteImage){
      let si=siteImage.split('.')[0];
      let ext=siteImage.split('.')[1];
      path=`${constant.expressUrl}/usa/images/site/uploads/${si[0]}/${si[1]}/${si[2]}/${si.substr(3)}.${ext}`
    }else{
      path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
    }
    return(
      <div>
      <div style={{height: 20}}/>
      
      <h3 style={{display: "inline-block"}}>Site Image</h3>
      <div/>
      <img width="240" src={path} style={{padding: 10}}/>
      </div>
    )
  }
  
  render(){
    if(this.state.loaded){
//       cl(this.state.siteSel)
      let index=getSiteIndex(this.state.siteSel)
      let siteInfo=this.sitesInfo[index]||{}
//       let siteInfo=getSiteInfo(this.state.siteSel)
      let siteName=siteInfo.name
      let siteImage=siteInfo.siteImage
//       cl(siteName)
      return(
        <div>
          <C18Confirm00 parms={this.state.popup}/>
          {this.showSelectSite()}
          <div>
            <C18Button00 type="button" className="danger" onClick={()=>this.onChange("deleteSite")}>Delete</C18Button00>
          </div>
          
          <p>Site ID: {this.state.siteSel}</p>
          <label htmlFor="site-name">Site Name</label>
          <C18Input00 type="text" id="site-name" 
            value={siteName}
            maxLength="50"
            onChange={e=>this.onChange("siteName",{siteName:e.currentTarget.value})}
          />

          <br />
          {this.showSelectWeatherStation()}

          <br />
          
          <C18Button00 type="button" className="icon" style={{
            width:200, 
            height:40, 
          }}>
            Upload Site Image
            <span className="material-icons-outlined">
              upload_file
            </span>
          </C18Button00>
          
          <form>
          <C18Input00 type="file"  onChange={this.markImage} style={{
            position:"absolute", 
            width:200, 
            height:40, 
            marginTop:-40, 
            zIndex:10,                   
            opacity:0,
            cursor: "pointer",
          }}/>
          </form>
          
          <p>File must be an image and smaller than 2MB.</p>
          {this.showSiteImage(siteImage)}
          <p>Add Site</p>
          <label htmlFor="site-name">Site Name</label>
          <C18Input00 type="text" id="site-add-name" className="with-right-button" 
            value={this.state.siteAddName}
            maxLength="50"
            onChange={e=>this.onChange("siteAddName",{siteAddName:e.currentTarget.value})}
          />
          <C18Button00 type="button" className="outlined"
            onClick={e=>this.onChange("addSite")}>Add Site</C18Button00>
        </div>
      )
      
    }else{
      return <div>loading. . .</div>
    }
//       let zoneInfo=getZoneInfo(this.state.zoneSel)
  }
}
      
export default C18ManageSites;
