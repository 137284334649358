var git=require('../../generatedGitInfo.json')

var g = {
  host: "prodS",
  server: "prodS",
}

var cl=console.log

var initConfig=()=>{
  let hosts={
    prod0:"prod0",
    prod0Test:"prod0Test",
    prodS:"prod0Test",
    prodX:"prodX",
    dev:"sg",
    ryan:"sg_ryan",
    wayne:"wayne",
    stage:"sg_stage",
    alpha:"sg_alpha",
    demo:"demo",
  }
  let server=sessionStorage.getItem("serverOverride")
  if(server){
    g.server=server
    g.host=hosts[server]
  }
}

initConfig()
cl(g)
// var setHost=()=>{
//   switch(git.gitBranch){
//     case "prodS":
//       g={host:"prodS",server:"prodS"}
//       break
//     case "prodx_safe":
//       g={host:"prodX",server:"prodX"}
//       break
//     case "prodTest_safe":
//       g={host:"prod0Test",server:"prod0Test"}
//       break
//     case "prod2":
//       g={host:"prod0",server:"prod0"}
//       break
//     default:
//       break
//   }
// }
//
// setHost()

module.exports = g
