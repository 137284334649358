var deq={
    "none": {},
    "onoff": {
        "activeCool_cool3": 0,
        "activeCool_heat6": 0,
        "cycleOffPeriod": 0,
        "cycleOffPeriodHr": 0,
        "cycleOffPeriodMn": 0,
        "cycleOffPeriodSc": 0,

        "activeCool_heat5": 0,
        "activeCool_heat4": 0,
        "activeCool_cool5": 0,
        "CD_heat_6": 0,
        "CD_heat_5": 0,
        "CD_heat_4": 0,
        "CD_heat_3": 0,
        "CD_heat_2": 0,
        "CD_heat_1": 0,
        "CD_cool_1": 0,
        "CD_cool_2": 0,
        "CD_cool_3": 0,
        "CD_cool_4": 0,
        "CD_cool_5": 0,
        "CD_cool_6": 0,

        "CD_heat6_onoff": 0,
        "CD_heat5_onoff": 0,
        "CD_heat4_onoff": 0,
        "CD_heat3_onoff": 0,
        "CD_heat2_onoff": 0,
        "CD_heat1_onoff": 0,
        "CD_cool1_onoff": 0,
        "CD_cool2_onoff": 0,
        "CD_cool3_onoff": 0,
        "CD_cool4_onoff": 1,
        "CD_cool5_onoff": 1,
        "CD_cool6_onoff": 1,
        "activeCool_cool6": 0,
        "activeCool_cool4": 0,
        "CD_normal_onoff": 0,
        "isCycling": 0,
        "activeCool_cool2": 0,
        "enableActiveCoolStageOverride": 0,
        "activeCool_cool1": 0,
        "activeCool_normal": 0,
        "cycleOnPeriod": 0,
        "cycleOnPeriodHr": 0,
        "cycleOnPeriodMn": 0,
        "cycleOnPeriodSc": 0,

        "activeCool_heat3": 0,
        "activeCool_heat2": 0,
        "pump_onoff": 0,
        "activeCool_heat1": 0,
    },

    "irr": {
        "week_a_mon": 1,
        "week_a_wed": 1,
        "tankSensor": 255,
        "on_duration": 0,
        "week_b_thu": 1,
        "start_time_5": 1440,
        "week_a_sun": 1,
        "week_b_sat": 1,
        "week_a_fri": 1,
        "week_b_fri": 1,
        "week_b_wed": 1,
        "start_time_3": 1440,
        "week_b_sun": 1,
        "week_a_thu": 1,
        "week_a_sat": 1,
        "irrigation_mode": 0,
        "start_time_6": 1440,
        "start_time_4": 1440,
        "start_time_2": 1440,
        "start_time_1": 1440,
        "week_b_mon": 1,
        "week_b_tue": 1,
        "week_a_tue": 1,
        "off_duration": 180,
        "start_astroadjust": 0,
        "start": 0,
        "end_astroadjust": 0,
        "end": 0,
        "setting_1_threshold_light": 0,
        "setting_1_threshold_temp": 40,
        "setting_2_threshold_light": 0,
        "setting_2_threshold_temp": 40,
        "soil_moisture_input": 0,
        "soil_moisture_threshold": 0,
        "vpdacc_threshold": 0
    },

    "co2": {
        "CD_end_astroadjust_co2": 0,
        "CD_start_astroadjust_co2": 0,
        "start_time": 0,
        "stage": 6,
        "light_level": 0,
        "end_time": 0,
        "normal_co2_level": 0,
        "increased_co2_level": 0
    },

    "light": {
        "end_astroadjust": 0,
        "start_astroadjust": 0,
        "off_threshold": 25,
        "light_sensor_enabled": 0,
        "off_delay": 30,
        "start": 1020,
        "end": 1260,
        "light_mode": 0,
        "on_threshold": 20,
        "start_time_1": 1440,
        "start_time_2": 1440,
        "start_time_3": 1440,
        "start_time_4": 1440,
        "start_time_5": 1440,
        "start_time_6": 1440,
        "week_a_sun": 1,
        "week_a_mon": 1,
        "week_a_tue": 1,
        "week_a_wed": 1,
        "week_a_thu": 1,
        "week_a_fri": 1,
        "week_a_sat": 1,
        "week_b_sun": 1,
        "week_b_mon": 1,
        "week_b_tue": 1,
        "week_b_wed": 1,
        "week_b_thu": 1,
        "week_b_fri": 1,
        "week_b_sat": 1,
        "dliSensor": 21,
        "dliThreshold": 11.0,
        "dliStartTime": 360,
        "dliOnTime": 900,
        "dliEndTime": 1140,
        "dliEnergyOutput": 1000.0
    },

    "pump": {
        "smartInject": [
          {
            "difference": 0,
            "ratio": 1.0
          },
          {
            "difference": 0,
            "ratio": 1.0
          },
          {
            "difference": 0,
            "ratio": 1.0
          },
          {
            "difference": 0,
            "ratio": 1.0
          },
          {
            "difference": 0,
            "ratio": 10.0
          }
        ],
        "triggers": [
          {
            "index": 0,
            "type": 0,
            "volume": 0
          },
          {
            "index": 0,
            "type": 0,
            "volume": 0
          },
          {
            "index": 0,
            "type": 0,
            "volume": 0
          }
        ],
        "injectingLimitHours": 0,
        "triggerDelay": 0,
        "pulse_ff_time": 0,//None,
        "probeType": 0,
        "resetInjectionTracker": 0,
        "activeTrigger": 3,
        "injectionRate": 300,
        "pulse_on_time": 0,//None,
        "mixingTimeSeconds": 30,
        "offDelay": 0,
        "threshold": 1,
        "injectingVolumeMilliLiters": 30,
        "pumpMeasurementUnits": 0,
        "followChannelIndex": 0,
        "probeSensorIndex": 0,
        "injectingTimeSeconds": 5,
        "CD_sensorHoldTime": 3,
        "pump_type": 1,
        "feedingMode": 0,
        "injectingLimitSeconds": 0,
        "injectingLimitMilliLiters": 0,
        "dir": 1,
        "CD_light_threshold": 9999,
        "CD_temp_sensor": 255,
        "enableSmartInject": 0,
        "probe_id": 0,//None,
        "pump_id": 1,
        "CD_temp_threshold": 100.0,
        "setpoint": 0.0,
        "deadband": 0.0,
        "injectingBatchTimeSeconds": 0.0,
        "injectingBatchVolumeMilliLiters": 0.0,
        "tankSize": 0.0,
        "followRatio": 1.0,
        "CD_light_drive_to": 0.0,
        "CD_temp_drive_to": 0.0
    },

    "fval": {
        "triggers": [
          {
            "waterVolume": 0,
            "endTime": 0,
            "startIndex": 0,
            "endType": 3,
            "endIndex": 0,
            "startType": 0
          },
          {
            "waterVolume": 0,
            "endTime": 0,
            "startIndex": 0,
            "endType": 3,
            "endIndex": 0,
            "startType": 0
          },
          {
            "waterVolume": 0,
            "endTime": 0,
            "startIndex": 0,
            "endType": 3,
            "endIndex": 0,
            "startType": 0
          }
        ],
        "tankSensor": 255,
        "activeTrigger": 3
    },
    "mzon": {
        "nighttime_stage": 12,
        "daytime_astroadjust": 0,
        "nighttime_astroadjust": 0,
        "daytime_start": 0,
        "daytime_stage": 12,
        "daytime_enabled": 0,
        "mode": 0,
        "nighttime_enabled": 0,
        "nighttime_start": 0,
        "sensor": 0,
        "nighttime_setpoint": 68.0,
        "daytime_setpoint": 68.0,
        "pump": 0
    },
    "vent": {
        "hgPcnt_3": 0,
        "acPcnt_4": 0,
        "begin_closing_leeward_trigger_end": 40,
        "acPcnt_1": 0,
        "begin_closing_windward_trigger_start": 15,
        "time": 120,
        "hgPcnt_2": 0,
        "cool_3": 0,
        "max_open_rain": 0,
        "thgPcnt_2": 0,
        "begin_closing_leeward_trigger_start": 15,
        "rtPcnt_4": 0,
        "activeCool_cool5": 0,
        "rtHum_4": 0,
        "rtHum_1": 0,
        "rtPcnt_3": 0,
        "rtHum_3": 0,
        "rtHum_2": 0,
        "rtPcnt_1": 0,
        "thgPcnt_4": 0,
        "max_open": 0,
        "activeCool_normal": 0,
        "activeCool_cool1": 0,
        "activeCool_cool3": 0,
        "activeCool_cool6": 0,
        "thgPcnt_3": 0,
        "vps_sensor": 5,
        "vps_alarm_limit": 25,
        "hgPcnt_4": 0,
        "normal": 0,
        "activeCool_cool2": 0,
        "open_var": 99,
        "cool_1": 0,
        "direction": 0,
        "closing_delay": 0,
        "rtPcnt_2": 0,
        "cool_6": 0,
        "thgPcnt_1": 0,
        "vps_error_exception_limit": 50,
        "hgPcnt_1": 0,
        "acPcnt_3": 0,
        "acPcnt_2": 0,
        "temp_var": 0,
        "activeCool_cool4": 0,
        "cool_2": 0,
        "cool_5": 0,
        "cool_4": 0,
        "vps_alarm_duration": 30,
        "vent_type": 0,
        "begin_closing_windward_trigger_end": 30,
        "acTemp_3": 0.0,
        "hgTemp_3": 0.0,
        "hgTemp_4": 0.0,
        "hgTemp_1": 0.0,
        "acTemp_2": 0.0,
        "rhgTemp_4": 0.0,
        "acTemp_4": 0.0,
        "hgTemp_2": 0.0,
        "temp_below_trigger": 0.0,
        "acTemp_1": 0.0,
        "rhgTemp_2": 0.0,
        "rhgTemp_3": 0.0,
        "rhgTemp_1": 0.0,
        "autoCalibration": false
    },
    "vent2": {
        "hgPcnt_3": 0,
        "acDoDelay": 0,
        "acPcnt_4": 0,
        "hailDelay": 0,
        "firePos": 0,
        "rtDeadband": 0,
        "rhgDoDelay": 0,
        "rtPcnt_4": 0,
        "thgPcnt_2": 0,
        "rtHum_1": 0,
        "rtHum_3": 0,
        "rtHum_2": 0,
        "rtPcnt_1": 0,
        "thgPcnt_4": 0,
        "rhgUpDelay": 0,
        "rtPcnt_3": 0,
        "thgPcnt_3": 0,
        "rtDoDelay": 0,
        "thgPcnt_1": 0,
        "cp3Wind": 0,
        "cp2Pos": 0,
        "cpPos": 0,
        "cpWind": 0,
        "spDelay": 0,
        "fireDelay": 0,
        "spWind": 0,
        "cp2Delay": 0,
        "rtHum_4": 0,
        "fireIn": 14,
        "cp2Wind": 0,
        "hailPos": 0,
        "rtPcnt_2": 0,
        "cpDelay": 0,
        "cp3Delay": 0,
        "spPos": 0,
        "hgPcnt_1": 0,
        "hgUpDelay": 0,
        "acPcnt_3": 0,
        "rtUpDelay": 0,
        "acPcnt_2": 0,
        "cp3Pos": 0,
        "hgPcnt_4": 0,
        "acPcnt_1": 0,
        "acUpDelay": 0,
        "hgDoDelay": 0,
        "hgPcnt_2": 0,
        "acTemp_3": 0.0,
        "hgTemp_3": 0.0,
        "acTemp_4": 0.0,
        "hgTemp_1": 0.0,
        "acTemp_2": 0.0,
        "rhgTemp_4": 0.0,
        "rhgDeadband": 0.0,
        "rhgTemp_1": 0.0,
        "hgTemp_2": 0.0,
        "hgDeadband": 0.0,
        "hailThresh": 0.0,
        "acTemp_1": 0.0,
        "rhgTemp_2": 0.0,
        "rhgTemp_3": 0.0,
        "acDeadband": 0.0,
        "hgTemp_4": 0.0
    },
    "curt": {
        "shade_end": 0,
        "energy_start_astroadjust": 0,
        "energy_start": 0,
        "highlight_cover_trigger_light": 500,
        "low_light_threshold_light": 100,
        "keep_open_threshold_light": 200,
        "advanced_close_perc": 99,
        "shade_start": 0,
        "shade_start_astroadjust": 0,
        "energy_end_astroadjust": 0,
        "shade_end_astroadjust": 0,
        "advanced_time": 0,
        "low_light_threshold_duration": 20,
        "advanced_stage": 12,
        "energy_end": 0,
        "highlight_cover_perc": 0,
        "advanced_shock_threshold": 40.0,
        "highlight_cover_trigger_temp": 105.0,
        "keep_open_threshold_temp": 70.0
    },
 "mixv": {
        "passive_cooling": 0,
        "shock_protect_open_valve_perc": 0,
        "valve_open_time": 0,
        "supply_pump": 0,
        "shock_protect_water_sensor": 0,
        "heat_on_delay": 0,
        "shock_protect_enabled": 0,
        "cooling_device": 0,
        "air_temp_sensor": 0,
        "water_temp_sensor": 5,
        "disable_inside_temp_pid": 0,
        "inside_temp_d": 0.4,
        "water_temp_p": 2.0,
        "water_temp_i": 0.05,
        "water_temp_max": 120.0,
        "water_temp_min": 45.0,
        "inside_temp_p": 1.0,
        "shock_protect_threshold": 140.0,
        "water_temp_d": 0.0,
        "fine_adjust_range": 80.0,
        "inside_temp_i": 0.4,
        "shock_protect_time_closed": 0,
        "air_heat_setpoint": 68.0,
        "air_cool_setpoint": 72.0,
        "analog_min": 0,
        "analog_max": 10
    },
 "mixva": {
        "passive_cooling": 0,
        "shock_protect_open_valve_perc": 0,
        "valve_open_time": 0,
        "supply_pump": 0,
        "shock_protect_water_sensor": 0,
        "heat_on_delay": 0,
        "shock_protect_enabled": 0,
        "cooling_device": 0,
        "air_temp_sensor": 0,
        "water_temp_sensor": 5,
        "disable_inside_temp_pid": 0,
        "inside_temp_d": 0.4,
        "water_temp_p": 2.0,
        "water_temp_i": 0.05,
        "water_temp_max": 120.0,
        "water_temp_min": 45.0,
        "inside_temp_p": 1.0,
        "shock_protect_threshold": 140.0,
        "water_temp_d": 0.0,
        "fine_adjust_range": 80.0,
        "inside_temp_i": 0.4,
        "shock_protect_time_closed": 0,
        "air_heat_setpoint": 68.0,
        "air_cool_setpoint": 72.0,
        "analog_min": 0,
        "analog_max": 10
    },
    "pmz": {
        "shock_protect_enabled": 0,
        "shock_protect_open_valve_perc": 0,
        "valve_open_time": 0,
        "supply_pump": 0,
        "shock_protect_water_sensor": 0,
        "heat_on_delay": 0,
        "cooling_device": 0,
        "air_temp_sensor": 0,
        "water_temp_sensor": 5,
        "disable_inside_temp_pid": 0,
        "inside_temp_d": 0.4,
        "air_cool_setpoint": 72.0,
        "water_temp_min": 45.0,
        "water_temp_max": 120.0,
        "water_temp_p": 2.0,
        "inside_temp_p": 1.0,
        "water_temp_i": 0.05,
        "water_temp_d": 0.0,
        "shock_protect_threshold": 140.0,
        "air_heat_setpoint": 68.0,
        "fine_adjust_range": 100.0,
        "inside_temp_i": 0.4,
        "shock_protect_time_closed": 0,
        "analog_min": 0.0,
        "analog_max": 10.0,
        "passive_cooling": 0
    },
    "gpid": {
        "time": 0,
        "override_dir": 2,
        "supply_pump": 0,
        "hold_delay": 0,
        "override_sensor": 64,
        "override_action_target": 1,
        "override_limit": 0,
        "output_type": 0,
        "sensor": 0,
        "override_trigger": 0.0,
        "setpoint": 0.0,
        "P": 1.0,
        "analog_max": 0.0,
        "I": 0.4,
        "analog_min": 0.0,
        "D": 0.4
    },
    "varout":{}// not included in LinkConn
}

export {deq}
