import React from 'react';

class C18ModbusDevicesEdit00 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div>
                Hello
            </div>
        )
    }
}

export default C18ModbusDevicesEdit00;
