import React from 'react';
import {cl} from '../../components/utils/utils';
import { ResponsiveBar } from '@nivo/bar'

class C18Bar extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    this.state={}
  }

  valueFormat = (v) => {
  	// get unit
  	cl(v)
  	return `${v}`
  }

  formatXTicks = (v) => {

  	return v.length > 10 ? (
        <tspan>
        {v.substring(0, 10) + "..."}
        <title>{v}</title>
        </tspan>
    ) : (
        <title>{v}</title>
    );
  }

  formatTooltip = (v) => {
  	return (
			<div style={{background: "white", color: "inherit", fontSize: "inherit", borderRadius: "2px", boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px", padding: "5px 9px"}}>
  			<div style={{whiteSpace: "pre", display: "flex", alignItems: "center"}}>
			  	<span style={{display: "block", width: "12px", height: "12px", background: v.color, marginRight: "7px"}}></span>
			  	<span>{`${v.label}:`} <strong>{`${v.value}${(v.id != "Count") ? v.data.unit : ""}`}</strong></span>
				</div>
			</div>
		)
  	// return (
  	// 	<div style={{pointerEvents: "none", position:"absolute", zIndex: 10, top: "0px", left: "0px", transform: `translate(${v.x}px, ${v.y}px)`}}>
  	// 		<div style={{background: "white", color: "inherit", fontSize: "inherit", borderRadius: "2px", boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px", padding: "5px 9px"}}>
	  // 			<div style={{whiteSpace: "pre", display: "flex", alignItems: "center"}}>
		// 		  	<span style={{display: "block", width: "12px", height: "12px", background: "rgb(244, 117, 96)", marginRight: "7px"}}></span>
		// 		  	<span>TEST</span>
		// 			</div>
		// 		</div>
		// 	</div>
		// )
  }

  formatBarAriaLabel = (v) => {
  	return v
  }

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
 	showResponsiveBar = ( data /* see data tab */ ) => {
  		// split into multiple bar graphs if necessary
  		cl(data)
  		if (data[0]) {
	  		let aggregates = Object.entries(data[0].aggregates).map(([k, v], i)=>{
	    			return k
  			})
  			// let units = Object.entries(data[0].aggregates).map(([k, v], i)=>{
	    	// 		return k
  			// })
	  		data.forEach((d)=>{
	  			Object.assign(d, d.aggregates)
	  		})
	  		cl(aggregates)
	  		cl(data)
	  		return (
		    <ResponsiveBar
		        data={data}
		        keys={aggregates}
		        groupMode="grouped"
		        layout="vertical"
		        valueFormat={this.valueFormat}
		        indexBy="fullSensorName"
		        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
		        padding={0.3}
		        valueScale={{ type: 'linear' }}
		        indexScale={{ type: 'band', round: true }}
		        colors={{ scheme: 'nivo' }}
		        defs={[
		            {
		                id: 'dots',
		                type: 'patternDots',
		                background: 'inherit',
		                color: '#38bcb2',
		                size: 4,
		                padding: 1,
		                stagger: true
		            },
		            {
		                id: 'lines',
		                type: 'patternLines',
		                background: 'inherit',
		                color: '#eed312',
		                rotation: -45,
		                lineWidth: 6,
		                spacing: 10
		            }
		        ]}
		        // fill={[
		        //     {
		        //         match: {
		        //             id: 'Max'
		        //         },
		        //         id: 'dots'
		        //     },
		        //     {
		        //         match: {
		        //             id: 'Min'
		        //         },
		        //         id: 'lines'
		        //     }
		        // ]}
		        borderColor={{
		            from: 'color',
		            modifiers: [
		                [
		                    'darker',
		                    1.6
		                ]
		            ]
		        }}
		        axisTop={null}
		        axisRight={null}
		        axisBottom={{
		            tickSize: 5,
		            tickPadding: 5,
		            tickRotation: 0,
		            legendPosition: 'middle',
		            legendOffset: 32,
		            format: (v) => this.formatXTicks(v)
		        }}
		        axisLeft={{
		            tickSize: 5,
		            tickPadding: 5,
		            tickRotation: 0,
		            legendPosition: 'middle',
		            legendOffset: -40
		        }}
		        labelSkipWidth={12}
		        labelSkipHeight={12}
		        labelTextColor={{
		            from: 'color',
		            modifiers: [
		                [
		                    'darker',
		                    '1.7'
		                ]
		            ]
		        }}
		        legends={[
		            {
		                dataFrom: 'keys',
		                anchor: 'bottom-right',
		                direction: 'column',
		                justify: false,
		                translateX: 120,
		                translateY: 0,
		                itemsSpacing: 2,
		                itemWidth: 100,
		                itemHeight: 20,
		                itemDirection: 'left-to-right',
		                itemOpacity: 0.85,
		                symbolSize: 20,
		                effects: [
		                    {
		                        on: 'hover',
		                        style: {
		                            itemOpacity: 1
		                        }
		                    }
		                ]
		            }
		        ]}
		        tooltip={(v) => this.formatTooltip(v)}
		        role="application"
		        ariaLabel="C18 Bar Chart"
		        barAriaLabel={function(e){return `${e.id} - ${e.indexValue}: ${e.formattedValue}`}}
		    />
			)
		}
	}
  
  render(){
    return  (
		<div style={{height:"500px"}}>
    		{this.showResponsiveBar(this.props.data)}
    	</div>
	)
  }
}

export default C18Bar;
 
