import React from 'react';
// import VentPos from './VentPos'
// import CropProtection from './CropProtection'
// import StructuralProtection from './StructuralProtection'
// import CravoSetpoint0 from './CravoSetpoint0'
import SaveCancel from './SaveCancel'
import {p, pi, pInd} from '../../components/utils/paramIds';
import {cl, globs, constant, leadZeros, getTime,getTimeI} from '../../components/utils/utils';
import {getParamId,getPearlUsed} from '../../usa/utils/utils';
import {sendArray, checkSendArray,dbVals} from '../../components/utils/http';

class TempStaging00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.props.getValue(props.ind, {type: "saveCancel"})
    this.state={
    }
    this.loadInfo()
//     cl("load info done")
//     setParamIds(this)
//     this.getCravoData()
//     cl(this.state)
  }
  
  getStagePids=()=>{
    let res=getParamId("configuration_channel_data","CD_cool_1")
//     cl(res)
    var getPidArray=(arr)=>{
      return arr.map(id=>{return (id=="")?-1:
//         cl(tab,id)
        getParamId("configuration_channel_data",id)})
    }
    let ventIds=["","","","","","","CD_normal_vent","cool_1","cool_2","cool_3","cool_4",
      "cool_5","cool_6"]
    let ventIds_1900=["","","","","","","normal_onoff","CD_cool_1","CD_cool_2",
      "CD_cool_3","CD_cool_4","CD_cool_5","CD_cool_6"]
    let onoIds=["CD_heat_6","CD_heat_5","CD_heat_4","CD_heat_3","CD_heat_2","CD_heat_1",
    "normal_onoff","CD_cool_1","CD_cool_2","CD_cool_3","CD_cool_4","CD_cool_5","CD_cool_6"]
    if(this.gwType==1900){
      ventIds=ventIds_1900
    }
    return{
      ventPids:getPidArray(ventIds),
      onoPids:getPidArray(onoIds)
    }
  }
  
  getGatewayType=(zoneInd)=>{
//     cl(this.zone)
//     cl(globs.userData)
    let siteId=globs.userData.siteLoaded
    let zoneInfo=globs.zonesInfo.info.filter(zi=>{return(zi.siteId==siteId)&&(zi.siteZoneIndex==zoneInd)})
    return zoneInfo[0]?.gatewayType
//     cl(zoneInfo)
  }
  
  loadInfo=()=>{
    let pr=this.props
//     cl(pr)
    let parts=pr.zuci.split("-")
    this.zone=+parts[0]
    let isPearl=pr.current.gwType==1900
    this.gwType=+this.getGatewayType(this.zone)
//     cl(this.gwType)
    let stagePids=this.getStagePids()
//     cl(stagePids)
    let pid=getParamId("configuration_zone_settings","Heat stages")
    if(dbVals.z[this.zone][255]){
      this.heatStages=((dbVals.z[this.zone][255]||{})[pid])||1
      pid=getParamId("configuration_zone_settings","Cool stages")
      this.coolStages=(+dbVals.z[this.zone][255][pid])||6
    }else{
      this.heatStages=1
      this.coolStages=6
    }
    let chans=[]
    var pearlUsed=[],pChans=[]
//     let zInd=+this.zuci[0]
//     cl(pr)
    if(isPearl){
      pearlUsed=getPearlUsed(this.zone)
      pChans=Object.keys(pearlUsed).map(k=>{return pearlUsed[k].chInd})
    }
//     cl(pr.current)
    let i2=1
    for(let i=0;i<40;i++){
      let ci=pr.current.channelInfo[i]
//       cl(ci)
      if((ci.used||pChans.includes(i))&&
        ([constant.EQ_ONOFF,constant.EQ_VENT].includes(+ci.channelType))){
//         (this.gwType==1900))&&([constant.EQ_ONOFF,constant.EQ_VENT].includes(+ci.channelType))){
//         cl(ci)
        let pids=(ci.channelType==constant.EQ_ONOFF)?stagePids.onoPids:stagePids.ventPids
//         cl(pids)
//         cl(dbVals.z[this.zone][i])
        let vals=pids.slice(6-this.heatStages,+this.coolStages+7)
          .map(id=>{return (id<0)?-1:+(dbVals.z[this.zone][i][id]||0)})
        chans.push({
          ch:i,
          chId:(isPearl)?1+i:i2++,
          type:ci.channelType,
          name:ci.channelName||"None",
          stages:vals
        })
      }
    }
//     cl(chans)
    this.state.chans=chans
//     this.setState({chans:chans})
  }
  
  getValue=(ind, val)=>{
  }
  
  showSaveCancel=()=>{
    return(
      <SaveCancel 
        onChange={(ind,val)=>this.onChange("saveCancel",val)}
        getValue={this.getValue}
        ind="0"
      />
    )
  }
  
  saveStagingData=()=>{
    let parms=[]
    let st=this.state
    cl(st)
    let stagePids=this.getStagePids()
    st.chans.forEach(ch=>{
    let pidBase=6-this.props.current.heatStages
    let pids=(ch.type==constant.EQ_ONOFF)?stagePids.onoPids:stagePids.ventPids
      ch.stages.forEach((st,i)=>{
        if(pids[i+pidBase]>=0){
          parms.push({
            z:this.zone,
            c:ch.ch,
            t:getTimeI(),
            f:1,
            i:pids[i+pidBase],
            d:st,
          })
        }
      })
    })
    cl(parms)
    cl(this.props.current)
    checkSendArray(parms,this.props.current.virtual).then(r=>{globs.events.publish("saveOK",true)})
/* parms:
{
    c,d,f,i,t,z - s will be filled in
  }*/
  }
  
  onChange=(type,vals)=>{
    var updateChans=()=>{
      let chans=this.state.chans.slice(0)
      this.setState({chans:chans})
    }
    cl(type,vals)
    switch(type){
      case "focus":
        vals.ch.focus=vals.st
        updateChans()
        break
      case "blur":
        vals.ch.focus=-1
        updateChans()
        break
      case "vent":
        globs.events.publish("savePageEnable",true)
        vals.ch.stages[vals.st]=vals.val
        updateChans()
        break
      case "ono":
        globs.events.publish("savePageEnable",true)
        vals.ch.stages[vals.st]=(vals.ch.stages[vals.st])?0:1
        updateChans()
        break
      case "saveCancel":
        switch(vals.value){
          case "save":
            cl("save")
            this.saveStagingData()
//             this.saveCravoData()
            this.props.onChange(this.props.ind, vals)
            break
          default:
            break
        }
        break
    }
  }
  
  showChan=(ch,i)=>{
//     cl(ch.focus)
//     cl(ch)
//     let colors=["#ab5555","#ab6755","#ab7855","#ab8955","#ab9a55","#abab55",
    let colors=["#eb5555","#db5555","#cb5555","#bb5555","#ab5555","#ab7855",
    
      "#ababab","#55ab55","#55ab78","#55ab9a","#559aab","#5588cb","#5570eb"]
    let base=6-this.heatStages
    let col=colors[base+i]
//     cl(base+i)
    let name=ch.name||`Nam${ch.ch+1}`
    return(
      <tr key={i}><td width="50">{ch.chId}</td><td>{name}</td>
      {ch.stages.map((st,j)=>{
//         cl(st)
        let col=colors[base+j]
        var val
        if(ch.type==constant.EQ_ONOFF){
          val=(st)?"On":"Off"
          return(
            <td key={j}>
              <button  style={{backgroundColor:col,color:(st)?"white":"black",
                opacity:(st)?1.0:0.2
              }}
                  className={`date toggle ${(st)?"selected":""}`}
                  onClick={e=>{this.onChange("ono",{ch:ch,st:j})}}
              >
                {val}
              </button>
            </td>
          )
          
        }else{
          val=(st<0)?"--":`${st}%`
          return(<td key={j} style={{paddingBottom:10,}}>
          <input type="text" style={{width:58,backgroundColor:col,color:"white",}}
          value={(ch.focus==j)?st:val}
          disabled={st<0}
          onChange={e=>this.onChange("vent",{ch:ch,st:j,val:e.currentTarget.value})}
          onFocus={e=>this.onChange("focus",{ch:ch,st:j})}
          onBlur={e=>this.onChange("blur",{ch:ch,st:j})}
          />
          </td>)
        }
      })}
      </tr>
    )
  }
  
  showStageHead=()=>{
    let heads=["Heat6","Heat5","Heat4","Heat3","Heat2","Heat1","Normal","Cool1","Cool2","Cool3",
      "Cool4","Cool5","Cool6"]
      let head2=heads.slice(6-this.heatStages,+this.coolStages+7)
      head2.unshift("Name")
      head2.unshift("Chan")
    return (
      <tr>
      {head2
//         let vals=pids.slice(6-this.heatStages,+this.coolStages+7)
        .map((h,i)=>{return <th key={i}><h3>{h}</h3></th>})}
      </tr>
    )
  }
  
  showChans=()=>{
//     cl(this.state.chans)
//     cl(window.innerWidth)
    let wid=0.54*window.innerWidth
    if(wid>1000){wid=1000}
    return(
      <div style={{width:"100%",overflowX:"auto"}}>
        <table><tbody>
        {this.showStageHead()}
        {this.state.chans.map((ch,i)=>this.showChan(ch,i))}
        </tbody></table>
      </div>
    )
  }
  
  render(){
    let c=this.state.chan
    return(
      <div
        title={(this.props?.rest?.parent||(e=>{}))({uri:"pid",pr:this.props})}
      >
      <h3>Equipment Temperature Stage Settings</h3>
      {this.showChans()}
      {this.showSaveCancel()}
      </div>
    )
  }
}

export default TempStaging00 ;
