import React from 'react';
import C18MenuBar00 from './C18MenuBar00'
// import C18Breadcrumb00 from './C18Breadcrumb00'
// import history from "../../history"
import {loadSitesInfo,loadSiteData, loadZonesInfo,getZoneInfo,getSiteInfo} from './C18utils'
import {sensorIds,getParamId,tempUnit,lightUnit,windUnit,initSensorIds} from '../utils/utils'
import {dbVals} from '../../components/utils/http';
import {cl, globs} from '../../components/utils/utils';

class C18WeatherStation00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.loadData()
    this.state={
      winDir: "-",
      winSpeed: "",
      winUnits: "mph",
      outTemp: 57.7,
      outTempUnits: "F",
      outHum: 82.3,
      outLight: 324,
      blackPlate: 92.5,
      blackPlateMapping: 0,
      // outLightUnits: "W/m" + "2".sub(), // subscript doesn't work on react
      outLightUnits: "W/m2",
      rain: 0,
      snow: 0,
      rs_status: "",
      mode:"sites",
    }
    this.windDirs=[
      "N",
      "NNE",
      "NE",
      "ENE",
      "E",
      "ESE",
      "SE",
      "SSE",
      "S",
      "SSW",
      "SW",
      "WSW",
      "W",
      "WNW",
      "NW",
      "NNW",
    ]
    this.checkWSInt=setInterval(this.checkWS,5*1000)
  }

  componentWillUnmount=()=>{
    clearInterval(this.checkWSInt)
  }

  checkWS=()=>{
//     cl("check WS")
    this.updateData()
  }

  loadData=async()=>{
    await loadSitesInfo()
    await loadSiteData(this.props.parms.site)
    await loadZonesInfo()
    let zInd=getZoneInfo(this.props.parms.zone).siteZoneIndex
    initSensorIds(zInd)
    this.ids={
      winDirId:sensorIds.e0oWd.id,//getParamId("snapshots", "windDirection")
      winSpeedId:sensorIds.e0oWs.id,//getParamId("snapshots", "windSpeed")
      outTempId:sensorIds.e0ouT.id,//getParamId("snapshots", "outTemperature")
      outHumId:sensorIds.e0ouH.id,//getParamId("snapshots", "outHumidity")
      outLightId:sensorIds.e0ouL.id,//getParamId("snapshots", "outLight")
      blackPlateId:sensorIds.e0bpT.id,//getParamId("snapshots", "outLight")
      blackPlateMapping:4216+177,//getParamId("configuration_unit_settings","Outside Temperature 2 Mapping"),
      rainId:sensorIds.e0ran.id,//getParamId("snapshots", "rain")
      snowId:sensorIds.e0sno.id,//getParamId("snapshots", "snow")
    }
//     cl(this.ids)
//     cl(this.ids)
    this.updateData()

    //     cl([winUnitsId, outTempUnitsId, outLightUnitsId, ])
  }
  
  updateData=()=>{
    let si=getSiteInfo(this.props.parms.site)
//     cl(si)
    let weatherStation=si?.weatherStation||this.props.parms.zone
//     cl(weatherStation)
    let zi=getZoneInfo(weatherStation)
//     cl(zi)
    let wsz=(zi||{}).siteZoneIndex||0
    if(!(dbVals.z[wsz]||{})[240]){return}
//     cl((dbVals.z[wsz]||{})[240])
    let winUnits=windUnit(wsz).t//getParamId("configuration_zone_settings", "Windspeed Units")
    let outTempUnits=tempUnit(wsz).t//getParamId("configuration_zone_settings", "Temperature Units")
    let outLightUnits=lightUnit(wsz).t//getParamId("configuration_zone_settings", "Light Units")
//     cl(outLightUnits)
    if(!dbVals.z[wsz]){return}

    let winDir = (dbVals.z[wsz]||{})[240][this.ids.winDirId];
//     cl(dbVals)
//     cl(dbVals.z)
//     cl(wsz)
//     cl(dbVals.z[wsz])
//     cl(dbVals.z[wsz][240])
//     cl(winDirId)
    let winSpeed = dbVals.z[wsz][240][this.ids.winSpeedId];
    let outTemp = dbVals.z[wsz][240][this.ids.outTempId];
    let outHum = dbVals.z[wsz][240][this.ids.outHumId];
    let outLight = dbVals.z[wsz][240][this.ids.outLightId];
    let blackPlate = dbVals.z[wsz][240][this.ids.blackPlateId];
    let blackPlateMapping=dbVals.z[wsz][240][this.ids.blackPlateMapping];
//     cl(blackPlateMapping)
    let rain = +dbVals.z[wsz][240][this.ids.rainId];
    let snow = +dbVals.z[wsz][240][this.ids.snowId];
    let rs_status=`${(rain)?"rain":""} ${(snow)?"snow":""}`
//     cl([winDir,winSpeed,outTemp,outHum,outLight,rain,snow])

//     let rs_status = ""
//     if (rain == 1) rs_status += "rain "
//     if (snow == 1) rs_status += "snow "
      

    this.setState({
      winDir: this.windDirs[winDir],
      winSpeed: winSpeed,
      winUnits: winUnits,
      outTemp: outTemp,
      outHum: outHum,
      outTempUnits: outTempUnits,
      outLight: outLight,
      outLightUnits: outLightUnits,
      blackPlate: blackPlate,
      blackPlateMapping: blackPlateMapping,
      rain: rain,
      snow: snow,
      rs_status: rs_status,
      })
  }
  
  getZoneIndex=(zoneId)=>{
    for(let i=0;i<globs.zonesInfo.info.length;i++){
      let z=globs.zonesInfo.info[i]
      if(z.zoneId==zoneId){return z.siteZoneIndex}
    }
  }
  
  
  render(){
//     cl(this.state)
//     cl(this.state.blackPlateMapping)
//     cl(((+this.state.blackPlateMapping)!=0))
    return (
      <div id="weather-station">
        <div className="inner">
          <span className="title">
            Weather<br />Station
          </span>
          <span id="ws-icons" className={this.state.rs_status}>
            <img id="rain-icon" src="/rain.png"/>
            <img id="snow-icon" src="/snow.png"/>
          </span>
          <span>
            <span id="ws-wind-direction">{this.state.winDir}</span> | <span id="wind-speed">{this.state.winSpeed}</span><span className="ws-title"> {this.state.winUnits}<br />
              <span className="material-icons-outlined ws-small-icon">
                air
              </span>
              <span className="ws-title">Wind</span>
          </span></span>
          <span><span id="ws-temp">{this.state.outTemp}</span> <span id="ws-temp-units">{this.state.outTempUnits}</span><br />
              <span className="material-icons-outlined ws-small-icon">
                thermostat
              </span>
              <span className="ws-title">Air Temperature</span>
          </span>
          <span><span id="ws-humidity">{this.state.outHum}</span> %<br />
              <span className="material-icons-outlined ws-small-icon">
                water_drop
              </span>
              <span className="ws-title">Humidity</span>
          </span>
          <span><span id="ws-light">{this.state.outLight}</span> {this.state.outLightUnits}<br />
              <span className="material-icons-outlined ws-small-icon">
                light_mode
              </span>
              <span className="ws-title">Light</span>
          </span>
          {((this.state.blackPlateMapping||0)!=0)&&
            <span><span id="ws-blackPlate">{this.state.blackPlate}</span> {this.state.outTempUnits}<br />
              <span className="material-icons-outlined ws-small-icon">
                thermostat
              </span>
              <span className="ws-title">Black Plate Temp</span>
            </span>
            
          }
        </div>
      </div>
    )
  }
}
      
export default C18WeatherStation00;
