import React from 'react';
// import C18DateFormat00 from './C18DateFormat00'
// import C18Button00 from './C18Button00'
// import C18Anchor00 from './C18Anchor00'
// import {getParamId,getParamId2,getParamId800} from '../utils/utils'
import C18Select01 from './C18Select01'
import {loadSitesInfo,loadZonesInfo,getZoneIndex,loadSiteData,getChannelType,
  getSiteName,getZoneName} from './C18utils'
import {getParamId2} from '../utils/utils'
import {pInd} from '../../components/utils/paramIds'
// import {getEquipmentStatus} from './C18EquipmentStatus00'
import {dbVals,putZValue,sendArray} from '../../components/utils/http';
import {cl,globs,getTimeI} from '../../components/utils/utils';
// import {pInd} from '../../components/utils/paramIds';
//
// import history from "../../history"

class C18PearlInputConfig00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      loaded:false,
      mode:"sites",
      inpChan:0,
      inpType:"soil",
      inpMode:"an10v0",
      freqAvg:5,
      raw1:0,
      cal1:0,
      raw2:0,
      cal2:0,
      inputs:[
        {inpType:"thermist",
          inpMode:"an5v0",
          freqAvg:3,
          raw1:2,
          cal1:3,
          raw2:4,
          cal2:5,
        },
      ],
      inpBoard:"",
    }
    this.loadInfo()
    this.setBreadCrumbs()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.savePage)
  }

  setBreadCrumbs=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
    let siteName=getSiteName(this.props.parms.site)
    let zoneName=getZoneName(this.props.parms.zone)
    this.props.parms.onChange(
      {
        cmd: "breadcrumbs",
        data:
          {breadcrumbs: [
            {t:"Sites", url:"/usa/c18/sites"},
            {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
            {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`},
            {t:`Sensor Settings`, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/settings/sensor1800`},
            {t:`Input Mapping`, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/settings/mapping`},
            {t:`Pearl Input Config`, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/pearl/inputConfig`},
          ]},
      },
    )
  }

  componentWillUnmount=()=>{
    if(this.subscribe_savePageEvent){this.subscribe_savePageEvent.remove()}
  }

  saveToDb=(inputs)=>{
    cl(inputs)

  }

  savePage=(cmd)=>{
    let zInd=this.zone.siteZoneIndex
    let ch=240
    let arr=[]
    let now=getTimeI()
    var putVal=(nVals,oVals,name,ofs,id)=>{
      if(nVals[name]!=(oVals||{})[name]){
//         cl(name)
        let i=ofs+this.inpCfgIds[id]
        let d=nVals[name]
        if(putZValue(zInd,ch,i,d)){
          arr.push({
            c:ch,
            d:d,
            f:2,
            i:i,
            t:now,
            z:zInd,
          })
        }
      }
    }
    if(cmd=="save"){
      let st=this.state
      let inputs=st.inputs.slice(0)
      inputs.forEach((inp,i)=>{
        let ofs=i*this.inpCfgIds.entSize
        if(inp!=this.initInputs[i]){
          let inpI=this.initInputs[i]
          let [x0,y0,x1,y1]=[inp.raw1,inp.cal1,inp.raw2,inp.cal2]
          inp.calM=(y1-y0)/(x1-x0)
          inp.calB=y0-inp.calM*x0
          putVal(inp,inpI,"inpType",ofs,"typeId")
          putVal(inp,inpI,"inpMode",ofs,"modeId")
          putVal(inp,inpI,"pullUp",ofs,"pullUpId")
          putVal(inp,inpI,"calM",ofs,"calMId")
          putVal(inp,inpI,"calB",ofs,"calBId")
          putVal(inp,inpI,"freqAvg",ofs,"freqAvgId")
        }
      })
      sendArray(arr,this.zone.virtual,this.zone.gatewayType).then(r=>{
        globs.events.publish("saveOK",true)
      })
    }
  }

  loadInputExpansions=()=>{
    let st=this.state
    let gwType=this.zone.gatewayType
    let zInd=this.zone.siteZoneIndex
    let ch=240
    let tab=pInd[gwType].config_expansion_boards
    let startInputId=getParamId2(gwType,"configuration_expansion_boards","startInput")
    let numInputId=getParamId2(gwType,"configuration_expansion_boards","numInputs")
    let mult=tab[2]
    let cnt=tab[3]

    this.c2bc={}
    this.bc2c={};
    let boards=[];
    [...Array(cnt)].forEach((x,i)=>{
      let ofs=i*mult
      let pid=startInputId+ofs
      let board=i
      let start=+dbVals.z[zInd][ch][startInputId+ofs]||0
      let count=+dbVals.z[zInd][ch][numInputId+ofs]||0
//       cl(start,count)
      if(count){
        boards.push(i)
        for(let j=0;j<count;j++){
          if(j==0){this.bc2c[i]={}}
          let ch=start+j// j is the channel on the board, ch is global, i is the board
          this.c2bc[ch]=[i,j]
          this.bc2c[i][j]=ch
        }
      }
    })
    st.inpBoard=Object.keys(this.bc2c)[0]
    st.inpChan=Object.keys(this.bc2c[st.inpBoard])[0]
//     cl(st.inpChan)
//     cl("loaded")
  }

  loadInputConfig=()=>{
//     cl(this.zone)
    let gwType=this.zone.gatewayType||1800
    let zInd=this.zone.siteZoneIndex
    let ch=240
//     cl(gwType)
    let tab=pInd[gwType].pearl_input_config||{}
//     cl(dbVals)
    this.inpCfgIds={
      typeId:getParamId2(1900,"pearl_input_config","type"),
      modeId:getParamId2(1900,"pearl_input_config","inputMode"),
      pullUpId:getParamId2(1900,"pearl_input_config","pullUpEnabled"),
      calMId:getParamId2(1900,"pearl_input_config","cal_m"),
      calBId:getParamId2(1900,"pearl_input_config","cal_b"),
      freqAvgId:getParamId2(1900,"pearl_input_config","freqAvgTime"),
      base:tab[0],
      entSize:tab[2],
      entCnt:tab[3]
    }
    let ici=this.inpCfgIds;
    let inputs=[...Array(this.inpCfgIds.entCnt)].map((ic,i)=>{
      let ofs=i*ici.entSize
      let calM=+dbVals.z[zInd][ch][ici.calMId+ofs]
      let calB=+dbVals.z[zInd][ch][ici.calBId+ofs]
      return{
        inpType:dbVals.z[zInd][ch][ici.typeId+ofs],
        inpMode:dbVals.z[zInd][ch][ici.modeId+ofs],
        pullUp:dbVals.z[zInd][ch][ici.pullUpId+ofs],
        calM:dbVals.z[zInd][ch][ici.calMId+ofs],
        calB:dbVals.z[zInd][ch][ici.calBId+ofs],
        raw1:0,
        cal1:calB,
        raw2:1,
        cal2:calB+calM,
        freqAvg:dbVals.z[zInd][ch][ici.freqAvgId+ofs],
      }
    })
//     cl(this.inpCfgIds)
//     cl(inputs)
    return inputs
  }

  loadInfo=()=>{
    let pr=this.props
    let pa=this.props.parms
    this.zone=globs.zonesInfo.info.filter(z=>{return z.zoneId==pa.zone})[0]
//     cl(this.zone)
    let inputs=this.loadInputConfig()
    let xBoards=this.loadInputExpansions()
    this.initInputs=inputs.slice(0)
    Object.assign(this.state,{loaded:true,inputs:inputs})
//     this.setState({loaded:true,inputs:inputs})
//     cl(pa)

  }

  onChange=(type,vals)=>{
    cl(type,vals)
    let st=this.state
    switch(type){
      case "upd":
//         cl(vals)
        let id=Object.keys(vals)[0]
        if(["inpChan","inpBoard"].includes(id)){//"inpChan" in vals
          this.setState(vals)
        }else{
          globs.events.publish("savePageEnable",true)
          let ch=st.inpChan
          let inputs=st.inputs.slice(0)
          if(!inputs[ch]){inputs[ch]={}}
          inputs[ch]=Object.assign({},inputs[ch],vals)// creates new object, detect on save
          this.setState({inputs:inputs})
        }
        break
      default:
        break
    }
  }

  getInpChan=()=>{
    let st=this.state
    return this.bc2c[st.inpBoard][st.inpChan]
  }

  showFreqAvg=()=>{
    let st=this.state
    let inpChan=this.getInpChan()
    return(
      <div>
        <label htmlFor="freqAv">Frequency Average</label>
        <input type="number"
//           value={st.freqAvg}
          value={(st.inputs[this.getInpChan()]||{}).freqAvg||0}
          onChange={e=>{this.onChange("upd",{freqAvg:e.currentTarget.value})}}
        />
      </div>
    )
  }

  showNumericInput=(label,valueName)=>{
    let st=this.state
//     cl((st.inputs[this.getInpChan()]||{})[valueName])
    return(
      <div>
        <label>{label}</label>
        <input type="number"
          value={(st.inputs[this.getInpChan()]||{})[valueName]||0}
          onChange={e=>{
            let vals={}
            vals[valueName]=e.currentTarget.value
            this.onChange("upd",vals)}}
        />
      </div>
    )
  }

  showSelectMode=()=>{
    let st=this.state
    let opts=[
      {v:0,t:"Analog 1.0V"},
      {v:1,t:"Analog 3.3V"},
      {v:2,t:"Analog 5.0V"},
      {v:3,t:"Analog 10.0V"},
      {v:4,t:"Digital"},
      {v:5,t:"Fast Digital"},
    ]
    return(
      <C18Select01 parms={{
        label:"Input Mode",
        valueName:"inpMode",
//         inpMode:st.inpMode,
        inpMode:(st.inputs[this.getInpChan()]||{}).inpMode||"an1v0",
        opts:opts,
        onChange:this.onChange,
      }}/>
    )
  }

  showSelectType=()=>{
    let st=this.state
//     cl(st)
    let opts=[
      {v:0,t:"None"},
      {v:1,t:"Active Temp"},
      {v:2,t:"Thermistor"},
      {v:3,t:"Analog Light"},
      {v:4,t:"Soil Moisture"},
      {v:5,t:"Wind Speed"},
      {v:6,t:"Wind Direction"},
      {v:7,t:"Rain"},
      {v:8,t:"Voltage"},
      {v:9,t:"Custom"},
    ]
    return(
      <C18Select01 parms={{
        label:"Input Type",
        valueName:"inpType",
        inpType:(st.inputs[this.getInpChan()]||{}).inpType||"none",
        opts:opts,
        onChange:this.onChange,
      }}/>
    )
  }

  showPullUp=()=>{
    let st=this.state
    let pu=+((st.inputs[this.getInpChan()]||{}).pullUp||0)
//     cl(pu)
    return(
      <div>
        <label>Pull Up Enabled</label>
        <input type="checkbox" style={{display:"block",minWidth:"auto"}}
          checked={pu?true:false}
          onChange={e=>this.onChange("upd",{pullUp:(e.currentTarget.checked)?1:0})}
        /><br/>
      </div>
    )
  }

  showSelectBoard=()=>{
    let st=this.state
//     let boards=Object.keys(this.bc2c)
//     let opts=[]
//     cl(boards)
    let opts=Object.keys(this.bc2c).map(b=>{return{v:b,t:`Bd ${+b+1}`}})
//     opts.push({v:"t",t:"test"})
    return(
      <div style={{display:"inline-block"}}>
        <C18Select01 parms={{
          label:"Input Board",
          valueName:"inpBoard",
          inpChan:st.inpBoard,
          opts:opts,
          onChange:this.onChange,
        }}/>
      </div>
    )
  }

  showSelectChannel=()=>{
    let st=this.state
    let chans=Object.keys(this.bc2c[st.inpBoard])
    let opts=chans.map(c=>{return{v:c,t:`Chan ${+c+1}`}})
    return(
      <div style={{display:"inline-block"}}>
        <C18Select01 parms={{
          label:"Input Channel",
          valueName:"inpChan",
          inpChan:st.inpChan,
          opts:opts,
          onChange:this.onChange,
        }}/>
      </div>
    )
  }

//   showSelectChannelO=()=>{
//     let st=this.state
//     let opts=[...Array(64)].map((c,i)=>{
//       return({
//         v:i,
//         t:`Channel ${i+1}`
//       })})
//     return(
//       <C18Select01 parms={{
//         label:"Input Channel",
//         valueName:"inpChan",
//         inpChan:st.inpChan,
//         opts:opts,
//         onChange:this.onChange,
//       }}/>
//     )
//   }

  showCalibration=()=>{
    return(
      <table style={{width:"auto"}}><tbody>
      <tr>
      <td>{this.showNumericInput("Raw 1","raw1")}</td>
      <td>{this.showNumericInput("Calibrated 1","cal1")}</td>
      </tr>
      <tr>
      <td>{this.showNumericInput("Raw 2","raw2")}</td>
      <td>{this.showNumericInput("Calibrated 2","cal2")}</td>
      </tr>
      </tbody></table>
    )

  }

  render(){
    if(this.state.loaded){
      return(
        <div>
        {this.showSelectBoard()}
        {this.showSelectChannel()}
        {this.showSelectType()}
        {this.showPullUp()}
        {this.showSelectMode()}
        {this.showFreqAvg()}
        <h3>Input Calibration</h3>
        {this.showCalibration()}
        </div>
      )
    }else{
      return <div id="content-area">loading equipment. . .</div>
    }
  }
}

export default C18PearlInputConfig00;
