import React from 'react';
import C18Anchor00 from './C18Anchor00'
import {loadZonesInfo,getZoneInfo,setSiteZoneTypes,loadSiteData,checkSave,saveTable} from './C18utils'
import {wsTrans} from '../utils/utils'
import {cl,globs,getRandomString} from '../../components/utils/utils';

class C18Modbus00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      loaded:false,
      registerOpts:{
        abcd:{// device id
          efgh:{// register id
            registerId: "efgh",
            name: "Register One",
            type: 0,
            func: 0,
            addr: 23,
            conv: "mult",
            cParm: 10,
            unit: "min",
            alLo: 50,
            alHi: 70,
          },
          ijkl:{// register id
            registerId: "ijkl",
            name: "Register Two",
            type: 0,
            func: 0,
            addr: 23,
            conv: "mult",
            cParm: 10,
            unit: "min",
            alLo: 50,
            alHi: 70,
          }
        }
      },
      typeOpts:{
        abcd:{
          name: "Type One",
        },
        xyz:{
          name: "Type Two",
        }
      },
      typeSel:"abcd",
      registerSel:"efgh",
      regTypeSel:"rod",
      indexNameOpts:{
        1:{deviceType:0,name:"Index One"}
      },
      indexValueOpts:{
        1:{
          0:{t:"ind zero"},
          1:{t:"ind one"},
        },
      },
      indexNameSel:1,
      deviceOpts:{
        "skuU0AhGtLpcbjU6":{// device id
          "123":{
            name:"Device 1",
            zone:3,
            unit:0,
            addr:4,
          }
        }
      },
      deviceSel:"123",

    }
    this.loadData()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveModbus)
    if(this.props.parms.saveOK){this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})}
//     this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.setBreadCrumbs()
  }
  
  setBreadCrumbs=()=>{
//     cl(this.props)
    let pages={types:"Types",devices:"Devices"}
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Modbus", url:`/usa/c18/modbus/devices`},
//               {t:pages[this.props.parms.pageType], url:`/usa/c18/modbus/${this.props.parms.pageType}`},
//               {t:"Schedules", url:`/usa/c18/idoser/schedules`},
            ]},
        },
      )
    }
  }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
  }
  
//   componentDidUpdate=()=>{
//     cl(this.props.parms.pageType)
//     cl("update")
//   }

  saveType=async()=>{
    let typeId=+this.state.typeSel
    let type=Object.assign({},this.state.typeOpts[typeId],{gatewayId:this.gatewayId,typeId:typeId})
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusTypes", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: type})
    await saveTable("modbusTypes",this.gatewayId)
  }
  
  saveDevice=async()=>{
    let deviceId=this.state.deviceSel
    let device=Object.assign({},(this.state.deviceOpts[this.state.typeSel]||{})[deviceId],
      {gatewayId:this.gatewayId,typeId:this.state.typeSel,deviceId:deviceId})
//     cl(device)
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusDevices", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: device})
    await saveTable("modbusDevices",this.gatewayId)
    globs.events.publish("saveOK",true)
  }
  
  saveRegisters=async()=>{
//     cl(this.state.registerOpts)
    let ro=this.state.registerOpts[this.state.typeSel]||{}
//     cl(ro)
//     cl(this.props.parms)
//     cl(this.props.parms.zone)
//     cl(getZoneInfo(this.props.parms.zone))
//     let gatewayId=getZoneInfo(this.props.parms.zone).gatewayId
//     cl(gatewayId)
    let registers=[]
    Object.keys(ro).forEach(registerId=>{
      registers.push(ro[registerId])
    })
//     cl(registers)
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusRegisters", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId,typeId:+this.state.typeSel,regs:registers}})
    await saveTable("modbusRegisters",this.gatewayId)
    globs.events.publish("saveOK",true)
    
  }
  
  saveIndexes=async()=>{
    //index names
// +----------------+-------------+------+-----+---------+----------------+
// | Field          | Type        | Null | Key | Default | Extra          |
// +----------------+-------------+------+-----+---------+----------------+
// | name           | varchar(32) | NO   | UNI | NULL    |                |
// | device_type_id | int(11)     | NO   |     | NULL    |                |
// | conv_index_id  | int(11)     | NO   | PRI | NULL    | auto_increment |
// +----------------+-------------+------+-----+---------+----------------+
// modbus_conv_index_values
// +---------------------+-------------+------+-----+---------+----------------+
// | Field               | Type        | Null | Key | Default | Extra          |
// +---------------------+-------------+------+-----+---------+----------------+
// | conv_index_id       | int(11)     | NO   | MUL | NULL    |                |
// | ivindex             | int(11)     | NO   |     | NULL    |                |
// | value               | varchar(32) | NO   |     | NULL    |                |
// | conv_index_value_id | int(11)     | NO   | PRI | NULL    | auto_increment |
// +---------------------+-------------+------+-----+---------+----------------+
//     cl(this.state)
    let st=this.state
    cl(st)
    let inNames=[]
    let indValues=[]
    Object.keys(st.indexNameOpts).forEach(k=>{// k is for each index
      let ind=st.indexNameOpts[k]
      inNames.push({
        name:ind.name,
        deviceType:+st.typeSel,
        convIndexId:+k,
      })
      let values=st.indexValueOpts[k]
      if(values){
        let uniques={}
        cl(values)
        Object.keys(values).forEach(k1=>{// k1 is for each value
          let key=100*k+k1
          cl(key)
          if(!uniques[key]){
            uniques[key]=1
            indValues.push({
              indexId:+k,
              ivIndex:+k1,
              value:values[k1].t,
              level:values[k1].l,
              enable:(values[k1].e)?1:0,
            })
          }
        })
      }
    })
    cl(inNames)
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusIndexNames", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId,typeId:+st.typeSel,inNames:inNames}})
/* each device type can have multiple indexes 
each index has multiple values
indexValueOpts and indexNameOpts are specific to the selected deviceType

 */
    cl(indValues)
//     let indValues=[]
//     Object.keys(st.indexNameOpts).forEach(k0=>{
//       
//     })
//     let stIndValues=st.indexValueOpts[st.typeSel]
//     Object.keys(stIndValues).forEach(k=>{
//       indValues.push({
//         name:stIndValues[k].t,
//         ivIndex:k,
//         indexId:st.indexNameSel,
//       })
//     })

    r=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusIndexValues", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId,
//         typeId:this.state.typeSel,
        indexId:st.indexNameSel,
        indValues:indValues}})

    await saveTable("modbusConvIndexNames",this.gatewayId)
    await saveTable("modbusConvIndexValues",this.gatewayId)
  }
  
  saveModbus=(cmd)=>{
//     cl(this.state)
//     cl(this.props)
//     cl(cmd)
    if(cmd=="save"){
      switch(this.props.parms.pageType){
        case "types":
          this.saveType()
          this.saveRegisters()
          this.saveIndexes()
          break
        case "devices":
          this.saveDevice()
          break
      }
    }
  }
  
  loadZones=()=>{
    let zo=[]
    globs.zonesInfo.info.forEach(z=>{
      if(z.siteId==globs.userData.session.siteId){
//         cl(z)
        zo.push(z.siteZoneIndex)
      }
    })
    zo.sort()
//     cl(zo)
    return zo
  }
  
  getMaxId=(data,field)=>{
    let max=0
    data.forEach(da=>{if(max<+da[field]){max=+da[field]}})
    return 1+max
  }
  
  loadData=async()=>{
//     cl(this.state)
//     cl("load data")
    await loadZonesInfo()
    cl(this.props.parms.zone)
    this.gatewayId=getZoneInfo(this.props.parms.zone).gatewayId
    cl(this.gatewayId)
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusTypes", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})
    this.nextTypeId=this.getMaxId(r.data,"typeId")
//     cl(this.maxTypeId)
    let to={}
//     cl(r.data)
    r.data.forEach(ty=>{// somehow "null" values came from LinkConn
      if((ty.typeId||(ty.typeId==0))&&(ty.typeId!="null")){to[ty.typeId]=ty}
    })
//     cl(to)
    let typeSel=Object.keys(to)[0]
    r=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusRegisters", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})
    this.nextRegisterId=this.getMaxId(r.data,"registerId")
//     cl(r.data)
    this.allRegisters=r.data
    let ro={}// registerOpts
    r.data.forEach(reg=>{
      if(!ro[reg.typeId]){ro[reg.typeId]={}}
      ro[reg.typeId][reg.registerId]=reg
    })
//     let indName
//     cl(ro)
    let registerSel=Object.keys(ro[typeSel]||{})[0]
    let indexNameSel=(ro[typeSel]||{})[registerSel]?.cParm
//     cl(registerSel)
//     let regData=ro[typeSel][registerSel]
//     cl(regData)
    r=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusDevices", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})
//     cl(r.data)
    this.nextDeviceId=this.getMaxId(r.data,"deviceId")
    let dop={}
    r.data.forEach(device=>{
//       cl(device.typeId)
//       cl(device.deviceId)
      if((device.typeId)&&(device.deviceId)){
        if(!dop[device.typeId]){dop[device.typeId]={}}
        dop[device.typeId][device.deviceId]=device
      }
    })
//     cl(dop)
    var deviceSel=-1
    if(Object.keys(dop).length){
//       cl(dop)
//       cl(typeSel)
//       cl()
      deviceSel=Object.keys(dop[typeSel]||{})[0]
    }
    r=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusIndexNames", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})
    this.nextIndexId=this.getMaxId(r.data,"convIndexId")
    let tempNames={}
    r.data.forEach(r=>{tempNames[r.convIndexId]=r})// to remove duplicate IDs
//     cl(r.data)
    this.allIndexNames=Object.values(tempNames)//r.data
//     cl(this.allIndexNames)
    let indNames=this.getIndexNames(typeSel)
//     cl(indexNameSel)
//     cl(indNames)
    if(!indNames[indexNameSel]){
//       cl(Object.keys(indNames))
//       let keys=Object.keys(indNames)
//       cl(keys)
//       cl(keys[0])
      indexNameSel=Object.keys(indNames)[0];
      ((ro[typeSel]||{})[registerSel]||{}).cParm=indexNameSel
    }
//     cl(r.data)
    r=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusIndexValues", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})
    this.nextIndexValueId=this.getMaxId(r.data,"convIndexValueId")
    
    this.allIndexValues=r.data
//     cl(this.allIndexValues)
    let indValues=this.getIndexValues(typeSel)
//     cl(indValues)
//     cl(r.data)
    this.setState({loaded:true,typeOpts:to,typeSel:typeSel,registerOpts:ro,registerSel:registerSel,
      zoneOpts:this.loadZones(),deviceOpts:dop,deviceSel:deviceSel,indexNameOpts:indNames,
      indexNameSel:+indexNameSel,indexValueOpts:indValues
    })
//     cl(this.state)
//     cl([this.nextTypeId,this.nextRegisterId,this.nextDeviceId,this.nextIndexId,this.nextIndexValueId])
    
  }
  
//   getIndexValues=(ind)=>{
//     
//   }
  
  getIndexNames=(deviceType)=>{
    let indNames={}
    this.allIndexNames.forEach(na=>{
      if(na.deviceType==deviceType){
        indNames[na.convIndexId]={
          deviceType:na.deviceType,
          name:na.name,
        }
      }
    })
    return indNames
//     cl(indNames)
  }
  
  getIndexValues=(deviceType)=>{
    let indIds=[]
//     cl(this.allIndexNames)
//     cl(deviceType)
    this.allIndexNames.forEach(na=>{
      if(+na.deviceType==deviceType){indIds.push(+na.convIndexId)}
    })
//     cl(indIds)
    let indValues={}
    this.allIndexValues.forEach(va=>{
      if(indIds.includes(va.indexId)){
//         cl(va)
        if(!indValues[va.indexId]){indValues[va.indexId]={}}
        indValues[va.indexId][va.ivIndex]={
          t:va.value,
          e:va.enable,
          l:va.level,
          
        }
//         cl(va)
      }
    })
//     cl(indValues)
    return indValues
  }
  
//   selectRegister=(regSel){
//     cl(regSel)
//   }
  
  onChange=async(type,vals)=>{
    cl(type,vals)
    var ino,res,ro,reg
//     cl(this.state)
    let st=this.state
    switch(type){
      case "typeSel":// also needs to reset deviceId
//         cl(this.props.parms)
        let typeSel=vals.typeSel
        let devType=this.props.parms.pageType=="devices"
        let saveType=(devType)?"this Device":"these Type"
        res=(this.props.parms.pageModified)?await this.props.parms.getPopup({text:`Do you want to save the changes to this ${saveType}?`, buttons:["Cancel","Yes"]}):"Yes"
        if(res=="Yes"){
          if(devType){
            this.saveDevice()
          }else{
            this.saveType()
            this.saveRegisters()
          }
        }
        let deviceSel=Object.keys(this.state.deviceOpts[typeSel]||{})[0]
        let registerSel=Object.keys(this.state.registerOpts[typeSel]||{})[0]
//         cl(st)
//         cl(registerSel)
//         cl((st.registerOpts[typeSel]||{})[registerSel])
        let indexNameOpts=this.getIndexNames(typeSel)
//         cl(indexNameOpts)
        let indexNameSel=((st.registerOpts[typeSel]||{})[registerSel]||{}).cParm
//         cl(indexNameSel)
        if(!indexNameOpts[indexNameSel]){
          indexNameSel=Object.keys(indexNameOpts)[0];
          ((st.registerOpts[typeSel]||{})[registerSel]||{}).cParm=indexNameSel
        }
//         cl(indexNameSel)
        
        Object.assign(vals,{
          deviceSel:+deviceSel,
          registerSel:registerSel,
          indexNameSel:indexNameSel,//st.registerOpts[typeSel][registerSel].cParm,
          indexNameOpts:indexNameOpts,//this.getIndexNames(typeSel),
          indexValueOpts:this.getIndexValues(typeSel)})
//         vals["indexNameOpts"]=this.getIndexNames(typeSel)
//         vals.indexValueOpts=this.getIndexValues(typeSel)
//         cl(vals)
        this.setState(vals)
        break
      case "regSel":
//         globs.events.publish("savePageEnable",true)
//         this.selectRegister(vals.registerSel)
        reg=st.registerOpts[st.typeSel][vals.registerSel]
        cl(reg)
        vals.indexNameSel=reg.cParm
        cl(st.indexValueOpts)
        this.setState(vals)
        break
      case "deviceSel":
//         globs.events.publish("savePageEnable",true)
        cl(this.props.parms)
        res=(this.props.parms.pageModified)?await this.props.parms.getPopup({text:"Do you want to save the changes to this Device?", buttons:["Cancel","Yes"]}):"Yes"
        if(res=="Yes"){
          this.saveDevice()
          this.setState(vals)
        }
        break
      case "deviceUpd":
        globs.events.publish("savePageEnable",true)
        let dop=this.state.deviceOpts
//         cl(this.state.typeSel)
//         cl(this.state.deviceSel)
        Object.assign(dop[this.state.typeSel][this.state.deviceSel],vals)
        this.setState({deviceOpts:dop})
        break
      case "typeUpd":
        globs.events.publish("savePageEnable",true)
        let to=this.state.typeOpts
        Object.assign(to[this.state.typeSel],vals)
        this.setState({typeOpts:to})
        break
      case "regUpd":
        cl(vals)
        globs.events.publish("savePageEnable",true)
        ro=Object.assign({},this.state.registerOpts)
        reg=ro[this.state.typeSel][this.state.registerSel]
        cl(reg)
        let vk=Object.keys(vals)
        if(vk.includes("func")){reg.type=(reg.type&0xFF)|(vals.func<<8)}
        if(vk.includes("type")){reg.type=(reg.type&0xFF00)|(vals.type)}
        let key=vk[0]
        if(["alLo","alHi","alDB"].includes(key)){
          vals[key]=Math.round((+vals[key]*10))
          cl(vals)
        }
        delete vals.type
        Object.assign(reg,vals)
        this.setState({registerOpts:ro})
        break
      case "index":// needs to set cParm
        globs.events.publish("savePageEnable",true)
        
//         cl(vals)
//         cl(vals.indexNameSel)
//         this.setState(vals)
        ro=Object.assign({},this.state.registerOpts)
        ro[st.typeSel][st.registerSel].cParm=vals.indexNameSel
        this.setState({registerOpts:ro,indexNameSel:vals.indexNameSel})
//         cl(this.state.indexNameSel)
        break
      case "indexEdit":
        globs.events.publish("savePageEnable",true)
        cl(type,vals)
        ino=Object.assign({},this.state.indexNameOpts||{})
        if(!ino[this.state.indexNameSel]){ino[this.state.indexNameSel]={}}
        cl(ino)
        Object.assign((ino[this.state.indexNameSel]),vals)
        this.setState({indexNameOpts:ino})
        break
      case "indexValue":
        cl(vals)
        globs.events.publish("savePageEnable",true)
        let ivo=Object.assign({},this.state.indexValueOpts)
        if(!ivo[this.state.indexNameSel]){ivo[this.state.indexNameSel]={}}
        if(!ivo[this.state.indexNameSel][vals.index]){ivo[this.state.indexNameSel][vals.index]={}}
        cl(ivo)
        Object.assign(ivo[this.state.indexNameSel][vals.index],vals)
//         ivo[this.state.indexNameSel][vals.index]={t:vals.name}
        this.setState({indexValueOpts:ivo})
        break
      case "addIndex":
        globs.events.publish("savePageEnable",true)
        let indexId=this.nextIndexId++
//         let max=0
//         this.allIndexNames.forEach(ind=>{
//           if(max<+ind.convIndexId){max=+ind.convIndexId}
//         })
//         cl(max)
        ino=Object.assign({},this.state.indexNameOpts)
//         let ind=max+1
        ino[indexId]={name:"New Index",deviceType:+st.typeSel}

        ro=Object.assign({},this.state.registerOpts)
        ro[st.typeSel][st.registerSel].cParm=indexId
//         this.setState({registerOpts:ro,indexNameSel:vals.indexNameSel})

        this.setState({indexNameOpts:ino,registerOpts:ro,indexNameSel:indexId})
        break
      case "deleteIndex":
        globs.events.publish("savePageEnable",true)
        break
      case "addRegister":
        globs.events.publish("savePageEnable",true)
        cl(type,vals)
        this.addRegister()
        break
      case "deleteRegister":
        globs.events.publish("savePageEnable",true)
        this.deleteRegister()
        break
      case "deleteDevice":
//         globs.events.publish("savePageEnable",true)
        this.deleteDevice()
        break
      case "addDevice":
        globs.events.publish("savePageEnable",true)
        this.addDevice()
        break
      case "deleteType":
        cl("deletetype")
//         globs.events.publish("savePageEnable",true)
        this.deleteType()
        break
      case "addType":
        globs.events.publish("savePageEnable",true)
        cl(type,vals)
        this.addType()
        break
    }
    
  }
  
  makeNewDeviceType=()=>{
    let st=this.state
    let max=-1
    Object.keys(st.typeOpts).forEach(k=>{
      if(max<+k){max=+k}
    })
    return max+1
  }
  
//   makeNewRegisterId=()=>{
//     let st=this.state
//     let max=-1
//     this.allRegisters.forEach(re=>{
//       if(max<re.registerId){max=re.registerId}
//     })
//     cl(st)
// //     let typeId=st.
//     return max+1
//   }
  
  addType=()=>{
    cl(this.state)
    cl("addType")
    let typeId=this.nextTypeId++//  this.makeNewDeviceType()//getRandomString(16)
    let type={
      name:"New Type",
    }
    let to=Object.assign({},this.state.typeOpts)
    to[typeId]=type
    cl(to)
    this.setState({typeOpts:to, typeSel:typeId})
  }
  
  deleteType=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Device Type?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let to=Object.assign({},this.state.typeOpts)
      delete to[this.state.typeSel]
      let obj={gatewayId:this.gatewayId,typeId:this.state.typeSel}
      cl(obj)
      let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusTypes", method: "delete",
        sessionId: globs.userData.session.sessionId,
        body: obj})
      let typeId=Object.keys(to)[0]
      var registerSel=this.state.registerSel
      if(this.state.registerOpts[typeId]){
        registerSel=Object.keys(this.state.registerOpts[typeId])[0]
      }
      this.setState({typeOpts:to, typeSel:typeId, registerSel:registerSel})
    }
  }
  
  addDevice=()=>{
    cl(this.state)
    let deviceId=this.nextDeviceId++
    let device={
      deviceId: deviceId,
      name: "New Device",
      zone:0,
      unit:0,
      addr:0,
    }
    let dop=Object.assign({},this.state.deviceOpts)
    if(!dop[this.state.typeSel]){dop[this.state.typeSel]={}}
    dop[this.state.typeSel][deviceId]=device
    this.setState({deviceSel:deviceId, deviceOpts:dop})
  }
  
  deleteDevice=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Device?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
    let dop=Object.assign({},this.state.deviceOpts)
    delete dop[this.state.typeSel][this.state.deviceSel]
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusDevices", method: "delete", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId,deviceId:this.state.deviceSel}})
    let deviceId=Object.keys(dop[this.state.typeSel])[0]
    this.setState({deviceSel:deviceId, deviceOpts:dop})
    }
  }
  
  addRegister=()=>{
    cl(this.state)
    let regId=this.nextRegisterId++
    let reg={
      registerId: regId,
      name: "New Register",
      type: 0,
      func: 0,
      addr: 0,
      conv: 0,
      cParm: 10,
      unit: "min",
      alLo: 0,
      alHi: 0,
    }
    let ro=Object.assign({},this.state.registerOpts)
    if(!ro[this.state.typeSel]){ro[this.state.typeSel]={}}
    ro[this.state.typeSel][regId]=reg
    this.setState({registerSel:regId, registerOpts:ro})
  }
  
  deleteRegister=()=>{
    let ro=Object.assign({},this.state.registerOpts)
    cl(ro)
    delete ro[this.state.typeSel][this.state.registerSel]
    let regId=Object.keys(ro[this.state.typeSel])[0]
    cl(ro)
    cl(regId)
    this.setState({registerSel:regId, registerOpts:ro})
  }
  
  showDevices=()=>{
    return(
      <div id="modbus_devices">
          <div className="clearfloat"></div>
        {this.showTypeSelect({edit: false})}
        {this.showDeviceSelect()}
        {this.showDeviceEdit()}
      </div>
    )
  }
  
  showIndexEdit=()=>{
    let st=this.state
//     cl(st)
    if(!st.registerOpts[st.typeSel]){return}
    let ro=st.registerOpts[st.typeSel][st.registerSel]
    if(ro.conv!=2){return null}// checking if index type
    
    let ino=st.indexNameOpts
    let gotIndex=Object.keys(ino).length!=0
//     if(!Object.keys(ino).length){return}
    cl(ino)
    let values=st.indexValueOpts[st.indexNameSel]||[]
    cl(values)
    return(
      <div>
              <span className="custom-select">
                <label htmlFor="modbus-index">Index</label>
                <select id="modbus-index"
                  value={st.indexNameSel}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("index",{indexNameSel:e.currentTarget.value})}
                >
                {Object.keys(ino).map((indexId,i)=>{
                  return(
                    <option key={i} value={indexId}>{ino[indexId].name}</option>
                  )
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
                <span className="save-message hidden">Please save before changing</span>
              </span>
              

              <button type="button" className="material-icons trash after-selector" aria-label="delete index"
                disabled={!this.props.parms.saveOK}
                onClick={e=>this.onChange("deleteIndex")}
              >
                delete_outline
              </button>

              <button type="button" className="material-icons-outlined add after-selector" aria-label="add index"
                disabled={!this.props.parms.saveOK}
                onClick={e=>this.onChange("addIndex")}
              >
                add
              </button>

              <div className="clearfloat"></div>
        {gotIndex&&
          <div>
                  <br /><hr /><br />
            <label htmlFor="modbus-index-name">Index Name</label>
            <input id="modbus-index-name" type="text"
              value={st.indexNameOpts[st.indexNameSel]?.name||""}
              disabled={!this.props.parms.saveOK}
              onChange={e=>this.onChange("indexEdit",{name:e.currentTarget.value})}

            />

            <br />

            <div>
              <label>Index Values</label>
              <table width="auto" style={{width:"auto"}}><tbody>
              <tr><th></th><th>Name</th><th>Alarm<br/>Level</th><th>Alarm<br/>Enable</th></tr>
              {[...Array(32).keys()].map(v=>{

                let n=(values[v]||{}).t||""
                let al=(values[v]||{}).l||0
                let en=(values[v]||{}).e||false
    //             cl(values)
    //             cl(values[v])

    //             let n=(values[v])?values[v].t:""
    //             let al=(values[v])?values[v].l:0
    //             let en=(values[v])?values[v].e:false
    //             cl(values[v])
    //             cl(n,al,en)
                return(
                  <tr key={v}><td>
                    <div className="clearfloat"></div>
                    {v}.&nbsp;</td><td>
                    <input className="inline-block" type="text"
                      style={{marginBottom:10}}
                      value={n}
                      disabled={!this.props.parms.saveOK}
                      onChange={e=>this.onChange("indexValue",{index:v,t:e.currentTarget.value})}
                    />
                    </td><td>
                      <span className="custom-select">
                        <select id="modbus-register-func"
                          value={al}
                          disabled={!this.props.parms.saveOK}
                          onChange={e=>this.onChange("indexValue",
                            {index:v,l:e.currentTarget.value})}
                        >
                        {
                          [...Array(7).keys()].map(i=>{
                            return(
                              <option key={i} value={i}>{i+1}</option>
                            )
                          })
                        }
                        </select>
                        <span className="material-icons down-arrow">
                          keyboard_arrow_down
                        </span>
                      </span>
                      </td><td>

                      <input id="modbus-alarm-enable" type="checkbox"
                          style={{minWidth:20,marginBottom:18}}
                          checked={en||false}
                          disabled={!this.props.parms.saveOK}
                          onChange={e=>this.onChange("indexValue",
                            {index:v,e:e.currentTarget.checked})}
                      />
                      </td></tr>


                )
              })}
              </tbody></table>

            </div>
          </div>
        }
      </div>
    )
  }
  
  showAlarmEdit=(ro)=>{
    let st=this.state
    if(!st.registerOpts[st.typeSel]){return}
//     let ro=st.registerOpts[st.typeSel][st.registerSel]
    if(ro.conv==2){return null}// checking if index type
    let funcOpts=[
      "Just View",
      "Archive",
      "Alarm",
      "Alarm Type",
      "Heat SP 1",
      "Heat SP 2",
      "Hum SP 1",
      "Hum SP 2",
    ]
    let func=(ro.type>>8)&0x0FF
    if(func!=1){return null}
    let alLo=Math.floor(ro.alLo||0)/10
    let alHi=Math.floor(ro.alHi||0)/10
    let alDB=Math.floor(ro.alDB||0)/10
    return(
      <div>
        <span className="inline-block">
          <div className="bottom-margin">
            <input id="modbus-alarm-enable" type="checkbox" 
                style={{minWidth:20}}
                checked={ro.alEn||false}
                disabled={!this.props.parms.saveOK}
                onChange={e=>this.onChange("regUpd",{alEn:e.currentTarget.checked})}
            />
            <label 
            style={{display:"inline-block"}}
            htmlFor="modbus-alarm-enable">Enable Alarm</label>
          </div>
        </span>
        <div className="clearfloat"></div>
        
        <span className="inline-block">
          <div className="bottom-margin">
            <label htmlFor="modbus-extra1">Alarm Low</label>
            <input id="modbus-extra1" type="number" step="0.1" min="0" max="65535" 
                value={alLo}
                disabled={!this.props.parms.saveOK}
                onChange={e=>this.onChange("regUpd",{alLo:e.currentTarget.value})}
            />
            
          </div>
        </span>
        <span className="inline-block">
          <div className="bottom-margin">
            <label htmlFor="modbus-extra2">Alarm High</label>
            <input id="modbus-extra1" type="number" step="0.1" min="0" max="65535" 
                value={alHi}
                disabled={!this.props.parms.saveOK}
                onChange={e=>this.onChange("regUpd",{alHi:e.currentTarget.value})}
            />
          </div>
        </span>

        <div className="clearfloat"></div>
        <span className="custom-select">
          <label htmlFor="modbus-register-func">Alarm Level</label>
          <select id="modbus-register-func"
            value={ro.alLevel}
            disabled={!this.props.parms.saveOK}
            onChange={e=>this.onChange("regUpd",{alLevel:e.currentTarget.value})}
          >
          {
            [...Array(7).keys()].map(i=>{
              return(
                <option key={i} value={i}>{i+1}</option>
              )
            })
          }
          </select>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </span>
        
        <div className="clearfloat"></div>
        <span className="inline-block">
          <div className="bottom-margin">
            <label htmlFor="modbus-extra3">Alarm Deadband</label>
            <input id="modbus-extra1" type="number" step="0.1" min="0" max="65535" 
                value={alDB}
                disabled={!this.props.parms.saveOK}
                onChange={e=>this.onChange("regUpd",{alDB:e.currentTarget.value})}
            />
          </div>
        </span>

        <div className="clearfloat"></div>
      </div>
    )
  }

  showConvParam=(ro)=>{
    if(ro.conv!=1){return null}
//     cl(ro)
//     if(ro.
    return(
      <div>
              <span className="inline-block">
                <label htmlFor="modbus-conversion-param">Conv Param</label>
                <input id="modbus-conversion-param" type="number" step="0.1" min="0" 
                  value={ro.cParm}
                  onChange={e=>this.onChange("regUpd",{cParm:e.currentTarget.value})}
                />
              </span>

              <div className="clearfloat"></div>
              <br />
      </div>
    )
  }
  
  showRegisterEdit=()=>{
    let typeOpts=[
      "RW Coil",
      "RO Coil", 
      "RO Discrete Input",
      "RW Holding Register", 
      "RO Holding Register",
      "RO Input Register",
    ]
    let funcOpts=[
      "Just View",
      "Archive",
      "Alarm",
      "Alarm Type",
      "Heat SP 1",
      "Heat SP 2",
      "Hum SP 1",
      "Hum SP 2",
    ]
//     let typeOpts={
//       rwc:"RW Coil",
//       roc:"RO Coil", 
//       rod:"RO Discrete Input",
//       rwh:"RW Holding Register", 
//       roh:"RO Holding Register",
//       roi:"RO Input Register",
//     }
//     let funcOpts={
//       view:"Just View",
//       arch:"Archive",
//       alr:"Alarm",
//       altyp:"Alarm Type",
//       hesp1:"Heat SP 1",
//       hesp2:"Heat SP 2",
//       husp1:"Hum SP 1",
//       husp2:"Hum SP 2",
//     }

    let convOpts=[
      "None",
      "Multiply",
      "Indexed",
      "On / Off",
      "\u00B0C to\u00B0F",
      "\u00B0F to\u00B0C",
    ]
    
//     let convOpts={
//       none:"None",
//       mult:"Multiply",
//       ind:"Indexed",
//       onoff:"On / Off",
//       c2f:"\u00B0C to\u00B0F",
//       f2c:"\u00B0F to\u00B0C",
//     }
    
    let unitOpts={
      feet:"feet", 
      "\u00B0C":"\u00B0C", 
      "\u00B0F":"\u00B0F", 
      minutes:"minutes", 
      inches:"inches", 
      cfm:"cfm", 
      mm:"mm"      ,
    }
    if(!this.state.registerOpts[this.state.typeSel]){return}
    let ro=this.state.registerOpts[this.state.typeSel][this.state.registerSel]
//     cl(this.state)
//     cl(ro)
//     let func=(ro.type>>8)&0xFF
    
    return(
      <div>
              <label htmlFor="modbus-register-name">Register Name</label>
              <input id="modbus-register-name" type="text" 
                value={ro.name}
                disabled={!this.props.parms.saveOK}
                onChange={e=>this.onChange("regUpd",{name:e.currentTarget.value})}
              />

              <br />

              <span className="custom-select">
                <label htmlFor="modbus-register-type">Type</label>
                <select id="modbus-register-type"
                  value={ro.type&0x0FF}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("regUpd",{type:e.currentTarget.value})}
                >
                  {Object.keys(typeOpts).map((typeId,i)=>{
                    return(
                      <option key={i} value={typeId}>{typeOpts[typeId]}</option>
                    )
                  })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>

              <span className="custom-select">
                <label htmlFor="modbus-register-func">Function</label>
                <select id="modbus-register-func"
                  value={(ro.type>>8)&0xFF}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("regUpd",{func:e.currentTarget.value})}
                >
                  {Object.keys(funcOpts).map((funcId,i)=>{
                    return(
                      <option key={i} value={funcId}>{funcOpts[funcId]}</option>
                    )
                  })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>

              <br />

              <label htmlFor="modbus-addr">Address (0-65535)</label>
              <input id="modbus-addr" type="number" step="1" min="0" max="65535" 
                  value={ro.addr}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("regUpd",{addr:e.currentTarget.value})}
              />

              <div className="clearfloat"></div>
              <br />

              <span className="custom-select">
                <label htmlFor="modbus-conversion-type">Conversion Type</label>
                <select id="modbus-conversion-type"
                  value={ro.conv}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("regUpd",{conv:e.currentTarget.value})}
                >
                  {Object.keys(convOpts).map((convId,i)=>{
                    return(
                      <option key={i} value={convId}>{convOpts[convId]}</option>
                    )
                  })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>

              <div className="clearfloat"></div>
              
              {this.showConvParam(ro)}

              <span className="custom-select">
                <label htmlFor="modbus-register-unit">Unit</label>
                <select id="modbus-register-unit"
                  value={ro.unit}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("regUpd",{unit:e.currentTarget.value})}
                >
                  {Object.keys(unitOpts).map((unitId,i)=>{
                    return(
                      <option key={i} value={unitId}>{unitOpts[unitId]}</option>
                    )
                  })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>

              <div className="clearfloat"></div>
              {this.showAlarmEdit(ro)}


      </div>

    )
  }
  
  showTypeEdit=()=>{
//     cl(this.state)
//     cl(this.state.typeOpts)
//     cl(this.props.parms)
    let ro=this.state.registerOpts[this.state.typeSel]||{}
    return(
      <div>
              <label htmlFor="modbus-name">Device Type Name</label>
              <input id="modbus-name" type="text"
              
              value={this.state.typeOpts[this.state.typeSel]?.name||""}
              disabled={!this.props.parms.saveOK}
              onChange={e=>this.onChange("typeUpd",{name:e.currentTarget.value})}
              />

              <br />

              <span className="custom-select">
                <label htmlFor="modbus-registers">Registers</label>
                <select id="modbus-registers"
                  value={this.state.registerSel}
                  onChange={e=>this.onChange("regSel",{registerSel:e.currentTarget.value})}
                >
                {Object.keys(ro).map((registerId,i)=>{
                  return(
                    <option key={i} value={registerId}>{ro[registerId].name}</option>
                  )
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
                <span className="save-message hidden">Please save before changing</span>
              </span>

              <button type="button" className="material-icons trash after-selector" aria-label="delete register"
              disabled={!this.props.parms.saveOK}
              onClick={e=>this.onChange("deleteRegister")}
              >
                delete_outline
              </button>

              <button type="button" className="material-icons-outlined add after-selector" aria-label="add register"
              disabled={!this.props.parms.saveOK}
              onClick={e=>this.onChange("addRegister")}
              >
                add
              </button>

              <div className="clearfloat"></div>
              <br /><hr /><br />
      </div>
    )
  }

  showTableHead=()=>{
    return(
      <tr>
        <th>Type</th>
        <th>Name</th>
        <th>Address</th>
        <th>Unit</th>
        <th>Zone</th>
        <th>Edit</th>
        <th>Delete</th>
      </tr>
    )
    
  }

  showTableLine=(u,i,dop)=>{
    return (
      <tr key={i}>
        <td>
           {this.state?.typeOpts[Number(dop[i].typeId) || Number(this.state.typeSel)]?.name}
        </td>
        <td>
           {dop[i].name.length > 20 ? dop[i].name.substring(0,20)+"..." : dop[i].name} 
        </td>
        <td>
           {dop[i].addr > 255 ? 255: dop[i].addr}
        </td>
        <td>
           {dop[i].unit}
        </td>
        <td>
           {Number(dop[i].zone) + 1}
        </td>
        <td>
        {/* <C18Anchor00 to={`/usa/c18/admin/modbusDevicesEdit/${dop[i].deviceId}`} className="settings-button material-icons-outlined flip">edit</C18Anchor00> */}
        <button type="button" className="settings-button material-icons-outlined flip"
            onClick={e=>this.onChange("deviceSel",{deviceSel:dop[i].deviceId})}
        >
          edit
        </button>
         </td>
        <td>
        <button type="button" className="material-icons trash after-selector" aria-label="delete device"
            disabled={!this.props.parms.saveOK}
            onClick={e=>this.onChange("deleteDevice")}
        >
          delete_outline
        </button>
        </td>
      </tr>
    )
  }

  showTableLines=()=>{
    let dop=this.state.deviceOpts[this.state.typeSel]||{}
    this.dop=[]
    { Object.keys(this.state.deviceOpts).map((u,i)=>{
      this.dop.push(this.state.deviceOpts[u])
    })
    }
   
    this.dopUpdated=[]
    for (let i = 0; i < this.dop.length; i++) {
      { Object.keys(this.dop[i]).map((u,ii)=>{
        this.dopUpdated.push(this.dop[i][u])
      })
      }
    }
    
    let dopUpdated = this.dopUpdated.map((u,i)=>{
      return this.showTableLine(u, i, this.dopUpdated)
    })
    return dopUpdated
  }
  
  showDeviceSelect=()=>{
    let dop=this.state.deviceOpts[this.state.typeSel]||{}
    return(
      <div>
        <span>
          <label htmlFor="modbus-device">Devices</label>
          <div className="section-controls floatright" style={{marginRight:'75px'}}>
          <button type="button" className="material-icons-outlined add" aria-label="add device"
            disabled={!this.props.parms.saveOK}
            onClick={e=>this.onChange("addDevice")}
           >
            add
          </button>
          </div>

          <div className="clearfloat"></div>
          <br />
          <table className="user-table"><tbody>
              {this.showTableHead()}
              {this.showTableLines()}

          </tbody></table>
          <span className="save-message hidden">Please save before changing</span>
        </span>

       

        <div className="clearfloat"></div>
        <br /><hr /><br />
      </div>
    )
  }
  
  showDeviceEdit=()=>{
    if(!this.state.deviceOpts[this.state.typeSel]){return null}
//     cl(this.state.deviceOpts[this.state.typeSel])
    let dop=this.state.deviceOpts[this.state.typeSel]
    let units=["Master","Slave 1","Slave 2","Slave 3"]
//     cl(this.state.deviceSel)
    if (this.dop.length > 0) {
    return(
      <div>
        <label htmlFor="idoser-name">Name</label>
        <input id="idoser-name" type="text" 
          value={dop[this.state.deviceSel]?.name||""}
          disabled={!this.props.parms.saveOK}
          onChange={e=>this.onChange("deviceUpd",{name:e.currentTarget.value})}
        />

        <br />

        <span className="custom-select">
          <label htmlFor="modbus-zone">Zone</label>
          <select id="modbus-zone"
            value={dop[this.state.deviceSel]?.zone}
            disabled={!this.props.parms.saveOK}
            onChange={e=>this.onChange("deviceUpd",{zone:e.currentTarget.value})}
          >
          {this.state.zoneOpts.map((z,i)=>{
            return(
              <option key={i} value={z}>{z+1}</option>
            )
          })}
          </select>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </span>

        <span className="custom-select">
          <label htmlFor="modbus-slave">Slave ID</label>
          <select id="modbus-slave"
            value={dop[this.state.deviceSel]?.unit}
            disabled={!this.props.parms.saveOK}
            onChange={e=>this.onChange("deviceUpd",{unit:e.currentTarget.value})}
          >
          {units.map((u,i)=>{
            return(
              <option key={i} value={i}>{u}</option>
            )
          })}
          </select>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </span>

        <br />

        <label htmlFor="modbus-addr">ModBus Addr (0-255)</label>
        <input id="modbus-addr" type="number" step="1" min="0" max="255" 
          value={dop[this.state.deviceSel]?.addr > 255 ? 255 : dop[this.state.deviceSel]?.addr||0}
          disabled={!this.props.parms.saveOK}
          onChange={e=>this.onChange("deviceUpd",{addr:e.currentTarget.value})}
        />
      </div>
    )
    }
    
  }
  
  showTypeSelect=(parms)=>{
//     cl(this.state)
    let to=this.state.typeOpts
    return(
      <div>
      <span className="custom-select">
        <label htmlFor="modbus-device">Device Type</label>
        <select id="modbus-device"
          value={this.state.typeSel}
          onChange={e=>this.onChange("typeSel",{typeSel:e.currentTarget.value})}
        >
        {Object.keys(to).map((typeId,i)=>{
          return(
            <option key={i} value={typeId}>{to[typeId].name}</option>
          )
        })}
        </select>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
        <span className="save-message hidden">Please save before changing</span>
      </span>
      {parms.edit&&
        <>
          <button type="button" className="material-icons trash after-selector" aria-label="delete type"
            disabled={!this.props.parms.saveOK}
            onClick={e=>this.onChange("deleteType")}
          >
            delete_outline
          </button>

          <button type="button" className="material-icons-outlined add after-selector" aria-label="add type"
            disabled={!this.props.parms.saveOK}
            onClick={e=>this.onChange("addType")}
          >
            add
          </button>
          <div className="clearfloat"></div>
          <br /><hr /><br />
        </>
      }
      </div>

    )

  }
  
  showTypes=()=>{
    return(
      <div id="modbus_types">
          <div className="clearfloat"></div>
      {this.showTypeSelect({edit:true})}
      {this.showTypeEdit()}
      {this.showRegisterEdit()}
      {this.showIndexEdit()}

      </div>
    )
  }
  
  
  render(){
//     cl(this.props)
//     cl(this.state)
    if(this.state.loaded){
      if(this.props.parms.pageType=="types"){
        return this.showTypes()
      }else{
        return this.showDevices()
      }
    }else{return<div>loading. . .</div>}
  }
}
      
export default C18Modbus00;
