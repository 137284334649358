import React from 'react';
import C18Anchor00 from './C18Anchor00'
import C18Images00 from './C18Images00'
import C18MessageThread00 from './C18MessageThread00'
import {wsTrans} from '../utils/utils'
import {loadUsersInfo} from './C18utils'
import {cl,globs,constant,dateToDisplayDate} from '../../components/utils/utils';
import history from "../../history"

class C18MessageDisplay00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      loaded:false,
    }
    this.setBreadCrumbs()
    this.loadInfo()
  }
  
  setBreadCrumbs=()=>{
    let pa=this.props.parms
//     cl(pa)
    if(pa){
      pa.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Messages", url:`/usa/c18/messaging/messageList`},
              {t:"Display Message", url:`/usa/c18/messaging/messageDisplay/${pa.messageId}`},
            ]},
        },
      )
      pa.onChange(
        {
          cmd: "pageTitle",
          pageTitle: "Display Message"
        }
        
      )
    }
  }
  
  loadMessage=async(messageId)=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/messages", 
      method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {
        messageId:messageId,
        userId:globs.userData.session.userId,
      }})
    return res.data
  }
  
  loadInfo=async()=>{
    await loadUsersInfo()
    this.userArr={}
    globs.usersInfo.info.forEach(ui=>{this.userArr[ui.userId]=ui})
//     cl(this.userArr)
//     globs.usersInfo.info.forEach(ui=>)
    let msg=await this.loadMessage(this.props.parms.messageId)
    let recips=msg.chatId.split("&")
    let recips2=recips.filter(re=>{return re!=globs.userData.session.userId})
//     let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/messages", 
//       method: "retrieve", 
//       sessionId: globs.userData.session.sessionId,
//       body: {
//         messageId:this.props.parms.messageId
//       }})
//     cl(res)
    this.setState({loaded:true,message:msg,recipients:recips2})
  }
  
//   showImages=()=>{
// //     cl(this.state)
//     return(
//       <div>
//         <C18Images00 parms={{
//           images:this.state.message.images||[],
//           edit:false,
//            
//         }}/>
//       </div>
//     )
//   }
  
  showMessage=()=>{
    let st=this.state
    let pa=this.props.parms
//     cl(pa)
    let msg=st.message
    let da=new Date(1000*msg.ts)
    let daShow=dateToDisplayDate(da,"mm/dd/yyyy h:mm ap")
    let subject=msg.subject||"No Subject"
    let names=st.recipients.map(re=>{return this.userArr[re].name})
    let recipients=names.join(", ")
//     cl(msg)
    let body=msg.chatText
    return(
      <div>
      <div style={{overflowWrap: "break-word"}}>{`${daShow}: ${subject}`}</div>
      <div>{`Recipients: ${recipients}`}</div>
      <div style={{overflowWrap: "break-word"}}>{body}</div>
      <div className="clearfloat"/><br/>
      <C18Images00 parms={{
        images:this.state.message.images||[],
        edit:false,
        onChange:pa.onChange,
          
      }}/>
      </div>
    )
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    let pa=this.props.parms
    switch(type){
      case "reply":
        vals.e.preventDefault()
        history.push(`/usa/c18/messaging/messageEdit/${pa.messageId}`)
//         cl("reply")
        break
      case "imgClick":
        break
      case "thread":
        cl(vals)
        this.setState({message:vals.message})
        this.showMessage();
        break
    }
  }
  
  render(){
    let pa=this.props.parms
    let st=this.state
    if(st.loaded){
      return(
        <div>
          <div className="section-controls floatright">
            <C18Anchor00 to="" onClick={(e)=>this.onChange("reply", {e:e})} className="material-icons-outlined add" aria-label="reply">
              reply
            </C18Anchor00>
          </div>
          {this.showMessage()}
          <div className="clearfloat"/><br/>
          <C18MessageThread00 parms={{
            message:st.message,
             chatId:st.message.chatId,
             ts:st.message.ts,
            messageId:pa.messageId,
            userArr:this.userArr,
            onChange:e=>this.onChange("thread",e),
//             skip:1,
          }}/>
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
      
export default C18MessageDisplay00
