import React from 'react';
import {cl} from './utils'
import FUISelect from '../../fui/controls/FUISelect';

var makeOptions=(opts)=>{
    return Object.keys(opts).map((k,i)=>{
      return (
        <option key={i} value={k}>{opts[k].t}</option>
      )
    })
  }

  var makeOptionsForFUISelect=(opts)=>{
    let hp=[]
    Object.keys(opts).map((k,i)=>{
     let obj={}
     obj['v']=k
     obj['t']=opts[k].t
     hp.push(obj)
    })
    return {options:hp}
   }
  
var showSelect=(type, val,valId,ind,opts, onChange)=>{
//   cl(opts)
    return(
      <select style={{marginLeft: "0.3em",}}
        value={val} 
        onChange={e=>onChange(type, valId,ind,e)}
      >
      {makeOptions(opts)}
      </select>
    )
  }

  var showFUISelect=(type, val,valId,ind,opts, onChange, getValue)=>{
    //   cl(opts)
        return(
          <FUISelect 
          key={{}}
          style={{marginLeft: "0.3em",}}
          getValue={getValue}
          custom={makeOptionsForFUISelect(opts)}
          value={val} 
          saveOK={true}
          onChange={e=>onChange(type, valId,ind,e)} 
          cont={{}} />
        )
      }

var showInput=(type, val, valId, ind, onChange, width=70)=>{
    return(
      <input
        style={{width:width}}
        value={val}
        type="number"
        inputMode="decimal"
        onChange={e=>onChange(type, valId, ind, e)}
      />
    )
  }

  export {showSelect, showInput, showFUISelect
}
