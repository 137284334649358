import React from 'react';
import { TimePicker } from "@material-ui/pickers";
import {cl,globs} from '../../components/utils/utils';

class C18MuiTimePicker extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
  }
  
  render(){
//<div><label for="">1.</label> <input type="time" id="" /> <input type="checkbox" id="" /><label for="">Enable</label></div>
    
// <div><label for="">1.</label> <input type="time" id="" /> <input type="checkbox" id="" /><label for="">Enable</label></div>
    let pr=this.props
    if(globs.noMui){
      return(
        <>
          <input type="time"
            id={pr.id}
            title={pr.title}
            value={pr.value}
            onChange={pr.onChange}
            disabled={!pr.saveOK}
            style={{backgroundColor:pr.bgColor}}
          >
            {pr.children}
            
          </input>
          <label>{pr.label}</label>
        </>
          
      )
    }else{
      return React.createElement(TimePicker,pr)
    }
  }
}
      
export default C18MuiTimePicker;
 
