import React from 'react';
import C18Anchor00 from './C18Anchor00'
import history from "../../history"
import {wsTrans,doGetPostBasic} from '../utils/utils'
import {cl,globs,constant,dateToDisplayDate} from '../../components/utils/utils';
import UsaIcon from '../components/UsaIcon';

class C18MessageThread00 extends React.Component{
  constructor(props) {
    super(props)
    cl(props)
    let pa=props.parms
    this.state={
      loaded:false,
      insertIndex:0,
//       chatId:pa.message.chatId,
//       subject:pa.message.subject||"No Subject",
    }
    this.msgTime=pa.message.ts
//     cl(this.state)
    this.loadThread(pa.messageId)
    this.loadThreadTimer=setInterval(e=>this.loadThread(this.messageId),5000)
  }
  
//   componentDidUpdate=()=>{
//     let pa=this.props.parms
//     cl(pa.messageId)
//     cl("update")
//   }
  
  componentWillUnmount=()=>{
    clearInterval(this.loadThreadTimer)
  }
  
  
  loadThread=async(messageId)=>{
/*
to load the thread:
search for messageId in infoChat, to get chatId
then, search for chatId sorted by time with limit
*/
    if((messageId==this.messageId)||!messageId){return []}
    let st=this.state
    let pa=this.props.parms
    cl(pa)
//     let msg=this.props.parms.message
//     let subject=st.subject||"No Subject"
    let recips=pa.chatId?.split("&")
    let userId=globs.userData.session.userId
    let recips2=recips.filter(re=>{return re!=userId})
    
    let res2=await wsTrans("usa", {cmd: "cRest", uri: "/s/messages", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {chatId:st.chatId,time:{$lt:this.msgTime},skip:0,limit:10}})//
//     let res2={data:[]}
    let msgs=res2.data
//     cl(this.msgTime)
    msgs.forEach(msg=>{
      msg.userName=this.props.parms.userArr[msg.userId]?.name
    })
    this.setState({loaded:true,thread:msgs,recipients:recips2})//,subject:subject
    this.messageId=messageId
//     cl(messageId)
    return 
  }
  
  onChange=async(type,vals)=>{
//     cl(type,vals)
    let st=this.state
    let pa=this.props.parms
    switch(type){
      case "msg":
        vals.e.preventDefault()
        return pa.onChange(vals)
//         let msg=st.thread.filter(m=>{return m.messageId==vals.messageId})[0]
//         this.msgTime=msg.ts
//         cl(msg)
//         let url=`/usa/c18/messaging/messageDisplay/${vals.messageId}`
//         cl(url)
//         await this.loadThread(vals.messageId)
//         cl(this.state)
//         history.push(url)
    }
  }
  
  showThread=()=>{
    let pa=this.props.parms
    let st=this.state
    let skip=pa.skip||0
//     cl(pa)
//     cl(this.state.thread)

    let customColor;
    if (globs.device?.deviceTheme=="originalDark"){
      customColor= "grey"
    }

    if(pa.messageId){
      return(
        <div>
          {st.thread.filter((th,i)=>{return i>=skip}).map((msg,i)=>{
//             cl(msg)
            let da=new Date(1000*msg.ts)
            let daShow=dateToDisplayDate(da,"mm/dd/yyyy h:mm ap")
            let title=`${daShow} ${msg.userName}:`
            let body=msg.chatText
            if(msg.images) {
              if(msg.images.length > 0) {
                this.image=<UsaIcon icon="parameters-Image" color={customColor} />
              }
              else {
                this.image = ''
              }
            }
            else {
              this.image = ''
            }
            return(
              <div key={i}>
                {this.image}
                <C18Anchor00 to="{}"
                  onClick={e=>this.onChange("msg",{e:e,messageId:msg.messageId, message: msg})}
                >
                  {title}</C18Anchor00>
                <div style={{overflowWrap: "break-word"}}>{body}</div>
                <div className="clearfloat"/><br/>
              </div>
            )
            
          })}
        </div>
      )
    }
  }
  
  render(){
    if(this.state.loaded){
      return this.showThread();
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}

export default C18MessageThread00;
