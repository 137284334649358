import React from 'react';
// import TextField from '@material-ui/core/TextField';
// import InputLabel from '@material-ui/core/InputLabel';
import C18MuiTextField from '../../usa/components/C18MuiTextField'
import C18Duration00 from '../../usa/components/C18Duration00'

// import C18MuiInputLabel from '../../usa/components/C18MuiInputLabel'
import {cl, globs, leadZeros} from '../../components/utils/utils';
// import { TimePicker } from "@material-ui/pickers";
// import DateFnsUtils from '@date-io/date-fns';
// import {
//   MuiPickersUtilsProvider,
//   // KeyboardTimePicker,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';

class DurationOnOff extends React.Component{
  constructor(props) {
    super(props);
    this.updateFui()
    this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
    this.state={
      value: "",
      focused: false,
      msg: ""
    }
  }

  updateFui=()=>{
//     cl(this.changed)
    if(!this.changed){
//       this.props.getValue(this.props.ind, {type: "scalar"})
      this.props.getValue(this.props.ind, {type: "array", count: 3})
//       cl(this.props.value)
    }
  }

  savePageEvent=()=>{
    this.changed=false
  }

  componentWillUnmount=()=>{
    this.subscribe_updateFui.remove()
    this.subscribe_savePageEvent.remove()
  }

  focused = false;

  isNumber=(x)=>{
    if (x==="") return true;
    let y = x*1;
    return(y >= 0) && (y < 60);
  }

  checkParts=(parts)=>{
    let isNum = true;
    let tot = 0;
    for (let i = 0 ; i < parts.length ; i++){
      let p = parts[i]
      if (this.isNumber(p)){
        tot = tot * 60 + p*1;
      }else{
        return -1;
      }
    }
    if (this.props.custom.mode === "min"){
      tot *= 60;
    }
    return tot;
  }

  tryColons=(str)=>{
    let parts=str.split(":");
    return this.checkParts(parts);
  }

  hmsToSecs=(hms)=>{
    // cl(hms)
    let parts = hms.split(":")
    let mults = {h: 3600, m: 60, s: 1}
    let tot = 0;
    let isNum = true;
    // cl(parts)
    parts.forEach(p=>{
      if (p!==""){
        let len = p.length
        let num = p.substr(0, len -1)
        isNum &= this.isNumber(num)
        if (!isNum) return -1;
        let typ = p.substr(len-1, 1)
        tot += num * mults[typ]
      }
    })
    return tot;
  }

  tryHMS=(str)=>{
    let strh = str.replace("h", "h:")
        .replace("m", "m:")
        .replace("s", "s:");
    let secs = this.hmsToSecs(strh)
    return secs;
  }

  isNumber=(x)=>{
    let y = x*1;
    return(y >= 0) && (y < 60);
  }

  parseTimeString=(str)=>{
    let secs = this.tryColons(str)
    if (secs < 0) secs = this.tryHMS(str)
    if (this.props.custom.mode === "min"){
      secs = Math.floor(secs / 60);
    }
//     cl(secs);
    return secs;
  }

  onChange=(ind,val)=>{
    let secs=val.value
    cl(secs)
    let hr=Math.floor(secs/3600)
    let mn=Math.floor(secs/60)%60
    let sc=secs%60
    this.props.onChange(ind,{value:[hr,mn,sc]})
//     this.props.onChange(this.props.ind,
//         {value: secs})
//     if(this.state.focused){
//       cl("focus")
//       let secs = this.parseTimeString(e.target.value)
//       let valid = (secs >= 0)?"":"not a valid duration";
//       this.setState({value: e.target.value, msg: valid})
//     }else{
//       let secs = this.parseTimeString(this.state.value)
//       if (secs >= 0){
//         this.props.onChange(this.props.ind,
//             {value: secs})
//       }
//     }
  }

  secsToHMS=(secs)=>{
    // cl(this.props.custom.mode)
    if (this.props.custom.mode === "min"){
      secs = secs * 60;
    }
    let h = Math.floor(secs / 3600);
    let m = Math.floor(secs / 60) % 60;
    let s = secs % 60;
    let ret = h + ":" + leadZeros(m, 2);
    if (this.props.custom.mode !== "min"){
      ret += ":" + leadZeros(s, 2);
    }
    return ret ; // h + ":" + leadZeros(m, 2) +        ":" + leadZeros(s, 2);

  }

  onBlur=()=>{
    let secs = this.parseTimeString(this.state.value)
    if (secs >= 0){
      this.props.onChange(this.props.ind,
          {value: secs})
    }
    this.setState({focused: false})
  }

  onFocus=()=>{
    cl(this.changed)
    this.setState({focused: true,
      value: this.secsToHMS(this.props.value)})
  }

  convToTime=(str)=>{
    return "here"
  }

  render(){
    // cl(this.props);
    let val = (this.state.focused) ?
        this.state.value : this.secsToHMS(this.props.value);
//             <C18MuiTextField
//                 label={this.props.title}
//                 onBlur={this.onBlur}
//                 onFocus={this.onFocus}
//                 // type="time"
//                 value={val}
//                 onChange={this.onChange}
//             /><br />

    let pr=this.props
//     cl(pr.value)
    let vals=pr.value

    let secs=3600*(+vals[0]||0)+60*(+vals[1]||0)+(+vals[2]||0)
    return(
        <div>
          <form className={"container"}>
          <label>{this.props.title}</label>
            <C18Duration00
            id={`{"cmd":"watch","type":"${pr.type}","id":${pr.cont.pid},"zuci":"${pr.zuci}"}`}
            title={(this.props?.rest?.parent||(e=>{}))({uri:"pid",pr:this.props})}
            parms={{
              duration:secs,//this.props.value,//so.mixtime,
              onChange:e=>{
                this.changed=true
                this.onChange(this.props.ind,{value:e.duration})
//                 this.props.onChange(this.props.ind,{value:e.duration})
              },
              saveOK:this.props.saveOK,
//               onChange:e=>this.onChange("upd",{mixtime:e.duration}),
              unit: (this.props.custom.type == "hour") ? "(HH:MM)" : "(HH:MM:SS)",
              pattern: (this.props.custom.type == "hour") ?
                "[0-9]{2}:[0-9]{2}" :
                "[0-9]{2}:[0-9]{2}:[0-9]{2}",
            }}
            />
            {this.state.msg}
          </form>
          <div className="clearfloat"></div><br/>
        </div>
    );
  }
}

export default DurationOnOff ;
