import React from 'react';
import C18Select00 from './C18Select00'
import C18Button00 from './C18Button00'
import C18SubMenuHeader00 from './C18SubMenuHeader00'
import LiveFui from '../../fui/LiveFui';
import {dbVals,sendArray} from '../../components/utils/http';
import {wsTrans,getParamId,makeOpts,getChannelTypeId} from '../utils/utils'
import {loadSiteData,loadZonesInfo,getSiteName,getZoneName,getChannelType,
} from './C18utils'
import {paramIdToTableColumn} from '../../fui/utilsFui'

import {cl,globs,constant,dateToDisplayDate,secsToHms,maxLen,getRandomString} from '../../components/utils/utils';

class C18GroupsEdit00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      loaded:false,
//       members:[],
      groups:[
        {t:"Group 1",v:"g1"},
        {t:"Group 2",v:"g2"},
        {t:"Group 3",v:"g3"},
      ],
      selGroup:"",
      selConfig:"",
      groupName:"Group 1",
      groupType:"chan",
      pageType:"edit",
      memberSortMode:3,
      commonControls:[],
      showConflictPopup:false,
      conflictLeft:300,
      conflictTop:300,
      conflictWidth:300,
      conflictHeight:500,
      conflictName:"chan",
      conflictKey:"",
      conflictOverrides:[],
//       selectedKeys:[],
    }
    this.notifies={}
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveGroup)
    this.loadInfo()
    this.setBreadCrumbs()
    this.controlRefs=[]
    document.addEventListener("mousedown",this.md,false)
    this.subscribe_keyUpEvent=globs.events.subscribe("keyUp",this.keyUp)
  }

  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
    this.subscribe_keyUpEvent.remove()
    document.removeEventListener("mousedown",this.md,false)
  }

  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Groups", url:`/usa/c18/admin/groups`},
            ]},
        },
      )
    }
  }

  loadChannels=()=>{
    let pa=this.props.parms
    let zones=globs.zonesInfo.info.filter(z=>{return z.siteId==pa.site})
    let chIndId=getParamId("configuration_channels","channelIndex")
    let chNameId=getParamId("configuration_channels","channelName")
    let chTypeId=getParamId("configuration_channels","channelType")
    let chUsedId=getParamId("configuration_channels","used")
//     cl(chNameId)
//     cl(zones)
//     cl(dbVals)
    this.chans=[]//{}
    this.chanMap={}
    zones.forEach(z=>{
//       cl(z)
//       let zoneChans=[]
      let zInd=z.siteZoneIndex
      for(let i=0;i<40;i++){
        let chName=dbVals.z[zInd][i][chNameId]
        let gwType=z.gatewayType||1800
        let typeId=getChannelTypeId(gwType,zInd,i)
        let type=dbVals.z[zInd][i][chTypeId]
        let used=dbVals.z[zInd][i][chUsedId]
        let chanType=getChannelType(gwType,zInd,i)
//         cl(this.fuiPageMap)
        if(used&&(type!=0)){
//           cl(this.fuiPageMap[chanType])
//           cl(typeId,chName)
//           cl(this.fuiPageMap)
//           cl(chanType)
//           cl(chanType)
          let controls=this.fuiPageMap[chanType].controls
//           cl(z)
//           cl(gwType)
          let keys=controls.filter(c=>{return c.pid>=0}).map(c=>{
//             c.tabCol=paramIdToTableColumn(c.pid,gwType)
            return `${gwType}-${c.pid}-${c.type}`
          })
//           cl(keys)
//           cl(z.gatewayType)
          let key=`${z.zoneId}-${i}`
          let chan={
            used:used,
            ind:dbVals.z[zInd][i][chIndId],
            name:dbVals.z[zInd][i][chNameId],
            zName:z.zoneName,
            zInd:z.siteZoneIndex,
            gwType:gwType,
            type:type,
            chanType:chanType,
            controls:controls,
            keys:keys,
            typeName:constant.CHAN_TYPES[typeId||"0"],
            key:key,
//             controlCount:this.fuiPageMap[chanType].controls.length,
          }
          this.chanMap[key]=chan
          this.chans.push(chan)
        }
      }
//       this.chans[z.zoneId]=zoneChans
    })
//     cl(this.chanMap)
//     cl(this.chans)
  }

  loadFuiPages=async()=>{// this actually has to be controllerType-specific
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/fuiPages", method: "retrieve",
      sessionId: globs.userData.session.sessionId, body: {gatewayType:1800}})
//     cl(res)
    this.fuiPages=res.data
//     cl(this.fuiPages)
    this.fuiPageMap={}
    this.controlTabCol={}
    this.fuiPages.forEach(fp=>{
//       cl(fp)
      this.fuiPageMap[fp.type]=fp
      fp.controls.forEach(c=>{
        if(c.pid>=0){
          let key=`${fp.gatewayType}-${c.pid}-${c.type}`
          this.controlTabCol[key]=paramIdToTableColumn(c.pid,fp.gatewayType)
        }
      })
    })
//     cl(this.fuiPageMap)
//     cl(this.controlTabCol)
  }

  loadGroups=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/groups", method: "retrieve",
      sessionId: globs.userData.session.sessionId, body: {}})
    let groups=res.data
//     cl(res.data)
    groups.forEach(d=>{
      let z0Id=d.members[0].split("-")[0]
//       cl(z0Id)
      let z0=globs.zonesInfo.info.filter(z=>{return z.zoneId==z0Id})
//       cl(z0)
      d.v=d.groupId
      d.t=d.name
      d.gwType=z0.gatewayType||1800
//       if(d.type=="zone"){
//         let z0=globs.zonesInfo.info.filter(z=>{return z.zoneId==d.members[0]})[0]
//         d.gwType=z0.gatewayType||1800
//         cl(z0)
//       }
    })
    groups.sort((a,b)=>{
      if(a.name>b.name){return 1}
      if(a.name<b.name){return -1}
      return 0
    })
//     cl(groups)
    return groups
  }

  loadInfo=async()=>{
    let pa=this.props.parms
    await loadZonesInfo()
    this.zoneMap={}
    globs.zonesInfo.info.forEach(z=>{this.zoneMap[z.zoneId]=z})
//     cl(this.zoneMap)
    await loadSiteData(pa.site)
    let groups=await this.loadGroups()
//     cl(groups[0])
    await this.loadFuiPages()
    this.loadChannels()
    this.sorts=[1,2,3]
    let vals={loaded:true,groups:groups}

//     cl("load info call")
    vals.commonControls=this.getCommonControls(vals)
//     cl(vals)
    this.setState(vals)
  }

  notify=(note)=>{
//     cl(note)
    if(note.unMount){// called when widget is unmounted
      this.notifies[note.id].forEach((n,i)=>{
        if(n==note.func){
          this.notifies[note.id].splice(i,1)
        }
      })
    }else{
      if(note.id in this.notifies){}else{
        this.notifies[note.id]=[]
      }
      this.notifies[note.id].push(note.func)
    }
  }

  doNotify=(event,cmd,data)=>{
    var res
    if(event in this.notifies){
      res=this.notifies[event].map(f=>{
        return f({cmd:cmd, data:data})
      })
    }
    return res
  }



  saveApplyGroup=async()=>{
    let st=this.state
    let res=this.doNotify("localSave")[0]
//     cl(res)
    let gr=st.groups.filter(gr=>{return gr.v==st.selGroup})[0]// get selected group
//     cl(gr)
//     cl(this.chanMap)
    let updType=(gr.type=="zone")?"zone":"channel"
    let pNames=res.map(r=>{
      let [tab,col]=paramIdToTableColumn(r.i,gr.gwType)
      return col
    })
    var zNames=[]
    var sendArr=[]
    if(gr.type=="chan"){
      zNames=gr.members.map(m=>{// go through the channels
        let chan=this.chanMap[m]
        res.forEach(r=>{
//           cl(r)
          let pObj=Object.assign({},r)
          pObj.z=chan.zInd
          pObj.c=chan.ind
          sendArr.push(pObj)
        })
        return `${chan.zName}-${chan.name}`
      })
//       cl(sendArr)
    }else{
      zNames=gr.members.map(m=>{
        let zone=this.zoneMap[m]
//         cl(zone)
        return `${zone.zoneName}`
      })
    }
//     cl(zNames)
    let vPlural=(res.length!=1)?"s":""
    let zPlural=(gr.members.length!=1)?"s":""
    let msg=`This will update ${res.length} value${vPlural} (${pNames.join(", ")}) on ${gr.members.length} ${updType}${zPlural} (${zNames.join(", ")}). Is this \
      what you want to do?`
    let confirm=await this.props.parms.getPopup({text:msg,
      buttons:["Cancel","Yes"]})
    cl(confirm)
    if(confirm=="Yes"){
//       cl("ok do it")
      let virtual=false
      let controllerId=""
      let localOnly=false
//       cl(virtual,gr.gwType,controllerId,localOnly)
      sendArray(sendArr,virtual,gr.gwType,controllerId,localOnly)
        .then(e=>{globs.events.publish("saveOK",true)});
    }else{// need to restore original values
      globs.events.publish("newContext")
      cl("new cont")
    }
  }

  saveGroup=async(cmd,data)=>{
    let st=this.state
    if(st.pageType=="apply"){return this.saveApplyGroup()}
//     return
    let pa=this.props.parms
    if(cmd=="save"){
      let gr=st.groups.filter(gr=>{return gr.v==st.selGroup})[0]
      cl(gr)
      let group={
        groupId:st.selGroup,
        name:gr.t,
        members:gr.members,
        siteId:pa.site,
        type:gr.type,
//         type:"chan",

      }
      let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/groups", method: "update",
        sessionId: globs.userData.session.sessionId, body: group})
    }
    globs.events.publish("saveOK",true)
}

  deleteGroup=async(groupId)=>{
    let st=this.state
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/groups", method: "delete",
      sessionId: globs.userData.session.sessionId, body: {groupId:st.selGroup}})
  }

  doSortMembers=(vals,sort)=>{
//     cl(vals)
    let st=Object.assign({},this.state,vals)
//     let sort=st.memberSortMode
    var pv=(obj,col)=>{
      if(["zName","typeName","name"].includes(col)){
//         cl(obj,col)
        return (obj[col]||"").toLowerCase()
      }else{
        return obj[col]
      }

    }
    let sortCols=["","sel","zName","ind","typeName","name"]
    this.sorts.pop()
    this.sorts.unshift(sort)
//     cl(sort)
//     cl(this.sorts)
//     cl(this.sorts)

//     let st=this.state
    let sInd=Math.abs(sort)
    let sInd2=Math.abs(this.sorts[1])
    let sortCol=sortCols[sInd]
    let sortCol2=sortCols[sInd2]
    let sDir=(sort>0)?1:-1
    let sDir2=(this.sorts[1]>0)?1:-1

    let gr=st.groups.filter(gr=>{return gr.v==st.selGroup})[0]
//     cl(st.selGroup)
    if([sInd,sInd2].includes(1)){
      this.chans.forEach(c=>{
        c.sel=!(gr.members||[]).includes(c.key)
      })
    }

//     cl(sortCol,sortCol2)
    this.chans.sort((a,b)=>{
      if(pv(a,sortCol)>pv(b,sortCol)){return sDir}
//       if(a[sortCol]>b[sortCol]){return sDir}
      if(pv(a,sortCol)<pv(b,sortCol)){return 0-sDir}
//       if(a[sortCol]<b[sortCol]){return 0-sDir}

      if(a[sortCol2]>b[sortCol2]){return sDir2}
      if(a[sortCol2]<b[sortCol2]){return 0-sDir2}
      return 0
      return 0
    })
  }

  getFuiPageType=(vals)=>{// when a group is selected
    let st=Object.assign({},this.state,vals||{})
//     cl(st)

    let gr=st.groups.filter(gr=>{return gr.v==st.selGroup})[0]// the selected group
//     cl(gr)
    var key,chan,pageType
    if(gr.type=="zone"){
      pageType=st.selConfig//chan.chanType
    }else{
      key=(gr.members||[])[0]||""
      chan=this.chans.filter(c=>{return c.key==key})[0]
      pageType=chan.chanType
    }
//     cl(pageType)
    return pageType

  }

  onChange=async(type,vals)=>{
//     cl(type,vals)
    let st=this.state
    var groups,gr
    switch(type){
      case "selGroup":
        vals.fuiPageType=this.getFuiPageType(vals)
        this.fuiPageType=vals.fuiPageType
        this.doSortMembers(vals,st.memberSortMode)
        vals.commonControls=this.getCommonControls(vals)
        cl(vals)
        cl(vals.fuiPageType)
        await this.setState(vals)
        cl(this.state)
        cl(this.state.fuiPageType)
//         globs.events.publish("newContext")
        break
      case "groupName":
        globs.events.publish("savePageEnable",true)
        groups=st.groups.slice(0)
        gr=groups.filter(gr=>{return gr.v==st.selGroup})[0]
        gr.t=vals.groupName
        this.setState({groups:groups})
        break
      case "groupType":
        globs.events.publish("savePageEnable",true)
        vals.members=[]
        vals.selGroup=""
//         vals.commonControls=this.getCommonControls(vals)
        this.setState(vals)
        break
      case "sortMembers":
        if(vals.memberSortMode==Math.abs(st.memberSortMode)){
          vals.memberSortMode=0-st.memberSortMode
        }
        this.doSortMembers(vals,vals.memberSortMode)
        this.setState(vals)
        break
      case "member":
        globs.events.publish("savePageEnable",true)
//         let members=(st.groups.members||[]).slice(0)
        groups=st.groups.slice(0)
        gr=groups.filter(gr=>{return gr.v==st.selGroup})[0]
        if(!gr.members){gr.members=[]}

        if(gr.members.includes(vals.key)){
          let pos=gr.members.indexOf(vals.key)
          gr.members.splice(pos,1)
        }else{
          gr.members.push(vals.key)
        }
//         cl(vals.key)
        delete vals.key
        vals.groups=groups
        vals.commonControls=this.getCommonControls(vals)
        this.setState(vals)
//         this.setState({groups:groups})
        break
      case "deleteGroup":
        groups=st.groups.slice(0)
        groups=groups.filter(g=>{return (g.v!=st.selGroup)})
        this.deleteGroup(st.selGroup)
        vals={groups:groups,selGroup:groups[0].v}
        vals.commonControls=this.getCommonControls(vals)
        this.setState(vals)
        break
      case "addGroup":
        groups=st.groups.slice(0)
        let groupId=getRandomString(16)
        groups.push({t:"New Group",v:groupId,type:st.groupType})
        vals={groups:groups,selGroup:groupId}
        vals.commonControls=this.getCommonControls(vals)
        this.setState(vals)
        break
      case "selConfig":
        vals.commonControls=this.getCommonControls(vals)
      case "groupPage":// edit or apply page
//         cl(vals)
        this.setState(vals)
        break
      case "conflict":
        this.conflictClick(vals)
        break
      case "overrideConflict":
        let overrides=st.conflictOverrides.slice(0)
        overrides.push(st.conflictKey)
        cl(overrides)// an array of the keys that have been overridden
//         cl(st.conflictKey)
        this.setState({showConflictPopup:false,conflictOverrides:overrides})
      case "cancelConflict":
        this.setState({showConflictPopup:false})
        break
    }
  }

  showSelectGroup=()=>{
    let st=this.state
//     cl(st)
//     cl(st.selGroup)
//     cl(st)
//             let vals={}
//             vals[parms.v]=e.currentTarget.value
    return(
      <div style={{width:300}}>
        <label htmlFor="selGroup">Select Group</label>
        <C18Select00 id="selGroup"
          parms={{list:true,height:200}}
          value={st.selGroup}
          onChange={e=>{
//             cl("on change x")
            let vals={selGroup:e.currentTarget.value}
            this.onChange("selGroup",vals)}
          }
        >
          {makeOpts(st.groups.filter(g=>{return g.type==st.groupType}))}
        </C18Select00>
      </div>
    )
  }

  showEditGroup=()=>{
    let st=this.state
//     cl(st)
    let gr=st.groups.filter(gr=>{return gr.v==st.selGroup})[0]
    if(!gr){return}
//     cl(gr)
    let grName=gr.t
    return(
      <div>
        <label htmlFor="groupName">Group Name</label>
        <input id="groupName" type="text"
          value={grName}
          style={{display:"inline-block"}}
          onChange={e=>this.onChange("groupName",{groupName:e.target.value})}
        />
        <div style={{display:"inline-block"}}>
          <button type="button" className="material-icons trash after-selector"
          aria-label="delete group"
          onClick={e=>{
            cl("click")
            this.onChange("deleteGroup",{a:5})}
          }
          style={{marginBottom:-10}}
          >
            delete_outline
          </button>

          <button type="button" className="material-icons-outlined add after-selector"
          aria-label="add group"
          onClick={e=>this.onChange("addGroup")}
          style={{marginBottom:-10}}
          >
            add
          </button>
        </div>
    <div className="clearfloat"></div>
      </div>
    )
  }

  showGroupType=()=>{
    let st=this.state
//     cl(st)
    let labelStyle={display:"inline-block", paddingRight:20}
    return(
      <div>
        <label htmlFor="groupType">Group Type</label>
        <div id="groupType">
          <input type="radio" id="zone" name="groupType" value="zone"
            checked={st.groupType=="zone"}
            onChange={e=>this.onChange("groupType",{groupType:e.currentTarget.value})}
          />
          <label htmlFor="zone" style={labelStyle}>Zones</label>
          <input type="radio" id="chan" name="groupType" value="chan"
            checked={st.groupType=="chan"}
            onChange={e=>this.onChange("groupType",{groupType:e.currentTarget.value})}
          />
          <label htmlFor="chan" style={labelStyle}>Channels</label>
        </div>
      </div>
    )
  }

  showZoneMembers=()=>{
    var showOneHead=(ind,txt)=>{
      return(
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sortMembers",{memberSortMode:ind+1})}
        >{txt} <span className="material-icons-outlined">{icons[ind]}</span></button></th>
      )
    }
    var showMemberHead=()=>{
      return(
        <tr style={pntStyle}>
          {showOneHead(0,"Select")}
          {showOneHead(1,"Zone")}
        </tr>
      )
    }
    let st=this.state
    let pa=this.props.parms
    let pntStyle={cursor:"pointer"}
    let icons=[]
    let zones=globs.zonesInfo.info.filter(z=>{return z.siteId==pa.site})
    let zoneLines=[]
    let gr=st.groups.filter(gr=>{return gr.v==st.selGroup})[0]
    if(!gr){return}
    zones.forEach((z,i)=>{
      var bgColor
      if((gr.members||[]).includes(z.zoneId)){bgColor="#CCDDFF"}
      zoneLines.push(
        <tr key={i}
          style={{cursor:"pointer",backgroundColor:bgColor}}
          onClick={e=>this.onChange("member",{key:z.zoneId})}
        ><td></td><td>{z.zoneName}</td>
        </tr>
      )
    })
    return(
      <div>
        <label htmlFor="members">Group Member Zones</label>
        <div id="members" style={{width:500,
          borderStyle:"solid",borderWidth:1,borderRadius:10,
          overflowY:"auto",padding:20
        }}
        >
          <table><tbody>
            {showMemberHead()}
            {zoneLines}
          </tbody></table>
        </div>
      </div>
    )
  }

  showMembers=()=>{
    let st=this.state
    var showOneHead=(ind,txt)=>{
      return(
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sortMembers",{memberSortMode:ind+1})}
        >{txt} <span className="material-icons-outlined">{icons[ind]}</span></button></th>
      )
    }
    var showMemberHead=()=>{
      return(
        <tr style={pntStyle}>
          {showOneHead(0,"Select")}
          {showOneHead(1,"Zone")}
          {showOneHead(2,"EQ Chan")}
          {showOneHead(3,"EQ Type")}
          {showOneHead(4,"EQ Name")}
        </tr>
      )
    }
    let pntStyle={cursor:"pointer"}
    let sort=st.memberSortMode// 1-5 for the columns
    let icons=[]
    for(let i=0;i<6;i++){
      let icon=""
      if(Math.abs(sort)==i+1){
        icons.push((sort<0)?"keyboard_arrow_up":"keyboard_arrow_down")
      } else{
        icons.push("")
      }
    }
//     cl(icons)
    let chans=[]
//     Object.keys(this.chans).forEach(k=>{
//       let zoneId=k
      let gr=st.groups.filter(gr=>{return gr.v==st.selGroup})[0]
      if(!gr){return}
      this.chans.forEach((c,i)=>{

      if(c.used){
        var bgColor
        if((gr.members||[]).includes(c.key)){bgColor="#CCDDFF"}
        chans.push(
          <tr key={`${c.key}-${i}`}
            style={{cursor:"pointer",backgroundColor:bgColor}}
            onClick={e=>this.onChange("member",{key:c.key})}
          >
            <td></td>
            <td>{maxLen(c.zName,12)}</td>
            <td>{c.ind+1}</td>
            <td>{c.typeName}</td>
            <td>{c.name||"None"}</td>
          </tr>
        )
      }
    })
//     })
    return(
      <div>
        <label htmlFor="members">Group Member Channels</label>
        <div id="members" style={{width:500,
          borderStyle:"solid",borderWidth:1,borderRadius:10,
          overflowY:"auto",padding:20
        }}
        >
          <table><tbody>
            {showMemberHead()}
            {chans}
          </tbody></table>
        </div>
      </div>
    )
  }

//   channelTypeToFuiPage=(channelType)=>{
//
//   }

//   getCcValue=()=>{
// //     return dbVals.z[z][c][i]
//
//   }

  conflictClick=(vals)=>{
    let [x,ind]=vals.e.currentTarget.id.split("-")// get the control ID
//     cl(ind)
    let cont=this.group0.controls[ind]
//     cl(cont)
    let key=`${this.group0.gwType}-${cont.pid}-${cont.type}`
    let left=vals.e.currentTarget.offsetLeft+vals.e.currentTarget.offsetWidth
    let top=vals.e.currentTarget.offsetTop
//     cl(left,top,key)
    this.setState({showConflictPopup:true,conflictLeft:left,conflictTop:top,
      conflictKey:key,
    })
  }

  conflictHandler=(cont,i)=>{
    let wid=this.controlRefs[i]?.current?.clientWidth||0
    let hgt=this.controlRefs[i]?.current?.clientHeight||0
//     cl(this.controlRefs.length)
//     cl(wid,hgt)
    return(
      <div key={i} ref={this.controlRefs[i]} style={{position:"relative",
        padding:10,cursor:"pointer"
      }}
      onClick={e=>this.onChange("conflict",{e:e})}
      id={`handle-${i}`}
      >
          {cont}
          <div style={{position:"absolute",left:0,top:0,width:wid,height:hgt,backgroundColor:"#888888",
            opacity:0.3,borderRadius:10,color:"red",textAlign:"center",
           fontSize:40,paddingTop:(hgt-40)/2,
          }}
          >
          Conflict
          </div>

      </div>
    )
  }

  md=(e)=>{// close conflict popup when clicked outside
    let st=this.state
    let [x,y]=[e.clientX,e.clientY]
    if(
      (x<st.conflictLeft)||(x>st.conflictLeft+st.conflictWidth)||
      (y<st.conflictTop)||(y>st.conflictTop+st.conflictHeight)
    ){
      if(st.showConflictPopup){this.setState({showConflictPopup:false})}
    }
  }

  keyUp=(e)=>{
//     cl(e)
    if(e=="Escape"){
      this.setState({showConflictPopup:false})
    }
  }

  showConflict=()=>{
//     return
    if(!this.commonValues){return}
    let st=this.state
//     cl(st)
//     if(st.groupType=="zone"){return}
    let key=st.conflictKey
//     if(!key){return}
//     cl(this.commonValues[key])
//     cl(this.commonValues)
//     cl(key)
    let vals=this.commonValues[key]
//     cl(vals)
    if(!vals){return}
    var chans
    if(st.groupType=="zone"){
      chans=Object.keys(vals).filter(k=>{return k!="conflict"})
      .map((k,i)=>{
        let zone=this.zoneMap[k]
//         cl(k)
//         cl(zone)
        let name=`${zone.zoneName}`
        let val=vals[k]
        return(
          <tr key={i} ><td>{`${name}`}</td><td>{val}</td></tr>
        )
      })
    }else{
      chans=Object.keys(vals).filter(k=>{return(k.indexOf("-")>=0)})
      .map((k,i)=>{
        let chan=this.chanMap[k]
  //       cl(chan)
        let name=`${chan.zName}-${chan.name}`
        let val=vals[k]
        return(
          <tr key={i} ><td>{`${name}`}</td><td>{val}</td></tr>
        )
      })
    }
//     cl(vals)


//     cl(this.controlTabCol)
//     cl(key)
    let tc=this.controlTabCol[key]
    if(!tc){return}
    let [tab,col]=tc
    let name=`${tab}-${col}`
    let itemName=(st.groupType=="zone")?"zones":"channels"
    if(st.showConflictPopup){
//       cl("show it")
//       let chans=<tr><td>line</td></tr>
      return(
        <div style={{position:"absolute",left:st.conflictLeft,top:st.conflictTop,width:st.conflictWidth,
//           height:st.conflictHeight,
          backgroundColor:"white",zIndex:2,borderRadius:10,boxShadow:"10px 10px 24px #00000080",
          borderStyle:"solid",borderWidth:1,textAlign:"center",padding:20}}>
          <h2>{name}</h2>
          <p>If you change this setting, all of these {itemName} will be set to the same value. Currently, they have different settings:
          </p>
          <table><tbody>
          {chans}
          </tbody></table>
          <p>You can override this conflict, and set all of these {itemName} to the same value, or you can cancel this operation, and leave them as they are.
          </p>
          <C18Button00 type="button" className="outlined" onClick={()=>this.onChange("overrideConflict",{})}>
            Override</C18Button00>
          <C18Button00 type="button" className="filled" onClick={()=>this.onChange("cancelConflict",)}
          style={{marginLeft:20}}>
            Cancel</C18Button00>
        </div>
      )
    }else{return null}
  }

  getCommonControlValues=(groupChans,commonControls)=>{
// for each channel, for each control, get the value
//     cl(groupChans)
//     cl(commonControls)
    let commonValues={}
    commonControls.forEach(cc=>{
      let [cont,pid,type]=cc.split("-")
      let contValues={}
      var val0,conflict=false
      groupChans.forEach((ch,i)=>{
        let [zoneId,chan]=ch.key.split("-")
//         cl(zoneId,chan)
        let zone=globs.zonesInfo.info.filter(z=>{return z.zoneId==zoneId})[0]
//         cl(zone)
        let val=dbVals.z[zone.siteZoneIndex][chan][pid]
        contValues[ch.key]=val
        if(i){
          conflict=conflict||(val!=val0)
        }else{
          val0=val
        }
      })
      contValues.conflict=(conflict)?this.conflictHandler:null
      commonValues[cc]=contValues
    })
    return commonValues
//     cl(commonValues)
  }

  getZoneCommonControlValues=(config,groupZones,commonControls)=>{
// for each channel, for each control, get the value
//     cl(groupChans)
//     cl(commonControls)
//     cl(groupZones)
    let commonValues={}
//     cl(config)
    let configType=config.split("_")[0]
    commonControls.forEach(cc=>{
      let [cont,pid,type]=cc.split("-")
      let contValues={}
      var val0,conflict=false
      groupZones.forEach((zo,i)=>{
        let zoneId=zo
//         let [zoneId,chan]=ch.key.split("-")
//         cl(zoneId,chan)
        let zone=globs.zonesInfo.info.filter(z=>{return z.zoneId==zoneId})[0]
//         cl(zone)
        let chan=(configType=="zone")?255:240
        let val=dbVals.z[zone.siteZoneIndex][chan][pid]

        contValues[zoneId]=val
        if(i){
          conflict=conflict||(val!=val0)
        }else{
          val0=val
        }
      })
      contValues.conflict=(conflict)?this.conflictHandler:null
      commonValues[cc]=contValues
    })
//     cl(commonValues)
    return commonValues
//     cl(commonValues)
  }

  getZoneCommonControls=(gr,vals)=>{
//     cl("zone conts")
//     cl(vals)
//     cl(gr)
    let st=Object.assign({},this.state,vals)
//     cl(st.selConfig)
    let fuiPage =this.fuiPageMap[st.selConfig]
    if(!fuiPage){return}
//     cl(fuiPage)
    let controls=fuiPage.controls
    let z0=globs.zonesInfo.info.filter(z=>{return z.zoneId==gr.members[0]})
//     cl(z0)
    let gwType=z0.gatewayType||1800
    this.group0={controls:controls,gwType:gwType}
    let commonControls=controls.filter(c=>{return c.pid>=0}).map(c=>{
      return `${gwType}-${c.pid}-${c.type}`
    })

//     cl(commonControls)
    for(let i=0;i<(commonControls||[]).length/*controlCount*/;i++){
      if(!this.controlRefs[i]){this.controlRefs[i]=React.createRef()}
    }
//     cl(controls)
//     cl(commonControls)
    this.commonValues=this.getZoneCommonControlValues(st.selConfig,gr.members,commonControls)
    return commonControls
  }

  getCommonControls=(vals)=>{// vals will update the state, use the new values
//     cl("get common")
//     cl(this.chans)
    let st=Object.assign({},this.state,vals)
//     cl(st)
//     cl("still")
    let gr=st.groups.filter(gr=>{return gr.v==st.selGroup})[0]// get selected group
    if(!gr){return[]}
    if(st.groupType=="zone"){return this.getZoneCommonControls(gr,vals)}
    let members=gr.members||[]
    if(members.length==0){cl("No Members");return[]}
    let groupChans=this.chans.filter(c=>{return members.includes(c.key)})// all the chans in the group
// each chan has an array of "keys" for the controls that it has
//     cl(groupChans[0])
//     this.controls=groupChans[0].controls
    this.group0=groupChans[0]||{}
//     cl(this.group0)
    let commonControls=this.group0.keys||[]
//     cl(groupChans[0].keys)
//     cl(this.group0.controls)
    for(let i=0;i<(this.group0.controls||[]).length/*controlCount*/;i++){
      if(!this.controlRefs[i]){this.controlRefs[i]=React.createRef()}
    }
    for(let i=1;i<groupChans.length;i++){// for each chan, filter to the controls this chan has
      let keys=groupChans[i].keys
      commonControls=commonControls.filter(cc=>{
        return keys.includes(cc)
      })
    }
//     cl(commonControls)
    this.commonValues=this.getCommonControlValues(groupChans,commonControls)
//     cl(this.commonValues)
//     cl(commonControls)
    return commonControls
  }

  showCommonControls=()=>{
    let st=this.state
//     cl(st)
//     cl("get")
    let commonControls=st.commonControls//this.getCommonControls()
    if(!commonControls){return}
//     cl(commonControls)
    return(
      <div>
        <label htmlFor="commonControls">Group Common Controls</label>
        <div id="commonControls" style={{width:300,height:300,overflowY:"auto",
          borderStyle:"solid",borderWidth:1,borderRadius:10,padding:10,
        }}>
        {commonControls.map((cc,i)=>{
//           cl(this.controlTabCol[cc])
          let [tab,col]=this.controlTabCol[cc]
          return(
          <div key={i}>{`${tab}-${col}`}</div>
        )})}
        </div>
      </div>
    )
  }

  editGroups=()=>{
    let st=this.state
//     cl(st)
    var members
    switch(st.groupType){
      case "chan":
        members=(
          <div>
            <div className="clearfloat"></div><br/>
            {this.showMembers()}
            <div className="clearfloat"></div><br/>
            {this.showCommonControls()}
          </div>
        )
        break
      case "zone":
        members=(
          <div>
            <div className="clearfloat"></div><br/>
            {this.showZoneMembers()}
          </div>
        )
        break
    }
    return(
      <div>
        <C18SubMenuHeader00 parms={{
          items:[
            {v:"edit",t:"Edit Groups"},
            {v:"apply",t:"Apply a Group"},
          ],
          pageType: this.state.pageType,
          onChange: o=>this.onChange("groupPage",o),
        }}/>
        <div className="clearfloat"></div><br/>
        {this.showSelectGroup()}
        <div className="clearfloat"></div><br/>
        {this.showEditGroup()}
        <div className="clearfloat"></div><br/>
        {this.showGroupType()}
        {members}
      </div>
    )
  }

  showFuiFields=()=>{
//     cl("show fui")
//     console.trace()
    let st=this.state
//     cl(st)
//     cl(this.props)
//     cl(this.fuiPageMap)
//     cl(this.controlTabCol)
//     cl(this.chans)
    let gr=st.groups.filter(gr=>{return gr.v==st.selGroup})[0]// the selected group
//     cl(gr)
    if(!gr||((gr.type=="zone")&&(!st.selConfig))){return}
    var key,parts,zoneId,chanId,zone,zInd,gwType,chan,pageType,commonControls
//     cl(gr)
    if(gr.type=="zone"){
      key=(gr.members||[])[0]||""
  //     cl(key)
      parts=key.split("-")
      zoneId=parts[0]
//       chanId=+parts[1]
      chanId=255
      zone=globs.zonesInfo.info.filter(z=>{return z.zoneId==zoneId})[0]
  //     cl(zone)
  //     cl(zone.siteZoneIndex)
      zInd=zone.siteZoneIndex
  //     cl(zInd)
      gwType=zone.gatewayType||1800
      chan=this.chans.filter(c=>{return c.key==key})[0]
      cl(chan)
      pageType=st.selConfig//chan.chanType
      commonControls=st.commonControls//this.getCommonControls(this.state)
//       cl(commonControls)
//       cl("set")
//       return
    }else{
      key=(gr.members||[])[0]||""
      parts=key.split("-")
      zoneId=parts[0]
      chanId=+parts[1]
      zone=globs.zonesInfo.info.filter(z=>{return z.zoneId==zoneId})[0]
      zInd=zone.siteZoneIndex
      gwType=zone.gatewayType||1800
      chan=this.chans.filter(c=>{return c.key==key})[0]
//       cl(chan)
//       cl(key)
      pageType=chan.chanType
      commonControls=st.commonControls//this.getCommonControls(this.state)
    }
//     cl(gr)
//     cl(commonControls)
//     cl(gr)
//     cl(key)
//     cl(chan)

//     let zInd=0
    let unit=0
//     let sInd=1
//     let pageType="channel_Irrigation_Scheduled"
//     cl(sInd)
//     cl(pageType)
//     return(<div>test</div>)
//     cl(this.fuiPageType)
    return(
      <div>
        <LiveFui
          parms={{
          getPopup:o=>this.getPopup("admin",o),
          mode:"c18",
          onChange:o=>this.onChange("fui",o),
          pageModified:false,
          gatewayType:gwType,
          commonControls:commonControls,
          commonValues:this.commonValues,
          conflictOverrides:st.conflictOverrides,
        }}
          match={{
          params:{
            pageType:this.fuiPageType,//st.fuiPageType,//pageType,
            zuci:`${zInd}-${unit}-${chanId}-0`,
//             url:`/usa/c18/fui/intemp_sensor/0-${unit}-240-${sInd}`,
          }
          }}
          notify={this.notify}
        />
      </div>
    )
  }

  showConfigSelect=()=>{
    let st=this.state
    let gr=st.groups.filter(gr=>{return gr.v==st.selGroup})[0]
    if((!gr)||(gr.type!=st.groupType)){return}
    let z0=globs.zonesInfo.info.filter(z=>{return z.zoneId==gr.members[0]})[0]
    if(!z0){return}
//     if(!z0){return}
    let gwType=z0.gatewayType||1800
//     cl(z0)
//     cl(gr)
//     cl(st)
    if(st.groupType!="zone"){return}
    let pages={1800:[
      {v:"HumDeHum",t:"Hum and DeHum"},
      {v:"setpoints",t:"Setpoints"},
      {v:"temp_Staging",t:"Temperature Staging"},
      {v:"unit_Accumulator",t:"Accumulator"},
      {v:"unit_Analog_Temp_Calibration",t:"Analog Temperature Calibration"},
      {v:"unit_Analog_Temp_Mapping",t:"Analog Temperature Mapping"},
      {v:"unit_Generic_Calibration",t:"Generic Calibration"},
      {v:"unit_Generic_Mapping",t:"Generic Mapping"},
      {v:"unit_Input_Calibration",t:"Input Calibration"},
      {v:"unit_Input_Mapping",t:"Input Mapping"},
      {v:"unit_Input_Multipliers",t:"Input Multipliers"},
      {v:"unit_Irrigation_Sensor_Mapping",t:"Irrigation Sensor Mapping"},
      {v:"unit_Miscellaneous",t:"Miscellaneous"},
      {v:"unit_Mixing_Tank_Calibration",t:"Mixing Tank Calibration"},
      {v:"unit_Mixing_Tanks",t:"Mixing Tanks"},
      {v:"unit_Network_Sensors",t:"Network Sensors"},
      {v:"unit_Soil_Moisture_Calibration",t:"Soil Moisture Calibration"},
      {v:"unit_Vent_Position_Calibration",t:"Vent Position Calibration"},
      {v:"unit_Vent_Position_Mapping",t:"Vent Position Mapping"},
      {v:"zone_Alarms",t:"Alarms"},
      {v:"zone_Aux_Alarms",t:"Aux Alarms"},
      {v:"zone_Aux_Controls",t:"Aux Controls"},
      {v:"zone_Aux_PVariables",t:"Aux PVariables"},
      {v:"zone_Aux_Variables",t:"Aux Variables"},
      {v:"zone_Deadband",t:"Deadband"},
      {v:"zone_Fallback",t:"Fallback"},
      {v:"zone_H-C_Demand",t:"H-C Demand"},
      {v:"zone_History",t:"History"},
      {v:"zone_Hum_DeHum",t:"Hum DeHum"},
      {v:"zone_Irrigation",t:"Irrigation"},
      {v:"zone_Lighting",t:"Lighting"},
      {v:"zone_Output",t:"Output"},
      {v:"zone_Peristaltic",t:"Peristaltic"},
      {v:"zone_Setpoints",t:"Setpoints"},
      {v:"zone_Smartcool",t:"Smartcool"},
      {v:"zone_Smartcool",t:"Smartcool"},
      {v:"zone_SP_Drive_to_Avg",t:"SP Drive to Avg"},
      {v:"zone_SP_Influence_Factors",t:"SP Influence Factors"},
      {v:"zone_SP_Retractable_Greenhouse",t:"SP Retractable Greenhouse"},
      {v:"zone_Stages",t:"Stages"},
      {v:"zone_Units",t:"Units"},
    ],
    }
    return(
      <div style={{width:300}}>
        <label htmlFor="selGroup">Select Configuration Page</label>
        <C18Select00 id="selGroup"
          parms={{list:true,height:200}}
          value={st.selConfig}
          onChange={e=>{
            let vals={selConfig:e.currentTarget.value}
            this.onChange("selConfig",vals)}
          }
        >
          {makeOpts(pages[gwType])}
        </C18Select00>
      </div>
    )
  }

  applyGroup=()=>{
//     cl("apply group")
//         <div className="clearfloat"></div><br/>
//         {this.showEditGroup()}
//         <div className="clearfloat"></div><br/>
//         {this.showGroupType()}
//         <div className="clearfloat"></div><br/>
//         {this.showMembers()}
//         <div className="clearfloat"></div><br/>
//         {this.showCommonControls()}
    let st=this.state
    let gr=st.groups.filter(gr=>{return gr.v==st.selGroup})[0]
//     cl(gr)
    return(
      <div>
        <C18SubMenuHeader00 parms={{
          items:[
            {v:"edit",t:"Edit Groups"},
            {v:"apply",t:"Apply a Group"},
          ],
          pageType: this.state.pageType,
          onChange: o=>this.onChange("groupPage",o),
        }}/>
        <div className="clearfloat"></div>
        <h2>{gr?.name||"No Group Selected"}</h2>
        <div className="clearfloat"></div><br/>
        {this.showSelectGroup()}
        <div className="clearfloat"></div><br/>
        {this.showConfigSelect()}
        <div className="clearfloat"></div><br/>
        {this.showFuiFields()}
        {this.showConflict()}
      </div>
    )
  }

  render(){
    let st=this.state
//     cl(st)
    let pages={"edit":this.editGroups,"apply":this.applyGroup}
    if(st.loaded){
      return pages[st.pageType]()
    }else{
      return <div id="content-area">loading . . .</div>
    }
  }
}

export default C18GroupsEdit00 ;

