import React from 'react';
import C18Button00 from './C18Button00'
import C18Input00 from './C18Input00'
import UsaIcon from './UsaIcon';
import {makeImagePath} from './C18utils'
import {wsTrans,doGetPostBasic} from '../utils/utils'
import {cl,globs,constant,getRandomString} from '../../components/utils/utils';

class C18Images00 extends React.Component{
  constructor(props) {
    super(props)
    this.state={
      insertIndex:0,
    }
  }
  
/****************** Image Handling *******************************/
  sendImages=async(images)=>{// send new ones, right now
    let data = new FormData()
    images.forEach((img,i)=>{
      cl(["send",img])
      data.append("type", "message")
      data.append(`id${i}`, img.id)
      data.append(`file${i}`, img.image)
      cl(img.image)
    })
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
    cl(url)
    let r=await doGetPostBasic(url, method, data, type)// pro  mise
    let obj=await r.json()
    for(let i=0;i<images.length;i++){
      images[i].width=obj[i].width
      images[i].height=obj[i].height
      images[i].format=obj[i].format
      delete images[i].image
    }
    cl(obj)
  }
  
  markImage=(e)=>{
    let images=[]
    for(let i=0;i<e.target.files.length;i++){
      let fi=e.target.files[i]
      let pos=fi.name.lastIndexOf(".")
      let ext=fi.name.substring(pos+1)
      images.push({id: `${getRandomString(16)}.${ext}`, image: fi})
    }
    return images// only new images
  }
  
  updateImages=async(e)=>{
    let images=this.markImage(e)
    if(images.length){
      await this.sendImages(images)
      let imArr=this.props.parms.images.concat(images)
      this.props.parms.onChange({cmd:"images",images:imArr})
//       this.setState({images:imArr})
//       globs.events.publish("savePageEnable", true)
    }
  }
  
/****************** End Image Handling *******************************/

  showNewImageSelect=()=>{
    let buttonLabelColor = '';
    if (globs.device?.deviceTheme=="originalDark") {
      buttonLabelColor = 'white';
    }
    return(
      <div style={{position:"relative"}}>
        <C18Button00 type="button" aria-label="upload" style={{
          width:100, height:40, borderRadius:10,
          border:1,borderStyle:"solid",borderColor:"#AAAAAA",
          cursor:"pointer", color:buttonLabelColor
        }}>Add Image
        </C18Button00>
        <form className="file-upload-form">
        <C18Input00 type="file" multiple onChange={this.updateImages} style={{
          position:"absolute", 
          width:100, 
          height:40, 
          marginTop:0, 
          marginLeft:0,
          zIndex:10,                   
          opacity:0,
          cursor: "pointer",
        }}/>
        </form>
      </div>
    )
  }
  
//   makeImagePath=(id, ext)=>{
//     return `${id[0]}/${id[1]}/${id[2]}/${id.substr(3)}`// .${ext}
//   }
  
  showOneImage=(i,w,h,path)=>{
    var i,w,h,path
    return(
      <div key={i} style={{width: w, height: h}} className="image-popup" onMouseDown={e=>cl("mdown")}>
        <img width={w} src={`${constant.expressUrl}/usa/images/uploads/${path}`}
        style={{backgroundColor: "#C0FFC0", borderRadius: 10, 
          boxShadow: "5px 10px 10px #888888"}}
        onMouseDown={e=>this.mouseDownImage(e,i)} onMouseMove={this.mouseMoveImage} onMouseUp={this.mouseUp} 
        onDragStart={this.dragStartImage} onDragEnd={this.dragEnd}/>
        <div className="image-close-button">
          <UsaIcon icon={`im${i}-Cancel`} result={o=>this.onChange("imageIcon",o)}/>
        </div>
        
      </div>
    )
  }
  
  dEnter=(e,i)=>{
    if(this.state.dragIndex>=0){
      if(this.state.insertIndex!=i){
        this.setState({insertIndex:i})
      }
    }
  }

  dStart=(e,i)=>{
    if(this.props.parms.edit){
      this.setState({dragIndex:i})
    }
  }
  
  moveImageOrder=(start,end)=>{// move image from start position to end position
    if(end>start){end-=1}
    let images=this.props.parms.images.slice(0)
    let im=images.splice(start,1)[0]
    images.splice(end,0,im)
//     this.setState({images:images})
    this.props.parms.onChange({cmd:"images",images:images})
  }

  dEnd=(e)=>{
    if(this.state.dragIndex>=0){
      let st=this.state
      this.moveImageOrder(st.dragIndex,st.insertIndex)
      this.setState({dragIndex:-1,insertIndex:-1})
    }
  }
  
  showImageArray=()=>{
    let pa=this.props.parms
//     cl(pa)
    return (pa.images??[]).map((im,i)=>{
      let path=makeImagePath(im.id,"jpg")
      let totPath=`${constant.expressUrl}/usa/images/uploads/${path}`
      let disp=(i==this.state.insertIndex)?"inline-block":"none"
//             onMouseDown={e=>this.md(e,i)} 
//             onMouseUp={this.mu} 
      return(
        <div key={i} style={{display:"inline-block"}}>
          {pa.edit&&
            <div key={i+1000} style={{width:5,opacity: 0.3, height:80,backgroundColor:"#0080FF", 
              margin:5, 
              display:disp}}>
            </div>
          }
          <div key={i} style={{display:"inline-block",backgroundColor:"#FFFFFF",
            position:"relative", margin:10, boxShadow: "5px 5px 10px #808080"
            }}
            onClick={e=>pa.onChange({cmd:"image",id:i,path:path})}
          >
            {pa.edit&&
              <div style={{width:20, textAlign: "center", left:80, float:"right",position:"absolute", 
                cursor:"pointer", backgroundColor:"#EEEEEE",
              }}
              onClick={e=>pa.onChange("imgDelClick",{e:e,id:i})}
              >X</div>
            }
            <img key={i} src={totPath} width="100"
              onDragStart={e=>this.dStart(e,i)}
              onDragEnter={e=>this.dEnter(e,i)}
              onDragEnd={e=>this.dEnd(e,i)}
              />
          </div>
        </div>
      )
    })
  }
  
  showImages=()=>{
    let pa=this.props.parms
//     cl(pa)
    return(
      <>
        <label htmlFor="images">Images
        {pa.edit&&
          <div style={{marginLeft:20,display:"inline-block"}}>
            {this.showNewImageSelect()}
          </div>
        }
        </label>
        <div id="images" style={{width: 500, 
          borderRadius:10,
            border:1,borderStyle:"solid",borderColor:"#AAAAAA",
            padding:10,flexWrap:"wrap"}}>
            {this.showImageArray()}
        </div>
      </>
    )
  }
  
  render(){
    return this.showImages()
  }
}

export default C18Images00;
