import React from 'react';
// import C18DateFormat00 from './C18DateFormat00'
// import C18Button00 from './C18Button00'
// import C18Anchor00 from './C18Anchor00'
import C18Select01 from './C18Select01'
import {getParamId2} from '../utils/utils'
import {pInd} from '../../components/utils/paramIds'
import {dbVals,putZValue,sendArray} from '../../components/utils/http';
import {lc,pearlSensorIds} from '../../components/utils/landruConstants'
import {loadSitesInfo,loadZonesInfo,getZoneIndex,loadSiteData,getChannelType,
  getSiteName,getZoneName,} from './C18utils'
// import {getEquipmentStatus} from './C18EquipmentStatus00'
// import {dbVals,getZoneControllers} from '../../components/utils/http';
import {cl,globs,getTimeI} from '../../components/utils/utils';
// import {pInd} from '../../components/utils/paramIds';
//
// import history from "../../history"

class C18PearlMapping00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.inputIds={
      "0":"None",
      "1":"Serial Sensor 1",
      "2":"Serial Sensor 2",
      "3":"Analog Input 1",
      "4":"Analog Input 2",
      "5":"Analog Input 3",
      "6":"Analog Input 4",
      "7":"Analog Input 5",
      "8":"Analog Input 6",
      "9":"Analog Input 7",
      "10":"Analog Input 8",
      "11":"Fast Digital 1",
      "12":"Fast Digital 2",
      "13":"Fast Digital 3",
      "14":"Digital Input 1",
      "15":"Digital Input 2",
      "16":"Digital Input 3",
      "17":"Digital Input 4",
      "18":"Digital Input 5",
      "19":"Digital Input 6",
      "20":"Digital Input 7",
      "21":"Digital Input 8",
      "22":"Software",
      "23":"Remote",
      "24":"Exp Analog 1",
      "25":"Exp Analog 1",
      "26":"Sensor Module",
      "27":"Digital Temp / Hum",
      "28":"Sensor Module Backup",
      "29":"Modbus Input",
      "100":"Zone 1",
      "101":"Zone 2",
      "102":"Zone 3",
      "103":"Zone 4",
      "104":"Zone 5",
      "105":"Zone 6",
      "106":"Zone 7",
      "107":"Zone 8",
      "108":"Zone 9",
      "109":"Zone 10",
      "110":"Zone 11",
      "111":"Zone 12",
      "112":"Zone 13",
      "113":"Zone 14",
      "114":"Zone 15",
      "115":"Zone 16",
      "116":"Zone 17",
      "117":"Zone 18",
      "118":"Zone 19",
      "119":"Zone 20",
      "120":"Zone 21",
      "121":"Zone 22",
      "122":"Zone 23",
      "123":"Zone 24",
      "124":"Zone 25",
      "125":"Zone 26",
      "126":"Zone 27",
      "127":"Zone 28",
      "128":"Zone 29",
      "129":"Zone 30",
      "130":"Zone 31",
      "131":"Zone 32",
    }
    this.inputTypes={
      inTemp:[0,1,2,26,27,29,22,23,26,],
      inHum:[0,1,2,27,29,22,23,26],
      inLight:[0,3,4,5,6,7,29,22,23,26],
      inCo2:[0,22,23,26],
      outTemp:[0,1,2,27,29,22,23],
      bpTemp:[0,1,2,3,4,5,6,7,29,22,23],
      outHum:[0,1,2,27,29,22,23],
      outLight:[0,29,22,23,26],
      wndSpd:[0,29,22,23],
      wndDir:[0,9,29,22,23],
      rain:[0,11,12,13,29,22,23],
      snow:[0,14,15,16,17,18,19,20,21,29,22,23],
      baro:[0,3,4,5,6,7,29,22],
      buTemp:[0,1,2,26,27,29,22,23],
      irrTrig:[0,26,29,22,23],
      anTemp1:[0,1,2,26,27,29,22,23],
      anTemp2:[0,1,2,26,27,29,22,23],
      anTemp3:[0,1,2,26,27,29,22,23],
      anTemp4:[0,1,2,26,27,29,22,23],
      anTemp5:[0,1,2,26,27,29,22,23],
      ventPos1:[0,26,29,22,23],
      ventPos2:[0,26,29,22,23],
      ventPos3:[0,26,29,22,23],
      ventPos4:[0,26,29,22,23],
      ventPos5:[0,26,29,22,23],
      soil1:[0,26,29,22,23],
      soil2:[0,26,29,22,23],
      soil3:[0,26,29,22,23],
      soil4:[0,26,29,22,23],
      soil5:[0,26,29,22,23],
      gen1:[0,26,29,22,23],
      gen2:[0,26,29,22,23],
      loTemp:[0,1,2,26,27,29,22,23],
      volt1:[0,29],
      volt2:[0,29],
      volt3:[0,29],
      volt4:[0,29],
      volt5:[0,29],
      volt6:[0,29],
      volt7:[0,29],
      volt8:[0,29],
      exTemp6:[0,29],
      exTemp7:[0,29],
      exTemp8:[0,29],
      exTemp9:[0,29],
      exTemp10:[0,29],
      exTemp11:[0,29],
      exTemp12:[0,29],
      exTemp13:[0,29],
      exTemp14:[0,29],
      exTemp15:[0,29],
      exTemp16:[0,29],
      exTemp17:[0,29],
      exTemp18:[0,29],
      exTemp19:[0,29],
      exTemp20:[0,29],
      exTemp21:[0,29],
      exTemp22:[0,29],
      exTemp23:[0,29],
      exTemp24:[0,29],

//       loTemp:[0,1,2,26,27,29,22,23],

    }
    this.state={
      loaded:false,
    }
    this.loadInfo()
    this.setBreadCrumbs()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.savePage)
  }

  setBreadCrumbs=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
    let siteName=getSiteName(this.props.parms.site)
    let zoneName=getZoneName(this.props.parms.zone)
    this.props.parms.onChange(
      {
        cmd: "breadcrumbs",
        data:
          {breadcrumbs: [
            {t:"Sites", url:"/usa/c18/sites"},
            {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
            {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`},
            {t:`Sensor Settings`, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/settings/sensor1800`},
            {t:`Input Mapping`, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/settings/mapping`},
            {t:`Pearl Mapping`, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/pearl/mapping`},
          ]},
      },
    )
  }

  componentWillUnmount=()=>{
    if(this.subscribe_savePageEvent){this.subscribe_savePageEvent.remove()}
  }

  savePage=(cmd)=>{
    let zInd=this.zone.siteZoneIndex
    let ch=240
    let arr=[]
    let now=getTimeI()
    var putVal=(nVals,oVals,name,ofs,id)=>{
//       cl(nVals)
//       cl(oVals)
      if(nVals[name]!=oVals[name]){
//         cl(name)
        let i=ofs+this.inpMapIds[id]
        let d=nVals[name]
        if(putZValue(zInd,ch,i,d)){
          arr.push({
            c:ch,
            d:d,
            f:2,
            i:i,
            t:now,
            z:zInd,
          })
        }
      }
    }
    if(cmd=="save"){
      let st=this.state
      let inputs=st.inputs.slice(0)
      inputs.forEach((inp,i)=>{
        if(inp!=this.initInputs[i]){
          cl(inp)
          let ofs=i*this.inpMapIds.entSize
          let inpI=this.initInputs[i]
          putVal(inp,inpI,"mapping",ofs,"mappingId")
          putVal(inp,inpI,"channel",ofs,"channelId")
          putVal(inp,inpI,"board",ofs,"boardId")
        }
      })
      cl(arr)
      sendArray(arr,this.zone.virtual,this.zone.gatewayType).then(r=>{
        globs.events.publish("saveOK",true)
      })
    }
  }

  loadInputMap=()=>{
    let gwType=this.zone.gatewayType
    let zInd=this.zone.siteZoneIndex
    let ch=240
    let tab=pInd[gwType].pearl_sensor_config
    this.inpMapIds={
      mappingId:getParamId2(1900,"pearl_sensor_config","mapping"),
      channelId:getParamId2(1900,"pearl_sensor_config","channel"),
      boardId:getParamId2(1900,"pearl_sensor_config","board"),
      base:tab[0],
      entSize:tab[2],
      entCnt:tab[3]
    }
    let imi=this.inpMapIds;
    let inputs=[...Array(imi.entCnt)].map((ic,i)=>{
      let ofs=i*imi.entSize
      return{
        mapping:dbVals.z[zInd][ch][imi.mappingId+ofs],
        channel:dbVals.z[zInd][ch][imi.channelId+ofs],
        board:dbVals.z[zInd][ch][imi.boardId+ofs],
      }
    })
    return inputs
  }

  loadExpansions=()=>{
    let gwType=this.zone.gatewayType
    let zInd=this.zone.siteZoneIndex
    let ch=240
    let tab=pInd[gwType].config_expansion_boards
    let startInputId=getParamId2(gwType,"configuration_expansion_boards","startInput")
    let numInputId=getParamId2(gwType,"configuration_expansion_boards","numInputs")
    let boardTypeId=getParamId2(gwType,"configuration_expansion_boards","boardType")
    let mult=tab[2]
    let cnt=tab[3]
    this.c2bc={}
    this.bc2c={}
    this.b2type={};
    [...Array(cnt)].forEach((x,i)=>{
      let ofs=i*mult
      let pid=startInputId+ofs
      let board=i
      let start=+dbVals.z[zInd][ch][startInputId+ofs]||0
      let count=+dbVals.z[zInd][ch][numInputId+ofs]||0
      let bType=+dbVals.z[zInd][ch][boardTypeId+ofs]||0
      if(bType!=0){this.b2type[i]=bType}
      if(count){
        for(let j=0;j<count;j++){
          if(j==0){this.bc2c[i]={}}
          let ch=start+j// j is the channel on the board, ch is global, i is the board
          this.c2bc[ch]=[i,j]
          this.bc2c[i][j]=ch
        }
      }
    })
//     cl(this.bc2c)
//     cl(this.c2bc)
  }

  loadInfo=()=>{
// get mapping, board, chan for 128 entries:
    let maps=[]
    let pa=this.props.parms
    this.zone=globs.zonesInfo.info.filter(z=>{return z.zoneId==pa.zone})[0]
    let inputs=this.loadInputMap()
    this.initInputs=inputs.slice(0)
    this.loadExpansions()
    Object.assign(this.state,{loaded:true,inputs:inputs})
//     this.setState({loaded:true,inputs:inputs})

  }

  onChange=(type,vals)=>{
    let st=this.state
//     cl(st)
    cl(type,vals)
    var id,ind,inputs,bc
    switch(type){
      case "map":
        globs.events.publish("savePageEnable",true)
        id=Object.keys(vals)[0]
        ind=pearlSensorIds[id].i
        inputs=st.inputs.slice(0)
        let board=inputs[ind].board
//         cl(vals[id])
        if([lc.setup_t_InputMap_INPUTNAME_SENSOR_DTH,
          lc.setup_t_InputMap_INPUTNAME_MBUS_INPUT]
          .includes(+vals[id])/*==lc.setup_t_InputMap_INPUTNAME_SENSOR_DTH*/){
          board=Object.keys(this.bc2c)[0]
//           cl(this.bc2c)
//           cl(board)
        }
        inputs[ind]=Object.assign({},inputs[ind],{
          mapping:vals[id],
          board:board})// creates new object
//         cl(board)
//         cl(ind)
//         cl(inputs[ind])
//         cl(inputs)
        this.setState({inputs:inputs})
        break
      case "board":
        globs.events.publish("savePageEnable",true)
        id=Object.keys(vals)[0]
        ind=pearlSensorIds[id].i
        inputs=st.inputs.slice(0)
        bc=this.bc2c[vals[id]]// keyval of the channels on the board
        cl(bc)
        cl(id)
        cl(vals[id])
        inputs[ind]=Object.assign({},inputs[ind],
          {board:+vals[id],
          channel:+Object.keys(bc)[0]})// creates new object
        this.setState({inputs:inputs})
        break
      case "bChan":
        globs.events.publish("savePageEnable",true)
        id=Object.keys(vals)[0]
        ind=pearlSensorIds[id].i
        inputs=st.inputs.slice(0)
        inputs[ind]=Object.assign({},inputs[ind],
          {channel:+vals[id]})// creates new object
        this.setState({inputs:inputs})
        break
      case "chan":
        globs.events.publish("savePageEnable",true)
        id=Object.keys(vals)[0]
        ind=pearlSensorIds[id].i
        inputs=st.inputs.slice(0)
        bc=this.c2bc[vals[id]]
//         inputs[ind].board=bc[0]
//         inputs[ind].channel=bc[1]
        inputs[ind]=Object.assign({},inputs[ind],{board:bc[0],channel:bc[1]})// creates new object
        this.setState({inputs:inputs})
        break
    }
  }

  showSensorMappingSelect=(id)=>{
    let st=this.state
    let opts=Object.keys(this.inputIds).filter(k=>{return this.inputTypes[id].includes(+k)})
      .map(k=>{return{v:k,t:this.inputIds[k]}})
    let inp0=pearlSensorIds[id]

    let inp1=st.inputs[inp0.i]
    let types=this.inputTypes[id]
//     let info=pearlSensorIds[id]
    let parms={
      label:inp0.t,//inTemp:{i:1,t:"Inside Temperature"},
      valueName:id,
      opts:opts,
      onChange:(t,v)=>this.onChange("map",v),
    }
    parms[id]=+inp1.mapping
    return(
      <div style={{display:"inline-block"}}>
        <C18Select01 parms={parms}/>
      </div>
    )
  }

  showSensorChanSelect=(id)=>{
    var showBoardSelect=()=>{
      let boards=Object.keys(this.bc2c).map(b=>{
        return{v:b,t:`Bd ${+b+1}`}})
//       boards.push({v:"2",t:"tester"})
//       cl(boards)
      let parms={
        valueName:id,
        opts:boards,
        onChange:(t,v)=>this.onChange("board",v),
      }
      parms[id]=inp1.board
      return(
        <div style={{display:"inline-block"}}>
          <C18Select01 parms={parms}/>
        </div>
      )
    }
    var showChanSelect=()=>{
//       cl(inp1)
//       cl(id)
      let chans=Object.keys((this.bc2c[inp1.board]||{})).map(c=>{
        return{v:c,t:`Ch ${+c+1}`}
      })
      let parms={
        valueName:id,
        opts:chans,
        onChange:(t,v)=>this.onChange("bChan",v),// "board channel"
      }
      parms[id]=inp1.channel
      return(
        <div style={{display:"inline-block"}}>
          <C18Select01 parms={parms}/>
        </div>
      )
    }
// now, show board and channel
    let st=this.state
    let chans=Object.keys(this.c2bc).sort().map(c=>{
      return{v:c,t:`Input Channel ${(+c)+1}`}
    })
    let opts=Object.keys(this.inputIds).filter(k=>{return this.inputTypes[id].includes(+k)})
      .map(k=>{return{v:k,t:this.inputIds[k]}})
    let inp0=pearlSensorIds[id]// the input type info

    let inp1=st.inputs[inp0.i]//the actual input info
    let isModbus=(inp1.mapping==lc.setup_t_InputMap_INPUTNAME_MBUS_INPUT)
//     cl(isModbus)
//     let types=this.inputTypes[id]
//     let parms={
//       valueName:id,
//       opts:chans,
//       onChange:(t,v)=>this.onChange("chan",v),
//     }
//     parms[id]=(this.bc2c[+inp1.board]||{})[+inp1.channel]

    return(
      <div style={{display:"inline-block"}}>
        {showBoardSelect()}
        {isModbus&&showChanSelect()}
      </div>
    )
  }

  showSensorChanSelectO2=(id)=>{
    let st=this.state
    let chans=Object.keys(this.c2bc).sort().map(c=>{
      return{v:c,t:`Input Channel ${(+c)+1}`}
    })
//     cl(chans)
    let opts=Object.keys(this.inputIds).filter(k=>{return this.inputTypes[id].includes(+k)})
      .map(k=>{return{v:k,t:this.inputIds[k]}})
    let inp0=pearlSensorIds[id]

    let inp1=st.inputs[inp0.i]
    let types=this.inputTypes[id]
//     let info=pearlSensorIds[id]
    let parms={
//       label:inp0.t,//inTemp:{i:1,t:"Inside Temperature"},
      valueName:id,
      opts:chans,
      onChange:(t,v)=>this.onChange("chan",v),
    }
    parms[id]=(this.bc2c[+inp1.board]||{})[+inp1.channel]

    return(
      <div style={{display:"inline-block"}}>
        <C18Select01 parms={parms}/>
      </div>
    )
  }

  showSensorChanSelectO=(id)=>{
    let st=this.state
    let inp0=pearlSensorIds[id]
    let inp1=st.inputs[inp0.i]
    let val="6"
    return(
      <div>chan
      <input type="number" min="1" max="128" step="1"
        value={val}
        onChange={e=>this.onChange("chan",e.currentTarget.value)}
      />
      </div>
    )
  }

  showSensor=(id,i)=>{
//     cl(id,i)
    let st=this.state
    let inp0=pearlSensorIds[id]
    let inp1=st.inputs[inp0.i]
//     cl(inp1)
    return(
      <tr key={i}>
        <td>
          {this.showSensorMappingSelect(id)}
          {([lc.setup_t_InputMap_INPUTNAME_MBUS_INPUT,
            lc.setup_t_InputMap_INPUTNAME_SENSOR_DTH]
            .includes(+inp1.mapping))&&
            this.showSensorChanSelect(id)}
        </td>
      </tr>
    )
  }

  showSensors=()=>{
    let st=this.state
//     cl(pearlSensorIds)
    let sensorList=[""]
    let sensors=Object.keys(pearlSensorIds).filter(s=>{return this.inputTypes[s]})
    .map((s,i)=>{
      return this.showSensor(s,i)
    })
//     cl(sensors)
//     inTemp outTemp inHum inCo2 outLight winSpd anTemp1 anTemp2 anTemp3 anTemp4 anTemp5 fbInTemp
//     winDir soil1 soil2 soil3 soil4 soil5 rain irrTrig diffP inLight vent1 vent2 vent3 vent4 vent5
//     gen1 gen2 outHum locInTemp locInHum snow bpTemp baro volt1 volt2 volt3 volt4 volt5 volt6 volt7 volt8


    return(
      <table><tbody>
      {sensors}
      </tbody></table>
    )
  }

  render(){
    if(this.state.loaded){
      return(
        <div><h3>Sensor Mapping</h3>
        {this.showSensors()}
        </div>
      )
    }else{
      return <div id="content-area">loading equipment. . .</div>
    }
  }
}

export default C18PearlMapping00;
