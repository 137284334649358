import React from 'react';
// import C18MuiFormControl from '../../usa/components/C18MuiFormControl'
// import C18MuiInputLabel from '../../usa/components/C18MuiInputLabel'
// import C18MuiMenuItem from '../../usa/components/C18MuiMenuItem'
// import C18MuiSelect from '../../usa/components/C18MuiSelect'
import {getParamId} from '../../usa/utils/utils';
import {dbVals,putZValue,sendArray} from '../../components/utils/http'
import {az} from '../../components/utils/utils'
import {HSVtoRGB,makeColor} from '../../usa/components/C18utils'
import {wsTrans} from '../../usa/utils/utils'
import {pInd} from '../../components/utils/paramIds'
import * as d3 from 'd3';
import {cl, globs,getTimeI,interpolateColor} from '../../components/utils/utils';

class Setpoints extends React.Component{
  constructor(props) {
    super(props);
    this.svgTmpDiv=React.createRef();
    this.svgHumDiv=React.createRef();
//     this.w=700
    this.h=150
    this.setpoints=[
      {time:360,heat:50,cool:70,hum:30,dehum:40},
      {time:420,heat:55,cool:75,hum:35,dehum:45},
      {time:480,heat:60,cool:80,hum:40,dehum:50},
      {time:540,heat:65,cool:85,hum:45,dehum:55},
    ]
    this.state={
      loaded:false,
      tempUnit:(["F","C"][props.current.units[0]])
    }
//     this.graphPath={}
    this.graphColors=["#0000FF","#FF0000","#AAAA00","#00AAAA"]
    this.textColor = '';
    this.backgroundColor = '#EEEEEE'
    this.markerColor = 'black';
    if (globs.device?.deviceTheme=="originalDark") {
      this.textColor = 'black'
      this.backgroundColor = '';
      this.markerColor = 'white'
    }
    this.loadInfo()
//     cl(props)
//     let col2=this.makeColor("#224466","#666666",0.5)
//     cl(col2)
//     cl(props)
//     this.updateFui()
//     this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
    this.subscribe_sideBarClicked=globs.events.subscribe("sideBarClicked", this.sideBarClicked)
  }
  
  savePageEvent=()=>{
//     this.changed=false
    this.saveSetpointData()
  }

  componentWillUnmount=()=>{
//     this.subscribe_updateFui.remove()
    this.subscribe_savePageEvent.remove()
    this.subscribe_sideBarClicked.remove()
  }
  
  saveSetpointData=()=>{
    let parms=this.getSaveSetpoints("save")
    cl(parms)
    sendArray(parms,this.props.current.virtual).then(e=>{
      globs.events.publish("saveOK",true)
      cl()
    })
//     cl(parms)
//     sendArray(parms).then(r=>{globs.events.publish("saveOK",true)})
  }

  getSaveSetpoints=(getSave)=>{
    let z=this.zuci[0]
    let tab=pInd[1800].config_setpoints
    let mult=tab[2]
    let setpoints=[]// used for both get and save
    let fields=["name","enabled","startTimeOfDay","astroAdjust","rampMinutes","heatSetpoint","coolSetpoint",
      "humidifySetpoint","dehumidifySetpoint"]
    let now=getTimeI()
    for(let i=0;i<8;i++){
      if(getSave=="get"){
        let val={}
        fields.forEach(f=>{ if(dbVals.z[z]) {
          let tVal=dbVals.z[z][255][mult*i+getParamId("configuration_setpoints",f)]
          if(f!="name"){tVal=+tVal}
          val[f]=tVal
        }})
        //val.enabled=+(val.enabled||0)
        setpoints.push(val)
//         cl(val)
//         cl(setpoints)
      }else{// settings, send to controller
//         return
        fields.forEach(f=>{ 
          let val=this.state.setpoints[i]
//           val[f]=dbVals.z[z][255][mult*i+getParamId("configuration_setpoints",f)]
          let pid=mult*i+getParamId("configuration_setpoints",f)
          if (putZValue(+z,255,pid,val[f])){
            setpoints.push({
              c:255,// zone wide
              d:val[f],
              f:1,
              i:pid,
              t:now,
              z:+z,
            })
          }
        })
      }
    }
//     cl(setpoints)
//     cl(setpoints[0])
//     cl(setpoints[0].rampMinutes)
    return setpoints
  }
  
  saveSetpoints=()=>{
    
  }
  
  loadInfo=async()=>{
//     cl(this.props.current)
    this.zuci=this.props.zuci.split("-")
    this.state.setpoints=this.getSaveSetpoints("get")
//     cl(this.state.setpoints)
    await this.loadMBInfo()
//     cl("set state")
    this.setState({loaded:true})
    
  }
  
  componentDidMount=()=>{// called after constructor
    this.mounted=true
//     cl(this.svgTmpDiv.current)
    this.svgTmp=this.svgTmpDiv.current
    this.svgHum=this.svgHumDiv.current
    this.w=this.svgTmp.offsetWidth
    this.graphTmp=this.makeAGraph(this.svgTmp)
    this.graphHum=this.makeAGraph(this.svgHum)
//     this.makeGraph()
    this.redrawGraph(this.graphTmp,"tmp")
    this.redrawGraph(this.graphHum,"hum")
  }

  sideBarClicked=()=>{
    setTimeout(() => {
    if(this.w !== this.svgTmpDiv.current.offsetWidth) {
      let svgElement = document.getElementsByTagName("svg")[0]
      let svgElement2 = document.getElementsByTagName("svg")[1]
      this.svgTmp=this.svgTmpDiv.current
      this.svgHum=this.svgHumDiv.current
      svgElement.remove();
      svgElement2.remove();
      this.w=this.svgTmp.offsetWidth
      this.graphTmp=this.makeAGraph(this.svgTmp)
      this.graphHum=this.makeAGraph(this.svgHum)
//       this.makeGraph()
      this.redrawGraph(this.graphTmp,"tmp")
      this.redrawGraph(this.graphHum,"hum")
    }
    },500);
    
  }
  
  minToHM=(mins)=>{
    let h=Math.floor(mins/60)
    let m=mins%60
    return `${az(h,2)}:${az(m,2)}`
  }

/**************** Verano Modbus Routines ************************************/

  loadMBInfo=async()=>{
/*
get the mb types, and mb devs find the devs on this zone, and the *types* on this zone
then, get the registers for those types
*/
    let gwId=this.props.current.gwId
    let zInd=+this.zuci[0]
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusTypes", method: "retrieve",
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:gwId}})
    let mbTypes={}// keyval from type to info
    res.data.forEach(d=>{mbTypes[d.typeId]=d})

    res=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusDevices", method: "retrieve",
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:gwId}})
    let mbDevices=res.data
    let gotTypes=mbDevices.filter(d=>{return d.zone==zInd})// array of mb devs on this zone
      .map(d=>{return d.typeId})
//     cl(gotTypes)
    res=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusRegisters", method: "retrieve",
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:gwId}})
//     cl(res.data)
    let mbRegs=res.data.filter(r=>{
      return gotTypes.includes(r.typeId)&&(
        [4,5,6,7].includes((r.type>>8)&0xFF)// modbus setpoint types
      )
    }).map(r=>{return (r.type>>8)&0x01})
//     cl(mbRegs)
    this.useMbSetpoints=mbRegs
//     cl(this.useMbSetpoints)

  }
  
  showMbSetpointHeader=()=>{
    let style0={fontSize:16,fontWeight:700,textAlign:"center"}
    var makeStyle=(ind)=>{
      return Object.assign({color:this.graphColors[ind]},style0)
    }
    return(
      <tr>
      <th style={makeStyle(1)}>Name</th>
      <th style={makeStyle(1)}>MB Temp<br/>Setpoint</th>
      <th style={makeStyle(3)}>MB Hum<br/>Setpoint</th>
      </tr>
    )
  }

  showMbSetpointRow=(i)=>{
    return [
//       this.showSetpointInput(i,0,"enabled","checkbox"),
//       this.showSetpointInput(i,1,"name","text"),
      this.showSetpointInput(i,1,"name","display"),
//       this.showSetpointInput(i,2,"startTimeOfDay","time"),
//       this.showSetpointInput(i,3,"heatSetpoint","number"),
      this.showSetpointInput(i,4,"coolSetpoint","number"),
//       this.showSetpointInput(i,5,"humidifySetpoint","number"),
      this.showSetpointInput(i,6,"dehumidifySetpoint","number"),
//       this.showSetpointInput(i,7,"rampMinutes","number"),
//       this.showSetpointInput(i,8,"astroAdjust","astro"),
    ]
  }

  showMbSetpointTable=()=>{
    let rows=[...Array(2).keys()].map(i=>{
      cl(i)
      cl(this.useMbSetpoints)
      if(this.useMbSetpoints.includes(i)){
        return(
          <tr key={i} style={{textAlign:"center"}}>
          {this.showMbSetpointRow(i)}
          </tr>
        )
      }else{return null}
    })
    return(
      <div>
      <table style={{width:"auto"}}><tbody>
      {this.showMbSetpointHeader()}
      {rows}
      </tbody></table>
      </div>
    )
  }

/**************** End Verano Modbus Routines ************************************/

  makeAGraph=(ref)=>{
    var margin = {top: 10, right: 30, bottom: 30, left: 40},
    width = this.w - margin.left - margin.right,
    height = this.h - margin.top - margin.bottom;
    let graph={}
    graph.svg2=d3.select(ref/*this.svgTmp*/).append("svg")
    graph.svg3=graph.svg2
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    graph.svg3
      .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");
    graph.graph=graph.svg3
    graph.x=d3.scaleLinear()// set x scale
      .domain([0,1440])
      .range([0,width])
    graph.y = d3.scaleLinear()
      .domain([0,100]) // input
      .range([height, 0]); // output
    graph.xAxis=d3.axisBottom(graph.x)
      .tickValues([...Array(8).keys()].map(x=>{return(180*x)}))
      .tickFormat(this.minToHM)
    graph.svgx=graph.svg3.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate("+0+"," + height + ")")
      .attr("opacity",1)
      .call(graph.xAxis)
    graph.yAxis=d3.axisLeft(graph.y)
    let svgy=graph.svg3.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate("+0+"," + 0 + ")")
      .attr("opacity",1)
      .call(graph.yAxis)
    graph.graphLine=d3.line()
      .x((d, i)=> { return graph.x(d.t); }) // set the x values for the line generator
      .y((d)=> { return graph.y(d.v); }) // set the y values for the line generator
    return graph
  }

//   makeGraph=()=>{
//     let graph=this.makeAGraph(this.svgTmp)
//     this.graph=graph
// //     this.graphLine=graph.graphLine
//     return
//     var margin = {top: 10, right: 30, bottom: 30, left: 40},
//     width = this.w - margin.left - margin.right,
//     height = this.h - margin.top - margin.bottom;
//     let svg2=d3.select(this.svgTmp).append("svg")
//     let svg3=svg2
//     .attr("width", width + margin.left + margin.right)
//     .attr("height", height + margin.top + margin.bottom)
//     .append("g")
//     svg3
//       .attr("transform",
//             "translate(" + margin.left + "," + margin.top + ")");
//     this.graph=svg3
//     this.x=d3.scaleLinear()// set x scale
//       .domain([0,1440])
//       .range([0,width])
//     this.y = d3.scaleLinear()
//       .domain([0,100]) // input
//       .range([height, 0]); // output
//     this.xAxis=d3.axisBottom(this.x)
//       .tickValues([...Array(8).keys()].map(x=>{return(180*x)}))
//       .tickFormat(this.minToHM)
//     let svgx=svg3.append("g")
//       .attr("class", "x axis")
//       .attr("transform", "translate("+0+"," + height + ")")
//       .attr("opacity",1)
//       .call(this.xAxis)
//     this.yAxis=d3.axisLeft(this.y)
//     let svgy=svg3.append("g")
//       .attr("class", "x axis")
//       .attr("transform", "translate("+0+"," + 0 + ")")
//       .attr("opacity",1)
//       .call(this.yAxis)
//     this.graphLine=d3.line()
//       .x((d, i)=> { return this.x(d.t); }) // set the x values for the line generator
//       .y((d)=> { return this.y(d.v); }) // set the y values for the line generator
//   }// end makeGraph
  
  showSetpointGraph=()=>{
    if((this?.useMbSetpoints?.length||[])>0){return}
      return(
        <div>
          <div ref={this.svgTmpDiv} style={{width:"calc(100% - 0px)",marginBottom:10,
            height:this.h,backgroundColor:this.backgroundColor,color:this.markerColor}}>
          </div>
          <div ref={this.svgHumDiv} style={{width:"calc(100% - 0px)",
            height:this.h,backgroundColor:this.backgroundColor,color:this.markerColor}}>
          </div>
        </div>
      )
  }
  
  redrawGraph=(ref,type)=>{
//     let ref=this.graphTmp
    if(!ref.graphPath){ref.graphPath={}}

    let drawPath=(ind,color)=>{
      let data=graphPoints[0].map((gp,i)=>{
        return( {t:graphPoints[0][i],v:graphPoints[ind][i]} )
      })
//       if(type=="hum"){cl(data)}

      if(ref.graphPath[ind]){ref.graphPath[ind].remove()}
      let path=ref.graph.append("path")
      path.datum(data)
        .attr("class", "line")
        .attr("fill", "none")
        .attr("stroke", color)
        .attr("stroke-width", 3)
        .attr("d", ref.graphLine) // 11. Calls the line generator
      ref.graphPath[ind]=path
    }// end drawPath
    
    let sp=this.state.setpoints?.slice(0)||[]
//     let td=sp.map(s=>{return s.enabled})
//     cl(td)
//     cl(sp[0].rampMinutes)
    sp.sort((a,b)=>{
      if(a.startTimeOfDay>b.startTimeOfDay){return 1}
      if(a.startTimeOfDay<b.startTimeOfDay){return -1}
      if(a.name>b.name){return 1}
      if(a.name<b.name){return -1}
      return 0
    })
//     cl(sp)
//     cl(sp[0].rampMinutes)
    for(let i=0;i<sp.length;i++){
      let sp0=sp[i]
      if(sp0.rampMinutes<0){sp0.rampMinutes=0}
      if(i<sp.length-1){
        let sp1=sp[i+1]
        let dif=0
        if(i){
          dif=sp1.startTimeOfDay-sp0.startTimeOfDay
//           cl(dif)
        }else{
          dif=sp1.startTimeOfDay-0
        }
//         cl(dif)
        if(dif<sp1.rampMinutes){sp1.rampMinutes=dif}
      }
    }
//     cl(sp)
//     cl(sp[0].rampMinutes)
    let graphPoints=[[],[],[],[],[]]
    let sp2=sp.filter(s=>{return s.enabled})
//     cl(sp2)
    let spLast=sp2[sp2.length-1]
    sp2.unshift(Object.assign({},spLast))
    sp2.push(Object.assign({},spLast))
    sp2[0].startTimeOfDay=0
    sp2[sp2.length-1].startTimeOfDay=1440
    let sp3=[]
//     td=sp2.map(s=>{return s.coolSetpoint})
//     cl(td)
    sp2.forEach((sp,i)=>{
      if((i>0)&&(i<sp2.length-1)){
        sp3.push(Object.assign({},sp2[i-1],{startTimeOfDay:sp.startTimeOfDay-sp.rampMinutes}))
      }
      sp3.push(sp)
    })
//     td=sp3.map(s=>{return s.coolSetpoint})
//     cl(td)
    sp3.forEach(s=>{
      graphPoints[0].push(s.startTimeOfDay)
      graphPoints[1].push(s.heatSetpoint)
      graphPoints[2].push(s.coolSetpoint)
      graphPoints[3].push(s.humidifySetpoint)
      graphPoints[4].push(s.dehumidifySetpoint)
    })
    let ofs=(type=="tmp")?0:2
    for(let i=0;i<2;i++){drawPath(ofs+i+1,this.graphColors[ofs+i])}
  }
  
  onChange=async(type,vals)=>{
//     cl(type,vals)
    var sp
    globs.events.publish("savePageEnable",true)
    if(vals.type=="time"){
//       cl(vals.val)
      let parts=vals.val.split(":")
      vals.val=60*(+parts[0])+(+parts[1])
    }
    if(vals.type=="checkbox"){
      vals.val=(vals.val)?1:0
    }
    sp=this.state.setpoints.slice(0)
//     cl(sp[0].rampMinutes)
    switch(type){
      case "sp":
//         let val=Math.floor((+vals.val)*10)/10
//         cl((+vals.val)*10)
//         cl(val)
//         sp[vals.i][vals.col]=val.toString()//Math.floor((+vals.val)*10)/10
//         cl(vals.i,vals.col,vals.val)
        sp[vals.i]=Object.assign({},sp[vals.i])
        sp[vals.i][vals.col]=vals.val

//         cl(sp[0].rampMinutes)
//         cl(this.state.setpoints[0].rampMinutes)
        break
      
    }
//     let td=sp.map(s=>{return s.enabled});cl(td)
    await this.setState({setpoints:sp})
//     cl(sp[0].rampMinutes)
    this.redrawGraph(this.graphTmp,"tmp")
//     cl(sp[0].rampMinutes)
    this.redrawGraph(this.graphHum,"hum")
//     cl(sp[0].rampMinutes)
//     cl(sp)
//     cl(sp[0].rampMinutes)
//     cl(this.state.setpoints[0].rampMinutes)
  }
  
//   makeColor=(h1,h2,frac,s,v)=>{
//     var d2h=(dec)=>{
//       return `00${dec.toString(16)}`.slice(-2)
//     }
//     let h=h1+frac*(h2-h1)
//     let rgb=HSVtoRGB(h,s,v)
//     return `#${d2h(rgb.r)}${d2h(rgb.g)}${d2h(rgb.b)}`
//   }
  
  showSetpointInput=(i,key,col,type)=>{
    let st=this.state
//     if((i==0)&&(key==7)){cl(st.setpoints[0].rampMinutes)}
    var keyType=`${i}_${key}`
//     var op=(st.mo==keyType)?0:1
    var unit=null
//     var unit=null
    // needs to distinguish between C and F
    if((key>2)&&(key<5))
// note that the marginLeft cannot be in %, since it's broken if the width changes
      unit=<span style={{cursor:"default", position:'absolute', marginLeft:-50, top:'12px',color:this.textColor}}>&deg;{st.tempUnit}</span>
    if((key>4)&&(key<7))
      unit=<span style={{cursor:"default", position:'absolute', marginLeft:-50, top:'12px',color:this.textColor}}>%</span>
    if((key>6)&&(key<8))
      unit=<span style={{cursor:"default", position:'absolute', marginLeft:-50, top:'12px',color:this.textColor}}>mins</span>
    if(st.mo==keyType){unit=null
//       unit=<span style={{marginLeft:-30}}></span>
      
    }
//     let unit=(
//       <span style={{marginLeft:-20}}>&deg;F</span>
//     )
//     cl(this.state.setpoints[i])
    var color="#FFFFFF"
//     let colors=["#CCEEFF","#CCCCFF","#CCCCFF","#CCCCFF","#CCCCFF","#CCCCFF","#CCCCFF","#FFCCCC"]
    let ri=this.state.setpoints[i]
    let width={checkbox:50,number:100,text:100}
    let value=ri[col]
    if(!(value||(value==0))){value=""}
    if(type=="time"){
      let dayCol = [245, 192, 0, .7]
      let nightCol = [192, 130, 209, .7]
      color = value<720 ? interpolateColor(nightCol, dayCol, value/720) : interpolateColor(dayCol, nightCol, (value-720)/720)
      value=this.minToHM(value)
//       cl(value)
    }
    if(["heatSetpoint","coolSetpoint"].includes(col)){
      value=Math.floor(10*value)/10
//       cl(value)
      let frac=(value-50)/30
      if(frac<0){frac=0}
      if(frac>1){frac=1}
      let h1=0.6
      let h2=1
      color=makeColor(h1,h2,frac,0.4,1)
    }
    if(["humidifySetpoint","dehumidifySetpoint"].includes(col)){
      let frac=(value-20)/60
      if(frac<0){frac=0}
      if(frac>1){frac=1}
      let h1=0.167
      let h2=0.600
      color=makeColor(h1,h2,frac,0.4,1)
    }
//     var min,max
//     if(["rampMinutes"].includes(col)){
//       min=0
//       max=120
//     }
//               min={min}
//               max={max}
    switch(type){
      case "astro":
        // assign color
        let colors=["", "#f5c000", "#c082d1"]
        return(
          <td key={key}>
            <select style={{padding:10,borderStyle:"solid",borderWidth:1,borderRadius:10, backgroundColor: colors[value]}}
            value={value}
            onChange={e=>this.onChange("sp",{i:i,col:col,type:type,val:e.currentTarget.value})}
            >
            <option style={{backgroundColor: "#FFFFFF"}} value="0">None</option>
            <option style={{backgroundColor: "#FFFFFF"}} value="1">Sunrise</option>
            <option style={{backgroundColor: "#FFFFFF"}} value="2">Sunset</option>
            </select>
          </td>
        )
      case "checkbox":
        return(
          <td key={key}>
            <input
              type={type}
              style={{width:width[type],backgroundColor:color}}
              checked={value}
              onChange={e=>this.onChange("sp",{i:i,col:col,type:type,val:e.currentTarget.checked})}
            />
          </td>
        )
      case "display":
        return(
          <td key={key}>
          {`Setpoint ${i+1}`}
          </td>
        )
      default:
//         if((key==7)&&(i==0)){cl(value)}
//         cl(value)
//               step="0.1"
        return(
          <td key={key} style={{position:'relative'}}>
            <input
              type={type}
              style={{width:width[type],backgroundColor:color,margin:0,zIndex:10,color:this.textColor}}
              value={value.toString()}
              onChange={e=>this.onChange("sp",{i:i,col:col,type:type,val:e.currentTarget.value})}
              onMouseOut={e=>{
                this.setState({mo:null})
//                 this.svgTmp.focus()
              }}
            />
            {unit}
          </td>
        )
    }
//     if(type=="astro"){
//     }else{
//     }
  }
  
  showSetpointHeader=()=>{
    let style0={fontSize:16,fontWeight:700,textAlign:"center"}
    var makeStyle=(ind)=>{
      return Object.assign({color:this.graphColors[ind]},style0)
    }
    return(
      <tr>
      <th style={style0}>Enable</th>
      <th style={style0}>Name</th>
      <th style={style0}>Start<br/>Time</th>
      <th style={makeStyle(0)}>Heat<br/>Setpoint</th>
      <th style={makeStyle(1)}>Cool<br/>Setpoint</th>
      <th style={makeStyle(2)}>Hum<br/>Setpoint</th>
      <th style={makeStyle(3)}>DeHum<br/>Setpoint</th>
      <th style={style0}>Ramp<br/>Time</th>
      <th style={style0}>Astro<br/>Adjust</th>
      </tr>
    )
  }
  
  showSetpointRow=(i)=>{
    return [
      this.showSetpointInput(i,0,"enabled","checkbox"),
      this.showSetpointInput(i,1,"name","text"),
      this.showSetpointInput(i,2,"startTimeOfDay","time"),
      this.showSetpointInput(i,3,"heatSetpoint","number"),
      this.showSetpointInput(i,4,"coolSetpoint","number"),
      this.showSetpointInput(i,5,"humidifySetpoint","number"),
      this.showSetpointInput(i,6,"dehumidifySetpoint","number"),
      this.showSetpointInput(i,7,"rampMinutes","number"),
      this.showSetpointInput(i,8,"astroAdjust","astro"),
    ]
//     cl(this.state.setpoints[i])
//     let ri=this.state.setpoints[i]
//     return(
//       <td>
//       <input 
//         type="number"
//         style={{width:70}}
//         value={ri.heatSetpoint}
//         onChange={e=>this.onChange("sp",{i:i,col:"heatSetpoint",val:e.target.value})}
//         
//         />
//       </td>
//     )
  }

  showNormalSetpointTable=()=>{
//     let spCount=(this.useMbSetpoints)?2:8
//     cl(spCount)
    let rows=[...Array(8).keys()].map(i=>{
  //       cl(i)
      return(
        <tr key={i} style={{textAlign:"center"}}>
        {this.showSetpointRow(i)}
        </tr>
      )
    })
    return(
      <div>
      <table><tbody>
      {this.showSetpointHeader()}
      {rows}
      </tbody></table>
      </div>
    )
  }
  
  showSetpointTable=()=>{
    if(this.state.loaded){
      if(this.useMbSetpoints.length>0){
        return this.showMbSetpointTable()
      }else{
        return this.showNormalSetpointTable()
      }
    }else{return<div>loading. . .</div>}
  }


  render(){
    return(
      <div style={{overflowX:"auto"}}>
        {this.showSetpointGraph()}
        <br/>
        {this.showSetpointTable()}
      </div>
    )
  }
}

export default Setpoints ;
