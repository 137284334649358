import React from 'react';
import {Link} from 'react-router-dom'
// import C18Anchor00 from './C18Anchor00'
import C18Select00 from './C18Select00'
import C18Select01 from './C18Select01'
import C18Button00 from './C18Button00'

// import ReactHtmlParser from 'react-html-parser'
import C18SubMenuHeader00 from './C18SubMenuHeader00'
import {wsTrans,sensorIds,getUId,doGetPostBasic,makeOpts,getParamId2,logIt} from '../utils/utils'
import {tableIds,dpNames,pi,pInd} from '../../components/utils/paramIds'
import {cl,globs,constant,dateToDisplayDate,getPWString,getTimeI,
  saveLocalStorage,getLocalStorage,getTime} from '../../components/utils/utils';
import {dbVals,} from '../../components/utils/http';
import {sendSocket,} from '../../components/utils/ws';
import {loadSiteData,} from './C18utils'
import {lc} from '../../components/utils/landruConstants'
class C18TechPortal00 extends React.Component{
  constructor(props) {
    super(props);
//     let dtFormat=dateToDisplayDate(new Date(),"yyyy-mm-ddThh:mm")
    let ts=getTime()
    cl(ts)
    this.state={
      accountSearch:"",
      userSearch:"",
      siteSearch:"",
      gatewaySearch:"",
      zoneSearch:"",
      searchType:"",
      oneSearch:"",
      searchRes:{},
      searchSel:-1,
      relUsers:[],
      relSites:[],
      relAccounts:[],
      relGateways:[],
      relZones:[],
      userSel:"",
      accountFeatures:{},
      resetPW:"",
      editUserAcctId:false,
      editGatewaySiteId:false,
      editType:"",
      editField:"",
      showSystemReport:false,
      disableCache:false,
      uploadFwResult:"Upload Firmware Image",
      debugText:"",
      debugResp:{},
      pageType:"techPortal",
      mqttLogTime:ts,//dtFormat,
      mqttClient:"",
      msgClick:0,
      contSel:"",

//       pageType:"users",
// //       selUser:"",
//       editObj:{},
//       editId:"",
//       editKey:"",
    }
    this.servers={
      mongo:"mongo.c2.link4cloud.com:3105",
      wayne:"wayne.link4cloud.com:3105",
      dev:"stage.link4cloud.com:3105",
      stage:"stage.link4cloud.com:3115",
      alpha:"stage.link4cloud.com:3125",
      prodx:"http0test.link4cloud.com:3125",
      prod:"http0test.link4cloud.com:3105",
      prods:"http0test.link4cloud.com:3115",
    }
//     cl(this.state.disableCache)
//     this.pageTitles={accounts:"Accounts",sites:"Sites",gateways:"Gateways",
//       zones:"Zones",users:"Users",}
//     this.pageParms={
//       users:["userSearch","users","name","email","userId","selUser"],
//       accounts:["accountSearch","accounts","name","adminEmail","accountId","selAccount"],
//       sites:["siteSearch","sites","name","","siteId","selSite"],
//       gateways:["gatewaySearch","gateways","name","","gatewayId","selGateway"],
//       zones:["zoneSearch","zones","zoneName","","zoneId","selZone"],
//     }
//     this.itemIds={accounts:"accountId",sites:"siteId",gateways:"gatewayId",zones:"zoneId",users:"userId"}
//     cl(this.state.not.here)
    this.loadInfo()
//     this.sendTestLog()
    this.setBreadCrumbs()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveData)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.subscribe_keyDownEvent=globs.events.subscribe("keyDown",
      e=>this.onChange("searchKey",{key:e}))
    this.dark=((globs.device?.deviceTheme||"").toLowerCase().indexOf("dark")>=0)?1:0
    this.bgColor1=(this.dark)?"#202020":"FFFFFF"
    this.bgColor2=(this.dark)?"#203030":"FFFFFF"
    this.selColor1=(this.dark)?"#606060":"DDDDDD"
    this.mqttInterval = setInterval(e=>{
      this.loadMqttContacts().then(cont=>{
        this.setState({mqttContacts:cont})
      })
    },5000)
    this.cmdTypes={
      "-1":{t1:"NAK",t2:"",t3:""},
      "0":{t1:"ACK",t2:"",t3:"",b:0},
      "1":{t1:"Write",t2:"Device",t3:"Config",b:0},
      "2":{t1:"Read",t2:"Device",t3:"Config",b:0},
      "3":{t1:"Report",t2:"Device",t3:"Config",b:0},
      "4":{t1:"Read",t2:"Device",t3:"Status",b:2000},
      "5":{t1:"Report",t2:"Device",t3:"Status",b:2000},
      "6":{t1:"Write",t2:"Aux",t3:"Config",b:4000},
      "7":{t1:"Read",t2:"Aux",t3:"Config",b:4000},
      "8":{t1:"Report",t2:"Aux",t3:"Config",b:4000},
      "9":{t1:"Read",t2:"Aux",t3:"Status",b:6000},
      "10":{t1:"Report",t2:"Aux",t3:"Status",b:6000},
      "11":{t1:"Debugging",t2:"Command",t3:"",b:6000},
    }
    cl(this.state.mqttLogTime)
  }

//   sendTestLog=()=>{
// //     let url=`${constant.expressUrl}/usa/log`
// //     let method="POST"
// //     let type="text/plain"//"application/json"
// //     let data={thisx:"is it"}
//
//     logIt({msg:"this is here"})
// //     let data={s:}
// //     cl(url)
// //     return doGetPostBasic(url, method, JSON.stringify(data), type)// promise
//
//   }

  componentDidMount(){
    window.addEventListener('beforeunload', this.saveSearch);
  }

  componentWillUnmount=()=>{
    cl("component will unmount")
    this.subscribe_keyDownEvent.remove()
    this.subscribe_savePageEvent.remove()
    // save tech portal search
    window.removeEventListener('beforeunload', this.saveSearch);
    // stop mqtt check
    if (this.mqttInterval) clearInterval(this.mqttInterval)
  }

  saveSearch = () => {
    saveLocalStorage("techPortalState", JSON.stringify(this.state))
  }

  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Tech Portal", url:`/usa/c18/admin/techPortal2`},
            ]},
        },
      )
    }
  }

  loadWsTrans=async(parms)=>{
    return await wsTrans("usa", {cmd: "cRest", uri: parms.uri,
      method: parms.method||"retrieve", sessionId: globs.userData.session.sessionId,
      body: parms.body})

  }

  makeItemLookups=(arr,idName)=>{
    let lu={}
    arr.forEach(ar=>{ lu[ar[idName]]=ar })
    return lu
  }

  loadMqttContacts=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/shared",
      method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: {type:"mqttContacts"}})
    let mqttContacts={}
    res.data.forEach(r=>{
      mqttContacts[r.clientId]=r
    })
    return mqttContacts
  }

  loadLandruConstants=()=>{
    let badPrefs=["ZC","CC","SP","SN","ECPH","SE","SX","CD",
    "CHC","SNC","SNCD","CAA","CAC","CAPV","CAV","CEB","CZ","CCS",
    "CES","CE","MSG","GATEWAY"]
    let lc2={}
    Object.keys(lc).forEach(k=>{
      let pref=k.split("_")[0]
      if(!badPrefs.includes(pref)){
        let val=lc[k]
        lc2[val]=k
      }
    })
    this.lc2=lc2
  }

  loadMqttLive2=async(conts,server,reqType)=>{// load the info from prodX
    let url=`http://${this.servers[server]}/usa/publicRest?uri=/o/${reqType}&method=retrieve`
    let method="GET"
    var data
    let type="text/plain"
    let res=await doGetPostBasic(url, method, data, type)
    let obj=await res.json()
//     cl(obj)
    conts.push({
      server:server,
      type:reqType,
      val:obj,
    })
  }

  processMqttHttpConts=(contsIn,gwIndex)=>{
//     cl(gwIndex)
    let st=this.state
    let lines=[]
    let conts=[]
    contsIn.forEach(c=>{
      switch(c.type){
        case "mqtt":
//           cl(c.val.data.clientIdToGatewayId)
          let gwIds=c.val.data.clientIdToGatewayId
          Object.keys(gwIds).forEach(k=>{
//             cl(gwIds[k])
            let gw=gwIds[k]
//             cl(gw)
//             cl(gw.gw)
//             cl(st.gwIndex[gw.gw])
            conts.push({
              server:c.server,
              type:c.type,
              gwId:gw.gw,
              name:gwIndex[gw.gw]?.name||`${gw.gw}`,
              val:gw})
          })
          break
        case "http":
//           cl(c.val.data.sessions)
          let sessions=c.val.data.sessions
          Object.keys(sessions).forEach(k=>{
//             cl(sessions[k])
            let session=sessions[k]
            conts.push({
              server:c.server,
              type:c.type,
              gwId:session.gatewayId,
              name:gwIndex[session.gatewayId].name,
              val:session,
            })
          })
          break
      }
//       cl(c)
    })
//     cl(conts)
    return conts

  }

//   loadMqttLive=async()=>{
//     let res=await wsTrans("usa", {cmd: "cRest", uri: "/o/mqtt", method: "retrieve",
//       sessionId: globs.userData.session.sessionId,// actually, not necessary
//       body: {cmd:"getContacts"}})
//     cl(res)
//   }
//
//   loadHttpLive=async()=>{
//     let res=await wsTrans("usa", {cmd: "cRest", uri: "/o/http", method: "retrieve",
//       sessionId: globs.userData.session.sessionId,// actually, not necessary
//       body: {cmd:"getContacts"}})
//     cl(res)
//
//   }

  loadInfo=async()=>{
// users, sites, accounts, zones, gateways,
    this.types=["account","user","site","gateway","zone"]
    let users=(await this.loadWsTrans({uri:"/su/allUsers"})).data
//     cl(users)
    let accounts=(await this.loadWsTrans({uri:"/su/suAccounts"})).data
    let sites=(await this.loadWsTrans({uri:"/su/allSites"})).data
    let gateways=(await this.loadWsTrans({uri:"/su/allGateways"})).data
    // if(!gateways/*.gw*/){// for old style response from server
    //   let newGW=gateways
    //   gateways={gw:newGW,agw:[]}
    // }
//     cl(gateways)
    let gwIndex={}
//     cl(gateways)
    gateways/*.gw*/.forEach(gw=>{gwIndex[gw.gatewayId]=gw})
    gateways/*.agw*/.forEach(gw=>{gwIndex[gw.gatewayId]=gw})
    let zones=(await this.loadWsTrans({uri:"/su/allZones"})).data
    let searchRes={}
    this.makeCustomSaveInfo()
    let mqttContacts=await this.loadMqttContacts()
    let conts=[]
    await this.loadMqttLive2(conts,"prods","mqtt")
    await this.loadMqttLive2(conts,"stage","http")
    let allConts=this.processMqttHttpConts(conts,gwIndex)
//     cl(allConts)
    var techPortalState
    this.loadLandruConstants()
//     cl(this.mqttContacts)
//     techPortalState = getLocalStorage("techPortalState")
    let myState = {
      loaded:true,
      users:users,
      accounts:accounts,
      sites:sites,
      gateways:gateways,
      gwIndex:gwIndex,
      controllers:conts,
      allConts:allConts,
      zones:zones,
      mqttContacts:mqttContacts,
      lookUps:{
        userId:this.makeItemLookups(users,"userId"),
        accountId:this.makeItemLookups(accounts,"accountId"),
        siteId:this.makeItemLookups(sites,"siteId"),
        gatewayId:this.makeItemLookups(gateways,"gatewayId"),
        zoneId:this.makeItemLookups(zones,"zoneId"),
      }
    }
    if (techPortalState) {
      let techObj=JSON.parse(techPortalState)
// here, delete the saved items we *don't* want
      delete techObj.mqttLogTime
      myState = Object.assign(techObj, myState)
    } else {
      myState = Object.assign(myState, {
        searchRes:searchRes,
        disableCache:globs.disableCache,// !(getLocalStorage("disableCache")=="false")||false,
        selDbTab:Object.keys(dpNames)[0],
      })
    }
    this.setState(myState)
//     cl(conts)
  }

  saveData=async(cmd)=>{
    let st=this.state
    cl(st)
    if(cmd=="save"){
      var upd
      if(st.editType){
        switch(st.editType){
          case "zone":
            let zone=st.zones.filter(z=>{return z.zoneId==st.selectedId})[0]
            cl(zone)
            upd={
              zoneId:st.selectedId,
            }
            upd[st.editField]=zone[st.editField]
            cl(upd)
            cl(st.editField)
            cl(zone[st.editField])
            cl("done")
            globs.events.publish("saveOK",true)
            return await wsTrans("usa", {cmd: "cRest", uri: "/su/allZones",
              method: "update", sessionId: globs.userData.session.sessionId,
              body: upd})
            break
          case "gateway":
            let gateway=st.gateways.filter(g=>{return g.gatewayId==st.selectedId})[0]
            cl(gateway)
            upd={
              gatewayId:st.selectedId,
            }
            upd[st.editField]=gateway[st.editField]
            cl(upd)
            cl(st.editField)
            cl(gateway[st.editField])
            cl("done")
            globs.events.publish("saveOK",true)
            return await wsTrans("usa", {cmd: "cRest", uri: "/su/allGateways",
              method: "update", sessionId: globs.userData.session.sessionId,
              body: upd})
            break
        }
      }
//       if(st.editUserAcctId){
//         this.setState({editUserAcctId:false})
//         globs.events.publish("saveOK",true)
//         let selUser=st.users.filter(u=>{return u.userId==st.selectedId})[0]
//         cl(selUser)
//         let upd={userId:selUser.userId,accountId:st.userAccountId}
//         return await wsTrans("usa", {cmd: "cRest", uri: "/su/allUsers",
//           method: "update", sessionId: globs.userData.session.sessionId,
//           body: upd})
//       }
      this.checkSaveFields(this.saveFields[0])

//       if(st.editGatewaySiteId){
//         this.setState({editGatewaySiteId:false})
//         globs.events.publish("saveOK",true)
//         let selGateway=st.gateways/*.gw*/.filter(g=>{return g.gatewayId==st.selectedId})[0]
//         cl(selGateway)
//         let upd={gatewayId:selGateway.gatewayId,siteId:st.gatewaySiteId}
//         return await wsTrans("usa", {cmd: "cRest", uri: "/su/allGateways",
//           method: "update", sessionId: globs.userData.session.sessionId,
//           body: upd})
//       }
    }
  }

/******************** Routines to handle the custom field saves **********************************/

  unSaveFields=()=>{
    globs.events.publish("savePageEnable",false)
    let vals={editType:null,editField:null}// old style custom field
    this.saveFields.forEach(sfi=>{
      vals[sfi.showEditFlag]=false
    })
//     cl(vals)
    this.setState(vals)

  }

  makeCustomSaveInfo=()=>{
// table for individual fields that get saved:
    this.saveFields=[
    {showEditFlag: "editGatewaySiteId",table:"gateways",id:"gatewayId",
      field:"gatewaySiteId", uri:"/su/allGateways",
      updField:"siteId",fieldName:"Site ID:"},
    {showEditFlag: "editUserAccountId",table:"users",id:"userId",
      field:"userAccountId", uri:"/su/usaSUAllUsers",
      updField:"accountId",fieldName:"Acct ID:"}
    ]
  }

  checkSaveFields=async(sfi)=>{// sfi is saveFieldInfo
// this also needs to save to local storage!
    let st=this.state
    this.saveFields.forEach(async sfi=>{
      if(st[sfi.showEditFlag]/*.editGatewaySiteId*/){
//         cl("saving "+sfi.showEditFlag)
        globs.events.publish("saveOK",true)
        let vals={}
        vals[sfi.showEditFlag]=false// end edit mode
        this.setState(vals)//this.setState({editGatewaySiteId:false})
        let selVals=st[sfi.table].filter(v=>{return v[sfi.id]==st.selectedId})[0]
//         cl(selVals)// the item that's being saved'
        let upd={}
        upd[sfi.id]=selVals[sfi.id]
        upd[sfi.updField]=st[sfi.field]
        Object.assign(selVals,upd)
//         cl(upd)
//         cl(sfi.uri)
        return await wsTrans("usa", {cmd: "cRest", uri: sfi.uri,
          method: "update", sessionId: globs.userData.session.sessionId,
          body: upd})
  //         return await wsTrans("usa", {cmd: "cRest", uri: "/su/allGateways",
  //           method: "update", sessionId: globs.userData.session.sessionId,
  //           body: upd})
      }
    })
  }

  hideShowCustomSaveField=(vals)=>{
/* this needs to set the editGatewaySiteId flag, *and*
save the current value to st.gatewaySiteId
when it enters edit mode
*/
//     cl("show custom")
//     cl(vals)
    this.setState(vals)
  }

  editCustomSaveField=(vals)=>{
//     cl("edit custom")
//     cl(vals)
    globs.events.publish("savePageEnable",true)
    this.setState(vals)

  }

  showCustomSaveField=(sfi)=>{
    let st=this.state
//     cl(st)
//     cl(sfi)
//     let arr=(sfi.table=="gateways")?st[sfi.table].gw:st[sfi.table]
//     let selItem=st[sfi.table].filter(v=>{return v[sfi.id]==st.selectedId})[0]||{}
    let selItem=st[sfi.table]/*arr*/.filter(v=>{return v[sfi.id]==st.selectedId})[0]||{}
    if(st[sfi.showEditFlag]/*.editGatewaySiteId*/){
//             <td
//               style={{cursor:"pointer"}}
//               onClick={e=>{
//                 let vals={type:sfi.field}
//                 vals[sfi.field]=e.currentTarget.value
//                 this.onChange("hideShowCustomSaveField",
//                 {gatewaySiteId:selItem[sfi.updField]})}
//               }
//             >
      return(
            <tr><td>{sfi.fieldName}</td>
            <td>
            <input type="text"
              style={{width:150,padding:0,borderRadius:0}}
              value={st[sfi.field]}

            onChange={
              e=>{
//                 let vals={type:sfi.field}
                let vals={}
                vals[sfi.field]=e.currentTarget.value
                this.onChange("editCustomSaveField",vals)
              }
            }
            ></input>
            </td></tr>
      )
    }else{
      return <tr><td>{sfi.fieldName}</td><td
        style={{cursor:"pointer"}}
        onClick={e=>{
          let vals={}
          vals[sfi.showEditFlag]=true
          cl(st)
          cl(st[sfi.table])
          cl(sfi.id)
          cl(st.searchSel)
//           let arr=(sfi.table=="gateways")?st[sfi.table].gw:st[sfi.table]
          let item=st[sfi.table]/*arr*/.filter(it=>{return it[sfi.id]==st.selectedId})[0]// or selectedId
          cl(item)
          cl(sfi)
          vals[sfi.field]=item[sfi.updField]
          this.onChange("hideShowCustomSaveField",vals)}
        }
      >
        <span>{selItem[sfi.updField]||"No ID"}</span>
      </td>
      </tr>
    }

  }

/******************** End Routines to handle the custom field saves **********************************/

  searchType=(vals,type)=>{
//     cl(vals)
    let parms={
      account:{l:"accounts",n:"name",s:"accountSearch",i:"accountId"},
      user:{l:"users",n:"name",s:"userSearch",i:"userId"},
      site:{l:"sites",n:"name",s:"siteSearch",i:"siteId"},
      gateway:{l:"gateways",n:"name",s:"gatewaySearch",i:"gatewayId"},
      zone:{l:"zones",n:"zoneName",s:"zoneSearch",i:"zoneId"},
    }[type]
    let search=vals["oneSearch"].toLowerCase()
//     let arr=(parms.l=="gateways")?this.state[parms.l].gw:this.state[parms.l]
//     let res=this.state[parms.l].filter(v=>{
    let res=this.state[parms.l]/*arr*/.filter(v=>{
      if(Array.isArray(v.zoneId)){v.zoneId=""}
      return ((v[parms.n]||"").toLowerCase().indexOf(search)>=0)||
      ((v.accountId||"").toLowerCase().indexOf(search)>=0)||
      ((v.userId||"").toLowerCase().indexOf(search)>=0)||
      ((v.siteId||"").toLowerCase().indexOf(search)>=0)||
      ((v.gatewayId||"").toLowerCase().indexOf(search)>=0)||
      ((v.clientId||"").toLowerCase().indexOf(search)>=0)||
      ((v.zoneId||"").toLowerCase().indexOf(search)>=0)||
      ((v.email||"").toLowerCase().indexOf(search)>=0)
    }).map(v=>{return {v:v[parms.i],t:v[parms.n],id:v["_id"]}})
    vals.searchRes[type]=res
  }

//   searchAccount=(vals)=>{this.searchType(vals,"account")}
//   searchUser=(vals)=>{this.searchType(vals,"user")}
//   searchSite=(vals)=>{this.searchType(vals,"site")}
//   searchGateway=(vals)=>{this.searchType(vals,"gateway")}
//   searchZone=(vals)=>{this.searchType(vals,"zone")}

  getSearchPos=()=>{
    let st=this.state
    if(st.searchSel==-1){return -1}
    for(let i=0;i<st.searchRes.length;i++){
      if(st.searchRes[i].v==st.searchSel){return i}
    }
    return -1
  }

  searchKey=(key)=>{
    let st=this.state
    let pos=this.getSearchPos()
    var searchSel=st.searchSel
    if(key=="ArrowDown"){
      if(pos<st.searchRes.length-1){
        searchSel=st.searchRes[pos+1].v
      }
    }else{
      if(pos>0){
        searchSel=st.searchRes[pos-1].v
      }
    }
    this.setState({searchSel:searchSel})
  }

  getFeatures=(accountId)=>{
    let st=this.state
    let selAccount=st.accounts.filter(a=>{return a.accountId==accountId})[0]
//     cl(selAccount)
    let features={};
    (selAccount.features||[]).forEach(f=>{
      features[f]=true
    })
    return features
  }

  ts2str=(ts)=>{
    return dateToDisplayDate(new Date(1000*ts),"mm/dd/yyyy h:mm:ss ap",420)
  }

  getZoneLastData=async(zoneId)=>{
//     cl(zoneId)
    let zone=this.state.zones.filter(z=>{return z.zoneId==zoneId})[0]
//     cl(zone)
    let query={siteId:zone.siteId,zoneInd:+zone.siteZoneIndex}
//     cl(query)

    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/lastData",
      method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: query})
//     cl(res.data)
    let ts=(res.data[0]||{}).t||0
    let daStr=this.ts2str(ts)
//     let da=new Date(1000*ts)
//     let daStr=dateToDisplayDate(da,"mm/dd/yyyy h:mm:ss ap",420)
//     cl(res)
    return daStr

  }

  resetPassword=async()=>{
    let st=this.state
    let pw=getPWString(8)
    let userId=st.selectedId

    let upd={userId:userId,password:pw}
    cl(upd)
    cl(st)
    this.setState({resetPW:pw})

    return await wsTrans("usa", {cmd: "cRest", uri: "/s/users",
      method: "update", sessionId: globs.userData.session.sessionId,
      body: upd})
  }

  activateUser=async()=>{
// if a user has been invited to an account, then they already have the accountId filled in
// if a user has not been activated, then they have a "token" field
    let st=this.state
    let user=st.users.filter(u=>{return u.userId==st.selectedId})[0]
    cl(user)
    cl("activate")
    if(user.accountId){//user invited to an account
      let pw=getPWString(8)
      let body={userId:user.userId, name: user.name, password: pw, token: user.token};
      wsTrans("usa", {cmd: "cRest", uri: "/o/invites", method: "create", body: body})
      this.setState({resetPW:pw})
    }else{// new user and account
      let query={token:user.token}
      cl(query)
      return await wsTrans("usa", {cmd: "cRest", uri: "/o/users/activate",
        method: "retrieve", sessionId: globs.userData.session.sessionId,
        body: query})
    }
}

  deleteUser=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this User?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let st=this.state
//       let user=st.users.filter(u=>{return u.userId==st.selectedId})[0]
      let user=st.users.filter(u=>{return u._id==st.id})[0]
      let users=st.users.filter(u=>{return u.userId!=st.selectedId})
      let relUsers=st.relUsers.filter(u=>u.v!=st.selectedId)
      cl(user)
      this.setState({users:users,relUsers:relUsers,selectedType:"",selectedId:""})//
      return await wsTrans("usa", {cmd: "cRest", uri: "/su/allUsers",
        method: "delete", sessionId: globs.userData.session.sessionId,
        body: {_id:user._id}})
    }
  }

  deleteSite=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Site?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let st=this.state
      cl(st)
      let site=st.sites.filter(s=>{return s._id==st.id})[0]// *if* selected from the site list
      let sites=st.sites.filter(s=>{return s.siteId!=st.selectedId})
      let relSites=st.relSites.filter(s=>s.v!=st.selectedId)

      if(!site){
        site=st.sites.filter(s=>{return s.siteId==st.selectedId})[0]
      }
      cl(site)
      this.setState({sites:sites,relSites:relSites,selectedType:"",selectedId:""})//
      return await wsTrans("usa", {cmd: "cRest", uri: "/su/allSites",
        method: "delete", sessionId: globs.userData.session.sessionId,
        body: {_id:site._id}})
    }
  }

  deleteGateway=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Gateway?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let st=this.state
      let gateway=st.gateways/*.gw*/.filter(g=>{return g._id==st.id})[0]// *if* selected from the site list
      let gateways=st.gateways/*.gw*/.filter(g=>{return g.gatewayId!=st.selectedId})
      let relGateways=st.relGateways.filter(g=>g.v!=st.selectedId)

      if(!gateway){
        gateway=st.gateways/*.gw*/.filter(g=>{return g.gatewayId==st.selectedId})[0]
      }
      cl(gateway)
      this.setState({gateways:gateways,relGateways:relGateways,selectedType:"",selectedId:""})//
// This should *completely* erase a zone, in the db,
// and in the tables that the server keeps
      cl("cont")
      await wsTrans("usa", {cmd: "cRest", uri: "/su/allConts",
        method: "delete", sessionId: globs.userData.session.sessionId,
        body: {cmd:"deleteGateway",gatewayId:st.selectedId}})
      cl("cont2")
//       await wsTrans("usa", {cmd: "cRest", uri: "/su/allGateways",
//         method: "delete", sessionId: globs.userData.session.sessionId,
//         body: {_id:gateway._id}})
//       cl("cont3")
      await wsTrans("usa", {cmd: "cRest", uri: "/su/allZones",
        method: "delete", sessionId: globs.userData.session.sessionId,
        body: {gatewayId:st.selectedId}})
    }
  }

  deleteAccount  =async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Account?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let st=this.state
      let account=st.accounts.filter(a=>{return a.accountId==st.selectedId})[0]
      let accounts=st.accounts.filter(a=>{return a.accountId!=st.selectedId})
      let relAccounts=st.relAccounts.filter(a=>a.v!=st.selectedId)
      cl(account)
      this.setState({accounts:accounts,relAccounts:relAccounts,selectedType:"",selectedId:""})//
      return await wsTrans("usa", {cmd: "cRest", uri: "/su/suAccounts",
        method: "delete", sessionId: globs.userData.session.sessionId,
        body: {accountId:account.accountId}})
    }
  }

  toggleDeleteZone=()=>{
//     cl("toggle delete")
    let st=this.state
    let zones=st.zones.slice(0)
    let zone=zones.filter(z=>{return z.zoneId==st.selectedId})[0]
//     cl(zone)
    zone.deleted=(zone.deleted)?false:true
    this.setState({zones:zones,editType:"zone",editField:"deleted"})//
  }

  deleteZone=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Zone?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let st=this.state
      cl(st)
      let zone=st.zones.filter(z=>{return z.zoneId==st.selectedId})[0]
      let zones=st.zones.filter(z=>{return z.zoneId!=st.selectedId})
      let relZones=st.relZones.filter(z=>z.v!=st.selectedId)
      cl(zone)
      this.setState({zones:zones,relZones:relZones,selectedType:"",selectedId:""})//
      return await wsTrans("usa", {cmd: "cRest", uri: "/su/allZones",
        method: "delete", sessionId: globs.userData.session.sessionId,
        body: {zoneId:zone.zoneId}})
    }
  }

  doRefresh=async(section)=>{
//     let users=(await this.loadWsTrans({uri:"/su/allUsers"})).data
// //     cl(users)
//     let accounts=(await this.loadWsTrans({uri:"/su/suAccounts"})).data
//     let sites=(await this.loadWsTrans({uri:"/su/allSites"})).data
//     let gateways=(await this.loadWsTrans({uri:"/su/allGateways"})).data
//     let zones=(await this.loadWsTrans({uri:"/su/allZones"})).data

    let vals={}
    let tp=(section=="techPortal")
    let st=this.state
    if((tp)||(section=="accounts")){
      vals.accounts=(await this.loadWsTrans({uri:"/su/suAccounts"})).data
    }
    if((tp)||(section=="users")){
      vals.users=(await this.loadWsTrans({uri:"/su/allUsers"})).data
    }
    if((tp)||(section=="sites")){
      vals.sites=(await this.loadWsTrans({uri:"/su/allSites"})).data
    }
    if((tp)||(section=="gateways")){
      vals.gateways=(await this.loadWsTrans({uri:"/su/allGateways"})).data
    }
    if((tp)||(section=="zones")){
//       cl(this.state)
      vals.zones=(await this.loadWsTrans({uri:"/su/allZones"})).data
      if(st.selectedType=="zone"){
        vals.zoneLastData=await this.getZoneLastData(st.selectedId)
      }
    }
    this.setState(vals)
//     cl(section)
  }

  addIGrowAcct=async(acct)=>{
    cl(acct)
    let st=this.state
    cl(st.iGrowAcct)
    cl(st)
    let res=await wsTrans("usa", {cmd: "cRest", uri: '/s/iGrow800Acct',
      method: 'retrieve', sessionId: globs.userData.session.sessionId,
      body: {igAccountId:st.iGrowAcct,siteId:st.selectedId,
        controllerType:1}})
    cl(res)
    let controllers=res.data
      .filter(c=>{return c.controllerType==1})
      .map(c=>{return c.serialNo})
    cl(controllers)
  }

  debugClick=async(text)=>{
//     cl(text)
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/su/debugCmd",
      method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: {cmd:this.state.debugText}})
    cl(res.data)
    this.setState({debugResp:res.data})
  }

  onChange=async(type,vals)=>{
    cl(type,vals)
    let st=this.state
    let searches={updAccount:this.searchAccount,updUser:this.searchUser,
      updSite:this.searchSite,updGateway:this.searchGateway,updZone:this.searchZone}
//     cl(type,vals)
    var gateway
    switch(type){
      case "updAccount":
      case "updUser":
      case "updSite":
      case "updGateway":
      case "updZone":
        vals.searchType=type
        searches[type](vals)
        vals.searchSel=-1
        this.setState(vals)
        break
      case "updSearch":
//         cl(st.searchRes)
        vals.searchRes={}
        this.types.forEach(k=>{
          this.searchType(vals,k)
        })
        Object.assign(vals,
          {relAccounts:[],relUsers:[],relSites:[],relGateways:[],relZones:[],selectedType:""}
        )
//         Object.keys(this.state.searchRes).forEach(k=>{
//           this.searchType(vals,k)
//         })
        this.setState(vals)
        break
      case "sel":
        this.unSaveFields()
        this.selectSearch(vals.type,vals.searchSel,vals.id)
        vals.selectedType=vals.type
//         cl(vals)
        if(vals.type=="account"){vals.accountFeatures=this.getFeatures(vals.searchSel)}
        if(vals.type=="zone"){
          this.zone=(st.zones.filter(z=>{return z.zoneId==vals.searchSel})||[])[0]
          this.gwType=this.zone?.gatewayType||1800
          vals.zoneLastData=await this.getZoneLastData(vals.searchSel)}
          if(this.zone){await loadSiteData(this.zone.siteId)}

        vals.searchRes={}
//         cl(vals)
        this.setState(vals)
        break
      case "searchKey":
//         cl(vals)
//         cl(st.searchType)
        if(st.searchType!=""){
          if(["ArrowDown","ArrowUp"].includes(vals.key)){this.searchKey(vals.key)}
          if(vals.key=="Enter"){this.selectSearch(st.searchSel)}
        }
        break
      case "accountSel":// these are selections from the related list
        this.unSaveFields()
        this.getRelatedItems(vals.accountSel)
//         cl(vals)
        let accountFeatures=this.getFeatures(vals.accountSel)
        this.setState({searchType:"",selectedType:"account",selectedId:vals.accountSel,
          accountSel:vals.accountSel,accountFeatures:accountFeatures,
        },
        )
        break
      case "userSel":
        this.unSaveFields()
        this.setState({searchType:"",selectedType:"user",selectedId:vals.userSel,
          userSel:vals.userSel,
        })
        break
      case "siteSel":
//         cl(vals)
//         cl(st)
        this.unSaveFields()
        let site=st.sites.filter(s=>{
          return s.siteId==vals.siteSel})[0]
        this.getRelatedItems(site.accountId,vals.siteSel,null,null,"site")
        this.setState({searchType:"",selectedType:"site",selectedId:vals.siteSel,
          siteSel:vals.siteSel,
        })
        break
      case "gatewaySel":
//         cl(vals)
        this.unSaveFields()
        gateway=st.gateways/*.gw*/.filter(g=>{
          return g.gatewayId==vals.gatewaySel})[0]
//         this.gwType=gateway.gatewayType||1800
//         cl(gateway)
//         cl(st.gateways)
        this.getRelatedItems(gateway.accountId,gateway.siteId,vals.gatewaySel,null,"gateway")
        this.setState({searchType:"",selectedType:"gateway",selectedId:vals.gatewaySel,
          gatewaySel:vals.gatewaySel,
        })
        break
      case "zoneSel":
//         cl("zonesel")
//         cl(vals)
        this.unSaveFields()
        let zone=st.zones.filter(z=>{return z.zoneId==vals.zoneSel})[0]
        cl(zone)
        gateway=st.gateways/*.gw*/.filter(g=>{
          return g.gatewayId==zone.gatewayId})[0]
        cl(gateway)
        this.gwType=gateway?.gatewayType||1800
        this.zone=zone
//         this.zInd=zone.siteZoneIndex

        let zoneLastData=await this.getZoneLastData(vals.zoneSel)
        cl("load site data")
        await loadSiteData(this.zone.siteId)
        cl("load site data done")
//         cl(zoneLastData)
        this.getRelatedItems(zone.accountId,zone.siteId,zone.gatewayId)
        this.setState({searchType:"",selectedType:"zone",selectedId:vals.zoneSel,
          gatewaySel:zone.gatewayId,zoneSel:vals.zoneSel,zoneLastData:zoneLastData,
        })
        break
      case "resetPassword":
        this.resetPassword()
        break
      case "activateUser":
        this.activateUser()
        break
      case "deleteUser":
        this.deleteUser()
        break
      case "hideShowCustomSaveField":
        this.hideShowCustomSaveField(vals)
        break
      case "editCustomSaveField":
        this.editCustomSaveField(vals)
        break
      case "showUserAccountId":
        vals.editUserAcctId=true
        this.setState(vals)
        break
//       case "showGatewaySiteId":
//         vals.editGatewaySiteId=true
//         this.setState(vals)
//         break
      case "userAccountId":
        globs.events.publish("savePageEnable",true)
        this.setState(vals)
        break
      case "deleteAccount":
        this.deleteAccount()
        break
      case "deleteZone":
        this.deleteZone()
        break
      case "toggleDeleteZone":
        globs.events.publish("savePageEnable",true)
        this.toggleDeleteZone()
        break
      case "deleteSite":
        this.deleteSite()
        break
      case "deleteGateway":
        this.deleteGateway()
        break
      case "feature":
        let features=Object.assign(st.accountFeatures)
        features[vals.feature]=vals.checked
        cl(vals)
        this.setState({accountFeatures:features})
        return
      case "saveFeatures":
//         cl(type)
        this.saveFeatures()
        break
      case "viewEdit":
        cl(vals)
        this.setState({editType:vals.type,editField:vals.field})
        cl(vals)
        break
      case "valEdit":
        globs.events.publish("savePageEnable",true)
        this.valEdit(vals)
        break
      case "link":
        vals.e.preventDefault()
        this.doLink(vals)
//         cl(vals)
        break
      case "upd":
        this.setState(vals)
        break
      case "refresh":
        this.doRefresh(vals.element)
//         cl(vals)
        break
      case "sendFwFile":
        cl(type)
        break
      case "igrow":
        this.addIGrowAcct(st.iGrowAcct)
        break
      case "disableCache":
        cl(vals.disableCache)
        saveLocalStorage("disableCache",vals.disableCache)
        this.setState(vals)
        cl(vals)
        break
      case "dbTab":
      case "dbCol":
      case "dbChan":
      case "dbInd":
      case "dbVal":
        if(type=="dbTab"){
          let tabId=dpNames[vals.selDbTab]
          cl(tabId)
          cl(this.gwType)
          let tab=pi[this.gwType][tabId]
          let selCol=Object.keys(tab)[0]
          vals.selDbCol=selCol
          cl(tab)

        }
        if(type!="dbVal"){
          vals.dbVal=this.getDBVal(vals)
        }
        cl(vals)

        this.setState(vals)
        break
      case "dbSave":
        this.setDBVal()
        break
      case "debugText":
        this.setState(vals)
        break
      case "debugClick":
        this.debugClick(st.debugText)
        break
      case "pageMenu":
        if(vals.pageType=="mqttLog"){
          await this.loadMqttLog()
//           vals.mqttLog=await this.loadMqttLog()
        }
//         cl(vals)
        this.setState(vals)
        break
      case "mqttLogTime":
//         let val=dateToDisplayDate(new Date(1000*st.mqttLogTime),"yyyy-mm-ddThh:mm")
        vals.mqttLogTime=(+Date.parse(vals.mqttLogTime))/1000
        this.loadMqttLog(vals.mqttLogTime)
        this.setState(vals)

        break
      case "mqttMsgClick":
//         cl(vals)
        this.setState(vals)
        break
      case "dTime":
//         cl(vals)
        this.loadMqttLog(st.mqttLogTime+vals.dif)
        this.setState({mqttLogTime:st.mqttLogTime+vals.dif})
        break
      case "liveContClick":
        this.setState(vals)
        break
      case "mqttClient":
        cl(vals)
        this.setState(vals)
        break
    }
  }

  valEdit=(vals)=>{
    let st=this.state
    switch(st.selectedType){
      case "zone":
        let zones=st.zones.slice(0)
        let zone=zones.filter(z=>{return z.zoneId==st.selectedId})[0]
        zone[st.editField]=vals.val
        this.setState({zones:zones})
        break
      case "gateway":
        let gateways=st.gateways.slice(0)
        let gateway=gateways.filter(g=>{return g.gatewayId==st.selectedId})[0]
        gateway[st.editField]=vals.val
        this.setState({gateways:gateways})
        break
    }
  }

  saveFeatures=async()=>{
    let st=this.state
//     cl("save features")
    let features=[]
    Object.keys(st.accountFeatures).forEach(f=>{
//       cl(f)
      if(st.accountFeatures[f]){features.push(f)}
    })
    let selAccount=st.accounts.filter(a=>{return a.accountId==st.selectedId})[0]
    selAccount.features=features
    cl(st)
    cl(features)

    return await wsTrans("usa", {cmd: "cRest", uri: '/su/suAccounts',
      method: 'update', sessionId: globs.userData.session.sessionId,
      body: {accountId:st.selectedId,features:features}})
  }

  showSearchBox=(type)=>{
    let st=this.state
//     cl(st.searchRes[type])
    return(
      <div style={{width:"100%",height:200,backgroundColor:this.bgColor2,marginRight:30,
        padding:10,overflowY:"auto",
      }}>
      <table><tbody>
      {st.searchRes[type].map((r,i)=>{
        let txt=(r.t)?r.t:"No Name"
//         cl(r)
        let bg=(r.v==st.searchSel)?this.selColor1:null
        return(
          <tr key={i} style={{backgroundColor:bg,cursor:"pointer"}}
            onClick={e=>this.onChange("sel",{type:type,searchSel:r.v,id:r.id})}
          ><td>{txt}</td></tr>
        )})}
      </tbody></table>
      </div>
    )
  }

  getRelatedItems=(accountId,siteId,gatewayId,zoneId,selType)=>{

//     cl(accountId,siteId,gatewayId,zoneId,selType)
    var filterRes=(type,val)=>{
//       cl(type,val)
//       if(type=="gateway"){cl(val.gatewayId)}

      if(siteId){
        if(gatewayId&&(type!="site")){
//           if(val.siteId=="wYlujiZmhVlPN0*n"){cl(type);cl(val)}
          if(zoneId){
            return (val.zoneId==zoneId)&&(val.siteId==siteId)
            &&(val.gatewayId==gatewayId)&&(val.accountId==accountId)
          }else{
            if(selType=="gateway"){
//               cl(val)
              return (val.gatewayId==gatewayId)&&(val.accountId==accountId)
            }else{
              let tVal=(val.siteId==siteId)&&(val.accountId==accountId)
                &&(val.gatewayId==gatewayId)
//               if(val.gatewayId=="ZAHN0AUF9MGY4Y35"){cl(tVal);cl(val)}
              return (val.siteId==siteId)&&(val.accountId==accountId)
                &&(val.gatewayId==gatewayId)
            }
          }
        }else{
          return (val.siteId==siteId)&&(val.accountId==accountId)
        }
      }else{
        return val.accountId==accountId
      }
    }

    let field={updAccount:"accountId"}
    let st=this.state
    let relAccounts=st.accounts.filter(a=>{return a.accountId==accountId}).map(a=>
      {return {v:a.accountId,t:a.name}})
    let relUsers=st.users.filter(u=>{return u.accountId==accountId}).map(u=>
      {return {v:u.userId,t:u.name}})

    let relSites=st.sites.filter(s=>{return filterRes("site",s)}).map(s=>
      {return {v:s.siteId,t:s.name}})

//     cl(st.gateways.filter(g=>{return g.siteId=="wYlujiZmhVlPN0*n"}))

    let relGateways=st.gateways/*.gw*/.filter(g=>{return filterRes("gateway",g)}).map(g=>
      {return {v:g.gatewayId,t:g.name||"No GW Name"}})
//     cl(relGateways)

    let relZones=st.zones.filter(z=>{return filterRes("zone",z)}).map(z=>
      {return {v:z.zoneId,t:z.zoneName}})
//     cl(relUsers)
    this.setState({relAccounts:relAccounts,relUsers:relUsers,relSites:relSites,
      relGateways:relGateways,relZones:relZones})
  }

  selectSearch=(type,id,id2)=>{// id2 is the mongo _id
//     cl(type,id,id2)
    let st=this.state
//     cl(st.searchType)
    let parms={
      account:{v:"accounts",id:"accountId"},
      user:{v:"users",id:"userId"},
      site:{v:"sites",id:"siteId"},
      gateway:{v:"gateways",id:"gatewayId"},
      zone:{v:"zones",id:"zoneId"},
    }[type]
    var val
//     let arr=(parms.v=="gateways")?st[parms.v].gw:st[parms.v]
    if(id2){
      val=st[parms.v]/*arr*/.filter(s=>{return s["_id"]==id2})[0]
    }else{
      val=st[parms.v]/*arr*/.filter(s=>{return s[parms.id]==id})[0]
    }
//     cl(val)
//     let accountId=val.accountId


//     cl("select "+id)
//     let accountId=id
//     cl(val.accountId,val.siteId,val.gatewayId,null,type)
    this.getRelatedItems(val.accountId,val.siteId,val.gatewayId,null,type)//,val.zoneId
    this.setState({searchType:"",selectedId:id})
  }

//   makeOpts=(vals)=>{
//     return vals.map((v,i)=>{return(
//       <option key={i} value={v.v}>{v.t}</option>
//     )})
//   }

//   showSelectUser=(relUsers)=>{
//     cl(relUsers)
//     return(
//       <div className="custom-select">
//         <div className="clearfloat"></div><br/>
//         <label htmlFor="">Related Users</label>
//
//         <C18Select00 id=""
//           parms={{list:true,height:200}}
//           value={this.state.userSel}
//           onChange={e=>this.onChange("userSel",{userSel: e.currentTarget.value})}
//         >
//           {this.makeOpts(relUsers)}
//         </C18Select00>
//         {false&&
//           <span className="material-icons down-arrow">
//             keyboard_arrow_down
//           </span>
//         }
//       </div>
//     )
//   }

  showSelectType=(relTypes,type)=>{
//     cl(type)
//     cl(relTypes)
    let parms={
      account:{t:"Accounts",v:"accountSel"},
      user:{t:"Users",v:"userSel"},
      site:{t:"Sites",v:"siteSel"},
      gateway:{t:"Gateways",v:"gatewaySel"},
      zone:{t:"Zones",v:"zoneSel"},
    }[type]
    return(
      <div className="custom-select">
        <div className="clearfloat"></div><br/>
        <label htmlFor="">Related {parms.t}</label>
        <C18Select00 id=""
          parms={{list:true,height:200}}
          value={this.state[parms.v]}
          onChange={e=>{
            let vals={}
            vals[parms.v]=e.currentTarget.value
            this.onChange(parms.v,vals)}
          }
        >
          {makeOpts(relTypes)}
        </C18Select00>
        {false&&
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        }
      </div>
    )
  }

  showFeatureFlags=(selAccount)=>{
    let features=[
    {v:"tasks",t:"Tasks"},
    {v:"messaging",t:"Messaging"},
    {v:"editFui",t:"Edit Fui"},
    {v:"cameras",t:"Cameras"},
    {v:"showGjId",t:"Show GJ ID"},
    {v:"techPortal",t:"Tech Portal"},
    {v:"salesPortal",t:"Sales Portal"},
    {v:"editInfo",t:"Edit Info Pages"},
    {v:"gjPing",t:"GJ Ping"},
    {v:"3rdParty",t:"Third Party"},
    {v:"fullSiteAccess",t:"Full Site Access"},
    {v:"showUserEmail",t:"Show Email"},
    {v:"zoneGroups",t:"Zone Groups"},
    {v:"saveDefaults",t:"Save Defaults"},
    {v:"equipmentImage",t:"Equipment Image"},
    {v:"configSave",t:"Save Config"},
//     {v:"videoTest",t:"Video Test"},
    {v:"testing",t:"Testing"},
    {v:"systemStatus",t:"System Status"},
    {v:"cropRecipes",t:"Crop Recipes"},
    {v:"svgEditor",t:"SVG Editor"},
    {v:"virtualZones",t:"Virtual Zones"},
    {v:"filterList",t:"Filter Lists"},
    {v:"watchParams",t:"Watch Parameters"},
    {v:"testAcct",t:"Test Account"},
    {v:"physView",t:"Physical View"},
    {v:"syncStatus",t:"Sync Status"},
    {v:"accesses",t:"View Page Accesses"},
    {v:"advancedDataViz",t:"Advanced Data Visualization"},    
    {v:"advancedGraphingInterp",t:"Advanced Graphing Interpolation"},    
    {v:"debug",t:"Debug Functions"},
    {v:"errorBounds",t:"Error Bounds"},
    {v:"fuiShow",t:"Show FUI PIDs"},
//     {v:"fwUpload",t:"Firmware Upload"},
// tasks, messaging, editFui, cameras, showGjId, techPortal, salesPortal, gjPing, 3rdParty,fullSiteAccess,
// showUserEmail, zoneGroups, configSave,videoTest
    ]
    let st=this.state
//     cl(st.accountFeatures)
    return(
      <div style={{height:100,backgroundColor:this.bgColor2, overflowY:"auto",padding:10,
        borderStyle:"solid",borderWidth:1,

      }}>
      <table><tbody>
      {features.map((f,i)=>{
        return(
        <tr key={i}><td>
        <input type="checkbox" style={{margin:5}}
          checked={st.accountFeatures[f.v]||false}
          onChange={e=>{this.onChange("feature",{feature:f.v,checked:e.currentTarget.checked})}}
        />
        </td>
        <td>{f.t}</td>
        </tr>
      )})}
      </tbody></table>
      </div>
    )
  }

  showUserAccountId=(user)=>{
//     cl(user)
    let st=this.state
    if(st.editUserAcctId){
      return(
        <div>
          <input type="text"
          style={{width:"100%",padding:0,borderRadius:0}}
          value={st.userAccountId}
          onChange={e=>this.onChange("userAccountId",
            {userAccountId:e.currentTarget.value})}
          ></input>
        </div>
      )
    }else{
      return <span>here{user.accountId}</span>
    }
  }

//   showGatewaySiteId=(gw)=>{
//     let st=this.state
//     let selGateway=null
//     if(st.editGatewaySiteId){
//       return(
//         <div>
//             <tr><td>Site ID:</td><td
//               style={{cursor:"pointer"}}
//               onClick={e=>this.onChange("showGatewaySiteId",{gatewaySiteId:selGateway.siteId})}
//             >
//             {this.showGatewaySiteId(selGateway)}
//             <input type="text"
//             style={{width:"100%",padding:0,borderRadius:0}}
//             value={st.gatewaySiteId}
//             onChange={e=>this.onChange("gatewaySiteId",
//               {gatewaySiteId:e.currentTarget.value})}
//             ></input>
//             </td></tr>
//         </div>
//       )
//     }else{
//
//       return <span>{gw.siteId||"No ID"}</span>
//     }
//   }

  showIGrow=()=>{
    let st=this.state
    let zones=st.zones.filter(z=>{
      return z.siteId==st.selectedId})
//     cl(zones[0]?.siteId,st.siteSel)
//     cl(zones)
    if(!zones.length){
      let sty={borderRadius:0,borderWidth:1,borderStyle:"solid",padding:0,width:60,
        display:"inline-block",marginLeft:5}
      let sty2=Object.assign({},sty,{width:40})
      return(
        <div>
          <h3>Add iGrow Account to Site</h3>
          <label style={{display:"inline-block"}}>AcctID:</label>
          <input type="number"
            value={st.iGrowAcct||0}
            onChange={e=>{this.onChange("upd",{iGrowAcct:e.target.value})}}
            style={sty}/>
          <button style={sty2}
          onClick={e=>{this.onChange("igrow")}}
          >Add</button>
        </div>
      )
    }
  }

  showDbTableSelect=()=>{
    let gwType=1800
    let st=this.state

    let opts=Object.keys(dpNames).map((ta,i)=>{return(//[gwType]
      <option key={i} value={ta}>{dpNames[ta]}</option>
    )})
    return(
      <div>
      <label htmlFor="tabSel" style={{marginBottom:0,marginRight:5,display:"inline-block"}}>tab:</label>
      <select id="tabSel"
        value={st.selDbTab||""}
        onChange={e=>{this.onChange("dbTab",{selDbTab:e.currentTarget.value})} }
      >
        {opts}
      </select>
      </div>
    )
  }

  showDbColumnSelect=()=>{
    let gwType=1800
    let st=this.state
//     cl(st.selDbTab)
    let tabId=dpNames[st.selDbTab]
//     cl(pi[gwType][tabId])
    let tab=pi[gwType][tabId]
    let opts=Object.keys(tab).map((k,i)=>{
      let op=tab[k]
      return <option key={i} value={k}>{k}</option>
    })
    return(
      <div>
      <label htmlFor="colSel" style={{marginBottom:0,marginRight:5,display:"inline-block"}}>col:</label>
      <select id="colSel"
        value={st.selDbCol||""}
        onChange={e=>{this.onChange("dbCol",{selDbCol:e.currentTarget.value})} }
      >
        {opts}
      </select>
      </div>
    )
  }

  showDbChanSelect=()=>{
//     let gwType=1800
    let st=this.state
//     cl(st.selDbTab)
//     cl(pi[gwType][st.selDbTab])
//     let tab=pi[gwType][st.selDbTab]

    let opts=[...Array(64)].map((k,i)=>{
      return <option key={i} value={i}>{i}</option>
    })
    return(
      <div>
      <label htmlFor="chSel" style={{marginBottom:0,marginRight:5,display:"inline-block"}}>chn:</label>
      <select id="chSel"
        value={st.selDbChan||""}
        onChange={e=>{this.onChange("dbChan",{selDbChan:e.currentTarget.value})} }
      >
        {opts}
      </select>
      </div>
    )
  }

  showDbIndexSelect=()=>{
//     let gwType=1800
    let st=this.state
//     cl(st.selDbTab)
//     cl(pi[gwType][st.selDbTab])
//     let tab=pi[gwType][st.selDbTab]

    let opts=[...Array(64)].map((k,i)=>{
      return <option key={i} value={i}>{i}</option>
    })
    return(
      <div>
      <label htmlFor="indSel" style={{marginBottom:0,marginRight:5,display:"inline-block"}}>ind:</label>
      <select id="indSel"
        value={st.selDbInd||""}
        onChange={e=>{this.onChange("dbInd",{selDbInd:e.currentTarget.value})} }
      >
        {opts}
      </select>
      </div>
    )
  }

  getParamInfo=(vals)=>{
    let st=this.state
    let gw=st.gateways/*.gw*/.filter(g=>{return g.gatewayId==st.gatewaySel})
    vals=Object.assign({},st,vals)
    let tabId=dpNames[vals.selDbTab]
    let tab=pInd[this.gwType][tabId]
    cl(tab)
    vals.selDbChan=+(vals.selDbChan||0)
    switch(tab[1]){
      case 0:
        cl("do 0")
        vals.selDbChan=240
        break
      case 2:
        vals.selDbChan+=192
        break
      default:
        break
    }
    vals.pid=getParamId2(this.gwType,vals.selDbTab,vals.selDbCol)
    vals.pid+=(+(vals.dbInd||0))*tab[2]
    cl(`zone: ${this.zone.siteZoneIndex}, chan: ${vals.selDbChan||0}, pid: ${vals.pid}`)
    cl(vals)
    return vals
  }

  setDBVal=(vals)=>{
    let st=this.state
    cl(vals)
    vals=this.getParamInfo(vals);
    ((dbVals.z[this.zone.siteZoneIndex]||{})[vals.selDbChan]||{})[vals.pid]=vals.dbVal
    let params=[{
      c:vals.selDbChan,
      d:st.dbVal,
      f:2,
      i:vals.pid,
      t:getTimeI(),
      z:this.zone.siteZoneIndex,
    }]
    let par={
      cmd:"data01",
      s:this.zone.siteId,
      gwType:this.zone.gatewayType||1800,
      sessionId:globs.userData.session.sessionId,
      user:globs.userData.session.userId,
      virtual:this.zone.virtual||false,
      params:params,
    }
    cl(par)
    sendSocket(par)
  }

  getDBVal=(vals)=>{
    vals=this.getParamInfo(vals)
    cl(vals)
//     cl(dbVals.z[0])
//     cl(dbVals.z[0][0])
//     cl(dbVals.z[0][0][507])
//     cl((dbVals.z[this.zone.siteZoneIndex]||{}))
//     cl(((dbVals.z[this.zone.siteZoneIndex]||{})[vals.selDbChan]||{}))
//     cl(((dbVals.z[this.zone.siteZoneIndex]||{})[vals.selDbChan]||{})[507])
//     cl(((dbVals.z[this.zone.siteZoneIndex]||{})[vals.selDbChan]||{})[+pid])
    let val=((dbVals.z[this.zone.siteZoneIndex]||{})[vals.selDbChan]||{})
      [vals.pid]||"nodata"
    cl(val)
    return val

//     let st=this.state
//     let gw=st.gateways/*.gw*/.filter(g=>{return g.gatewayId==st.gatewaySel})
//     vals=Object.assign({},st,vals)
//     let tabId=dpNames[vals.selDbTab]
//     let tab=pInd[this.gwType][tabId]
//     cl(tab)
//     vals.selDbChan=vals.selDbChan||0
//     switch(tab[1]){
//       case 0:
//         vals.selDbChan=240
//         break
//       case 2:
//         vals.selDbChan+=192
//         break
//       default:
//         break
//     }
//     let pid=getParamId2(this.gwType,vals.selDbTab,vals.selDbCol)
//     pid+=(+(vals.dbInd||0))*tab[2]
//     cl(`zone: ${this.zone.siteZoneIndex}, chan: ${vals.selDbChan||0}, pid: ${pid}`)
//     cl(vals.dbInd)
//     cl(tab[2])
//     cl((+vals.dbInd)*tab[2])
//     cl(dbVals.z[0])
//     cl(dbVals.z[0][0])
//     cl(dbVals.z[0][0][507])
//     cl((dbVals.z[this.zone.siteZoneIndex]||{}))
//     cl(((dbVals.z[this.zone.siteZoneIndex]||{})[vals.selDbChan]||{}))
//     cl(((dbVals.z[this.zone.siteZoneIndex]||{})[vals.selDbChan]||{})[507])
//     cl(((dbVals.z[this.zone.siteZoneIndex]||{})[vals.selDbChan]||{})[+pid])

//     let val=((dbVals.z[this.zone.siteZoneIndex]||{})[vals.selDbChan]||{})[pid]||"nodata"
//     return val
  }

  showBrowseDB=()=>{
    let st=this.state
//         <label htmlFor="chanText" style={{display:"inline-block"}}
//         >c:</label>
//         <input type="text" id="chanText"
//           style={{marginTop:10,marginLeft:10,padding:0,minWidth:0,width:50,borderRadius:0,
//             display:"inline-block"
//           }}
//         />
//         <label htmlFor="pidText" style={{display:"inline-block",marginLeft:15,}}>i:</label>
//         <input type="text" id="pidText"
//           style={{marginTop:10,marginLeft:10,padding:0,minWidth:0,width:50,borderRadius:0,
//             display:"inline-block"
//           }}
//         />
//         <label htmlFor="pidText" style={{display:"inline-block",marginLeft:15,}}>d:</label>
//         <input type="text" id="pidText"
//           style={{marginTop:10,marginLeft:10,padding:0,minWidth:0,width:50,borderRadius:0,
//             display:"inline-block"
//           }}
//         />
    return(
      <div>
        <div className="clearfloat"></div><br/>
        {this.showDbTableSelect()}
        {this.showDbColumnSelect()}
        {this.showDbChanSelect()}
        {this.showDbIndexSelect()}
        <label htmlFor="dbVal" style={{display:"inline-block",marginRight:5,}}>val:</label>
        <input type="text" id="dbVal"
          value={st.dbVal||""}
          onChange={e=>this.onChange("dbVal",{dbVal:e.currentTarget.value})}
          style={{padding:0,minWidth:0,width:100,borderRadius:0,
            display:"inline-block"
          }}
        />
        <button type="button" style={{borderStyle:"solid",borderWidth:1,padding:"0px 5px",
          borderRadius:0,marginLeft:10}}
          onClick={e=>{this.onChange("dbSave")}}
          >Save</button>
      </div>

    )
  }

  showSelectedInfo=(type)=>{
// this is for the one item currently selected
    let tiers={locked:"Locked",unlocked:"UnLocked"}
    let st=this.state
    var created
    switch(type){
      case "account":
        let selAccount=st.accounts.filter(a=>{return a.accountId==st.selectedId})[0]
        let owner=st.users.filter(u=>{return u.userId==selAccount.owner})[0]||{}
        created=(selAccount.created)?this.ts2str(selAccount.created):"(unknown)"
        return(
          <div>
            <h4>{selAccount.name}</h4>
            <table><tbody>
            <tr><td>ID:</td><td>{selAccount.accountId||"No ID"}</td></tr>
            <tr><td>Created:</td><td>{created}</td></tr>
            <tr><td>Email:</td><td>{selAccount.adminEmail}</td></tr>
            <tr><td>Owner:</td><td>{owner.name}</td></tr>
            </tbody></table>
            <div style={{borderStyle:"solid",borderWidth:1,borderRadius:10,
              padding:10
            }}>
              <h4>Feature Flags</h4>
              {this.showFeatureFlags(selAccount)}
              <div className="clearfloat"></div><br/>
              <C18Button00 type="button" className="filled"
              onClick={e=>this.onChange("saveFeatures")}
              >
              Save</C18Button00>
            </div>
            <div className="clearfloat"/><br/>
            <C18Button00 type="button" className="danger"
              onClick={e=>{this.onChange("deleteAccount")}}
            >Delete</C18Button00>
          </div>
        )
      case "user":
        let selUser=st.users.filter(u=>{return u._id==st.id})[0]
        if(!selUser){
          selUser=st.users.filter(u=>{return u.userId==st.selectedId})[0]// needed for selections from system check
        }
        let activated=(!selUser.token)
        let invited=!!selUser.accountId
        let pw=st.resetPW
        created=(selUser.created)?this.ts2str(selUser.created):"(unknown)"

//             <tr><td>Acct ID:</td><td
//               style={{cursor:"pointer"}}
//               onClick={e=>this.onChange("showUserAccountId",{userAccountId:selUser.accountId})}
//             >
//             {this.showUserAccountId(selUser)}
//             </td></tr>
        return(
          <div>
            <h4>{selUser.name}</h4>
            <table><tbody>
            <tr><td>ID:</td><td>{selUser.userId}</td></tr>
            <tr><td>Created:</td><td>{created}</td></tr>
            {this.showCustomSaveField(this.saveFields[1])}
            <tr><td>Email:</td><td>{selUser.email}</td></tr>
            <tr><td>Active:</td><td>{(selUser.active)?"Yes":"No"}</td></tr>
            <tr><td>Activated:</td><td>{(activated)?"Yes":"No"}</td></tr>
            {!activated&&
              <tr><td>Invited:</td><td>{(invited)?"Yes":"No"}</td></tr>
            }
            {(pw!="")&&
              <tr><td>Password:</td><td>{pw}</td></tr>
            }
            </tbody></table>
            {activated?
              <>
                <div className="clearfloat"/><br/>
                <C18Button00 type="button" className="filled"
                  onClick={e=>{this.onChange("resetPassword")}}
                >Reset Password</C18Button00>
              </>:
              <>
                <div className="clearfloat"/><br/>
                <C18Button00 type="button" className="filled"
                  onClick={e=>{this.onChange("activateUser")}}
                >Activate</C18Button00>
              </>
            }
            <div className="clearfloat"/><br/>
            <C18Button00 type="button" className="danger"
              onClick={e=>{this.onChange("deleteUser")}}
            >Delete</C18Button00>
          </div>
        )
      case "site":
        let selSite=st.sites.filter(s=>{return s.siteId==st.selectedId})[0]
        created=(selSite.created)?this.ts2str(selSite.created):"(unknown)"
//         cl(selSite)
        return(
          <div>
            <h4>{selSite.name}</h4>
            <table><tbody>
            <tr><td>ID:</td><td>{selSite.siteId}</td></tr>
            <tr><td>Created:</td><td>{created}</td></tr>
            <tr><td>Account ID:</td><td>{selSite.accountId}</td></tr>
            </tbody></table>
            <div className="clearfloat"/><br/>
            <C18Button00 type="button" className="danger"
              onClick={e=>{this.onChange("deleteSite")}}
            >Delete</C18Button00>
            {this.showIGrow()}
          </div>
        )
      case "gateway":
        let selGateway=st.gateways/*.gw*/.filter(g=>{return g.gatewayId==st.selectedId})[0]||{}
        let zones=st.zones.filter(z=>{return z.gatewayId==st.selectedId})
        zones.sort((a,b)=>{
          if(a.gatewayZoneId>b.gatewayZoneId){return 1}
          if(a.gatewayZoneId<b.gatewayZoneId){return -1}
          return 0
        })
        created=(selGateway.created)?this.ts2str(selGateway.created):"(unknown)"
        var mqttContact
        let mcInfo=this.state.mqttContacts[selGateway.clientId]
        if(mcInfo){
          let now=getTimeI()
          let lastComm=`${now-mcInfo.ts} seconds ago`
          mcInfo=(
            <>
              <tr><td>Last MQTT:</td><td>{lastComm}</td></tr>
              <tr><td>Server:</td><td>{mcInfo.server}</td></tr>
              <tr><td>Database:</td><td>{mcInfo.database}</td></tr>
              <tr><td>Clients:</td><td>{mcInfo.clients||"none"}</td></tr>
            </>

          )
//           cl(mcInfo)
        }
        return(
          <div>
            <h4>{selGateway.name}</h4>
            <table><tbody>
            <tr><td>GW ID:</td><td>{selGateway.gatewayId}</td></tr>
            <tr><td>Proc ID:</td><td
            style={{fontSize:11}}
            >{selGateway.clientId}</td></tr>
            <tr><td>Created:</td><td>{created}</td></tr>
            <tr><td>Last Comm:</td><td>{this.ts2str(selGateway.updateTime)}</td></tr>

            <tr><td>Account ID:</td>
              <td>{this.showViewEdit(selGateway,type,"accountId")}</td>
            </tr>

            {this.showCustomSaveField(this.saveFields[0])}
            <tr><td>Last IP:</td><td>{selGateway.ip||"None"}</td></tr>
            <tr><td>FW Version:</td><td>{selGateway.fwVersion||"None"}</td></tr>
            {zones.map((z,i)=>{
              return(
                <tr key={"z"+i}><td>{`Zone ${z.gatewayZoneIndex}:`}</td>
                <td>{`to Site: ${z.siteZoneIndex}`}</td></tr>
              )
            })}
            {mcInfo}
            </tbody></table>
            <div className="clearfloat"/><br/>
            <C18Button00 type="button" className="danger"
              onClick={e=>{this.onChange("deleteGateway")}}
            >Delete</C18Button00>
          </div>
        )
      case "zone":
        let selZone=st.zones.filter(z=>{return z.zoneId==st.selectedId})[0]
//         cl(selZone)
//         let tier=selZone.zoneTier
//             <td>{selZone.siteZoneIndex}</td>
//             <td>{selZone.zoneId}</td>
        created=(selZone.created)?this.ts2str(selZone.created):"(unknown)"
        return(
          <div>
            <h4>{selZone.zoneName}</h4>
            <table><tbody>
            <tr><td>Namex:</td>
            <td>{this.showViewEdit(selZone,type,"zoneName")}</td>
            </tr>

            <tr><td>ID:</td><td>{selZone.zoneId}</td></tr>

            <tr><td>Account ID:</td>
              <td>{this.showViewEdit(selZone,type,"accountId")}</td>
            </tr>

            <tr><td>Site ID:</td>
              <td>{this.showViewEdit(selZone,type,"siteId")}</td>
            </tr>
            <tr><td>Created:</td><td>{created}</td></tr>
            <tr style={{cursor:"pointer"}}
              onClick={e=>this.onChange("toggleDeleteZone")}
            ><td
            >Deleted:</td><td>{selZone.deleted?"True":"False"}</td></tr>
            <tr><td>GW Index:</td><td>{selZone.gatewayZoneIndex}</td></tr>

            <tr><td>Site Index:</td>
            <td>{this.showViewEdit(selZone,type,"siteZoneIndex")}</td>
            </tr>

            <tr><td>Gateway ID:</td><td>{selZone.gatewayId}</td></tr>
            <tr><td>Remote IP:</td><td>{selZone.remoteIp}</td></tr>
            <tr><td>Last ZData:</td><td>{st.zoneLastData}</td></tr>
            <tr><td>Connected:</td><td>{(selZone.connected)?"Yes":"No"}</td></tr>
            <tr><td>Sub Tier:</td><td>{tiers[selZone.zoneTier]||"None"}</td></tr>
            <tr><td>Virtual:</td><td>{(selZone.virtual)?"Yes":"No"}</td></tr>
            </tbody></table>
            <div className="clearfloat"/><br/>
            <C18Button00 type="button" className="danger"
              onClick={e=>{this.onChange("deleteZone")}}
            >Delete</C18Button00>
            {this.showBrowseDB()}
          </div>
        )
    }
  }

  showViewEdit=(val,type,field)=>{
    let st=this.state
    if((st.editType==type)&&(st.editField==field)){
      let sty={borderRadius:0,padding:0,width:150}
      return(
        <input type="text" style={sty}
          value={val[field]||""}
          onChange={e=>this.onChange("valEdit",{val:e.currentTarget.value})}
        />
      )
    }else{
      let vf=val[field]
      let txt=((vf!="")&&(vf||(vf==0)))?vf:"(none)"
//       let txt=val[field]||"(none)"
      return(
        <span onClick={e=>this.onChange("viewEdit",{val:val,type:type,field:field})}>
          {txt}
        </span>
      )
    }
//
  }

  showAccounts=()=>{
    let st=this.state
//     cl(st)
//     let selAccount=st.accounts.filter(a=>{return a.accountId==st.selectedId})[0]
    let type="account"
    return(
      <div style={{width:300,height:600,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"accounts"})}}
      >Accounts</h3>
      {(st.searchRes?.account?.length)?
        this.showSearchBox(type):
        ((st.selectedType==type)?
          this.showSelectedInfo(type):
          (st.relAccounts.length>0)&&
            this.showSelectType(st.relAccounts,type)
        )
      }
      </div>
    )
  }

//   showRelated=(rel)=>{
//     return(
//       <div style={{width:"100%",height:200,backgroundColor:"#CCCCFF",
//         overflowY:"auto"
//       }}>
//       <table><tbody>
//       {rel.map((r,i)=>{
//         return(
//           <tr key={i}><td>{r.t}</td></tr>
//         )
//       })}
//       </tbody></table>
//       </div>
//     )
//   }

  showUsers=()=>{
    let st=this.state
//     cl(st)
    let selUser=st.users.filter(u=>{return u.userId==st.selectedId})[0]
    let type="user"
//       {(st.searchRes?.user?.length)?
//         this.showSearchBox("user"):
//       (st.selectedType=="user")?
//         <>
//           <h4>{selUser.name}</h4>
//         </>:
//         (st.relUsers.length>0)&&
//           this.showSelectType(st.relUsers,"user")
//       }
    return(
      <div style={{width:300,height:600,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"users"})}}
      >Users</h3>
      {((st.searchRes||{})[type]?.length)?
        this.showSearchBox(type):
        ((st.selectedType==type)?
          this.showSelectedInfo(type):
          (st.relUsers.length>0)&&
            this.showSelectType(st.relUsers,type)
        )
      }
      </div>
    )
  }

  showSites=()=>{
    let st=this.state
//     cl(st)
    let selSite=st.sites.filter(s=>{return s.siteId==st.selectedId})[0]
    let type="site"
    return(
      <div style={{width:300,height:600,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"sites"})}}
      >Sites</h3>
      {((st.searchRes||{})[type]?.length)?
        this.showSearchBox(type):
        ((st.selectedType==type)?
          this.showSelectedInfo(type):
          (st.relSites.length>0)&&
            this.showSelectType(st.relSites,type)
        )
      }
      </div>
    )
  }

  showGateways=()=>{
    let st=this.state
    let selGateway=st.gateways/*.gw*/.filter(g=>{return g.gatewayId==st.selectedId})[0]
    let type="gateway"
    return(
      <div style={{width:300,height:600,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"gateways"})}}
      >Gateways</h3>
      {((st.searchRes||{})[type]?.length)?
        this.showSearchBox(type):
        ((st.selectedType==type)?
          this.showSelectedInfo(type):
          (st.relGateways.length>0)&&
            this.showSelectType(st.relGateways,type)
        )
      }
      </div>
    )
  }

  showZones=()=>{
    let st=this.state
//     cl(st)
    let selZone=st.zones.filter(z=>{return z.zoneId==st.selectedId})[0]
    let type="zone"
    return(
      <div style={{width:300,height:600,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"zones"})}}
      >Zones</h3>
      {((st.searchRes||{})[type]?.length)?
        this.showSearchBox(type):
        ((st.selectedType==type)?
          this.showSelectedInfo(type):
          (st.relZones.length>0)&&
            this.showSelectType(st.relZones,type)
        )
      }
      </div>
    )
  }

  showDebug=()=>{
    let st=this.state
//     cl(st)
    let selZone=st.zones.filter(z=>{return z.zoneId==st.selectedId})[0]
    let type="zone"
    return(
      <div style={{width:300,height:600,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"zones"})}}
      >Debug</h3>
      <input type="text"
        value={st.debugText}
        onChange={e=>this.onChange("debugText",{debugText:e.currentTarget.value})}
      />
      <button type="button"
      style={{borderStyle:"solid",borderWidth:1,borderRadius:10,padding:10,marginTop:10}}
      onClick={e=>this.onChange("debugClick")}
      >Send</button>
      <div
      style={{borderStyle:"solid",borderWidth:1,borderRadius:10,height:300,marginTop:10,
        overflowY:"auto",overflowX:"hidden"
      }}
      >{(JSON.stringify(st.debugResp)||"")
        .replace(/,/g,/, /)
        .replace(/:/g,/: /)
      }
      </div>
      </div>
    )
  }

  markImage=async(e)=>{

    cl(e.target.files)
    let files=e.target.files[0]
    let data = new FormData()
    data.append("type", "fwImage")
    data.append("sessionId", globs.userData.session.sessionId)
    data.append('file', files)
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
    cl("post image")
    cl(url,method,data,type)
//     var ret
    let ret=await doGetPostBasic(url, method, data, type)
    cl(ret)
    this.setState({uploadFwResult:"Upload OK!"})
    setTimeout(e=>this.setState({uploadFwResult:"Upload Firmware Image"}),5000)
//     let ret2 = await ret.json()
//     cl(ret2)
//     this.onChange("avatar",{avatar: ret2.avatar})
//     this.setState({avatar: ret2.avatar})
  }



  showFwUpload=()=>{
    let st=this.state
//     cl("show")
//       <C18Input00 type="file"  onChange={this.markImage} style={{
//         position:"absolute",
//         width:103,
//         height:103,
//         marginTop:0,
//         marginLeft:0,
//         zIndex:10,
//         opacity:0,
//         cursor: "pointer",
//       }}/>
//         <br/>
//         <button
//           type="button"
//           onClick={e=>this.onChange("sendFwFile")}
//           style={{padding:"2px 5px",borderStyle:"solid", borderWidth:1, borderRadius:0,backgroundColor:"#EEEEEE",marginTop:5}}
//         >Send File</button>
    let color=(st.uploadFwResult=="Upload Firmware Image")?"#404040":"green"
    return(
      <div style={{height:100}}>
      <br/>
      <div>
        <form style={{float:"auto"}}
        className="file-upload-form">
        <label style={{color:color}}>{st.uploadFwResult}</label>
        <input style={{width:"auto",height:"auto",marginTop:0,zIndex:"auto",opacity:1,
          position:"static",cursor:"auto"}}
          type="file"
          onChange={this.markImage}
          />
          </form>
        </div>
      </div>
    )
  }

/****************************** Sanity Checks *****************************************/

  doLink=(vals)=>{
// vals sends the 'type' (gateway), and the gatewayId to select it
    cl(vals)
    switch(vals.type){
      case "account":
        this.onChange("accountSel",{accountSel:vals.accountId})
        break
      case "site":
        this.onChange("siteSel",{siteSel:vals.siteId})
        break
      case "gateway":
        this.onChange("gatewaySel",{gatewaySel:vals.gatewayId})
        break
      case "zone":
        this.onChange("zoneSel",{zoneSel:vals.zoneId})
        break
      case "user":
        this.onChange("userSel",{userSel:vals.userId})
        break
    }
  }

  checkUnique=(arr,idName,typeName,typeChange,lines)=>{
//     cl(arr)
    let ids={}
    arr.forEach(ac=>{
      let id=ac[idName]
      if(ids[id]){
        ids[id]+=1
      }else{
        ids[id]=1
      }
    })
    let dups=Object.keys(ids).filter(id=>{
      return (ids[id]>1)/* || (id=="JY8N0-CyDE4gUdhy")*/
    })
//     cl(dups)
    dups.forEach(du=>{
      let vals={type:typeChange}
      vals[idName]=(du=="undefined")?undefined:du
      let errLine=(
        <span>{typeName} <Link style={{color:"blue"}} to=""
          onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
        >{du}</Link> has {ids[du]} entries</span>
      )
      lines.push(
        <div key={lines.length}>
          {errLine}<br/>
        </div>
      )
    })
  }

  checkAllUnique=(st,lines)=>{
//     cl(st)
    this.checkUnique(st.accounts,"accountId","Account","account",lines)
    this.checkUnique(st.sites,"siteId","Site","site",lines)
    this.checkUnique(st.gateways/*.gw*/,"gatewayId","Gateway","gateway",lines)
    this.checkUnique(st.zones,"zoneId","Zone","zone",lines)
    this.checkUnique(st.users,"userId","User","user",lines)
  }

  checkUndefined=(arr,idName,fields,typeName,typeChange,lines)=>{
    arr.forEach(ar=>{
      let id=ar[idName]||"undefined"
      let ok=true
      fields.forEach(f=>{
        ok=ok&&(ar[f])
      })
      if(!ok){
        let vals={type:typeChange}
        vals[idName]=(id=="undefined")?undefined:id
        let errLine=(
          <span>{typeName} <Link style={{color:"blue"}} to=""
            onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
          >{id}</Link> has undefined fields</span>
        )
        lines.push(
          <div key={lines.length}>
            {errLine}<br/>
          </div>
        )

      }
    })

  }

  checkAllUndefined=(st,lines)=>{
// checks the fields in the array to see if they're "undefined"
    this.checkUndefined(st.accounts,"accountId",["accountId","name"],"Account","account",lines)//,"adminEmail","owner"
    this.checkUndefined(st.sites,"siteId",["accountId","siteId","name"],"Site","site",lines)//,"adminEmail","owner"
    this.checkUndefined(st.gateways/*.gw*/,"gatewayId",["gatewayId","siteId","accountId","name","uId"],"Gateway","gateway",lines)
    this.checkUndefined(st.zones,"zoneId",["zoneId","gatewayId","siteId","accountId","zoneName"],"Zone","zone",lines)
    this.checkUndefined(st.users,"userId",["accountId","email","name"],"User","user",lines)
  }

  checkReferences=(arr,idName,fields,typeName,typeChange,lines)=>{
// check the field in the array of fields to see if they're real sites, accounts, etc.'
/* for 'siteId, we're checking the sites table to see that all the sites
 refer to accountIds that actually exist,
 so, ar is an item from the sites table
 ar[f]is the accountId of that entry*/
//     cl(this.state)
    let lu=this.state.lookUps
//     cl(lu)
    arr.forEach(ar=>{
      fields.forEach(f=>{
        let id=ar[f]// the accountId of the entry in sites
        let tab=lu[f]// the accountId lookup table
        if(!tab[id]){// missing - make the entry to bring up this site
          let vals={type:typeChange}
          let id2=ar[idName]
//           cl(ar)
          vals[idName]=(id2=="undefined")?undefined:id2
          let errLine=(
            <span>{typeName} <Link style={{color:"blue"}} to=""
              onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
            >{id2}</Link> has nonexistent {f}</span>
          )
          lines.push(
            <div key={lines.length}>
              {errLine}<br/>
            </div>
          )
        }
//         cl(key)
//         cl(tab)
      })
    })
  }

  checkDuplicateZoneSiteIndexes=(st,lines)=>{
    let keys={}
    st.zones.forEach(z=>{
      let key=`${z.siteId}_${z.siteZoneIndex}`
      if(!keys[key])
        {keys[key]=[z]}else
        {keys[key].push(z)}// so keys is an array of the zones that have that siteZoneIndex
    })
    Object.keys(keys).forEach(k=>{
      if(keys[k].length>1){
        keys[k].forEach(z2=>{
          let vals={type:"zone"}
          let id=z2.zoneId
          vals["zoneId"]=(id=="undefined")?undefined:id
          let errLine=(
            <span>Zone <Link style={{color:"blue"}} to=""
              onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
            >{id}</Link> has duplicate SiteIndex {k}</span>
          )
          lines.push(
            <div key={lines.length}>
              {errLine}<br/>
            </div>
          )
        })

      }
    })
  }

  checkZoneAccountReferences=(st,lines)=>{
    let gwLu=st.lookUps.gatewayId
    let siLu=st.lookUps.siteId
    st.zones.forEach(z=>{
      if(z.gatewayId && z.siteId){// has valid gateway and site IDs
        let gw=gwLu[z.gatewayId]
        let si=siLu[z.siteId]
//         cl(z)
//         cl(gw)
//         cl(si)
        if((gw&&si)&&((z.accountId!=gw.accountId)||(z.accountId!=si.accountId))){
          let vals={type:"zone"}
          let id=z.zoneId
          vals["zoneId"]=(id=="undefined")?undefined:id
          let errLine=(
            <span>Zone <Link style={{color:"blue"}} to=""
              onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
            >{id}</Link> has site or gw account error</span>
          )
          lines.push(
            <div key={lines.length}>
              {errLine}<br/>
            </div>
          )
        }
      }

    })
  }

  checkGatewayAccountReferences=(st,lines)=>{
//     let gwLu=st.lookUps.gatewayId
    let siLu=st.lookUps.siteId
    st.gateways/*.gw*/.forEach(gw=>{
      if(gw.siteId){// has valid gateway and site IDs
        let si=siLu[gw.siteId]
//         cl(gw)
//         cl(si)
        if((gw&&si)&&((gw.accountId!=si.accountId))){
          let vals={type:"gateway"}
          let id=gw.gatewayId
          vals["gatewayId"]=(id=="undefined")?undefined:id
          let errLine=(
            <span>Gateway <Link style={{color:"blue"}} to=""
              onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
            >{id}</Link> has site account error</span>
          )
          lines.push(
            <div key={lines.length}>
              {errLine}<br/>
            </div>
          )
        }
      }

    })
  }

  checkZoneSiteIndexes=(st,lines)=>{
    st.zones.forEach(z=>{
      let sziOk=((z.siteZoneIndex==0)||(z.siteZoneIndex>0))&&(z.siteZoneIndex<100)
      if(!sziOk){
        let vals={type:"zone"}
        let id=z.zoneId
        vals["zoneId"]=(id=="undefined")?undefined:id
        let errLine=(
          <span>Zone <Link style={{color:"blue"}} to=""
            onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
          >{id}</Link> has siteZoneIndex error</span>
        )
        lines.push(
          <div key={lines.length}>
            {errLine}<br/>
          </div>
        )
      }
    })
  }

  checkAllReferencesExist=(st,lines)=>{
//     this.checkReferences(st.accounts,"accountId",["accountId","name"],"Account","account",lines)//,"adminEmail","owner"
    this.checkReferences(st.sites,"siteId",["accountId"],"Site","site",lines)
    this.checkReferences(st.gateways/*.gw*/,"gatewayId",["accountId","siteId"],"Gateway","gateway",lines)
    this.checkReferences(st.zones,"zoneId",["accountId","siteId","gatewayId"],"Zone","zone",lines)
    this.checkReferences(st.users,"userId",["accountId"],"User","user",lines)
  }

  showSystemReport=()=>{
    let st=this.state
    let lines=[]// this is where the report will be put
    this.checkAllUnique(st,lines)
    this.checkAllUndefined(st,lines)
    this.checkAllReferencesExist(st,lines)
    this.checkDuplicateZoneSiteIndexes(st,lines)
    this.checkZoneAccountReferences(st,lines)
    this.checkGatewayAccountReferences(st,lines)
    this.checkZoneSiteIndexes(st,lines)
    return(
      <>
      <input type="checkbox"
        checked={st.showSystemReport}
        onChange={e=>this.onChange("upd",{showSystemReport:e.target.checked})}
      />
      <h3 style={{marginLeft:10,display:"inline-block"}}>Show System Report</h3>
      <input type="checkbox"
        style={{marginLeft:20}}
        checked={st.disableCache}

        onChange={e=>this.onChange("disableCache",{disableCache:e.target.checked})}
      />
      <h3 style={{marginLeft:10,display:"inline-block"}}>Disable Caching</h3>
      {st.showSystemReport&&
        <div style={{width: 1000, borderStyle:"solid", borderWidth:1,borderRadius:10,padding:10}}>
        {lines.map(li=>{return li})}
        </div>
      }
      </>
    )
  }

/****************************** End Sanity Checks *****************************************/

  showTechPortal=()=>{
    let st=this.state
    return(
        <div
        >
        <h2 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"techPortal"})}}
        >Tech Portal</h2>
        <div className="clearfloat"></div><br/>
        {this.showSystemReport()}
        <br/>
        <label>Search</label>
        <input type="text"
          value={st.oneSearch}
          onChange={e=>this.onChange("updSearch",{oneSearch:e.currentTarget.value})}
        />
        {this.showAccounts()}
        {this.showUsers()}
        {this.showSites()}
        {this.showGateways()}
        {this.showZones()}
        {this.showDebug()}
        {this.showFwUpload()}
        </div>
    )
  }

  scanMqttClients=(log)=>{
//     cl(log)
    let clients={}
    log.forEach(l=>{
      clients[l.top[2]]=1
    })
    return Object.keys(clients)
  }

  showClientSelect=()=>{
    let st=this.state
    let opts=st.mqttClients.map((c,i)=>{return({v:c,t:c})})
    return(
      <C18Select01 parms={{
        label:"Select Client",
        valueName:"mqttClient",
        mqttClient:st.mqttClient,
        opts:opts,
        onChange:(e,v)=>{this.onChange("mqttClient",v)}//this.onChange,
      }}/>
    )
  }

  showDateTimeSelect=()=>{
    let st=this.state
    let bStyle={borderStyle:"solid",borderWidth:1,borderRadius:0,padding:2,marginLeft:5}
    let val=dateToDisplayDate(new Date(1000*st.mqttLogTime),"yyyy-mm-ddThh:mm")
    return(
      <div>
        <label>Select Time</label>
        <input type="datetime-local"
          value={val}
          onChange={e=>this.onChange("mqttLogTime",{mqttLogTime:e.currentTarget.value})}
        />
        <button type="button" style={bStyle}
          onClick={e=>this.onChange("dTime",{dif:-3600})}>&lt;&lt;</button>
        <button type="button" style={bStyle}
          onClick={e=>this.onChange("dTime",{dif:-60})}>&lt;</button>
        <button type="button" style={bStyle}
          onClick={e=>this.onChange("dTime",{dif:60})}>&gt;</button>
        <button type="button" style={bStyle}
          onClick={e=>this.onChange("dTime",{dif:3600})}>&gt;&gt;</button>
      </div>
    )
  }

  dfProgress=(progressEvent)=>{
    globs.events.publish("progress",{prog:progressEvent.loaded/progressEvent.total})
  }

  downloadFileWithProgress=(url, method, data, type)=>{
    cl(url,method,type)
//     cl(data)
    return new Promise((r,e)=>{
      let xhr = new XMLHttpRequest();
      xhr.open(method, url);
      xhr.onprogress=this.dfProgress
//       xhr.upload.addEventListener("progress", this.dfProgress)
//       xhr.responseType="json"
      xhr.onload=()=>{
        this.setState({progressLoaded:0,progressTotal:0})
        globs.events.publish("progress",{prog:-1})
        r(xhr.response)
      }
      xhr.send(data);
    })

  }

  loadMqttLog=async(ts=this.state.mqttLogTime)=>{
    cl(ts)
    let name=`mqttLog${Math.floor(ts/1e5)}.txt`
    if(this?.curMqttLogLoaded==name){return}
    this.curMqttLogLoaded=name

    let url=`${constant.expressUrl}/usa/${name}`
    cl(url)
//     let url=`http://http0.link4cloud.com:3115/usa/${name}`
    let method="GET"
    var data
    let type="text/plain"
//     let res=await doGetPostBasic(url, method, data, type)
//     let txt=await res.text()
    let txt=await this.downloadFileWithProgress(url, method, data, type)
//     cl(txt.substring(0,100))
//     cl(txt)
    let lines=txt.split("\n")
    cl(lines.length)
    let mqttLog=[]
    lines.forEach(l=>{
//       cl(l)
      let f=l.split("\t")
      if(f.length==3){
        try{
//           cl("push")
          mqttLog.push({
              t:(+Date.parse(f[0]+"Z"))/1000,
              top:f[1].split("/"),
              msg:JSON.parse(f[2]),
          })
        }catch{}
      }
    })
    let mqttClients=this.scanMqttClients(mqttLog)
    let vals={
      mqttLog:mqttLog,
      mqttClients:mqttClients,
      mqttClient:mqttClients[0],
    }
    cl(vals)
    this.setState(vals)
  }

  showOneMqttEntry=()=>{
    let st=this.state
    let ent=st.mqttLog[st.msgClick]
    if(!ent){return}
//     cl(ent)
    let dtFormat=dateToDisplayDate(new Date(1000*ent.t),"yyyy-mm-ddThh:mm")

//     cl(st)
//     cl(ent.msg)
    let cmd=this.cmdTypes[ent.msg.cmd]
//     cl(cmd)
    let cmdLine=`${cmd.t1} ${cmd.t2} ${cmd.t3}`
    let lines=[]
    let base=cmd.b;
    let gotData=["Report","Write"].includes(cmd.t1)
    var parm="",val="";
    (ent.msg?.p||[]).forEach((p,i)=>{
      parm=this.lc2[base+p.id]||""
//       cl(p,parm)
      val=(gotData)?`: ${p.d}`:""
      let str=`${parm}${val}`
//       cl(str)
      lines.push(<span key={i}>{str}<br/></span>)
    })
//       {`${cmdLine}, ${parm}${val}`}<br/>
    return(
      <div>
      {`At ${dtFormat}: ${cmdLine}`}<br/>
      {lines}
      </div>
    )
  }

  showMqttEntries=()=>{
//     cl("show")
    let st=this.state
//     cl(st.mqttLog)
    let lines=[]
//     let ti=(+Date.parse(st.mqttLogTime))/1000
    cl(st.mqttLogTime)
//     cl(ti)
    let cnt=0
    let oLine=st.mqttLog[0]
//     cl(oLine.top[2],st.mqttClient)
    st.mqttLog.forEach((l,i)=>{
//       cl(l.t,ti)
//       cl(l.top[2]==st.mqttClient)
      if((l.top[2]==st.mqttClient)&&(l.t>st.mqttLogTime)&&(cnt++<30)){
//         cl(l)
//         cl(`add: ${l.t}`)
        let msg=JSON.stringify(l.msg)
        let bgColor=(i==st.msgClick)?"#DDEEEE":""
//         if(msg.length>80){msg=msg.substring(0,77)+"..."}
        lines.push(
          <span key={i} style={{cursor:"pointer",backgroundColor:bgColor}}
            onClick={e=>this.onChange("mqttMsgClick",{msgClick:i})}
          >{msg}<br/></span>
        )
      }
//       cl(l)
    })
    return(

      <div style={{width:"100%",overflowX:"hidden",textOverflow:"ellipsis",
        whiteSpace:"nowrap"}}>
      {lines}
      </div>
    )
  }

  showMqttLog=()=>{
    return(
      <div>
      <h2>MQTT Log</h2>
      {this.showClientSelect()}
      {this.showDateTimeSelect()}<br/>
      {this.showMqttEntries()}<br/>
      {this.showOneMqttEntry()}
      </div>
    )
  }

  showLiveContDetails=()=>{
    let st=this.state
    let cont=st.allConts.filter(c=>{return c.gwId==st.contSel})
//     cl(cont)
    return(
      <div>
      details
      </div>
    )
  }

  showLiveContSelect=()=>{
    let st=this.state
//     cl(st)
    let lines=st.allConts.map((c,i)=>{
      var bgColor
      if(c.gwId==st.contSel){bgColor="#C0E0FF"}
      return(
        <tr key={i} style={{cursor:"pointer",backgroundColor:bgColor}}
          onClick={e=>this.onChange("liveContClick",{contSel:c.gwId})}
        >
          <td>{c.name}</td>
          <td>{c.type}</td>
          <td>{c.server}</td>
          <td>{c.val.clientId?.slice(-6)}</td>
          <td>{c.val.accountId}</td>
        </tr>
      )
    })

    return(
      <div style={{height:200,borderStyle:"solid",borderWidth:1,
        borderRadius:10,padding:10,overflowY:"auto"}}
      >
      <table><tbody>
      {lines}
      </tbody></table>
      </div>
    )
    return null
  }

  showMqttLive=()=>{
    return(
      <div>
      {this.showLiveContSelect()}
      {this.showLiveContDetails()}
      </div>
    )
  }

  showPageType=()=>{
    switch(this.state.pageType){
      case "mqttLive":
        return this.showMqttLive()
      case "techPortal":
        return this.showTechPortal()
      case "mqttLog":
        return this.showMqttLog()
    }
  }

  render(){
//     cl("render")
    let st=this.state
//     let [searchId,type,field1,field2,itemId,selId]=this.pageParms[st.pageType]
    if(st.loaded){
      return(
        <div>
        <C18SubMenuHeader00 parms={{
          items:[
            {v:"techPortal",t:"Tech Portal"},
            {v:"mqttLog",t:"MQTT Log"},
            {v:"mqttLive",t:"MQTT Live"},
          ],
          pageType: this.state.pageType,
          onChange: o=>this.onChange("pageMenu",o),
        }}/>
        <div className="clearfloat"></div>
        {this.showPageType()}
        </div>

      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}

export default C18TechPortal00;
