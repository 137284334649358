import React from 'react';
import C18Button00 from './C18Button00'
import C18Anchor00 from './C18Anchor00'
import config from '../../components/utils/config'
import {wsTrans} from '../utils/utils'
import {cl, globs,constant,dateToYrMoDa,dateToMoDaYrStr,getDaysRemaining,dateToDisplayDate} from '../../components/utils/utils';
import {toggleAClass} from '../utils/utils';
import {gitCommitHash,gitBranch} from '../../generatedGitInfo.json'
import {loadUser,loadAlarmsInfo,loadPrivsInfo,getAlarmInfo,alLevel,privs,loadSubscriptionInfo,
  getRecurlyAccount,acctFeature,loadAddedSensorsInfo,loadMBInfo,loadUsersInfo,
} from './C18utils'
// var version="a.b.c"
// cl(version)
class C18MenuBar00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      loaded: false,
      navClass: " ",
      menuSel:props.parms.mode,
      serverUp:true,
      showSites:false,
      showSub:false,
      menuItems:[
        {v:"sites",t:"Sites"},
//         {v:"reports",t:"Reports and Graphs"},
//         {v:"reports",t:"Graphs"},
//         {v:"editFui",t:"Edit Fui"},
      ],
    }
    let mi=[{v:"sites",t:"Sites"}]
    if(acctFeature("dev")){mi=[{v:"home",t:"Home"}]}
    if(acctFeature("editFui")){mi.push({v:"editFui",t:"Edit Fui"})}
    if(acctFeature("tasks")){mi.push({v:"tasks",t:"Tasks"})}
    mi.push({v:"reports",t:"Reports & Graphs"})
    this.state.menuItems=mi
    
//     if(globs.userData.session.developer){
//         this.state.menuItems=[
//           {v:"home",t:"Home"},
//           ]
//         
//     }else{
//       if(acctFeature("editFui")){{}}
//       if((config.server=="dev")||(config.server=="ryan")||(config.server=="stage")){
//         this.state.menuItems.push(
//           {v:"editFui",t:"Edit Fui"},
//           {v:"tasks",t:"Tasks"},
//           {v:"reports",t:"Reports & Graphs"},
//         )
//       }
//       if((config.server=="alpha")
//         ||(config.server=="prod0")
//         ||(config.server=="demo")
//       ){
//         this.state.menuItems.push(
//   //         {v:"tasks",t:"Tasks"},
//   //         {v:"editFui",t:"Edit Fui"},
//           {v:"reports",t:"Reports and Graphs"}
//         )
//       }
//     }
    this.subscribe_wsServerOK=globs.events.subscribe("wsServerOK",this.wsServerOK)
    this.loadInfo()
    this.loadAlarmsInfo=setInterval(this.loadAlarmsInfo,30000)
    this.loadRecurlyInfo=setInterval(this.loadRecurlyInfo,120000) // 2 min
  }
  
  componentWillUnmount=()=>{
    if(this.subscribe_wsServerOK){this.subscribe_wsServerOK.remove()}
    clearInterval(this.loadAlarmsInfo)
    clearInterval(this.loadRecurlyInfo)
  }
  
  wsServerOK=(ok)=>{
    this.setState({serverUp:ok})
  }
  
  loadAlarmsInfo=async()=>{
//     cl("load alarms info")
    globs.alarmsInfo.got=false
    await loadAlarmsInfo()
    let notificationCount=await this.loadNotificationCount()// was await
    this.setState({update:(this.state.update||0)+1,notificationCount:notificationCount})
  }

  loadRecurlyInfo=async()=>{
//     cl("load recurly info")
    globs.subscriptionInfo.got=false
    await loadSubscriptionInfo()
    let days = getDaysRemaining(new Date(globs.subscriptionInfo.info.end_date))
    let onTrial = globs.subscriptionInfo.info.plan_code == "cloud2p0trialsub" && (days >= 0)
//     cl([days, onTrial])
    this.setState({days: days, onTrial: onTrial})
  }
  
  loadInfo=async()=>{
//     cl(this.props)
//     cl(this.state)
    await loadUser();
    await loadAlarmsInfo()
    await loadAddedSensorsInfo()
//     cl("loaded added sensors")
    await loadPrivsInfo()
    await loadSubscriptionInfo()
    await loadMBInfo()
    await loadUsersInfo()
    let notificationCount=await this.loadNotificationCount()
    // load subscription account (for billing) and days remaining
    let account = await getRecurlyAccount()
    let days = getDaysRemaining(new Date(globs.subscriptionInfo.info.end_date))
    let onTrial = globs.subscriptionInfo.info.plan_code == "cloud2p0trialsub" && (days >= 0)
//     cl(globs.alarmsInfo)
    this.setState({loaded: true,notificationCount: notificationCount, account: account, days: days, onTrial: onTrial})
  }
  
  mobile_menu_button_click=()=>{
    let curClass=toggleAClass(this.state.navClass,"mobile-menu-triggered")
    this.setState({navClass:curClass})
  }
  
  onClick=(e,type)=>{
    cl(type)
    if(["sites","tasks","reports","editFui"].includes(type)){
      this.setState({menuSel:type})
    }
//     cl(type)
    if(this.props.parms){
      this.props.parms.onChange({cmd:type})
    }
    e.preventDefault()
//     cl(type)
  }
  
  loadNotificationCount=async()=>{
    let userId=globs.userData?.session?.userId
    if(!userId){return 0}
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/messages", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {
        query:{recipientId:userId},
        skip:0,
        limit:10,
      }})
    let msgs=res.data
//     cl(msgs)
    let notes=await wsTrans("usa", {cmd: "cRest", uri: "/s/notifications", 
      method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: {
        userId:userId,
      }})
    notes.data.forEach(no=>{
      msgs.push({userId:-1,flags:no.flags})
    })
//     cl(notes)
//     cl(msgs)
    let msgs2=msgs.filter(msg=>{
      return ((msg.flags||0)&constant.CHAT_FLAG_UNREAD)/*&&(msg.userId!=userId)*/
    })
//     cl(msgs2)
//     cl(msgs)
    return msgs2.length
//     return 4
  }
  
  showDevHeader=(userName,path)=>{
    let mobile = (window.ReactNativeWebView || (window.innerWidth < 460))
    cl(mobile)
    return(
      <header id="main-header">
        { !mobile &&
        <div id="main-logo">
          <img src="/link4.png" alt="Link4 Controls logo"/>
        </div>
        }
        <div id="mobile-menu-button-container">
          <C18Button00 type="button" id="mobile-menu-button" onClick={this.mobile_menu_button_click}>
            <span></span>
            <span></span>
            <span></span>
          </C18Button00>
        </div>
        {this.state.serverUp&&
          <nav id="main-nav" className={this.state.navClass}>
            {this.state.menuItems.map((m,i)=>{
              let cn=(m.v==this.state.menuSel)?"current-menu-item":""
              return(
                <C18Anchor00 id={`/mainNav/${m.v}`} to="" key={i} className={cn} onClick={(e)=>this.onClick(e,m.v)}>{m.t}</C18Anchor00>
              )
            })}
          </nav>
        }
        <div id="header-notifications" >
          <C18Anchor00 to="" id="user-name" onClick={o=>{this.onClick(o,"user")}}>{userName}</C18Anchor00>
          <C18Anchor00 to="" id="header-portrait">
            <img src={path} onClick={o=>{this.onClick(o,"user")}}/>
          </C18Anchor00>
        </div>
      </header>
      
    )
    
  }
  
  showHeader=()=>{
//     cl(globs)
    // cl(globs.userData.session?.userId)
    // cl(globs.usersInfo.info)
    let gotUser= globs.userData.session?.userId && globs.usersInfo.info && (globs.usersInfo.info.filter(u=>{
      return u.userId==globs.userData.session.userId}).length!=0)
//     cl(gotUser)
    let userOpac=1
    let userTitle=globs.user.name
    if(!gotUser){
      userOpac=0.5
      userTitle="User is not in this account"
    }
//     cl(globs.userData.session)
    let dark=((globs.device?.deviceTheme||"").toLowerCase().indexOf("dark")>=0)?1:0
    let userName=globs?.user?.name||"Unknown User"
    let av=globs?.user?.avatar
    var path
    if(av){
      path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
    }else{
      path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
    }
    if(globs.userData?.session?.developer){return this.showDevHeader(userName,path)}
//     cl(globs.user)
//     cl(globs.alarmsInfo.info)
    let alarmCount=0
//     cl(globs.alarmsInfo)
//     cl(globs.privsInfo.info)
    globs.alarmsInfo.info.forEach(a=>{
//       cl(globs.zonesInfo)
//       cl(a)
//       cl(globs.zonesInfo.sz2z)
//       cl(a)
      if(globs.zonesInfo.sz2z[a.s]){
        let pr=privs("zone",(globs.zonesInfo.sz2z[a.s][a.z]||{}).zoneId,constant.AREA_PRIVS_READ)
        let ai=getAlarmInfo(a.a,a.s,a.z)
//         cl(ai)
        if(pr&&((ai.level<4))){//(ai.enable!=0)&&
//           cl(a)
//           if(a.a=="inTLoSa"){cl(a)}
          
          alarmCount+=1
          
        }
      }
    })
//     let alarmCount=globs.alarmsInfo.info.length
//     alarmCount=0
    let sst=this.props.parms.siteStatus
//     cl(sst)
    let siteDownCount=0
//     let expDate=dateToMoDaYrStr(new Date(globs.subscriptionInfo.info.end_date))
    let expDate=dateToDisplayDate(new Date(globs.subscriptionInfo.info.end_date),"yyyy-mm-dd")
    // cl(globs.device)
//     cl(this.state)
    let siteInfo=Object.keys(sst).map((k,i)=>{
      let s=sst[k]
//       cl(s.connected)
      if(!s.connected){siteDownCount++}
      return(
        <tr key={i}>
        <td>{s.name}</td><td>{(s.connected)?"Connected":"Down"}</td>
        </tr>
      )
    })
//     let siteInfo=siteDowns.map(s=>{
//     })
//     cl(siteDowns)
    // get plan name, expiration/renewal date
    let name = (this.state.onTrial) ? "Trial" : (globs.subscriptionInfo.info.plan_code == "cloud2p0trialsub") ? 
    "Expired Trial" : (globs.subscriptionInfo.info.add_ons?.annual) ? "Annual" : "Monthly"
    let dateStyle = (globs.subscriptionInfo.info.plan_code == "cloud2p0trialsub" && this.state.days < 15) 
      ? "grow-journal-tag power subscription" : ""
    let subInfo= [
      <tr key={1}>
      {false&&<td>{"Current Plan: "}</td>}
        <td className="grow-journal-tag equipment subscription">{name}</td>
      </tr>
      ,
      <tr key={2}>
      {false&&<td>{(globs.subscriptionInfo.info.plan_code == "cloud2p0trialsub" || !globs.subscriptionInfo.info.auto_renew) ?
        "End Date: " : "Renewal Date: "}</td>}
        <td className={dateStyle}>{expDate}</td>
      </tr>  
      ]
    let showSuper=false&&privs("super","",constant.AREA_PRIVS_READ)
//     let notificationCount=await this.loadNotificationCount()
    let showAdmin=(privs("account",0,constant.AREA_PRIVS_ADMIN)!=0)
    // sub permissions
    let showSub=(privs("account",0,constant.AREA_PRIVS_ADMIN)!=0)
    let mobile = (window.ReactNativeWebView || (window.innerWidth < 460))
//     cl(globs.version)
    let tight={marginTop:-5}
    return(
      <header id="main-header">
        { !mobile &&
        <div id="main-logo"
          style={{flexDirection:"column",fontSize:10,color:"#004080"}}
        >
          <div>
            <img src="/link4.png" alt="Link4 Controls logo"/>
          </div>
          <div id="appVersion">v{globs.version}</div>
          <div id="appCommit" style={tight}>{gitCommitHash}</div>
          <div id="appBranch" style={tight}>{gitBranch}</div>
        </div>
        }
        <div id="mobile-menu-button-container">
          <C18Button00 type="button" id="mobile-menu-button" onClick={this.mobile_menu_button_click}>
            <span></span>
            <span></span>
            <span></span>
          </C18Button00>
        </div>
        {this.state.serverUp&&
          <nav id="main-nav" className={this.state.navClass}>
            {this.state.menuItems.map((m,i)=>{
              let cn=(m.v==this.state.menuSel)?"current-menu-item":""
              return(
                <C18Anchor00 id={`/mainNav/${m.v}`} to="" key={i} className={cn} onClick={(e)=>this.onClick(e,m.v)}>{m.t}</C18Anchor00>
              )
            })}
          </nav>
        }
        {!this.state.serverUp&&
          <nav id="main-nav" className={this.state.navClass}>
          <span style={{backgroundColor:"red", color:"white", paddingLeft:50, paddingRight:50}}>Server Down</span>
          </nav>
        }
        <div id="header-notifications" >
          {acctFeature("messaging")&&
            <div id="header-alarm"
              className={(this.state.notificationCount>0)?"alarm-on":null}>
              <div id="header-alarm-icon" className="material-icons" 
              onClick={e=>this.props.parms.onChange({cmd:"notifyIcon"})}>message</div>
              {(this.state.notificationCount>0)&&
                <div id="alarm-number">{this.state.notificationCount}</div>
              }
            </div>
          }
          {showSuper&&
            <div id="header-alarm" style={{position:"relative"}}>
              <div id="header-alarm-icon" title="Super User">
              <img id="/menu/superUser" src={`${constant.expressUrl}/usa/images/icons/sm.png`}/>
              </div>
            </div>
          }
          {true&&
            <div id="header-alarm" className={"alarm-on sub-section"} style={{position:"relative"}}
              onClick={e=>this.onClick(e,"subscription")}
            >  
              <table><tbody>
                {subInfo}
              </tbody></table>
              {(this.state.account && !this.state.account.billingInfo)&&
                <div id="alarm-number">!</div>
              }
            </div>
          }
          {true &&
            <div id="header-alarm" className={"alarm-on sub-icon"}
              >
              <div id="header-sites-icon" onClick={e=>this.onClick(e,"subscription")}>R</div>
              {(this.state.account && !this.state.account.billingInfo)&&
                <div id="alarm-number">!</div>
              }
            </div>
          }
          {false&&
            <div id="header-alarm" className={(siteDownCount)?"alarm-on":""} style={{position:"relative"}}
              onClick={e=>this.onClick(e,"alarms")}
            >
              <div id="header-sites-icon"
                onMouseOver={e=>this.setState({showSites:true})}
                onMouseOut={e=>this.setState({showSites:false})}
              >S</div>
              {(siteDownCount>0)&&
                <div id="alarm-number">{siteDownCount}</div>
              }
              <div className={"gateway-status"} style={{display:(this.state.showSites)?"block":"none",
                position:"absolute",backgroundColor:(dark)?"black":"white",width:300,padding:20,borderRadius:10
              }}>
              <table><tbody>
                {siteInfo}
              </tbody></table>
              </div>
            </div>
          }
          {true&&
            <div id="header-alarm" className={(alarmCount)?"alarm-on":""}
              onClick={e=>this.onClick(e,"alarms")}
            >
              <div id="header-alarm-icon">!</div>
              {(alarmCount>0)&&
                <div id="alarm-number">{alarmCount}</div>
              }
            </div>
          }
          <div id="header-alarm" style={{position:"relative"}}
            onClick={e=>this.props.parms.onChange({cmd:"helpIcon"})}
          >
            <div id="header-alarm-icon" title="Info">
              <img id="/menu/info" style={{backgroundColor:(dark)?"black":"white"}}
              src={`${constant.expressUrl}/usa/images/icons/help.png`}/>
            </div>
          </div>

          {showAdmin&&
            <C18Anchor00 to="" id="header-settings-icon" className="material-icons"
              onClick={o=>{this.onClick(o,"settings")}}>settings</C18Anchor00>
          }
          {false&&
            <C18Anchor00 to="" id="user-name" onClick={o=>{this.onClick(o,"user")}}>{userName}</C18Anchor00>
          }
          <C18Anchor00 to="" id="header-portrait">
            <img src={path} onClick={o=>{
              if(gotUser){this.onClick(o,"user")}else{o.preventDefault()}
            }}
            title={userTitle} style={{opacity:userOpac}}/>
          </C18Anchor00>
        </div>
      </header>
    )
  }
  
  render(){
//     cl(this.state)
    if(this.state.loaded){
      return this.showHeader()
    }else{
      return <div id="content-area" style={{height:80}}>loading. . .</div>
    }
  }
}

export default C18MenuBar00;
