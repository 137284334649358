import React from 'react';
import {cl,globs} from '../../components/utils/utils';

class C18Select00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.list=props?.parms?.list||false
    this.dark=((globs.device?.deviceTheme||"").toLowerCase().indexOf("dark")>=0)?1:0
//     cl(props)
//     let sel=props.children.filter(ch=>{return (ch)?true:false})[0]
// //     cl(sel)
//     this.state={
//       sel:(sel||{})?.props?.value||"",
//     }
//     cl(this.state)
  }
  
  onChange=(type,vals)=>{
//     cl([type,vals])
    switch(type){
      case "select":
//         this.setState(vals)
        this.props.onChange({currentTarget:{value:vals.sel}})
        break
    }
  }
  
  showList=()=>{
//     let st=this.state
    let items=[]
    let pa=this.props.parms
//     cl(pa)
//     cl(this.props)
    this.props.children.forEach((ch,i)=>{
      if(ch){
        let bgColor=(ch.props.value==this.props.value)?(this.dark)?"#668888":"#CCEEEE":
          (this.dark)?"#202020":"#FFFFFF"
        items.push(
          <div key={i} style={{cursor:"pointer",backgroundColor:bgColor}}
          onClick={e=>{this.onChange("select",{sel:ch.props.value})}}
          >{ch.props.children}</div>
        )
      }
    })
    return(
      <>
      <div style={{
        border:1,
        borderStyle:"solid",
        borderRadius:10,
        padding:10,
        height:pa.height,
        overflowY:"auto",
      }}
      >
        {items}
      </div>
      </>
    )
  }
  
  render(){
    if(this.list){
      return this.showList()
    }else{
      return React.createElement("select",this.props)
    }
  }
}
      
export default C18Select00;
