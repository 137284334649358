import React from 'react';
import C18ZoneSensors00 from './C18ZoneSensors00'
import C18ZoneEquipment00 from './C18ZoneEquipment00'
import {loadSitesInfo,loadZonesInfo,getSiteName,getZoneName} from './C18utils'
import {cl,globs} from '../../components/utils/utils';
import history from "../../history"

class C18ZoneSensorsEquipment00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      mode:"sites",
    }
    this.setBreadcrumbs()
  }
  
  shouldComponentUpdate=()=>{
    return !globs.dragging
  }
  
//   onChange=(type)=>{
//     switch(type){
//       case "sensorsWrench":
//         cl("here")
//         this.props.parms.onChange({cmd:type})
// //         history.push(`${this.props.parms.url}/sensor`)
//         break
//       case "equipmentWrench":
//         break
//       default:
//         break
//     }
//     cl(type)
//   }

  setBreadcrumbs=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
    let siteName=getSiteName(this.props.parms.site)
    let zoneName=getZoneName(this.props.parms.zone)
    this.props.parms.onChange(
      {
        cmd: "breadcrumbs",
        data:
          {breadcrumbs: [
            {t:"Sites", url:"/usa/c18/sites"},
            {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
            {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`},
          ]},
      },
    )
  }
  
  
  render(){
//     cl(this.props)
    return (
      <div id="content-area">
        <C18ZoneSensors00 parms={{
          onChange: this.props.parms.onChange,
          site:this.props.parms.site,
          zone:this.props.parms.zone,
        }}/>
        <C18ZoneEquipment00 parms={{
          onChange:this.props.parms.onChange,
          cols:1,//(this.props.parms.sideBarOpen)?2:1,
          site:this.props.parms.site,
          zone:this.props.parms.zone,
          gwType:this.props.parms.gwType,
        }}/>
      </div>
    )
  }
}
      
export default C18ZoneSensorsEquipment00;
