import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import Edit from '@material-ui/icons/Edit';
import Settings from '@material-ui/icons/Settings';
import Power from '@material-ui/icons/PowerSettingsNew';
import Refresh from '@material-ui/icons/Refresh';
import Help from '@material-ui/icons/HelpOutline';
import Camera from '@material-ui/icons/Camera';
import Reply from '@material-ui/icons/Reply';
import ReplyAll from '@material-ui/icons/ReplyAll';
import Delete from '@material-ui/icons/Delete';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Message from '@material-ui/icons/Message';
import Share from '@material-ui/icons/Share';
import Eco from '@material-ui/icons/Eco';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
import Apps from '@material-ui/icons/Apps';
import Menu from '@material-ui/icons/Menu';
import ImageIcon from '@material-ui/icons/Image';

// import InputLabel from '@material-ui/core/InputLabel';
// import Input from "@material-ui/core/Input";
// import InputAdornment from '@material-ui/core/InputAdornment';
// import IconButton from '@material-ui/core/IconButton';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {cl} from '../../components/utils/utils';

class UsaIcon extends React.Component{
  
  icons = {
    "OK": Check,
    "Cancel": Clear,
    "Close": Clear,
    "Delete": Delete,
    "New": Add,
    "Create": Add,
    "Add": Add,
//     "Expand": Add,
    "Remove": Remove,
    "Edit": Edit,
    "Power": Power,
    "Refresh": Refresh,
    "Help": Help,
    "Camera": Camera,
    "Insert Image": Camera,
    "Reply": Reply,
    "Reply All": ReplyAll,
    "More": MoreHoriz,
    "Like": ThumbUp,
    "Comment": Message,
    "Share": Share,
    "Grow Journal": Eco,
    "To Do": FormatListBulleted,
    "Message": Message,
    "Expand": ExpandMore,
    "Show": ExpandMore,
    "Hide": ExpandLess,
    "Up": ArrowUpward,
    "Down": ArrowDownward,
    "Blank": null,
    "Grid": null,
    "List": null,
    "Settings": null,
    "Image": ImageIcon,
  }
  
  space=(n)=>{
    return <span style={{marginLeft: `${n}em`}}></span>
  }
  
  render(){
//     let Mycon=this.icons[this.props.icon]
    if(!this.props.icon) {return <div>no icon</div>}
    let iconInfo=this.props.icon.split("-")
    let Mycon=this.icons[iconInfo[1]]
//     cl(this.props)
//     Mycon=null

    let iconColor;
    if(!this.props.icon){iconColor=""}
    else{iconColor = this.props.color}

    return(
      <div style={{display: "inline", width: 100}}>
      {this.space(this.props.space)}
      <IconButton 
        onClick={e=>this.props.result(this.props.icon)} 
        title={iconInfo[1]} 
        disabled={this.props.saveOK}
        style={{display: "inline", padding: 0}}>
          {Mycon && <Mycon style={{color:iconColor}} />}
        </IconButton>
      </div>
    );
  }
}

export default UsaIcon;
