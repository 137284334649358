import React from 'react';
import Select from 'react-select'
import C18Button00 from './C18Button00'
import C18Input00 from './C18Input00'
import C18Images00 from './C18Images00'
import C18MessageThread00 from './C18MessageThread00'
import UsaIcon from './UsaIcon';
import {loadUsersInfo,getUserIndex,makeImagePath} from './C18utils'
import {cl,globs,constant,getRandomString,dateToDisplayDate,calcTimezoneOffset} from '../../components/utils/utils';
import {wsTrans,doGetPostBasic} from '../utils/utils'
import history from "../../history"

class C18MessageEdit00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      subject:"",
      recipients:["one"],
      body:"",
      images:[],
      loaded:false,
    }
//     this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.saveMessage)
//     this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.setBreadCrumbs()
    this.loadInfo()
//     this.subscribe_keyUpEvent=globs.events.subscribe("keyUp",this.keyUp)
  }
  
//   componentWillUnmount=()=>{
//     this.subscribe_keyUpEvent.remove()
//   }

  setBreadCrumbs=()=>{
    cl(this.props.parms)
    let newBreadcrumbs = []
    if (!this.props.parms.messageId){
      newBreadcrumbs = [{t:"Edit Message", url:`/usa/c18/messaging/messageEdit/${this.props.parms.reportId}`}]
    }
    else{
      newBreadcrumbs = [{t:"Display Message", url:`/usa/c18/messaging/messageDisplay/${this.props.parms.messageId}`},{t:"Edit Message", url:`/usa/c18/messaging/messageEdit/${this.props.parms.reportId}`}]
    }

    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Messaging", url:`/usa/c18/messaging/messageList`},
              // {t:"Edit Message", url:`/usa/c18/messaging/messageEdit/${this.props.parms.reportId}`},
              ...newBreadcrumbs
            ]},
        },
      )
      this.props.parms.onChange(
        {
          cmd: "pageTitle",
          pageTitle: "Add Message"
        }
      )
    }
  }
  
//   loadThread=async(messageId)=>{
// /*
// to load the thread:
// search for messageId in infoChat, to get chatId
// then, search for chatId sorted by time with limit
// */
//     if(!messageId){return []}
// //     cl(messageId)
//     let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/messages", method: "retrieve", 
//       sessionId: globs.userData.session.sessionId,
//       body: {messageId:messageId}})
// //     cl(res)
//     let msg=res.data
// //     cl(msg)
//     let subject=msg.subject||"No Subject"
//     let recips=msg.chatId.split("&")
//     let userId=globs.userData.session.userId
//     let recips2=recips.filter(re=>{return re!=userId})
// //     cl(recips2)
//     
//     let res2=await wsTrans("usa", {cmd: "cRest", uri: "/s/messages", method: "retrieve", 
//       sessionId: globs.userData.session.sessionId,
//       body: {chatId:msg.chatId,skip:0,limit:10}})
//     let msgs=res2.data
//     msgs.forEach(msg=>{
//       msg.userName=this.userArr[msg.userId].name
//     })
//     return {thread:msgs,subject:subject,recipients:recips2}
//   }

  loadMessage=async(messageId)=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/messages", 
      method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {
        messageId:messageId
      }})
    return res.data
  }
  
  loadInfo=async()=>{
    let pa=this.props.parms
    await loadUsersInfo()
    this.userArr={}
    this.userOptions=globs.usersInfo.info.map(ui=>{
      this.userArr[ui.userId]=ui
      return{value:ui.userId,label:`${ui.name}: ${ui.email}`}
    })
    let msg={}
    let recips2=[]
    if(pa.messageId){
      msg=await this.loadMessage(pa.messageId)
      let recips=msg.chatId.split("&")
      recips2=recips.filter(re=>{return re!=globs.userData.session.userId})
//       cl(recips)
//       cl(recips2)
    }
//     cl("load info")
//     let thread=await this.loadThread(pa.messageId)
//     let st=Object.assign({loaded:true},thread)
//     cl("setstate")
    this.setState({
      loaded:true,
      message:msg,
      subject:msg?.subject||"",
      recipients:recips2})
//     cl("loadinfodone")
//     cl(this.state.recipients)
  }
  
//   saveMessage=(cmd)=>{
//     globs.events.publish("saveOK",true)
//   }
  
  doSend=async()=>{
    cl("do send")
    cl(this.props)
    cl(this.state)
    
    let st=this.state
    let sendMessage={
      sender:globs.userData.session.userId,
      subject:st.subject,
      recipients:st.recipients,
      body:st.body,
      images:st.images}
    this.setState({subject:"",recipients:[],images:[],body:""})
    await wsTrans("usa", {cmd: "cRest", uri: "/s/messages", method: "create", 
      sessionId: globs.userData.session.sessionId,
      body: sendMessage})
    history.push('/usa/c18/messaging/messageList')
  }
  
  onKeyUp=(e)=>{
    if(e.key=="Enter"){this.doSend()}
  }
  
  onChange=(type,vals)=>{
    switch(type){
      case "upd":
//         globs.events.publish("savePageEnable", true)
        this.setState(vals)
        break;
      case "recipients":
//         globs.events.publish("savePageEnable", true)
        let recipients=vals.map(re=>{
          return re.value
        })
        cl("set recip")
        this.setState({recipients:recipients})
        break
      case "images":
        switch(vals.cmd){
          case "images":
            delete vals.cmd
            this.setState(vals)
            break
        }
        switch(vals) {
          case "imgDelClick":
          let images=this.state.images.slice(0)
          images.splice(vals.id,1)
          this.setState({images:images})
          break;
        }
        break
      case "send":
        this.doSend()
        break
    }
  }
  
  showSubject=(messageId)=>{
    let st=this.state
    cl(st.subject)
    if(messageId){
      return(
        <div>
          {`Subject: ${st.message.subject||""}`}
          <div className="clearfloat"/><br/>
        </div>
      )
    }else{
      return(
        <>
          <label htmlFor="messaging-subject">Subject</label>
          <input type="text" id="messaging-subject" 
            value={st.subject}
            onChange={(e)=>this.onChange("upd", {subject:e.target.value})}/>
          <br/>
        </>
      )
    }
  }
  
  makeRecipientList=()=>{
    return this.state.recipients.map(ha=>{
      let user=globs.usersInfo.info[getUserIndex(ha)]
      cl(user)
      return {value:user.userId,label:`${user.name}-${user.email}`}
    })
  }
  
  showRecipients=(messageId)=>{
    let st=this.state
//     cl(st)
    if(messageId){
      let names=st.recipients.map(re=>{
        return this.userArr[re].name
//         return `${this.userArr[re].name}: ${this.userArr[re].email}`
      }).join(", ")
      return(
        <div>
          {`Recipients: ${names}`}
          <div className="clearfloat"/><br/>
        </div>
      )
    }else{

      let customStyles = {};

      customStyles = {
        multiValue: (base, state) => {
          return  { ...base, backgroundColor: '#ffffff' } ;
        },
      }; 

      if (globs.device?.deviceTheme=="originalDark") {
        customStyles = {
          control: base => ({
            ...base,
            background: "#202020",
          }),
          menuList: (base, state) => ({
            ...base,
            background: "#202020",
          }),
          option: (styles, {isFocused}) => ({
            ...styles,
            background: isFocused ? '#4e5157' : undefined,
        }),
        placeholder: (defaultStyles) => {
          return {
            ...defaultStyles,
            color: '#ffffff',
          }
        }
        }; 
      }
      return(
        <div style={{width:500}}>
          <label htmlFor="recipients">Recipients</label>
          <Select
            id="recipients"
            value={this.makeRecipientList()}
            onChange={o=>{this.onChange("recipients",o)}}
            options={this.userOptions}
            isMulti={true}
            width="500"
            styles={customStyles}
          />
          <br/>
        </div>
      )
    }
  }
  
  showBody=()=>{
    return(
      <>
        <label htmlFor="message-body">Body</label>
        <textarea id="message-body" style={{width:500}}
          value={this.state.body}
          onChange={e=>this.onChange("upd",{body:e.currentTarget.value})}
          onKeyUp={this.onKeyUp}
        />
        <div className="clearfloat"/><br/>
      </>
    )
  }
  
  showSend=()=>{
    return(
      <C18Button00 type="button" className="filled" onClick={()=>this.onChange("send",)}>
        Send</C18Button00>
    )
  }
  
//   showThread=()=>{
//     let pa=this.props.parms
//     let st=this.state
// //     cl(pa)
// //     cl(this.state.thread)
//     if(pa.messageId){
//       return(
//         <div>
//           {st.thread.map((msg,i)=>{
//             let da=new Date(1000*msg.ts)
//             let daShow=dateToDisplayDate(da,"mm/dd/yyyy h:mm ap")
//             let title=`${daShow} ${msg.userName}:`
//             let body=msg.chatText
//             return(
//               <div key={i}>
//                 <div>{title}</div>
//                 <div>{body}</div>
//                 <div className="clearfloat"/><br/>
//               </div>
//             )
//             
//           })}
//         </div>
//       )
//     }
//   }
  
  showEditMessage=()=>{
    let st=this.state
//     cl(st)
//     cl(st.recipients)
    let pa=this.props.parms
//     cl(st)
    return(
      <div>
        {this.showSubject(pa.messageId)}
        {this.showRecipients(pa.messageId)}
        {this.showBody()}
        <C18Images00 parms={{
          images:st.images,
          edit:true,
          onChange:vals=>this.onChange("images",vals)
        }}/>
        <div className="clearfloat"/><br/>
        {this.showSend()}
        <div className="clearfloat"/><br/>
        {pa.messageId&&
          <C18MessageThread00 parms={{
            message:st.message,
            messageId:pa.messageId,
            chatId:st.message.chatId,
            userArr:this.userArr,
          }}/>
        }
      </div>
    )
  }
  
  
  render(){
    if(this.state.loaded){
      return this.showEditMessage();
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
    
}

export default C18MessageEdit00;
