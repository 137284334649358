import {cl, start, stop, show, globs, getTime} from './utils';
import {dbVals, putSite} from './http'; // , putStream
import {getToken, getSiteId} from './httpauth';
import zlib from 'react-zlib-js'
import {saveBrowserAccess,cache} from '../../usa/utils/utils'
// import {buffer} from 'buffer'
// cl(zlib)


// var globWs = 0;

// function testWs(){
//   cl("testing ws")
//   let ws = new WebSocket("ws://l4ol.com:constant.contWebSocket");
//   globWs = ws
//   ws.onopen = e=>onOpen(e);
//   ws.onclose = e=>onClose(e);
//   ws.onmessage = e=>onMessage(e);
//   ws.onerror = e=>onError(e);
// }

var iv = {
  ws: null,
  key: 0,
  callBacks: {},
};

var handleData00=(mo)=>{
//   cl("data00");
  // cl(mo);
//   let z = mo.zone;
//   cl(mo)
//   cl(mo.params)
  if(!mo.params){mo.params=[]}
  mo.params.forEach(p=>{
//     cl(`data00: ${JSON.stringify(p)}`)
//     cl(z, p.c, p.i, p.d)
    try{
//       cl(p)
//       if((p.i==104)&&(p.c==0)) {
//         cl(`chan 0: ${p.d}`)
//       }
      let dx=dbVals.z[p.z][p.c][p.i]
      if((dx!=null)&&(typeof dx=="object")){
        dx.val = p.d
//         cl("hook")
        dx.upd.forEach(upd=>{
          upd.f(upd)
//           cl(upd)
        })// update notifications
      }else{
        dbVals.z[p.z][p.c][p.i] = p.d;
      }
    }catch{}
//     if (p.i === 23){
//       cl("inTemp Change from Site: " + p.d);
//     }
//     if (p.i === 4642){
//       cl("Setpoint Change from Site: " + p.d);
//     }
  });
  globs.events.publish("data", mo);
}

var removeHook=(upd,z,c,ind)=>{
//   cl(upd)
//   cl([z,c,ind])
//   cl(dbVals.z[z][c][ind])
//   cl(dbVals)
  if(!dbVals?.z?.z?.c?.ind)return
  if(dbVals.z[z][c][ind].upd.length==1){
//     cl("remove " + ind)
    dbVals.z[z][c][ind]=dbVals.z[z][c][ind].val// no longer an object
    return
  }
//   cl(dbVals.z[z][c][ind])
  let updA=dbVals.z[z][c][ind].upd
  for(let i=0; i<updA.length; i++){
//     cl(i)
    if(updA[i].hookId==upd.hookId){
      updA.splice(i,1)
//       cl("found")
      return
    }
    
  }
//   dbVals.z[z][c][i].upd.forEach((u,i)=>{
//     cl(u)
//     cl(upd)
//     cl(dbVals.z[z][c][i])
//     if(u.hookId===upd.hookId){
//       cl(dbVals.z[z][c][i])
// //       dbVals.z[z][c][i].upd.splice(i,1)
//       cl(dbVals.z[z][c][i])
//     }
//   })
}

var hookId=0

var addDataHook=(upd, z, c, i)=>{
  // check to see if we already have one!
//   if(i==203){
//     cl([z, c, i])
//   }
//   cl(dbVals)
//   cl("add hook: " + i)
  
  if((!dbVals.z)||(!dbVals.z[z])||(!dbVals.z[z][c])) return
  upd.hookId=hookId++
//   cl(upd)
  if((typeof dbVals.z[z][c][i]) == "object"){
    dbVals.z[z][c][i].upd.push(upd)
//     let got=false
//     dbVals.z[z][c][i].upd.forEach(u=>{
//       if(u.f==upd.f) got=true
//     })
// //     cl(got)
//     if(!got)dbVals.z[z][c][i].upd.push(upd)
  }else{
//     cl("make object")
    let val = dbVals.z[z][c][i]
//     cl(val)
    dbVals.z[z][c][i]={val: val, upd: [upd]}
  }
//   cl(typeof dbVals.z[z][c][i])
//   cl([z, c, i])
}

var testHandleData00=()=>{
  cl("test handle")
  let p={
    z: 0,
    c: 240,
    i: 23,
    d: 66.5,
  }
  let mo={
    params: [p],
  }
  handleData00(mo);
}

var handleMsg=(mo)=>{
//   cl(mo)
  switch(mo.cmd){
    case "data00":
      handleData00(mo);
      break;
    default:
      break;
  }

}

var blobToBuf=(blob)=>{
  
}

var uploadTotal=0
var uploadCount=0

var doData00=(mo)=>{
  if(mo.params && mo.params.length){
    if(mo.cmd=="data00"){// not run when we just get site data
//       cl("setting contact time")
      let now=Math.floor(getTime())
      if(!globs.contacts[mo.s]){globs.contacts[mo.s]={}}
      globs.contacts[mo.s][mo.params[0].z]=now
      globs.events.publish("newZoneData",mo)
    }
    uploadCount+=mo.params.length
    cl(`Data for ${mo.s}, zone ${mo.params[0].z}`)
//     if(mo.params[0].z==7){cl(mo)}
  }
  if (mo.key !== undefined){
    iv.callBacks[mo.key][0](mo);
  }else{
    handleMsg(mo);
  }
//   cl(globs.contacts)
}

var doToClient=(mo)=>{
//   cl(mo)
  globs.events.publish("toClient",mo)
}

var recvSocket = async(msg)=>{
//   cl("recvSocket")
  var mo
  if(typeof(msg.data)!="string"){
    mo=await uncompress(msg.data)
  }else{
    mo = JSON.parse(msg.data);
  }
//   cl(mo.cmd)
  switch(mo.cmd){
    case "gotcursite00":
    case "data00":// was "gotcursite00"
      doData00(mo)
      break
    case "data01Resp":
//       cl("ws Publish saveOK")
      return globs.events.publish("saveOK",mo)
    case "toClient":
      doToClient(mo)
      break
    default:
      break
  }
}

setInterval(e=>{
  uploadTotal=0.8*uploadTotal+0.2*uploadCount
  uploadCount=0
//   cl(`count: ${uploadTotal}`)
},1000)

/*
sendSocket is connected to the server. The server knows our session, and knows how to send a message to the site that we're connected to

*/

var sendSocket = (msg)=>{
//   cl("send socket");
//   cl(msg)
  return new Promise((r, e)=>{
    msg.key = iv.key;
    iv.callBacks[msg.key] = [r, e];
    iv.key = (iv.key + 1) % 100 ;
    iv.ws.send(JSON.stringify(msg))
//     cl("sending 2")
//     cl(r)
//     try{
//       iv.ws.send(JSON.stringify(msg))
//       r(true)
//     }catch{
//       e(false)
//     }
  })
}

function sendPacks(z, packs, site, user){
  cl("send packs")
  let sendPack = {
    command: "data01",
    s: globs.userData.session.siteId,
//     site: site, //dbVals.site,
    user: user, // dbVals.user,
    sessionId: globs.userData.session.sessionId,
//     token: getToken(),
    zone: z,
    params: packs,
  }
  cl(sendPack)
  sendSocket(sendPack).then(r=>{
    cl(r);
  });
}

var checkNewSite=()=>{// if this is a new site, then load the info
//   cl(globs.userData.session)
//   cl("check new")
//   cl(globs.userData.session)
//   cl(globs.userData.session.siteId)
//   cl(globs.userData.siteLoaded)
  return new Promise((r,e)=>{
//     cl([globs.userData.siteLoaded,globs.userData.session.siteId])
//     siteLoaded: "0sna8jVYIh0xw6oF"
    if(globs.userData.siteLoaded==globs.userData.session.siteId){ r() }else{
      doGetCurSite(r,e)
    }
  })
}

var compress=(inp)=>{
  return new Promise((r,e)=>{
    var input=new Buffer.from(inp)// (inp, 'utf8');
    zlib.deflate(input, (er,buf)=>{
      if(er){
        e(er)
      }else{
        r(buf)
      }
      cl(er)
    })
  })
}

var uncompress=(inp)=>{// inp is a blob, returns an object
  return new Promise(async (r,e)=>{
    let inp2=await inp.arrayBuffer()//Buffer.from(msg.data)
    let inp3=Buffer(inp2)
    
    zlib.inflate(inp3, (er,buf)=>{
      if(er){
        e(er)
      }else{
        r(JSON.parse(buf.toString()))
      }
    })
  })
}

var getCurSiteData=(pack)=>{
//   cl("get cur site")
  let wsID="cont"
  let msgObj={uri:"/cont/getCurSite",method:"retrieve"}
  return new Promise(async(r,e)=>{
    let keys=[pack.s]// siteId
    var res
    if(!globs.disableCache){
      res=await cache.check(wsID,msgObj,keys,r,e)
      if(res){
        sendSocket(pack).then(re=>{
//           cl("got res")
          cache.save(wsID,msgObj,keys,re)
        })
        return
      }
    }
    sendSocket(pack).then(re=>{
      cache.save(wsID,msgObj,keys,re)
      r(re)
    })
//     if(res&&(!globs.disableCache)){
//     }else{
//     }
  })
}

var doGetCurSite=(res, rej)=>{
  if(!globs?.userData?.session){return}
  let pack = {
    cmd: "getcursite00",
    sessionId: globs.userData.session.sessionId,
    s: globs.userData.session.siteId,
  }
  cl(`Get Current for ${globs.userData.session.siteId} ${show("main")}`)
  globs.events.publish("waiting",{wait:true})
  let access={uri:`b_doGetCurSite_line302`,method:"retrieve",start:getTime()}
  getCurSiteData(pack).then(async r=>{
    cl("Got Current: "+show("main"))//cl(`time: ${getTime()}`)
    globs.events.publish("waiting",{wait:false})
    if(!r.cursite.length) cl(`No data for site ${pack.s}`)
    if(r.cmd === "gotcursite00"){
//       cl("put site")
      putSite(r.cursite, null);
      globs.userData.siteLoaded=globs.userData.session.siteId
      dbVals.siteAuthorized = true;
      dbVals.wsInfo=r.wsInfo// for debugging
      access.elapse=getTime()-access.start
      saveBrowserAccess(access)
    }
    res(r);
  })
}

// var loginToWebSocket = (res, rej)=>{
//   let token = getToken();
//   let site = getSiteId();
//   let pack = {
//     command: "login00",
//     token: token,
//     site: site,
// 
//   };
//   start("ws2");
//   sendSocket(pack).then(r=>{// login
// //     cl(show("ws2"));
//     if (r.result === 'ok'){
//       doGetCurSite(res);
//     }
//   })
// }

// function onOpen(e){
var onOpen = (r, e)=>{
//   cl("onOpen");
// on open, "login", by sending the JWT and the site that we want.
/* this is the hard part!
we want to wrap web socket communication in a Promise
so, when we send a request, it has a "key", then, when the response comes back, we look up the key, and */
//   cl("ws onOpen");
//   cl(globs.userData.session.siteId)
  let gws=globs.webSocket
  gws.open=true
  gws.res.forEach(re=>{re(true)})// activate the list of responses
  delete gws.res
  // this needs to be handled seperately:
//   if(globs.userData.siteLoaded==globs.userData.session.siteId){ r() }
//   doGetCurSite(r, e);
}

function onClose(e){
  cl("ws onClose");
  globs.webSocket.open=false
//   cl(e)
}

// function onMessage(e){
//   cl("ws onMessage");
// //   cl("received: " + e.data)
//   let mo = JSON.parse(e.data);
//   cl(mo);
// //   putStream(JSON.parse(e.data));
// //   cl(mo);
// }

function onError(e){
  cl("ws onError");
  cl(e)
}

var sendWS = (pack)=>{
  cl(pack);
  iv.ws.send(JSON.stringify(pack))
//   cl(iv);
}

function openWS(uri){
/* this is to manage *the* websocket with the server*/
//   cl("open ws: " + uri);
  let gws=globs.webSocket
  return new Promise((r, e)=>{
//     cl(gws.open)
    if(gws.open){r(); return}
//     cl("still opening")
    if(gws.res){gws.res.push(r); return}// add it to the list of responses
    gws.res=[r]// create the list of responses
    let ws = new WebSocket(uri);
    iv.ws = ws;
    ws.onopen = ()=>onOpen(r, e);
    ws.onclose = e=>onClose(e);
    ws.onmessage = e=>recvSocket(e);
    ws.onerror = e=>onError(e);
  });
}

export {openWS, sendWS, sendSocket, sendPacks, addDataHook, removeHook, checkNewSite,doGetCurSite,
  uploadTotal
}
