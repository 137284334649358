import React from 'react';
// import {Link} from 'react-router-dom'
import C18DateFormat00 from './C18DateFormat00'
import C18Anchor00 from './C18Anchor00'
import {loadZonesInfo,getZoneInfo,getZoneIndex,loadSiteData,getSetpoints,getCurrentSetpoint,updateSensors,
  addSensor,makeSensors,loadAddedSensorsInfo,loadCamerasInfo,loadGatewaysInfo,
  loadMBInfo,loadStationInfo} from './C18utils'

import Gauge2 from '../../visualization/components/Gauge01';
import {sensorIds, getParamId,stages,humStages,tempUnit,lightUnit,bVolUnit,sVolUnit,
  windUnit,nuteUnit,initSensorIds,setSensorIdNames,wsTrans,getParamId800} from '../utils/utils'
import {dbVals,getZoneControllers} from '../../components/utils/http';
import {pInd} from '../../components/utils/paramIds';
import {cl,globs,constant,getTime,dateToDisplayDate} from '../../components/utils/utils';
import history from "../../history"

class C18ZoneSensors00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.gauge={}
    this.state={
      loaded:false,
      mode:"sites",
      tileMode: false,
      sensors:[],
      updateInterval: setInterval(this.update,1000),
    }
//     this.makeSensors()
    this.loadInfo()
    this.checkZonesInt=setInterval(this.checkZone,60*1000)
  }
  
  componentWillUnmount=()=>{
    clearInterval(this.checkZonesInt)
    clearInterval(this.state.updateInterval)
  }
  
  checkDefaultSensors=(zo)=>{
    
  }
  
  checkZone=async()=>{
// update if zone is connected
    var gwType=1800
    globs.zonesInfo.got=false
    await loadZonesInfo()
    let zo=globs.zonesInfo.info.filter(z=>{return z.zoneId==this.zone.zoneId})[0]
//     cl(zo)
//     cl(zo)
    var zone,zInd,sensors=this.state.sensors
    let pa=this.props.parms
    if(zo.newZone){
//       gwType=1800
      [sensors,zone,zInd]=await makeSensors(this,/*gwType*/1800,pa.site,pa.zone)
      let now=getTime()
      if(now-zo.updateTime>600){
//         cl("turn off newZone")
        let writeZone={zoneId:zo.zoneId,newZone:false}
        wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "update", 
          sessionId: globs.userData.session.sessionId, body: writeZone})
      }
    }
    this.zone.connected=zo.connected
    this.zone.virtual=zo.virtual
    this.setState({updated:(this.state.updated||0)+1,sensors:sensors})
  }
  
  loadInfo=async()=>{
//     cl("load info")
//     var sensors
    let pa=this.props.parms
    await loadStationInfo()
//     cl("loaded stations")
//     cl(globs.stInfo)
    await loadAddedSensorsInfo()
    await loadCamerasInfo()
    await loadSiteData(pa.site)
    await loadGatewaysInfo()
    await loadMBInfo(pa.site)
    this.zoneInfo=getZoneInfo(pa.zone)
//     cl(this.zoneInfo)
//     cl(this.zoneInfo.gatewayType)
//     cl(globs)
//     cl("loaded&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&")
//     cl(pa)
//     cl(sensorIds)
//     cl(pa.zone)
//     cl("loadInfo")
//     cl(this.props)
//     let pa=this.props.parms
//     cl(this.props.parms)
//     setSensorIdNames()
    this.tankNames=[]
    let gwType=this.zoneInfo.gatewayType
//     cl(gwType)
//     cl(pa)
    let [sensors,zone,zInd]=await makeSensors(this,gwType,pa.site,pa.zone)
//     cl(sensors)
//     cl(sensors)
//     cl(zone)
//     cl(zInd)
    this.zone=zone
//     cl(this.zone)
    this.zInd=zInd
//     cl(zInd)
    this.setState({loaded:true,sensors:sensors})
  }
  
  update=async()=>{
    if(this.state.loaded){
//       cl(this.zInd)
      initSensorIds(this.zInd)
      if(this.zone){await setSensorIdNames(this.zone.zoneId)}
      let gwType=this.zoneInfo.gatewayType
//       cl(this.state.sensors)
      let sensRet=updateSensors(this,gwType,this.state.sensors,this.props.parms.site,this.zInd)
//       cl(sensRet)
      this.setState({sensors:sensRet})
    }
  }
  
  getCurVal3300=(vid)=>{
    cl(vid)
    let vals={
      spC:72,
      spH:68,
      taH:10,
      taL:10,
      stT:6,
      inT:70.5,
    }
    return (vals[vid])?vals[vid]:0
  }
  
  getCurVal=(vid)=>{
    let zone=dbVals.z[this.zone.siteZoneIndex]
    let [ch,pid]=this.getChPid(vid)
    return (zone[ch]||[])[pid]
  }
  
  getCurVal2=(vid)=>{
    let zone=dbVals.z[this.zone.siteZoneIndex]
    let [ch,pid]=this.getChPid(vid)
    return{val:zone[ch][pid],ch:ch,pid:pid}
//     return zone[ch][pid]
  }
//   getSnap=()=>{
//     
//   }

  scaleGauge=(min,max)=>{
/*max, min, should be the middle 25% of the total
hi and lo should be 1, 2, or 5 values
minmax: 68-72 4 degrees, 16 degrees total, rounded to 2:
62-78*/
    
  }
  
//   getTankNames=()=>{
//     let zone=dbVals.z[this.zone.siteZoneIndex]
// //     cl(zone)
//     let pid=sensorIds['taN'].id
// //     cl(pid)
//     let tankNames=[]
//     for(let i=192;i<200;i++){
// //       cl(zone[i])
//       let tn=((zone[i]||{})[pid])?zone[i][pid]:`Tank ${i-191}`
// //       cl(tn)
//       tankNames.push(tn)
//     }
// //     cl(tankNames)
//     return tankNames
//   }
//   
//   makeSensors=async()=>{
//     let sensors=[]
//     await loadZonesInfo()
// //     cl(globs.zonesInfo.info)
//     await loadSiteData(this.props.parms.site)
//     this.zone=getZoneInfo(this.props.parms.zone)
// //     cl(this.zone)
//     this.tankNames=this.getTankNames()
//     this.zInd=this.zone.siteZoneIndex
//     let sensorOrder=this.zone.sensorOrder||[]
//     sensorOrder.forEach(s=>{
// //       cl(s)
//       addSensor(sensors,s,this.props.parms.site,this.zInd)
//     })
//     cl(sensors)
//     this.setState({loaded:true,sensors:sensors})
//   }
  
  setGauge=(s)=>{
//     cl(s)
/*colors:
x
lo/hi: b0d9ff
mid: 67aded
pnt: 4bd087
cnt: f3f7ff
edg: e8efff
txt: a8a8a9
ttl: 6e6b7b
val: 393d46
unt: afb0b1

splow: c8c3ff
spmid: 0e01ac
sppnt: 0e01ac
*/
//       {name: "Inside Relative Humidity", type: "ga", vals:[-20, 0, 45, 65], cVal: 36, cUn: "% RH", val: 36, foot: "Normal"},
    let dark=(globs.device?.deviceTheme=="originalDark")?2:0
    let alarm=(s.al)?1:0
//     cl([dark,alarm])
//     cl(globs)
//     cl(dark)
//     cl(s)
    let val=s.val
//     cl(s)
    let bgColor=["#FFFFFF","#F4E5E5","#202020","#2b080c"][dark+alarm]
    let p={
      dec:1,
      mult:1,
      bgColor: bgColor,//(dark)?"#202020":"#FFFFFF",
      pntColor:"#4bd087",
      cntColor:(dark)?"#202020":"#f3f7ff",
      untColor:"#afb0b1",
      outRadius: 85,
      inRadius: 75,
      minVal: s.vals[0],
      maxVal:s.vals[s.vals.length-1],
    }
    let size=166
    let ga={}
    ga.value=s.val
    ga.centerValue=s.cVal
    ga.alarm=s.al
    ga.type=s.type
    ga.indText=s.indText// if displaying an index string
    ga.msg=s.msg
    ga.unit=s.cUn
    ga.unitColor=p.untColor
    ga.width=0.8*size-10//p.w-10,// sets size
    ga.height=0.62*size
    ga.fontSize=0.065*size//0.6*size,//12,
    ga.bgColor=p.bgColor
    ga.pointerColor=p.pntColor
    ga.centerColor=p.cntColor
    ga.ticks=s.vals
    if(s.type=="sp"){
      ga.tod=true
      ga.minAngle=0
      ga.maxAngle=360
      ga.colors=s.vals.map(v=>{return [v.start,v.col]})
//       ga.colors.unshift([0,"#c8c3ff"])
      ga.colors.push([1440+s.vals[0].start,"#FFFFFF"])
      ga.ticks=s.vals.map(v=>{return v.start})
//       cl(ga.colors)
//       s.vals.forEach(v=>{})
//       ga.colors=[
//       [0,"#c8c3ff"],
//       [s.vals[0],"#c8c3ff"],
//       [s.vals[1],"#0e01ac"],
//       [s.vals[2],"#c8c3ff"],
//       [1440,"#c8c3ff"],
//       [s.vals[3],"#FFFFFF"],
//       ]
      ga.min=0
      ga.max=1440
    }else{
      ga.minAngle=-120
      ga.maxAngle=120
      if(ga.ticks.length>2){
        ga.colors=[
        [s.vals[0],"#b0d9ff"],
        [s.vals[1],"#67aded"],
        [s.vals[2],"#b0d9ff"],
        [s.vals[3],"#FFFFFF"],
        ]
      }else{
        ga.colors=[
        [s.vals[0],"#b0d9ff"],
        [s.vals[1],"#FFFFFF"],
        ]
      }
      ga.min=p.minVal
      ga.max=p.maxVal
//       cl(ga.ticks)
    }

    ga.pntRadius=p.outRadius
    ga.ringInset=(size/2)*(100-p.outRadius)/100
    ga.ringWidth=(size/2)*(p.outRadius-p.inRadius)/100
//     ga.value=p.dataPoints.dp
//     ga.ticks=s.vals
    ga.update=this.update
//     cl(ga)
    return ga
  }
  
  setSetpoint=()=>{
    this.setGauge()
    this.gauge.value=480
    this.gauge.centerValue=2
    this.gauge.tod=true// time of day
    this.gauge.unit=""
    this.gauge.minAngle=0
    this.gauge.maxAngle=360
    this.gauge.min=0
    this.gauge.max=1440
    this.gauge.colors=[
    [0,"#c8c3ff"],
    [360,"#0e01ac"],
    [720,"#c8c3ff"],
    [1440,"#FFFFFF"],
    ]
    this.gauge.ticks=[0, 360, 720, 1080]
    cl(this.gauge)
    
    
  }
  
  mbClick=()=>{
    window.open("https://www.tridium.com/us/en", "_blank")
  }
  
  sensorClick=(e,id,type)=>{
    cl(id,type)
//     cl(type)
    let zi=getZoneInfo(this.props.parms.zone)
    let index=zi.siteZoneIndex
    e.preventDefault()
    var url
//     cl(id)
    let unit=+id.substring(1,2)
    let sId=id.substring(2)
    if(type.substring(0,2)=="mb"){return this.mbClick()}
    switch(sId){
      case "zoS":
        url=`/usa/c18/fui/setpoints/${index}-${unit}-255-0`
        history.push(url)
        break
//       case "MB":
//         return this.mbClick()
      default:
        let p=this.props.parms
        url=`/usa/c18/sites/${p.site}/zones/${p.zone}/sensor/${id}`
//         url=`/usa/c18/sites/${p.site}/zones/${p.zone}/sensorGraph/${id}/l24`
//         url=`/usa/c18/fui/unit_Input_Calibration/${index}-0-240-0`
        history.push(url)
        break
    }
  }
  
  cameraClick=(e,cam)=>{
    e.preventDefault()
//     cl(this.props)
    this.props.parms.onChange({cmd:"camera",cam:cam})
    
  }
  
  showCamera=(cam,i)=>{
//     cl(cam)
//     cl(constant.camUrl)
    let cameraId=cam.id.substring(4)
    let now=Math.floor(getTime())
//     cl(constant.camUrl)
    return(
      <C18Anchor00 to="" key={i} className={`sensor-box`} aria-label={cam.name} onClick={e=>this.cameraClick(e,cam)}>
        <div className="name">{cam.name}</div>
        <img src={`${constant.camUrl}:${constant.camExpress}/camVideo/${cameraId}/${now}`}
        style={{marginTop:20,}}
          width="150"
        />
      </C18Anchor00>
    )
  }
  
  showGaugeEtc=(showGauge,ga)=>{
//     cl(ga)
    if(showGauge){
      return <Gauge2 config={ga}/>
    }else{
//       cl(ga)
      if(["mbInd","mbIndAl"].includes(ga.type)){
//         cl(ga.indText)
        return(
          <div style={{paddingTop:30}}>
            <h3>{ga.indText}</h3>
          </div>
        )
      }
      if(ga.type=="st"){//station
        return ga.msg
      }
    }
  }
  
  showSensors=()=>{
//     cl(this.state.sensors)
//     this.setGauge()
//     this.setSetpoint()
    let sensors=[]
//     cl(this.state.sensors)
// key={i} className={`sensor-box ${sensorBoxClass}`} aria-label={s.name} onClick={e=>e.preventDefault()}    
    this.state.sensors.forEach((s,i)=>{
//       cl(s)
      if(s){
        if(["ga","sp","mb","st","mbAl","mbInd","mbIndAl"].includes(s.type)){
          let showGauge=!["mbAl","mbInd","mbIndAl","st"].includes(s.type)
  //         if(!showGauge){cl(s)}
  //         cl(showGauge)
          let ga=this.setGauge(s)
  //         cl(ga)
          let stageClass=(s.hilo)?((s.hilo=="hi")?"heat":"cool"):""
          let sensorBoxClass={lo:"low-alarm-on",hi:"high-alarm-on",lohi:"low-alarm-on high-alarm-on"}[s.al]
          sensors.push(
            <C18Anchor00 to="" key={i} className={`sensor-box ${sensorBoxClass}`} aria-label={s.name} onClick={e=>this.sensorClick(e,s.id,s.type)}>
              <div className="name">{s.name}</div>
              {this.showGaugeEtc(showGauge,ga)}
              <div className={`stage ${stageClass}`}>{s.foot}</div>
              <div className="low-alarm alarm-icon">i</div>
              <div className="high-alarm alarm-icon">i</div>
            </C18Anchor00>
          )
        }
        if(s.type=="ca"){
          sensors.push(this.showCamera(s,i))
        }
      }
      
    })
    return sensors
  }
  
  
  render(){
//     cl(globs.zonesInfo.info)
//     cl("zoneSensor render")
//     cl(this.state.loaded)
//     cl(this.props)
    
// let al=+getParmValue(zi,240,se.ind,+getParamId("config_annex_sensors","alarmLow"))||0
//     cl(ts)
//     cl(id)
        
    if(this.state.loaded){
//       cl(this.state)
//       cl(this.zone)
//       this.zone.connected=false
//       cl(this.zone.gatewayType)
      var id
      switch(this.zone.gatewayType){
        case 800:
          id=getParamId800("igR","controllerTime")
//           cl(id)
          break
        case 1900:
          id=getParamId("snapshots","currentTime")
          break
        case 1800:
        default:
          id=getParamId("snapshots","unix_timestamp(unitTime)")
          break
      }
//       let id=(this.zone.gatewayType==1900)?// Pearl
//         getParamId("snapshots","currentTime"):
//       let id=getParamId("snapshots","unix_timestamp(unitTime)")
      let ts=((dbVals.z[this.zInd||0]||{})[240]||{})[id]||0
      if(this.zone.gatewayType==1900){
        let da=new Date()
        let tzo=da.getTimezoneOffset()
        ts=ts-60*tzo
//         cl(tzo)
      }
      
//       cl([id,ts])
      return (
          <section id="sensors1800" className="left-half">
            <C18DateFormat00 parms={{
              leftString: dateToDisplayDate(new Date(1000*ts),"mm/dd/yyyy h:mm ap",0),
              leftClass: `date${(this.zone.connected||this.zone.virtual)?"":" not-synced"}`,
              type: "quickWrench",
              ariaLabel: "sensor settings",
              value: this.state.tileMode,
              valueId: "tileMode",
              gatewayType:globs.zonesInfo.info[getZoneIndex(this.props.parms.zone)].gatewayType,
              onChange: (val)=>this.props.parms.onChange({cmd:"sensorWrench", val:val})
            }}/>
            <div className="sensor-grid">{this.showSensors()}</div>
          </section>
      )
    }else{
      return <div id="content-area">loading sensors. . .</div>
    }
  }
}
      
export default C18ZoneSensors00;
