 import React from 'react';
 //  import { Router, Route, Switch } from "react-router-dom";// , Link
//  import {cl, inputField} from '../utils/utils';
 import {cl,globs, constant} from '../../components/utils/utils';
 import IconButton from '@material-ui/core/IconButton';
 import Check from '@material-ui/icons/Check';
 import Clear from '@material-ui/icons/Clear';
 //  import InputAdornment from '@material-ui/core/InputAdornment';
 //  import Input from "@material-ui/core/Input";
 //  import InputLabel from '@material-ui/core/InputLabel';
 //  import Register from './Register';
 //  import Login from './Login';
 import MainBar from '../../components/MainBar';
 import C18Anchor00 from './C18Anchor00'
 var config = require('../../components/utils/config');

 
 class PostRegister extends React.Component{
   constructor(props) {
    super(props);
//     cl("post")
     document.title="Registration Sent";
//      cl(globs.npi);
//      cl(props);
//      this.state={
//        name: "",
//        email: "",
//      }
   }
   
   showRegLink=()=>{
     cl(config)
     if(!["sg","sg_stage","sg_ryan","sg_alpha"].includes(config.host)){return}
     if(globs?.npi?.token){
       let url = constant.feUrl + `/usa/c18/activate/${globs?.npi?.token}`;
       return(
         <>
          This is the link in the email: 
          <a href={url}>{url}</a>
         </>
       );
     }
   }
   
   render(){
    let name=(globs?.npi?.name) ? globs.npi.name : "user";// npi is "nextPageInfo"
    let email=(globs?.npi?.email) ? globs.npi.email : "specified email";
//      let token=(globs?.npi?.token) ? globs.npi.token : "";
//      let regLink = (token)? `This is the link in the email: <a href="http://usa.c2.link4cloud.com:constant.reactFE/usa/activate/${token}">${token}</a>` : "";
     return(
      <div>

        <div className="login-register-new">

          <div className="login-left register">
          </div>

          <div className="login-right">

            <header>
              <div className="floatleft">
                <a href="https://link4controls.com/" className="main-logo"><img src="/img/link4_logo_blue_trans.png" alt="Link4 Controls logo"/></a>
              </div>
              <div className="floatright">
                  Have an account? <C18Anchor00 id="back-to-login" to="/usa/c18/login">Log In</C18Anchor00>
              </div>
            </header>

            <div className="info-section">
              <h1>Registration Activation Sent</h1>
              <p>Thank you, {name}! An Activation Email has been sent to {email}. Please click the activation link in that email to finish the Registration process. Then you'll be able to use your new account.</p>
              <p>{this.showRegLink()}</p>
            </div>

            <div id="footer" className="registration-confirmation">
              <p>Cloud-Based Environmental Controls</p>
              <p>Copyright &copy;2021 Link4 Corporation. All Rights Reserved.</p>
            </div>

          </div>

        </div>
      </div>
     );
   }
 }
 
 export default PostRegister ;
 
