import React from 'react';
import Select from 'react-select'
import C18Tags00 from './C18Tags00'
import C18Button00 from './C18Button00'
import C18Select01 from './C18Select01'
import C18Input00 from './C18Input00'
import UsaIcon from './UsaIcon';
import {loadSitesInfo,loadZonesInfo,loadUsersInfo,getUserIndex,getSiteName,getZoneName,
  makeImagePath,taskCategories,taskStatus,privs,notifyHandlers,addToAdminLog,saveToAdminLog} from './C18utils';
import {wsTrans,doGetPostBasic} from '../utils/utils'
// import {showImageArray} from './C18TaskUtils'
import history from "../../history"
import {cl,constant,globs,getRandomString,getTime,dateToDisplayDate,allTimezones,displayDateToDate} from '../../components/utils/utils';

class C18TaskEdit00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    let pa=props.parms
    let nowStr= dateToDisplayDate(new Date(),"yyyy-mm-dd")
    this.state={
      loaded:false,
      taskSearch:"",
      title:"New Task Title",
      body:"",
      handlers:[],
      scope:pa.scope,
      site:pa.site,
      zone:pa.zone,
      assignDate:nowStr,
      dueDate:nowStr,
      estimatedDays:0,
      estimatedHours:0,
      priority:"low",
      category:"greenhouse",
      status:"created",
      taskId:props.parms.adminInfo||getRandomString(16),
      images:[],
      dragIndex:-1,
      insertIndex:-1,
      tags:[],
      showSearch:false,
    }
    this.shift=false
//     cl(this.state.taskId)
    this.setBreadCrumbs()
    this.loadInfo()
    this.inputRef=React.createRef()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.saveTask)
    this.subscribe_keyUpEvent=globs.events.subscribe("keyUp", this.keyUp)
    this.subscribe_keyDownEvent=globs.events.subscribe("keyDown", this.keyDown)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
}
  
  componentDidMount=()=>{
    this.inputRef.current.focus()
  }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
    this.subscribe_keyUpEvent.remove()
    this.subscribe_keyDownEvent.remove()
  }
  
  keyUp=(key)=>{
    if(key=="Shift"){this.shift=false}
  }
  
  keyDown=(key)=>{
    if(key=="Shift"){this.shift=true}
  }
  
//   blockShiftMouseDown=e=>{
//     if(e.shiftKey){e.preventDefault()}
//   }

  saveMultipleTasks=async(tasks, adds)=>{
    cl("save multiple")
    let st=this.state
    let nowStr= dateToDisplayDate(new Date(),"yyyy-mm-dd")
    tasks.forEach(ta=>{
      delete ta._id
      delete ta.tags
      ta.createDate = (new Date()).getTime()
      ta.assignDate=nowStr
      ta.dueDate=nowStr
      ta.site=st.site
      ta.zone=st.zone
      ta.taskId=getRandomString(16)
      cl(ta)
      // add to adds log
      saveToAdminLog(adds, "createTask", ta.taskId)
    })
    await wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "create", 
      sessionId: globs.userData.session.sessionId, body: tasks})
    globs.events.publish("saveOK",true)
    history.push(this.baseUrl+"/tasks")
  }
  
  saveTask=async(cmd)=>{

    if (cmd==='save') {
      // if there are multiple tasks, then add them all, and return to TaskList
          cl(this.state)
          let adds = []
          let multi=this.state.tasks.filter(ta=>{return ta.select})
          if(multi.length){return this.saveMultipleTasks(multi, adds)}
      //     return
          let st=this.state
          let task={
            title:st.title,
            body:st.body,
            images:st.images,
            handlers:st.handlers,
            scope:st.scope,
            site:st.site,//st.site,
            zone:st.zone,//st.zone,
            assignDate:st.assignDate,
            dueDate:st.dueDate,
            estimatedHours:+st.estimatedHours+8*st.estimatedDays,
            priority:st.priority,
            category:st.category,
            status:st.status,
            tags:st.tags||[],
            taskId:st.taskId,
            estimatedDays: st.estimatedDays,
            estimatedHours: st.estimatedHours
          }
      //     cl(task)
          notifyHandlers(task)
          await wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "update", 
            sessionId: globs.userData.session.sessionId, body: task,})
          globs.events.publish("saveOK",true)
          // modifying existing
          if (this.props.parms.adminInfo) {
            this.saveToAdminLogTask(adds, st.old, st)
          } else {
            saveToAdminLog(adds, "createTask", st.taskId)
          }
          addToAdminLog(adds)
    }
   
  }

  saveToAdminLogTask=(adds,o,n)=>{
//     cl(o)
//     cl(n)
    let addObj={
      userId:globs.userData.session.userId,
      time:Math.floor(getTime())
    }
    if(o.title!=n.title){
      adds.push(
        Object.assign({},addObj,
        {
        action:"taskTitle",
        oldVal:o.title,
        newVal:n.title,
      }))
    }
    if(o.body!=n.body){
      adds.push(
        Object.assign({},addObj,
        {
        action:"taskBody",
        oldVal:o.body,
        newVal:n.body,
      }))
    }
    if(o.images!=n.images){
      adds.push(
        Object.assign({},addObj,
        {
        action:"taskImage",
        oldVal:o.images,
        newVal:n.images,
      }))
    }
    // compare handlers
    let oHandlers = o.handlers.join(", ")
    let nHandlers = n.handlers.join(", ")
    if(oHandlers!=nHandlers){
      adds.push(
        Object.assign({},addObj,
        {
        action:"taskHandlers",
        oldVal:oHandlers,
        newVal:nHandlers,
      }))
    }
    if(o.assignDate!=n.assignDate){
      adds.push(
        Object.assign({},addObj,
        {
        action:"taskAssignDate",
        oldVal:o.assignDate,
        newVal:n.assignDate,
      }))
    }
    if(o.dueDate!=n.dueDate){
      adds.push(
        Object.assign({},addObj,
        {
        action:"taskDueDate",
        oldVal:o.dueDate,
        newVal:n.dueDate,
      }))
    }
    if(o.estimatedHours!=n.estimatedHours){
      adds.push(
        Object.assign({},addObj,
        {
        action:"taskEstimatedHours",
        oldVal:o.estimatedHours,
        newVal:n.estimatedHours,
      }))
    }
    if(o.priority!=n.priority){
      adds.push(
        Object.assign({},addObj,
        {
        action:"taskPriority",
        oldVal:o.priority,
        newVal:n.priority,
      }))
    }
    if(o.category!=n.category){
      adds.push(
        Object.assign({},addObj,
        {
        action:"taskCategory",
        oldVal:o.category,
        newVal:n.category,
      }))
    }
    if(o.status!=n.status){
      adds.push(
        Object.assign({},addObj,
        {
        action:"taskStatus",
        oldVal:o.status,
        newVal:n.status,
      }))
    }
    // compare handlers
    let oTags = o.tags.join(", ")
    let nTags = n.tags.join(", ")
    if(oTags!=nTags){
      adds.push(
        Object.assign({},addObj,
        {
        action:"taskTags",
        oldVal:oTags,
        newVal:nTags,
      }))
    }
    
  }

  setBreadCrumbs=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
    let p=this.props.parms
    let siteName=getSiteName(p.site)
    let zoneName=getZoneName(p.zone)
//     cl(p)
    var baseUrl
    if(p){
      baseUrl="/usa/c18/sites"
      let bc=[
        {t:"Sites", url:baseUrl},
      ]
      if((p.scope=="site")||(p.scope=="zones")){
        baseUrl+=`/${p.site}`
        bc.push(
          {t:siteName, url:baseUrl}
        )
      }
      if(p.scope=="zones"){
        baseUrl+=`/zones/${p.zone}`
        bc.push(
          {t:zoneName, url:baseUrl},
        )
      }
      bc.push(
        {t:"Tasks", url:baseUrl+"/tasks"},
      )
      bc.push(
        {t:this.state.title, url:baseUrl+`/taskDetail/${this.state.taskId}`},
      )
      bc.push(
          {t:"Task Edit", url:baseUrl+"/tasks"},
      )
      p.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: bc},
        },
      )
      this.baseUrl=baseUrl
    }
  }
  
  loadTask=async(taskId)=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {
        taskId:{$in: [taskId]},// taskIds: ,
      }})
    return res.data[0]
  }
  
  loadAllTasks=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})
    res.data.forEach(ta=>{ta.show=true; ta.select=false})
    return res.data
  }
  
  loadInfo=async()=>{
    await loadUsersInfo()
    let tasks=await this.loadAllTasks()
//     cl(tasks)
    this.userOptions=globs.usersInfo.info.map(ui=>{
      return{value:ui.userId,label:ui.name}
    })
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/taskTags", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})
//     cl(res.data)
    let allTags=res.data

//     cl(this.props.parms)
    var vals={}
    if(this.props.parms.adminInfo){
      let ta=await this.loadTask(this.props.parms.adminInfo)
//       cl(ta)
      this.setState({
        title:ta.title,
        body:ta.body,
        images:ta.images,
        handlers:ta.handlers,
        site:ta.site,
        zone:ta.zone,
        assignDate:ta.assignDate,
        dueDate:ta.dueDate,
        estimatedDays:Math.floor(ta.estimatedHours/8),
        estimatedHours:ta.estimatedHours % 8,
        priority:ta.priority,
        category:ta.category,
        status:ta.status,
        tags:ta.tags||[],
        taskId:ta.taskId,
        loaded:true,
        tasks:tasks,
        allTags:allTags,
        old: ta,
      })
    }else{
//       this.state.loaded=true
      this.setState({loaded:true,tasks:tasks,allTags:allTags})
    }
  }
  
  copyTask=(ta)=>{
    let task={
      title:ta.title,
      body:ta.body,
      images:ta.images,
      handlers:ta.handlers,
      scope:ta.scope,
//       site:st.site,//st.site,
//       zone:st.zone,//st.zone,
      assignDate:ta.assignDate,
      dueDate:ta.dueDate,
      estimatedDays: ta.estimatedDays,
      estimatedHours:ta.estimatedHours,
      priority:ta.priority,
      category:ta.category,
      status:ta.status,
      tags:ta.tags||[],
//       taskId:st.taskId,
    }
    globs.events.publish("savePageEnable", true)
    this.setState(task)
    
  }
  
  deleteTask=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Task?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
//     cl("delete")
//     let url=this.baseUrl+"/tasks"
//     cl(url)
//     return
//     cl(this.state.taskId)
      await wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "delete", 
      sessionId: globs.userData.session.sessionId, body: {taskId:this.state.taskId}})
      let adds = []
      saveToAdminLog(adds, "deleteTask", this.state.taskId)
      addToAdminLog(adds)
      history.push(this.baseUrl+"/tasks")
  }
//     cl(this.props)
//     cl(this.state)
//     cl(this.baseUrl)
  }
  
  onChange=(type,vals)=>{
    cl([type,vals])
    var tasks
    switch(type){
      case "upd":
        globs.events.publish("savePageEnable", true)
        this.setState(vals)
        break
      case "showSearch":
        this.setState(vals)
        break
      case "updSearch":
        tasks=this.doTaskFilter(vals)
        Object.assign(vals,{tasks:tasks})
        this.setState(vals)
        break
      case "searchClick":
        cl(vals)
        vals.e.preventDefault()
        vals.e.stopPropagation()
        tasks=this.state.tasks.slice(0)
        tasks.forEach(ta=>{
          if(this.shift){
            if(ta.taskId==vals.ta.taskId){ta.select=!ta.select}
          }else{
            if(ta.taskId==vals.ta.taskId){
              this.copyTask(ta)
              ta.select=true
            }else{
              ta.select=false
            }
          }
        })
        this.setState({tasks:tasks})
        break
      case "deleteTask":
        this.deleteTask()
        break
      case "handlers":
        globs.events.publish("savePageEnable", true)
        let handlers=vals.map(ha=>{
          return ha.value
        })
        this.setState({handlers:handlers})
        break
      case "imgClick":
        cl(this.state.images[vals.id].id)
        let imageId=this.state.images[vals.id].id
        this.props.parms.onChange({cmd:"image",path:makeImagePath(imageId)})
//         let images=this.state.images.slice(0)
//         images.splice(vals.id,1)
//         this.setState({images:images})
        break
      case "imgDelClick":
//         vals.e.preventDefault()
        vals.e.stopPropagation()
        let images=this.state.images.slice(0)
        images.splice(vals.id,1)
        this.setState({images:images})
        globs.events.publish("savePageEnable", true)
        break
      case "tags":
        globs.events.publish("savePageEnable", true)
        this.setState(vals)
        break
      case "addTag":
        this.setState(vals)
        break
    }
  }
  
  showTitle=()=>{
//     cl(this.state)
//     cl(this.props)
//     cl(this.state.zone)
    let deleteOk=privs("zone",this.state.zone,constant.AREA_PRIVS_WRITE)?true:false
//     cl(deleteOk)
    return(
      <>
      <div style={{display:"inline-block"}}>
        <label htmlFor="title">Title</label>
        <input id="title" type="text" maxLength="50" style={{display:"inline-block",
          marginRight:20}}
          value={this.state.title}
          onChange={e=>this.onChange("upd",{title:e.currentTarget.value})}
          ref={this.inputRef}
        />
        {(this.props.parms.adminInfo?true:false)&&deleteOk&&
          <C18Button00 type="button" className="danger" 
            onClick={e=>this.onChange("deleteTask")}>Delete</C18Button00>
        }
      </div>
        
      </>
    )
  }
  
  showBody=()=>{
    return(
      <>
        <label htmlFor="body">Body</label>
        <textarea id="body"
          value={this.state.body}
          onChange={e=>this.onChange("upd",{body:e.currentTarget.value})}
        />
      </>
    )
  }
  
  showAssignDate=()=>{
    return(
      <>
        <label htmlFor="assignDate">Assign Date</label>
        <input id="assignDate" type="date"
          value={this.state.assignDate}
          onChange={e=>this.onChange("upd",{assignDate:e.currentTarget.value})}
        />
      </>
    )
  }
  
  showDueDate=()=>{
    return(
      <>
        <label htmlFor="dueDate">Due Date</label>
        <input id="dueDate" type="date"
          value={this.state.dueDate}
          onChange={e=>this.onChange("upd",{dueDate:e.currentTarget.value})}
        />
      </>
    )
  }
  
  showEstimatedTime=()=>{
    return(
      <>
        <label htmlFor="estimatedTime">Estimated Time</label>
        <input id="estimatedDays" type="number"
        inputMode="decimal" step="1" min="0" max="100"
          value={this.state.estimatedDays||0}
          onChange={e=>this.onChange("upd",{estimatedDays:e.currentTarget.value})}/>
          <span className="units">Days</span>
        <input id="estimatedHours" type="number"
        inputMode="decimal" step="1" min="0" max="8"
          value={this.state.estimatedHours||0}
          onChange={e=>this.onChange("upd",{estimatedHours:e.currentTarget.value})}/>
          <span className="units">Hours</span>
      </>
    )
    
  }
  
  showPriority=()=>{
    let priorities=[
      {v:"low",t:"Low"},
      {v:"medium",t:"Medium"},
      {v:"high",t:"High"},
      {v:"urgent",t:"Urgent"},
    ]
    return(
      <C18Select01 parms={{
        label:"Priority",
        priority:this.state.priority,
        valueName:"priority",
        opts:priorities,
        onChange:this.onChange,
      }}/>
    )
//     return this.showGeneralSelect("Priority",priorities, "priority")
  }
  
  showCategory=()=>{
    return(
      <C18Select01 parms={{
        label:"Category",
        category:this.state.category,
        valueName:"category",
        opts:taskCategories,
        onChange:this.onChange,
      }}/>
    )
  }
  
  showStatus=()=>{
    return(
      <C18Select01 parms={{
        label:"Status",
        status:this.state.status,
        valueName:"status",
        opts:taskStatus,
        onChange:this.onChange,
      }}/>
    )
  }
  
  makeHandlerList=()=>{
    return this.state.handlers.map(ha=>{
      let user=globs.usersInfo.info[getUserIndex(ha)]
      return {value:user?.userId,label:`${user?.name}-${user?.email}`}
    })
  }
  
  showHandlers=()=>{
//     cl(this.userOptions)
  let customStyles = {};

  customStyles = {
    multiValue: (base, state) => {
      return  { ...base, backgroundColor: 'white' } ;
    },
  }; 
    return(
      <div style={{width:500}}>
        <label htmlFor="handlers">Assignees</label>
        <Select
          id="handlers"
          value={this.makeHandlerList()}
          onChange={o=>{this.onChange("handlers",o)}}
          options={this.userOptions}
          isMulti={true}
          width="500"
          styles={customStyles}
        />        
      </div>
    )
  }
  
/****************** Image Handling *******************************/
  sendImages=async(images)=>{// send new ones, right now
    let data = new FormData()
    images.forEach((img,i)=>{
      cl(["send",img])
      data.append("type", "task")
      data.append(`id${i}`, img.id)
      data.append(`file${i}`, img.image)
    })
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
    cl(url)
    let r=await doGetPostBasic(url, method, data, type)// pro  mise
    let obj=await r.json()
    for(let i=0;i<images.length;i++){
      images[i].width=obj[i].width
      images[i].height=obj[i].height
      images[i].format=obj[i].format
      delete images[i].image
    }
    cl(obj)
  }
  
  markImage=(e)=>{
    let images=[]
    for(let i=0;i<e.target.files.length;i++){
      images.push({id: getRandomString(16), image: e.target.files[i]/*e.target.files[0]*/})
    }
    return images// only new images
  }
  
  updateImages=async(e)=>{
    let images=this.markImage(e)
    if(images.length){
      await this.sendImages(images)
      let imArr=(this.state.images||[]).concat(images)
      this.setState({images:imArr})
      globs.events.publish("savePageEnable", true)
    }
  }
  
/****************** End Image Handling *******************************/
  showNewImageSelect=()=>{
    return(
      <div style={{position:"relative"}}>
        <C18Button00 type="button" aria-label="upload" style={{
          width:100, height:40, borderRadius:10,
          border:1,borderStyle:"solid",borderColor:"#AAAAAA",
          cursor:"pointer",
        }}>Add Image
        </C18Button00>
        <form className="file-upload-form">
        <C18Input00 type="file" multiple onChange={this.updateImages} style={{
          position:"absolute", 
          width:100, 
          height:40, 
          marginTop:0, 
          marginLeft:0,
          zIndex:10,                   
          opacity:0,
          cursor: "pointer",
        }}/>
        </form>
      </div>
    )
  }
  
//   makeImagePath=(id, ext)=>{
//     return `${id[0]}/${id[1]}/${id[2]}/${id.substr(3)}`// .${ext}
//   }
  
  showOneImage=(i,w,h,path)=>{
    var i,w,h,path
    return(
      <div key={i} style={{width: w, height: h}} className="image-popup" onMouseDown={e=>cl("mdown")}>
        <img width={w} src={`${constant.expressUrl}/usa/images/uploads/${path}`}
        style={{backgroundColor: "#C0FFC0", borderRadius: 10, 
          boxShadow: "5px 10px 10px #888888"}}
        onMouseDown={e=>this.mouseDownImage(e,i)} onMouseMove={this.mouseMoveImage} onMouseUp={this.mouseUp} 
        onDragStart={this.dragStartImage} onDragEnd={this.dragEnd}/>
        <div className="image-close-button">
          <UsaIcon icon={`im${i}-Cancel`} result={o=>this.onChange("imageIcon",o)}/>
        </div>
        
      </div>
    )
  }
  
//   getOffset=( el )=>{
//     var _x = 0;
//     var _y = 0;
//     while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
//         _x += el.offsetLeft - el.scrollLeft;
//         _y += el.offsetTop - el.scrollTop;
//         el = el.offsetParent;
//     }
//     return { top: _y, left: _x };
// }
  
  dEnter=(e,i)=>{
    if(this.state.dragIndex>=0){
      if(this.state.insertIndex!=i){
        this.setState({insertIndex:i})
      }
    }
  }

  dStart=(e,i)=>{
    this.setState({dragIndex:i})
  }
  
  moveImageOrder=(start,end)=>{// move image from start position to end position
    if(end>start){end-=1}
    let images=this.state.images.slice(0)
    let im=images.splice(start,1)[0]
    images.splice(end,0,im)
    this.setState({images:images})
    
  }

  dEnd=(e)=>{
    let st=this.state
    this.moveImageOrder(st.dragIndex,st.insertIndex)
    this.setState({dragIndex:-1,insertIndex:-1})
  }
  
  showImageArray=()=>{
    return (this.state.images||[]).map((im,i)=>{
      let path=makeImagePath(im.id,"jpg")
      let totPath=`${constant.expressUrl}/usa/images/uploads/${path}`
      let disp=(i==this.state.insertIndex)?"inline-block":"none"
//             onMouseDown={e=>this.md(e,i)} 
//             onMouseUp={this.mu} 
      return(
        <div key={i} style={{display:"inline-block"}}>
          <div key={i+1000} style={{width:5,opacity: 0.3, height:80,backgroundColor:"#0080FF", 
            margin:5, 
            display:disp}}>
          </div>
          <div key={i} style={{display:"inline-block",backgroundColor:"#FFFFFF",
            position:"relative", margin:10, boxShadow: "5px 5px 10px #808080"
            }}
            onClick={e=>this.onChange("imgClick",{e:e,id:i})}
          >
            <div style={{width:20, textAlign: "center", left:80, float:"right",position:"absolute", 
              cursor:"pointer", backgroundColor:"#EEEEEE",
            }}
            onClick={e=>this.onChange("imgDelClick",{e:e,id:i})}
            >X</div>
            <img key={i} src={totPath} width="100"
              onDragStart={e=>this.dStart(e,i)}
              onDragEnter={e=>this.dEnter(e,i)}
              onDragEnd={e=>this.dEnd(e,i)}
              />
          </div>
        </div>
      )
    })
  }
  
  showImages=()=>{
    return(
      <>
        <label htmlFor="images">Images
        <div style={{marginLeft:20,display:"inline-block"}}>
          {this.showNewImageSelect()}
        </div>
        </label>
        <div id="images" style={{width: 500, 
          borderRadius:10,
            border:1,borderStyle:"solid",borderColor:"#AAAAAA",
            padding:10,flexWrap:"wrap"}}>
            {this.showImageArray()}
        </div>
      </>
    )
  }
  
  showGetTags=()=>{
//     cl(this.state)
    return(
      <>
        <C18Tags00 parms={{
          tags:this.state.tags,
          allTags:this.state.allTags,
          onChange:vals=>this.onChange("tags",vals)
        }}
        />
      </>
    )
  }
  
  doTaskFilter=(vals)=>{
    let st=this.state
    let tasks=st.tasks.slice(0)
    let tags=vals.taskSearch.split(" ")
    for(let i=0;i<tags.length;i++){tags[i]=tags[i].toLowerCase()}
    tasks.forEach(ta=>{
      let searchStr=((ta.tags||[]).join(" ")+" "+(ta.title||"")).toLowerCase()
      ta.show=true
      tags.forEach(tg=>{
        if(searchStr.indexOf(tg)<0){ta.show=false}
      })
    })
    return tasks
  }
  
  showMatchingTags=()=>{
    let match=this.state.taskSearch.toLowerCase()
    let tags=match.split(" ")
    this.tags=this.state.allTags.filter(ta=>{
      let showIt=true
      tags.forEach(tg=>{
        if(ta.toLowerCase().indexOf(tg)<0){showIt=false}
      })
      return showIt
//       return ta.toLowerCase().indexOf(match)>=0
    })
    let max=(this.state.taskSearch.length==0)?0:5
    return(
      <div style={{width:300,backgroundColor:"#EEEEEE",overflowY:"auto",
        position:"absolute"
      }}>
      {this.tags.map((ta,i)=>{
        let bg=(i==this.state.selIndex)?"#CCFFFF":"#EEEEEE"
        if(i<max){return <span key={i}
      style={{cursor:"pointer",backgroundColor:bg,margin:5}}
      onClick={e=>{this.onChange("addTag",{taskSearch:ta})}}
      >{ta}<br/></span>}
          
        })}
      </div>
    )
  }
  
  showSearch=()=>{
    let st=this.state
//     let tasks=this.doTaskFilter()
    return(
      <>
      <button type="button" aria-label="showSearch"
      onClick={e=>this.onChange("showSearch",{showSearch:!st.showSearch})}
      >Copy an Existing Task
      <span className="material-icons-outlined">{(this.state.showSearch)?
        "keyboard_arrow_up":"keyboard_arrow_down"}</span></button>
      <div style={{borderRadius:10,border:1,borderStyle:"solid",padding:20,
        display:(this.state.showSearch)?"block":"none"
      }}>
        <label htmlFor="taskSearch">Search</label>
        <input id="taskSearch" type="text"
          value={this.state.taskSearch}
          onChange={e=>this.onChange("updSearch",{taskSearch:e.currentTarget.value})}
        />
        {this.showMatchingTags()}
        <div className="clearfloat"/><br/>
        <div style={{width: 500, borderRadius:10,
            border:1,borderStyle:"solid",borderColor:"#AAAAAA",
            padding:10,flexWrap:"wrap"}}>
            <table><tbody>
            {st.tasks.map((ta,i)=>{
              if(ta.show){
                let bgColor=(ta.select)?"#CCCCCC":"#FFFFFF"
                return(
                  <tr key={i}>
                  <td style={{cursor:"pointer",backgroundColor:bgColor}}
                    onClick={e=>this.onChange("searchClick",{e:e,ta:ta})}
                    onMouseDown={e=>{if(e.shiftKey){e.preventDefault()}}}
                  >{ta.title}</td>
                  </tr>
                )
              }else{return null}
            })}
            </tbody></table>
        </div>
        <div className="clearfloat"/><br/>
      </div>
      <div className="clearfloat"/><br/>
      </>
    )
  }
  
  render(){
//     cl(this.state)
    if(this.state.loaded){
//       cl("loaded")
      return(
        <div>
        {(!this.props.parms.adminInfo)&&this.showSearch()}
        {this.showTitle()}
        <div className="clearfloat"/><br/>
        {this.showBody()}
        <div className="clearfloat"/><br/>
        {this.showImages()}
        <div className="clearfloat"/><br/>
        {this.showPriority()}
        {this.showCategory()}
        {this.showStatus()}
        {this.showAssignDate()}
        <div className="clearfloat"/><br/>
        {this.showDueDate()}
        <div className="clearfloat"/><br/>
        {this.showEstimatedTime()}
        <div className="clearfloat"/><br/>
        {this.showGetTags()}
        <div className="clearfloat"/><br/>
        {this.showHandlers()}
        </div>
      )
    }else{
      return <div ref={this.inputRef} id="content-area">loading. . .</div>
    }
  }
}
      
export default C18TaskEdit00;
