import React from 'react';
import C18Select01 from './C18Select01'
import C18Button00 from './C18Button00'
import C18Input00 from './C18Input00'
import {wsTrans} from '../../usa/utils/utils'
import {cl,globs,getRandomString} from '../../components/utils/utils';

class C18TestConfigs00 extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
//     cl(globs)
    this.state={
      loaded:false,
      showTestControl:true,
//       selTestConfig:"cfg2",
//       testConfigs:[
//         {id:"cfg1",name:"Config One",zones:{}},
//         {id:"cfg2",name:"Config Two",zones:{}},
//       ],
    }
    this.loadInfo()
  }

  loadInfo=async()=>{
    if(!globs.userData?.session){
      this.state.loaded=true
      return}
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/testConfigs", method: "retrieve",
      sessionId: globs.userData.session?.sessionId,
      body: {}})// now for gatewayId
//     cl(res)
    if(!res.data){
      this.state.loaded=true
      return}
    let cfgs=res.data.map(tc=>{
      return{
        id:tc.configId,
        name:tc.name,
      }
    })
    let selTestConfig=cfgs[0]?.id
    this.setState({loaded:true,selTestConfig:selTestConfig, testConfigs:cfgs})
  }

  saveConfig=async()=>{
    cl(globs)
    let st=this.state
    let cfg=st.testConfigs.filter(tc=>{return tc.id==st.selTestConfig})[0]
    if(cfg){
      let cfgSave={
        configId:cfg.id,
        name:cfg.name,
        siteId:globs.userData.siteSelected,
        zoneId:globs.userData.zoneSelected,
      }
      cl(cfgSave)
      await wsTrans("usa", {cmd: "cRest", uri: "/s/testConfigs", method: "update",
        sessionId: globs.userData.session.sessionId,
        body: cfgSave})// now for gatewayId
    }
  }

  deleteConfig=async()=>{
    let st=this.state
    await wsTrans("usa", {cmd: "cRest", uri: "/s/testConfigs", method: "delete",
      sessionId: globs.userData.session.sessionId,
      body: {configId:st.selTestConfig}})// now for gatewayId
  }

  restoreConfig=async()=>{
    let st=this.state
    let pa=this.props.parms
    cl(pa)
//     cl(st)
    let sects=["account","sites","siteData","zones","users"]
    let fields={}
    sects.forEach(s=>{
      if(st[s]){fields[s]=1}
    })
    cl(fields)
    var siteZoneIndex
    if(globs.userData.zoneSelected){
      let zone=globs.zonesInfo.info.filter(z=>{return z.zoneId==globs.userData.zoneSelected})[0]
      cl(zone)
      siteZoneIndex=zone.siteZoneIndex
    }
    let query={
        configId:st.selTestConfig,
        siteId:globs.userData.siteSelected,
        zoneId:globs.userData.zoneSelected,
        siteZoneIndex:siteZoneIndex,
        fields:fields,
        all:true}
    cl(query)
    await wsTrans("usa", {cmd: "cRest", uri: "/s/testConfigs", method: "retrieve",
      sessionId: globs.userData.session.sessionId,
      body: query})// now for gatewayId
  }

  onChange=(type,vals)=>{
    cl(type,vals)
    let st=this.state
//     cl(st)
    var cfgs,selTestConfig
    switch(type){
      case "configName":
        cfgs=st.testConfigs.slice(0)
        let cfg=cfgs.filter(tc=>{return tc.id==st.selTestConfig})[0]
        if(cfg){
          cfg.name=vals.configName
        }
//         cl(cfg)
        this.setState({testConfigs:cfgs})
        break
      case "saveTestConfig":
        return this.saveConfig()
      case "deleteTestConfig":
        this.deleteConfig()
        cfgs=st.testConfigs.slice(0)
        cfgs=cfgs.filter(tc=>{return tc.id!=st.selTestConfig})
        if(cfgs[0]){
          selTestConfig=cfgs[0].id
          this.setState({selTestConfig:selTestConfig, testConfigs:cfgs})
        }
        break
      case "selTestConfig":
      case "upd":
        this.setState(vals)
        break
      case "addTestConfig":
        cfgs=st.testConfigs.slice(0)
        selTestConfig=getRandomString(16)
        cfgs.push(
          {id:selTestConfig,name:"New Test Config",zones:{}}
        )
        this.setState({selTestConfig:selTestConfig, testConfigs:cfgs})
        break
      case "restoreTestConfig":
        return this.restoreConfig()
//       case "upd":
//         this.setState(vals)
//         break

    }
  }


    selectTestConfig=()=>{
      let st=this.state
      let configs=st.testConfigs.map(tc=>{return{v:tc.id,t:tc.name}})
//         <C18Button00 type="button" className="danger"
//           style={{marginLeft:20}}
//           onClick={e=>{this.onChange("deleteTestConfig")}}
//         >Delete</C18Button00>
      return(
        <div id="iDoser_nutrients">
          <C18Select01 parms={{
            label:"Test Configurations",
            valueName:"selTestConfig",
            selTestConfig:st.selTestConfig,
            opts:configs,
            onChange:(e,v)=>{this.onChange("selTestConfig",v)}//this.onChange,
          }}/>
          <button type="button" className="material-icons" aria-label="delete config"
            style={{color:"#EA5455"}}
            onClick={e=>this.onChange("deleteTestConfig")}>
            delete_outline
          </button>
          <button type="button" className="material-icons" aria-label="add nutrient"
            style={{color:"#1F8DED"}}
            onClick={e=>this.onChange("addTestConfig")}>
            add
          </button>


        </div>
      )
    }

    showTCEdit=()=>{
      let st=this.state
      let cfg=st.testConfigs.filter(tc=>{return tc.id==st.selTestConfig})[0]
//       cl(cfg)
//           <C18Button00 type="button" className="filled"
//           onClick={()=>this.onChange("restoreTestConfig")}>Restore</C18Button00>
      return(
        <div>
          <label htmlFor="config-name">Config Name</label>
          <C18Input00 type="text" id="config-name"
            value={cfg?.name||""}
            onChange={e=>this.onChange("configName",{configName:e.currentTarget.value})}
          />
        <div className="clearfloat"></div><br/>

        <C18Button00 type="button" className="filled"
        onClick={()=>this.onChange("saveTestConfig")}>Save</C18Button00>
        </div>
      )
    }

    showTCRestore=()=>{
      let st=this.state
      let sections=[
        {v:"account",t:"Account"},
        {v:"sites",t:"Sites"},
        {v:"siteData",t:"Site Data"},
        {v:"zones",t:"Zones"},
        {v:"users",t:"Users"},
      ]
      let sects=sections.map((s,i)=>{
        return(
          <div key={i}>
          <input id={s.v}
            type="checkbox"
            checked={st[s.v]||false}
            onChange={e=>{
              let vals={}
              vals[s.v]=e.currentTarget.checked
              this.onChange("upd",vals)
            }}
          />
          <label style={{marginLeft:10,display:"inline-block"}} htmlFor={s.v}>{s.t}</label>
          </div>
        )
      })
      return(
        <div
          style={{
            width:200,
             height:200,
             borderStyle:"solid",
             borderWidth:1,
             borderRadius:10,
             padding:10
          }}
        >
        {sects}
        <C18Button00 type="button" className="filled"
        onClick={()=>this.onChange("restoreTestConfig")}>Restore</C18Button00>
        </div>
      )
    }

    showTestControl=()=>{
      let st=this.state
      let pa=this.props.parms
      if(!pa.showTestControl){return}
      let wid=window.innerWidth
      let hgt=window.innerHeight
//       cl(window.innerHeight)
//       cl(hgt)
      let dWid=400
      let dHgt=200
      let x=(wid-dWid)/2
      let y=(hgt-dHgt)/2
//       cl(y)
      return(
        <div style={{position:"absolute",left:x,top:y,zIndex:20,width:dWid,
          backgroundColor:"white",borderStyle:"solid",borderWidth:1,borderRadius:10,
          padding:20,

        }}>
          <h3 style={{textAlign:"center"}}>Test Configs</h3>
          {this.selectTestConfig()}
          {this.showTCEdit()}
          <div className="clearfloat"></div><br/>
          {this.showTCRestore()}
        </div>
      )
    }

  render(){
    let st=this.state
//     cl(st)
    if(st.loaded){
      return(
        <div>
        {this.showTestControl()}
        </div>
      )
    }else{
      return <div id="content-area">loadingtc. . .</div>
    }
  }
}

export default C18TestConfigs00;
