import React from 'react';
import C18Select01 from './C18Select01'
// import C18DateFormat00 from './C18DateFormat00'
// import C18Button00 from './C18Button00'
// import C18Anchor00 from './C18Anchor00'
// import {getParamId,getParamId2,getParamId800} from '../utils/utils'
import {getParamId,getParamId2,getParamId800,getPearlNumIO} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo,getZoneIndex,loadSiteData,getChannelType,getSiteName,getZoneName} from './C18utils'
// import {getEquipmentStatus} from './C18EquipmentStatus00'
import {dbVals,getZoneControllers,sendArray} from '../../components/utils/http';
import {cl,globs,dateToDisplayDate,getTime,getTimeI,constant} from '../../components/utils/utils';
import {pInd} from '../../components/utils/paramIds';
//
// import history from "../../history"

class C18Expansion00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded:false,
      mode:"sites",
      selXBoard:0,
    }
    this.xBoardTypes={
      "0":"None",					//  0: MB_SLAVE_TYPE_NONE
      "1":"24 Digital Out",	//  1: MB_SLAVE_TYPE_24OUT
      "2":"8 Digital Out",		//  2: MB_SLAVE_TYPE_8OUT
      "3":"4 Digital Out",		//  3: MB_SLAVE_TYPE_4OUT
      "4":"2 Digital Out",		//  4: MB_SLAVE_TYPE_2OUT
      "5":"EC/pH",				//  5: MB_SLAVE_TYPE_EC_PH
      "6":"2 Analog Out",		//  6: MB_SLAVE_TYPE_ANALOG_2OUT
      "7":"1 Analog Out",		//  7: MB_SLAVE_TYPE_ANALOG_1OUT
      "8":"3 Analog Out",		//  8: MB_SLAVE_TYPE_ANALOG_3OUT
      "9":"5 Analog Out",		//  9: MB_SLAVE_TYPE_ANALOG_5OUT
      "10":"Sensor",				// 10: MB_SLAVE_TYPE_SENSOR_MODULE
      "11":"Analog 16-In",				// 11: MB_SLAVE_TYPE_ANALOG_16INPUT
      "12":"Analog 8-In/Dig 8-Out",		// 12:  MB_SLAVE_TYPE_CONTROLLER
      "13":"Analog 10-In-DTH",        // 13:  MB_SLAVE_TYPE_ANALOG_10IN_DHT
      "14":"4 Analog/4 Digital Out",		// 14:  MB_SLAVE_TYPE_4A_4D_OUTPUT
      "15":"DTH",		// 15:  MB_SLAVE_TYPE_DTH
      "16":"Analog 7-In-DTH",		// 16:  MB_SLAVE_TYPE_ANALOG_7IN_DHT
      "17":"Controller",		// 17:  MB_SLAVE_TYPE_CONTROLLER
    }

    this.loadInfo()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.savePage)
    this.setBreadCrumbs()
//     if(this.props.parms.saveOK){this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})}
  }

  componentWillUnmount=()=>{
    if(this.subscribe_savePageEvent){this.subscribe_savePageEvent.remove()}
  }

  setBreadCrumbs=()=>{
//     cl(this.props.parms)
    let p=this.props.parms
    let siteName=getSiteName(p.site)
    let zoneName=getZoneName(p.zone)
    if(p){
      p.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
              {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`},
//               {t:"iDoser", url:`/usa/c18/idoser/nutrients`},
              {t:"Expansion", url:`/usa/c18/sites/${p.site}/zones/${p.zone}/pearl/expansion`},
            ]},
        },
      )
    }
  }

  saveXBoardValue=(arr,x,k)=>{
//     cl(x,k)
    let id=this.xIDs[k]
    cl(id)
//     let curVal=+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.numOutId+i*mult]||0
//           numOuts:+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.numOutId+i*mult]||0,
  }

  saveXBoard=(arr,x)=>{
//     cl(x)
    let saveIds={startOut:this.xIDs.chIndex, startInp:this.xIDs.startInp}
    let mult=pInd[1900].config_expansion_boards[2]
//     cl(saveIds)
//     cl(this.xIDs)
    let now=getTimeI()
    if(getPearlNumIO(x.type)[0]){
//       cl(x.type)
      arr.push({
        c:240,
        d:x.startCh,
        f:2,
        i:saveIds.startOut+mult*x.boardIndex,
        t:now,
        z:this.zInfo.siteZoneIndex,
      })
    }
    if(getPearlNumIO(x.type)[1]){
//       cl(x.type)
      arr.push({
        c:240,
        d:x.startInp,
        f:2,
        i:saveIds.startInp+mult*x.boardIndex,
        t:now,
        z:this.zInfo.siteZoneIndex,
      })
    }
    cl(arr)
//     Object.keys(this.xIDs).forEach(k=>{
//       this.saveXBoardValue(arr,x,k)
//     })
  }

  savePage=async(cmd)=>{
    if(cmd=="save"){
      let st=this.state

//       let x0=
      cl("saving")
      let saveArr=[]
      let xBoards=st.xBoards.slice(0)
      st.xBoards.forEach(x=>{
        this.saveXBoard(saveArr,x)
      })
      let zi=this.zInfo
      cl(saveArr)
      await sendArray(saveArr,zi.virtual,zi.gatewayType,zi.controllerId,false)
      globs.events.publish("saveOK",true)
//       cl(xBoards)
    }
  }

  loadXBoards=(zInfo)=>{
    let mult=pInd[1900].config_expansion_boards[2]
    let ceb="configuration_expansion_boards"
    let zInd=zInfo.siteZoneIndex
    this.xIDs={
      numOutId:getParamId2(zInfo.gatewayType,ceb,"numOutputs"),
      chIndex:getParamId2(zInfo.gatewayType,ceb,"startChannelIndex"),// starting output
      addIndex:getParamId2(zInfo.gatewayType,ceb,"address"),
      typeId:getParamId2(zInfo.gatewayType,ceb,"boardType"),
      numInps:getParamId2(zInfo.gatewayType,ceb,"numInputs"),
      startInp:getParamId2(zInfo.gatewayType,ceb,"startInput"),
      mbPort:getParamId2(zInfo.gatewayType,ceb,"modbusPort"),
      uniqueId:getParamId2(zInfo.gatewayType,ceb,"uniqueId0"),
    }
    let xBoards=[]
    for(let i=0;i<40;i++){
      let type=+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.typeId+i*mult]||0
//       cl(type)
      if(type){
        xBoards.push({
          type:type,
          numOuts:+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.numOutId+i*mult]||0,
          startCh:+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.chIndex+i*mult]||0,
          numInps:+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.numInps+i*mult]||0,
          startInp:+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.startInp+i*mult]||0,
          mbAddr:+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.addIndex+i*mult]||0,
          mbPort:+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.mbPort+i*mult]||0,
          uniqueId:+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.uniqueId+i*mult]||0,
          boardIndex:i,
        })
      }
    }
//     cl(xBoards)
    return xBoards
  }

  loadInfo=async()=>{
    let pa=this.props.parms
//     cl(pa)
    await loadZonesInfo()
    this.zInfo=globs.zonesInfo.info[getZoneIndex(this.props.parms.zone)]
//     cl(this.zInfo)

    await loadSiteData(pa.site)
//     await this.loadXBoards(this.zInfo)
    let xBoards=await this.loadXBoards(this.zInfo)
//     cl(xBoards)
    this.setState({
      loaded:true,
      xBoards:xBoards,
      selXBoard:0,
    })
  }

  onChange=(type,vals)=>{
//     cl(type,vals)
    let st=this.state
    switch(type){
      case "selXBoard":
      case "upd":
        this.setState(vals)
        break
      case "xBoard":
        globs.events.publish("savePageEnable",true)
        let xBoards=st.xBoards.slice(0)
        let xBoard=xBoards[st.selXBoard]
        Object.assign(xBoard,vals)
        this.setState({xBoards:xBoards})
        break
      default:
        break
    }
  }

  showExpansionSelect=()=>{
    let st=this.state
    let opts=st.xBoards.map((x,i)=>{return({v:i,t:`Bd${i+1}`})})
    return(
      <C18Select01 parms={{
        label:"Select Expansion",
        valueName:"selXBoard",
        selXBoard:st.selXBoard,
        opts:opts,
        onChange:(e,v)=>{this.onChange("selXBoard",v)}//this.onChange,
      }}/>
    )
  }


  showExpansionEdit=()=>{
    let st=this.state
//     cl(st)
    let xBoard=st.xBoards[st.selXBoard]
    if(!xBoard){return}
//     cl(xBoard)
    let type=this.xBoardTypes[xBoard.type]
    let fields={"2":["startOut"],"13":["startInp"],"12":["startOut","startInp"]}[xBoard.type]||[]
//     cl(fields)
//         Starting Output Channel
//         Modbus Address
//         Modbus mbPort
//         Starting Input Channel
    return(
      <div>
        <h2>{type}</h2>
        <p>{`Modbus Port: ${xBoard.mbPort}`}<br/>
        {`Modbus Addr: ${xBoard.mbAddr}`}<br/>
        {`Number of Outs: ${xBoard.numOuts}`}<br/>
        {`Number of Ins: ${xBoard.numInps}`}<br/>
        {`Unique ID: ${xBoard.uniqueId}`}
        </p>
        {fields.includes("startInp")&&
          <>
            <label htmlFor="startInp">Starting Input</label>
            <input type="number" id="startInp"
              value={xBoard.startInp}
              onChange={e=>{this.onChange("xBoard",{startInp:+(e.currentTarget.value||0)})}}
            />
            <div className="clearfloat"></div><br/>
          </>
        }
        {fields.includes("startOut")&&
          <>
            <label htmlFor="startOut">Starting Output</label>
            <input type="number" id="startOut"
              value={xBoard.startCh}
              onChange={e=>{this.onChange("xBoard",{startCh:+(e.currentTarget.value||0)})}}
            />
            <div className="clearfloat"></div><br/>
          </>
        }
      </div>
    )

  }

  render(){
    if(this.state.loaded){
      return(
        <div>
        {this.showExpansionSelect()}
        {this.showExpansionEdit()}
        </div>
      )
    }else{
      return <div id="content-area">loading equipment. . .</div>
    }
  }
}

export default C18Expansion00;
