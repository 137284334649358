import React from 'react';
// import C18Select00 from './C18Select00'
import C18Select01 from './C18Select01'
import {privs,loadSitesInfo,loadUsersInfo,loadPrivsInfo} from './C18utils'
import {wsTrans,loadWsTrans,localAccess} from '../utils/utils'
import {cl, globs,getTime,constant,getRandomString,dateToDisplayDate} 
  from '../../components/utils/utils';

class C18PageAccesses00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded:false,
      fromSel:"min1",
      periodSel:"min1",
      fromTime:dateToDisplayDate(new Date(),"yyyy-mm-ddThh:mm"),
      accesses:[],
      showBrowser:false,
      showServer:false,
      showLocalAccesses:true,
//       siteSel:globs.userData.session.siteId,
//       users:[],
    }
    this.loadInfo()
  }
  
//   loadAccounts=async()=>{
//     var res
//     if(privs("super","",constant.AREA_PRIVS_READ)){
//       if(this.accounts){return}
//       res=await wsTrans("usa", {cmd: "cRest", uri: "/su/suAccounts", method: "retrieve", 
//         sessionId: globs.userData.session.sessionId,
//         body: {}})
//     }
//     this.accounts=res?.data
//     if(this.accounts){
//       this.accounts.sort((a,b)=>{
//         let na=(a.name||"").toLowerCase()
//         let nb=(b.name||"").toLowerCase()
//         if(na<nb){return -1}
//         if(na>nb){return 1}
//         return 0
//       })
//     }
//   }
  
  sortTab=(tab,field,dir=1)=>{
    tab.sort((a,b)=>{
      if(a[field]>b[field]){return dir}
      if(a[field]<b[field]){return 0-dir}
      return 0
    })
  }
//   { "_id" : ObjectId("6370e6ee7fc453164cd5d65e"), "accountId" : "a036uzDCxohZ7ovD", "userId" : "1S1tSCDsWatsjHeD", "siteId" : "d1fIue45CWvP@Nik", "uri" : "/s/thirdParty", "method" : "retrieve", "start" : 1668343534.438, "elapse" : 0.003000020980834961 }

  getFromTo=(st)=>{
    var times={min1:60,min5:300,min15:900,min60:3600}
    let now=getTime()
//     let st=this.state
//     cl(now)
    var fromTime
    if(st.fromSel=="custom"){
      cl(st.fromTime)
      cl(Date.parse(st.fromTime))
      cl(typeof(Date.parse(st.fromTime)))
      fromTime=Date.parse(st.fromTime).getTime()
      cl(fromTime)
    }else{
      fromTime=now-times[st.fromSel]
//       cl(fromTime)
    }
    return{fromTime:fromTime,toTime:fromTime+times[st.periodSel]}
  }

  loadAccesses=async(vals)=>{
    let st=Object.assign({},this.state,vals)
    let fromTo=this.getFromTo(st)
    let acc=await loadWsTrans({
      uri:"/s/accesses",
      method:"retrieve",
      body:{
        accountId:st.accountSel,
        userId:st.userSel,
        from:fromTo.fromTime,
        to:fromTo.toTime,
      }
    })
    this.sortTab(acc.data,"start",-1)
    this.setState({accesses:acc.data})
//     cl(acc.data)
  }
  
  selectDefaultUser=(accountSel)=>{
      let u0=this.users.filter(u=>{return u.accountId==accountSel})[0]
      return u0?.userId
  }
  
  loadInfo=async()=>{
    await loadPrivsInfo()
//     await this.loadAccounts()
    var accountSel,userSel
    if(privs("super","",constant.AREA_PRIVS_READ)){
      this.accounts=(await loadWsTrans({uri:"/su/suAccounts"})).data
//       accountSel=this.accounts[0].accountId
      accountSel="a036uzDCxohZ7ovD"
//       userSel="1S1tSCDsWatsjHeD"
      this.sites=(await loadWsTrans({uri:"/su/allSites"})).data
      this.zones=(await loadWsTrans({uri:"/su/allZones"})).data
      this.users=(await loadWsTrans({uri:"/su/allUsers"})).data
      this.selectDefaultUser(accountSel)
      this.sortTab(this.accounts,"name")
      this.sortTab(this.sites,"name")
      this.sortTab(this.zones,"zoneName")
      this.sortTab(this.users,"name")
    }
    this.setState({loaded:true,accountSel:accountSel,userSel:userSel})
  }
  
  onChange=(type,vals)=>{
    cl(type,vals)
    switch(type){
      case "upd":
        if("accountSel" in vals){
          vals.userSel=this.selectDefaultUser(vals.accountSel)
        }
        this.loadAccesses(vals)
        this.setState(vals)
        break
      case "refresh":
        this.loadAccesses({vals})
        break
      default:
        break
    }
  }
  
  showSelect=(label,value,arr,vField,tField)=>{
    let opts=arr.map(a=>{
      return {
        v:a[vField],
        t:a[tField]
      }
    })
    let parms={
      label:label,
      valueName:value,
      opts:opts,
      onChange:this.onChange,
    }
    parms[value]=this.state[value]
    return(
      <C18Select01 parms={parms}/>
    )
  }
  
  showFrom=()=>{
    let st=this.state
    let tab=[
      {v:"min1",t:"1 Minute"},
      {v:"min5",t:"5 Minute"},
      {v:"min15",t:"15 Minute"},
      {v:"min60",t:"1 Hour"},
      {v:"custom",t:"Custom"},
    ]
// {fromTime: '2022-11-16T09:32'}    
    return(
      <div>
        {this.showSelect("From (minutes ago)","fromSel",tab,"v","t")}
        {st.fromSel=="custom"&&
        <div>
          <input type="datetime-local"
            value={st.fromTime}
            onChange={e=>this.onChange("upd",{fromTime:e.target.value})}
          />
        </div>
        }
      </div>
    ) 
  }
  
  showPeriod=()=>{
    let tab=[
      {v:"min1",t:"1 Minute"},
      {v:"min5",t:"5 Minute"},
      {v:"min15",t:"15 Minute"},
      {v:"min60",t:"1 Hour"},
    ]
    return this.showSelect("Period (minutes to show)","periodSel",tab,"v","t")
  }
  
  showAccesses=()=>{
    let st=this.state
    let shows={b:st.showBrowser,s:st.showServer}
    let rows=(
      st.accesses.filter(ac=>{
        let acType=(ac.uri.indexOf("b_")==0)?"b":"s"
//         cl([ac.uri,acType])
        return shows[acType]
      })
      .map((ac,i)=>{
        let da=new Date(1000*ac.start)
        let daShow=dateToDisplayDate(da,"yyyymmdd hh:mm:ss",480)
        let elapse=Math.round(ac.elapse*1000)/1000
        return(
          <tr key={i}>
          <td style={{width:200}}>{daShow}</td>
          <td>{`${ac.uri}-${ac.method}`}</td>
          <td>{elapse}</td>
          </tr>
        )
      })
    )
    return(
      <table style={{width:600}}><tbody>
      <tr><th>Start</th><th>Uri</th><th>Elapsed</th></tr>
      {rows}
      </tbody></table>
    )
  }
  
  showCheck=(value,label)=>{
    let st=this.state
    return(
      <div>
      <input 
        type="checkbox"
        checked={st[value]}
        onChange={e=>{
          let vals={}
          vals[value]=e.target.checked
          this.onChange("upd",vals)}
        }
      />
      <label style={{display:"inline-block",marginLeft:10}}>{label}</label>
      </div>
    )
  }
  
  showLocalStoredAccesses=()=>{
    let st=this.state
    return(
      <div>
      <input 
        type="radio" 
        name="localAccess"
        value="local"
        checked={st.showLocalAccesses}
        onChange={e=>this.onChange("upd",{showLocalAccesses:true})}
      />
      <label style={{display:"inline-block",marginLeft:10}}>Local Accesses</label>
      <br/>
      <input 
        type="radio" 
        name="localAccess"
        id="stored"
        value="stored"
        checked={!st.showLocalAccesses}
        onChange={e=>this.onChange("upd",{showLocalAccesses:false})}
      />
      <label style={{display:"inline-block",marginLeft:10}}>Stored Accesses</label>
      <br/>
      </div>
    )
  }
  
  showLocalAccesses=()=>{
//     cl(localAccess)
    var la2=[]
    let laLen=localAccess.length
    for(let i=0;i<laLen;i++){
      let ac=localAccess[i]
//       cl(ac)
      la2.push({
        start:ac.start,
        uri:ac.uri,
        method:ac.method,
        elapse:ac.elapse||0,
        idle:0,
      })
    }
    for(let i=0;i<laLen-1;i++){
      let ac0=la2[i]
      let ac1=la2[i+1]
      let diff=ac1.start-ac0.start
      let idle=diff-(ac0.elapse||0)
      if(idle<0){idle=0}
      ac0.idle=idle
    }
//     cl(la2)
    let rows=la2.map((ac,i)=>{
      let width=100*ac.elapse
      let idleWid=100*ac.idle
      let idleCol="#FFCCCC"
      if(idleWid>100){
        idleWid=50
        idleCol="#FFFFCC"
      }
      let elapse=Math.round(ac.elapse*1000)/1000
      let idle=Math.round(ac.idle*1000)/1000
      let tm=ac.start%3600
      let mn=Math.floor(tm/60)
      let sc=Math.round((tm%60)*1000)/1000
      let da=`${mn}:${sc}`
      return(
        <tr key={i}>
        <td>{da}</td>
        <td>{ac.uri}</td>
        <td>
          <div style={{display:"inline-block",
            width:width,height:10,backgroundColor:"#CCFFCC"}}
            title={`${elapse} secs`}
            >
          </div>
          <div style={{display:"inline-block",
            width:idleWid,height:10,backgroundColor:idleCol}}
            title={`idle ${idle} secs`}
            >
          </div>
        </td>
        </tr>
      )
    })
    return(
      <div>
        <table style={{width:"auto"}}><tbody>
          {rows}
        </tbody></table>
      </div>
    )
  }
  
  showStoredAccesses=()=>{
    let st=this.state
    let users=this.users.filter(u=>{return u.accountId==st.accountSel})
    let zones=this.zones.filter(z=>{return z.accountId==st.accountSel})
    return(
      <div>
        {this.showSelect("Account","accountSel",this.accounts,"accountId","name")}
        {this.showSelect("User","userSel",users,"userId","name")}
        {this.showFrom()}
        <br/>
        {this.showPeriod()}
        {this.showCheck("showBrowser","Browser")}
        {this.showCheck("showServer","Server")}
        <h2 style={{cursor:"pointer"}}
          onClick={e=>this.onChange("refresh")}
        >Accesses</h2>
        {this.showAccesses()}
      </div>
    )
  }
  
  render(){
    let st=this.state
//         {this.showSelect("Site","siteSel",this.sites,"siteId","name")}
//         {this.showSelect("Zone","zoneSel",zones,"zoneId","zoneName")}
    if(st.loaded){
      return(
        <div>
          {this.showLocalStoredAccesses()}
          {st.showLocalAccesses?
            <div>{this.showLocalAccesses()}</div>:
            <div>{this.showStoredAccesses()}</div>
          }
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}

export default C18PageAccesses00;
