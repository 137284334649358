import React from 'react';
import C18MuiInput from '../../usa/components/C18MuiInput'
import ReactHtmlParser from 'html-react-parser'
import {cl, globs} from '../../components/utils/utils';

class TextGeneral extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.updateFui()
    this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
  }

  updateFui=()=>{
    if(!this.changed){
//       cl(this.props.ind)
      this.props.getValue(this.props.ind, {type: "scalar"})
    }
  }
  
  savePageEvent=()=>{
    this.changed=false
  }

  componentWillUnmount=()=>{
    this.subscribe_updateFui.remove()
    this.subscribe_savePageEvent.remove()
  }

  onChange=(e)=>{
    this.changed=true
    this.props.onChange(this.props.ind,
      {value: e.target.value})
  }

  render(){
//     cl(this.props);
//     cl(this.props.value)
    // className="floatleft"
    let pr=this.props
//     pr.rest.parent({one:"two"})
//     cl(pr)
// //     let title=(true||globs.keyDowns["Alt"])?"Here I am!":""
//     let title="this is one\ntwo"
//     cl(title)
//     let title=<div>this is one<br/>two</div>
    return(
      <div id={this.props?.custom?.infoId}>
        <label>{this.props.title}</label>
        <C18MuiInput type="text"
        title={pr.rest.parent({uri:"pid",pr:pr})}
        id={`{"cmd":"watch","type":"${pr.type}","id":${pr.cont.pid},"zuci":"${pr.zuci}"}`}
        value={this.props.value}
        onChange={this.onChange}
        maxLength={this.props.custom.maxlength}
        saveOK={this.props.saveOK}
        />
      </div>
    );
  }
}

  export default TextGeneral ;
