import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MainBar from '../components/MainBar';
import ChannelType from './controls/ChannelType';
import FUISelect from './controls/FUISelect';
import Methods from './Methods';
import BiweeklyIrrigation from './controls/BiweeklyRepeat';
import FUINumerical from './controls/FUINumerical';
// import C18SaveHeader00 from '../usa/components/C18SaveHeader00'
import history from "../history"
import {saveFuiPage, deleteFuiPage, getTemplates} from '../components/utils/httpauth';
import {makeFuiPage, controls, chTypes} from './utilsFui';
import {wsTrans} from '../usa/utils/utils'
import {cl, constant, userSpecificHome} from '../components/utils/utils';
import {deq} from '../components/utils/default1800Eq'
import {globs, getTime,getTimeI} from '../components/utils/utils';
import {dbVals, init, sendArray, getLazyArray, getZValue, putZValue,getZoneControllers} from '../components/utils/http';
import {checkLogin} from '../components/utils/httpauth';
import {pi, pb, pInd} from '../components/utils/paramIds';
import {getParamId} from '../usa/utils/utils';
import {loadZonesInfo,loadSiteData, getSiteName,getZoneName,privs,getZoneId,
  loadSensorsInfo
} from '../usa/components/C18utils'
import {getDatapointName} from '../usa/utils/utils'
import {checkLoggedIn, getHomeDashboard, getPearlUsed} from '../usa/utils/utils'

class LiveFui extends React.Component{
  /* need to load the fui pages,
  get this page
  get all the site data
  parse the z-u-c-i field
  the create a default control,
  connect it to the pid info,
  and show it.
  */
  constructor(props) {
    super(props);
//     cl(props)
    cl.oci(this,[this.constructor.name],this.onChange)
//     cl(dbVals)
//     cl("Live Fui Constructor")
//     cl(props)
//     document.title="Fui Page";
    
//     cl(globs)
    // cl(this.props.match.params)
    this.values = []; // 1, 2, 3, 4, 5
    this.rest={parent:this.parentRest}
    this.zoneGroupValue=-1
    // this.types = [];// type of each value
    this.state = {
      loaded: false,
      pageTitle: "FUI Page2",
      values: this.values,
      title: "none",
      local: -1,
      rc: 0,
      saveFunctions:[],// a list of functions to call when the data is saved
      loadMsg: "loading. . .",
      updateInterval: setInterval(this.update,100000)// should be a few seconds
    }
    // cl(Methods.showit());
    // cl(this.state)
    // this.mySetState("loc16",{loaded: true});
    // cl("state set")
    // let fpPromise = getFuiPages();
//     cl("live fui checked logged in");
//     cl(globs.userData.session.siteId)
    this.checkLoggedIn();
//     cl(globs.userData.session.siteId)
    // Promise.all([clP, fpP]).then(r=>{
    //   cl("returned");
    //   this.mySetState("loc17",{loaded: true});
    // });
    // let selPage = "_sel";
    // var selPage;
    globs.backMsg = {type: "fui", pageType: this.props.match.params.pageType};
    this.subscribe_saveOK=globs.events.subscribe("saveOK",this.afterSave)
    this.subscribe_newContext=globs.events.subscribe("newContext",this.newContext)
    if(this.props.notify){
      this.props.notify({id: "newSensor", func: this.newContext})
      this.props.notify({id: "localSave", func: this.localSave})

    }
//     cl(props.parms)
//     props.parms.onChange({cmd: "savePage", data:{savePage: true}})//,saveEnable:false
//     cl(this.props)
//     if(this?.props?.parms?.onChange){
//       this.makeFuiBreadcrumbs()
//     }
  }
  page = {};
  mounted = false;
  
  update=()=>{
//     console.trace()
//     cl("update")
//     cl(this.state.updCount)
//     cl("update")
    globs.events.publish("updateFui")
    this.mySetState("loc18",{updCount:(this.state.updCount||0)+1})
//     this.setState({sensors:this.updateSensors()})
  }
  
  mySetState=(loc,vals)=>{
//     console.trace()
//     cl([loc,vals])
//     cl(loc)
    this.setState(vals)
  }
  
  componentDidMount=()=>{
//     cl("did mount")
//     window.addEventListener("beforeunload", this.handleWindowBeforeUnload);
  }
  
  componentWillUnmount=()=>{
    clearInterval(this.state.updateInterval)
    this.subscribe_saveOK.remove()
    this.subscribe_newContext.remove()
    if(this.props.notify){
      this.props.notify({id: "newSensor", func: this.newContext, unMount: true})
      this.props.notify({id: "localSave", func: this.localSave, unMount: true})
    }
//     window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
  }
  
//   handleWindowBeforeUnload=(e)=>{
//     e.preventDefault()
//     e.returnValue=""
//   }
  
  makeFuiBreadcrumbs=()=>{
//     cl("makeFuiBreadcrumbs")
//     cl("***********************************")
//     return
    let siteId=globs.userData.session.siteId
    let p=this.props.match.params
    let parts=p.zuci.split("-")
    let z=+parts[0]
    let ch=+parts[2]
    let chName=this.current.channelNames[ch]||"No Name"
    let zoneId=getZoneId(siteId,z)
    let zoneName=getZoneName(zoneId)
//     cl(zoneId)
    this.saveOK=privs("zone",zoneId,constant.AREA_PRIVS_WRITE)
//     cl(`Fui SaveOk: ${this.saveOK}`)
//     cl(this.saveOK)
    if(this.saveOK&&this.connected){
      this.props.parms.onChange({cmd: "savePage", data:{savePage: true}})//,saveEnable:false
    }
//     cl(p.pageType)
    if((p.pageType=="channel_Vent_Retractable_Roof")||
      (p.pageType=="zone_SP_Retractable_Greenhouse")
    ){
      this.props.parms.onChange({cmd: "fullWidth"})//,saveEnable:false
    }
    let siteName=getSiteName(siteId)
//     cl([siteId, siteName, zoneName])
//     cl(globs.zonesInfo)
//     cl(this.props)
//     cl(this.state)
    let pageType=p.pageType
    let zuci=p.zuci
//     cl([pageType,zuci])
    let zoneBCI=[
      {t:"Sites", url:"/usa/c18/sites"},
      {t:siteName, url:`/usa/c18/sites/${siteId}`},
      {t:zoneName, url:`/usa/c18/sites/${siteId}/zones/${zoneId}`},
    ]
//     let bc=zoneBCI.slice(0)
    let sensorBCI=[
      {t:"Sensor Settings", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/sensor1800`},
    ]
    let mappingBCI=[
      {t:"Sensor Settings", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/sensor1800`},
      {t:"Mapping", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/mapping`},
    ]
    let calibrationBCI=[
      {t:"Sensor Settings", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/sensor1800`},
      {t:"Calibration", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/calibration`},
    ]
    let zoneConfigBCI=[
      {t:"Equipment Settings", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/equipment1800`},
      {t:"Zone Configuration", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/zone_configuration`},
    ]
    let equipBCI=[
      {t:"Equipment Settings", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/equipment1800`},
    ]
    let channelBCI=[
      {t:chName, url:`/usa/c18/sites/${siteId}/zones/${zoneId}`},
    ]
    let fuiBCInfo={
      "unit_Analog_Temp_Mapping":[zoneBCI,mappingBCI,
        [{t:"Analog Temp", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/mapping`}],],
      "unit_Irrigation_Sensor_Mapping":[zoneBCI,mappingBCI,
        [{t:"Irrigation", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/mapping`}],],
      "unit_Input_Mapping":[zoneBCI,mappingBCI,
        [{t:"Basic Mapping", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/mapping`}],],
      "unit_Vent_Position_Mapping":[zoneBCI,mappingBCI,
        [{t:"Vent Position", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/mapping`}],],
      "unit_Mixing_Tanks":[zoneBCI,mappingBCI,
        [{t:"Mixing Tanks", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/mapping`}],],
      "unit_Generic_Mapping":[zoneBCI,mappingBCI,
        [{t:"Generic", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/mapping`}],],
      "unit_Network_Sensors":[zoneBCI,mappingBCI,
        [{t:"Network Sensors", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/mapping`}],],
      "unit_Accumulator":[zoneBCI,mappingBCI,
        [{t:"Accumulator", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/mapping`}],],
        
      "unit_Input_Calibration":[zoneBCI,calibrationBCI,
        [{t:"Basic Calibration", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/calibration`}],],
      "unit_Analog_Temp_Calibration":[zoneBCI,calibrationBCI,
        [{t:"Analog Temp", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/calibration`}],],
      "unit_Soil_Moisture_Calibration":[zoneBCI,calibrationBCI,
        [{t:"Soil Moisture", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/calibration`}],],
      "unit_Vent_Position_Calibration":[zoneBCI,calibrationBCI,
        [{t:"Vent Position", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/calibration`}],],
      "unit_Mixing_Tank_Calibration":[zoneBCI,calibrationBCI,
        [{t:"Mixing Tanks", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/calibration`}],],
      "unit_Generic_Calibration":[zoneBCI,calibrationBCI,
        [{t:"Generic", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/calibration`}],],

      "zone_Setpoints":[zoneBCI,sensorBCI,
        [{t:"Setpoints", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/sensor1800`}],],
      "setpoints":[zoneBCI,sensorBCI,
        [{t:"Setpoints", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/sensor1800`}],],
      "HumDeHum":[zoneBCI,equipBCI,
        [{t:"Hum DeHum", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/equipment1800`}],],
        
      "unit_Input_Multipliers":[zoneBCI,sensorBCI,
        [{t:"Input Multipliers", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/sensor1800`}],],
      "unit_Miscellaneous":[zoneBCI,sensorBCI,
        [{t:"Miscellaneous", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/sensor1800`}],],

      "zone_Stages":[zoneBCI,zoneConfigBCI,
        [{t:"Stage Settings", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/zone_configuration`}],],
      "zone_Fallback":[zoneBCI,zoneConfigBCI,
        [{t:"Fallback Settings", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/zone_configuration`}],],
      "zone_Output":[zoneBCI,zoneConfigBCI,
        [{t:"Output Settings", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/zone_configuration`}],],
      "zone_History":[zoneBCI,zoneConfigBCI,
        [{t:"History, etc.", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/zone_configuration`}],],
      "zone_Units":[zoneBCI,zoneConfigBCI,
        [{t:"Units", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/zone_configuration`}],],
      "zone_Irrigation":[zoneBCI,zoneConfigBCI,
        [{t:"Irrigation", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/zone_configuration`}],],
      "zone_Peristaltic":[zoneBCI,zoneConfigBCI,
        [{t:"Peristaltic", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/zone_configuration`}],],
      "zone_Lighting":[zoneBCI,zoneConfigBCI,
        [{t:"Lighting", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/zone_configuration`}],],
      "zone_Alarms":[zoneBCI,zoneConfigBCI,
        [{t:"Alarms", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/zone_configuration`}],],
      "zone_Deadband":[zoneBCI,zoneConfigBCI,
        [{t:"Deadband", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/zone_configuration`}],],
      "zone_H-C_Demand":[zoneBCI,zoneConfigBCI,
        [{t:"Heat, Cool Demand", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/zone_configuration`}],],

      "zone_Smartcool":[zoneBCI,equipBCI,
        [{t:"Smart Cool", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/equipment1800`}],],
        
      "zone_Smartcool":[zoneBCI,equipBCI,
        [{t:"Smart Cool", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/equipment1800`}],],
        
      "zone_SP_Drive_to_Avg":[zoneBCI,equipBCI,
        [{t:"Drive to Average", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/equipment1800`}],],
      "zone_SP_Influence_Factors":[zoneBCI,equipBCI,
        [{t:"Influence Factors", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/equipment1800`}],],
      "zone_SP_Retractable_Greenhouse":[zoneBCI,equipBCI,
        [{t:"Retractable Greenhouse", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/equipment1800`}],],
      "zone_Hum_DeHum":[zoneBCI,equipBCI,
        [{t:"Hum / DeHum", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/equipment1800`}],],
      "temp_Staging":[zoneBCI,equipBCI,
        [{t:"Temp Staging", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/equipment1800`}],],
      "zone_Aux_Controls":[zoneBCI,equipBCI,
        [{t:"Aux Controls", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/equipment1800`}],],
      "zone_Aux_Variables":[zoneBCI,equipBCI,
        [{t:"Aux Variables", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/equipment1800`}],],
      "zone_Aux_PVariables":[zoneBCI,equipBCI,
        [{t:"Aux Persistent Variables", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/equipment1800`}],],
      "zone_Aux_Alarms":[zoneBCI,equipBCI,
        [{t:"Aux Alarms", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/equipment1800`}],],
        
      "channel_None":[zoneBCI,channelBCI],
      "channel_On_Off":[zoneBCI,channelBCI],
      "channel_On_Off_1900":[zoneBCI,channelBCI],
      "channel_Irrigation_Scheduled":[zoneBCI,channelBCI],
      "channel_Irrigation_Accumulated_Light":[zoneBCI,channelBCI],
      "channel_Irrigation_Cycle":[zoneBCI,channelBCI],
      "channel_Irrigation_Trigger":[zoneBCI,channelBCI],
      "channel_Irrigation_Soil_Trigger":[zoneBCI,channelBCI],
      "channel_Irrigation_VPD":[zoneBCI,channelBCI],
      "channel_CO2":[zoneBCI,channelBCI],
      "channel_Light_Supplemental":[zoneBCI,channelBCI],
      "channel_Light_Scheduled":[zoneBCI,channelBCI],
      "channel_Light_Cyclic":[zoneBCI,channelBCI],
      "channel_Light_DLI":[zoneBCI,channelBCI],
      "channel_Microzone":[zoneBCI,channelBCI],
      "channel_Supply_Pump":[zoneBCI,channelBCI],
      "channel_Peristaltic_Batch_Pump":[zoneBCI,channelBCI],
      "channel_Peristaltic_Recirculating_Pump":[zoneBCI,channelBCI],
      "channel_Peristaltic_Balance_Pump":[zoneBCI,channelBCI],
      "channel_Fill_Valve":[zoneBCI,channelBCI],
      "channel_Vent_Roof":[zoneBCI,channelBCI],
      "channel_Vent_Retractable_Roof":[zoneBCI,channelBCI],
      "channel_Vent_Side_Wall":[zoneBCI,channelBCI],
      "channel_Vent_Roof_1900":[zoneBCI,channelBCI],
      "channel_Vent_Retractable_Roof_1900":[zoneBCI,channelBCI],
      "channel_Vent_Side_Wall_1900":[zoneBCI,channelBCI],
      "channel_Curtain":[zoneBCI,channelBCI],
      "channel_Mix_Valve":[zoneBCI,channelBCI],
      "channel_Proportional_Microzone":[zoneBCI,channelBCI],
      "channel_PID":[zoneBCI,channelBCI],
      "channel_PID_analog":[zoneBCI,channelBCI],
      "channel_On_Off":[zoneBCI,channelBCI],
      "channel_Variable_Out":[zoneBCI,channelBCI],
      "channel_Mix_Valve_Analog":[zoneBCI,channelBCI],
    }
    this.setPageTitle(this.page.title)
//     cl(this.page.title)
//     cl(title)
    if(p.pageType in fuiBCInfo){
      let bc=[].concat(...fuiBCInfo[p.pageType])
//       cl("LiveFui updating BreadCrumbs")
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: bc,
              pageTitle:this.page.title,
            },
        },
      )
    }
  }
  
/*
fui pages:
  zone_Stages
  zone_Fallback
  zone_Output
  zone_History
  zone_Units
  zone_Irrigation
  zone_Peristaltic
  zone_Lighting
  zone_Alarms
  zone_Deadband
  zone_H-C_Demand

  zone_Smartcool
  zone_Setpoints
  zone_SP_Drive_to_Avg
  zone_SP_Influence_Factors
  zone_SP_Retractable_Greenhouse
  zone_Hum_DeHum
  zone_Aux_Controls

zone_Pump_Schedule
zone_Sensors
  unit_Input_Mapping
  unit_Analog_Temp_Mapping
  unit_Irrigation_Sensor_Mapping
  unit_Vent_Position_Mapping
  unit_Mixing_Tanks
  unit_Generic_Mapping
  unit_Network_Sensors
  unit_Accumulator
  unit_Input_Calibration
  unit_Analog_Temp_Calibration
  unit_Soil_Moisture_Calibration
  unit_Vent_Position_Calibration
  unit_Mixing_Tank_Calibration
  unit_Generic_Calibration
  unit_Input_Multipliers
  unit_Miscellaneous

channel_Irrigation_Scheduled
channel_Irrigation_Accumulated_Light
channel_Irrigation_Cycle
channel_Irrigation_Trigger
channel_Irrigation_Soil_Trigger
channel_Irrigation_VPD
channel_CO2
channel_Light_Supplemental
channel_Light_Scheduled
channel_Light_Cyclic
channel_Light_DLI
channel_Microzone
channel_Supply_Pump
channel_Peristaltic_Batch_Pump
channel_Peristaltic_Recirculating_Pump
channel_Peristaltic_Balance_Pump
channel_Fill_Valve
channel_Vent_Roof
channel_Vent_Retractable_Roof
channel_Vent_Side_Wall
channel_Curtain
channel_Mix_Valve
channel_Proportional_Microzone
channel_PID
channel_Variable_Out
*/

  getZoneInfo=()=>{
    this.siteId=globs.userData.session.siteId
//     cl("get zone info")
//     cl(this.siteId)
//     cl(this.zone)
    if(!this.zoneInfo){
      this.zoneInfo=globs.zonesInfo.info.filter(z=>{
        return ((z.siteId==this.siteId)&&(z.siteZoneIndex==this.zone))})[0]
    }
//     cl(this.zoneInfo)
  }

  checkLoggedIn=async(fpPromise)=>{
//     cl("this check")
    this.getZuci()
    await loadZonesInfo()
//     cl(globs.userData.session)
//     cl("this check")
//     cl(globs.zonesInfo.info)
//     cl(this.zone)
    this.getZoneInfo()
//     this.zoneInfo=globs.zonesInfo.info.filter(z=>{
//       return ((z.siteId==this.siteId)&&(z.siteZoneIndex==this.zone))})[0]
//     cl("this check")
//     cl(this.zoneInfo)
//     cl(this.zoneInfo)
//     cl(this.props)
//     await loadSensorsInfo()
//     cl("check")
//     cl(dbVals.gotSite)
//     cl([this.siteId,this.zoneInfo])
//     cl(this.zoneInfo)
    if ((globs.token === "") || (!dbVals.gotSite)){
//       cl("check2")
//       let gt = getTemplates();
//       cl(globs.userData.session.siteId)
      let chl = await checkLoggedIn();
//       cl(globs.userData.session)
//       cl(globs.zonesInfo.info)
//       cl(globs.userData.session.siteId)
//       cl("load site")
      await loadSiteData(globs.userData.session.siteId)
//       cl("load site done")
      await loadSensorsInfo()
//       cl("load sensors done")
      
      // checkLogin().
      Promise.all([/*gt, */chl]).then(r=>{
//         cl(r);
        init().then(r=>{// now, init does fuiPages, too
//           cl("initted")
//           if ((dbVals.siteAuthorized) &&
//               (r[0].command === "gotcursite00")){
//             cl("load fui pages")
            this.loadFuiPage();
//           cl(this.page.template);
            this.template = globs.templates[this.page.template];
//             cl(this.template);
            this.mySetState("loc1",{
              loaded: true,
              title: this.page.title,
              template: this.page.template,
              current: this.current,
            });
//           } else {
//             this.mySetState("loc2",{loadMsg: "Not Authorized"})
//           }
//             cl(dbVals)
        });
      });
    }else{// everything has already been loaded
//       cl("loaded")
      await this.loadFuiPage();
//       cl("loaded fui")
//       cl(this.mounted)
      if(this.mounted){
        this.mySetState("loc3",{
          loaded: true,
          title: this.page.title,
          template: this.page.template,
          current: this.current,
        });
        this.template = globs.templates[this.page.template];
      }else{
        this.state.loaded = true;
        this.state.title = this.page.title;
        this.state.template = this.page.template
        this.state.current = this.current;
  //       cl(this.page);
        this.template = globs.templates[this.page.template];
      }
//       cl(dbVals)
      // cl("set")
    }
    // cl(this.current)
  }

  makeCurrentAuxVarNames=()=>{
    let tab = "config_aux_variables";
    let base = pInd[1800][tab][0];
    let coef = pInd[1800][tab][2];
    let count = pInd[1800][tab][3];
    let z = this.zone ;
    let c = 240 + this.unit ;
    let ofs = pi[1800][tab]["variableName"];
    let pid = base + ofs;
    let auxNames = [];
    for (let i = 0 ; i < count ; i ++){
      auxNames.push(getZValue(z, c, pid + i * coef))
    }
    return auxNames
    // cl(names)
  }

  makeCurrentMixTanks=()=>{
    let tab = "config_ecph"
    let col = "name"
    let base = pInd[1800][tab][0];
    let coef = pInd[1800][tab][2];
    let count = pInd[1800][tab][3];
    // cl(base, coef, count)
    let z = this.zone ;
    let c = 192 ;
    let ofs = pi[1800][tab][col];
    let pid = base + ofs;
    let ret = [];
    for (let i = 0 ; i < count ; i ++){
      ret.push(getZValue(z, c + i, pid))
      // cl([z, c+i, pid])
    }
    return ret
  }

  makeCurrentHeatCoolStages=()=>{
    let tab = "zone_configuration_settings"
    let col = "Heat stages"
    let base = pInd[1800][tab][0];
    let z = this.zone ;
    let c = 255 ;
    let ofs = 2 * pi[1800][tab]["Heat stages"] + 1;
//     let ofs = pi[1800][tab]["Heat stages"];
    let h = +getZValue(z, c, base + ofs)||1
    ofs = 2 * pi[1800][tab]["Cool stages"] + 1;
    let co = +getZValue(z, c, base + ofs)||1
//     cl(h,co)
    return [h, co]
  }

  makeCurrentUnits=()=>{
    /*units:
    deg f, c
    mph, kph,
    w/m2, klux, uMol
    L/ml, gal/oz, gal/ml
    uS, CF, PPM
    */
    let tab = "zone_configuration_settings"
    let units = ["Temperature Units", "Windspeed Units",
      "Light Units", "Volume Measurement Units",
      "Nutrient Units"];
    let ret = [];
//     cl(this.zone)
    units.forEach((col, i)=>{
      let base = pInd[1800][tab][0];
      let z = this.zone ;
      let c = 255 ;
      let ofs = 2 * pi[1800][tab][col] + 1;
//       let ofs = pi[1800][tab][col]// the zone config table has changed in paramIds
//       cl([z,c,base+ofs])
      let v=getZValue(z, c, base + ofs)
//       cl(v)
      ret.push(v);
//       cl(dbVals.z[z])
    })
//     cl(ret)
    return ret;
  }

  getTabColInfo=(tab, col)=>{
    // pInd[1800].config_aux_variables =
    // [p.PID_BASE_CONFIG_AUX_VARIABLES, 0, 4, 64];//3640 4 * 64
    // let base = pInd[1800][tab][0];
    // let type = pInd[1800][tab][1];
    // let ofs = pi[1800][tab][col];
    return [...pInd[1800][tab], pi[1800][tab][col]]

  }

  getDBParmValue=(z, u, c, idx, pid, type, k)=>{
    let [z1, c1, i1] = this.getDBParms(z, u, c, idx, pid, type, k);
//     cl(i1)
    let defVal=(this.pidToDef||{})[i1]
//     if(pid==782){
//       cl(defVal)
//     }
    var tmp
    if(defVal==undefined){
      tmp=getZValue(z1, c1, i1)
    }else{
      tmp=defVal
//       delete this.pidToDef[i1]
    }
    return tmp//(tmp)?tmp:0
  }

  makeCurrentChannelNames=()=>{
    let names = [];
    let channelInfo=[]
    let cols=["channelName", "used", "isAnalog", "channelType",
      "stageDehumidfy1", "stageDehumidfy2", "stageColdDehumidfy", "stageHumidfy"]
    let offsets=[]
    var base, type, ofs
    cols.forEach(c=>{
      [base, type, , , ofs] =
          this.getTabColInfo("channels_configuration", c);
      offsets.push(ofs)
    })
//     cl([this.zone,this.unit,base,offsets[6],type])
//     cl(this.unit)
//     cl(this.zoneInfo)
    for (let i = 0 ; i < 40 ; i++){
      names.push(this.getDBParmValue(
          this.zone, this.unit,
          i, 0, base + offsets[0], type, 0));
      let obj={}
      cols.forEach((c,j)=>{
        obj[c]=this.getDBParmValue(
            this.zone, this.unit,
            i, 0, base + offsets[j], type, 0);
      })
      channelInfo.push(obj)
//       cl(obj)
    }
    if(this.zoneInfo?.gatewayType==1900){
      let pUsed=getPearlUsed(this.zone)
      pUsed.forEach(pu=>{
        channelInfo[pu.chInd].used=true
      })
//       cl(pUsed)
    }
//     cl(channelInfo)
    if(channelInfo[this.chan]){this.chanTypeOrig=channelInfo[this.chan].channelType}
    return [names, channelInfo];
  }

  makeCurrentFillValves=()=>{
    let valves = [];
    let [base, type, , , ofs] =
        this.getTabColInfo("channels_configuration", "channelType");
    for (let i = 0 ; i < 40 ; i++){
      let chType = this.getDBParmValue(
          this.zone, this.unit,
          i, 0, base + ofs, type, 0);
      // cl(chType)
      if (chType*1 === constant.EQ_FVALVE){
        valves.push(i);
      }
    }
    return valves ;
  }

  makeCurrentMasterPumps=()=>{
//     cl("make current master")
    /*Wow! Ugly much?
    this goes through the channels for the current zone
    and unit. For each "used" channel, it checks the type
    For each "Pump" type, it checks what *kind* of pump
    if  peristaltic batch, then it checks if it's a master
    If it's a master, then it saves it to the masters array
    that will be in current settings */
    let tab = "channels_configuration";
    let [base, type, , , ofs] =
        this.getTabColInfo(tab, "channelType");
    let ofs2 = this.getTabColInfo(
        tab, "used")[4];
    let masters = [];
    for (let i = 0 ; i < 40 ; i++){// got through the channels
      let used = this.getDBParmValue(
          this.zone, this.unit,
          i, 0, base + ofs2, type, 0);
      if (used*1 !== 0){// if used
        let chType = this.getDBParmValue(
            this.zone, this.unit,
            i, 0, base + ofs, type, 0);
        if (chType*1 === constant.EQ_PUMP){// if pump
          let [base3, type3, , , ofs3] =
              this.getTabColInfo(
                  "config_channels_configuration", "pump_type");
          let pumpType = this.getDBParmValue(
              this.zone, this.unit,
              i, 0, base3 + ofs3, type3, 0);
          if (pumpType*1 === constant.PUMP_PERISTALTIC_BATCH){// if batch
            let [base4, type4, , , ofs4] =
                this.getTabColInfo(
                    "config_channels_configuration", "feedingMode");
            let feedMode = this.getDBParmValue(
                this.zone, this.unit,
                i, 0, base4 + ofs4, type4, 0);
            if (feedMode*1 === 0){// if is a master
              masters.push(i);
            }
            // cl(i, feedMode)
          }
        }
      }
    }
    return masters;
  }

  makeCurrent=(unit)=>{
//     console.trace()
//     cl(this.state)
//     cl(this.props)
//     cl(this.zone)
    this.unit=unit//parms.body.unit
    let [h, c] = this.makeCurrentHeatCoolStages();
//     cl(this.zoneInfo)
    let [names, info]=this.makeCurrentChannelNames()
//     cl(names)
//     cl(this.props)
//     cl(this.state)
//     cl(this.unit)
//     cl(this.zoneInfo)
    let virt=this.zoneInfo?.virtual
//     cl(virt)
    this.current = {
      auxNames: this.makeCurrentAuxVarNames(),
      tankNames: this.makeCurrentMixTanks(),
      units: this.makeCurrentUnits(),
      masters: this.makeCurrentMasterPumps(),
      fillValves: this.makeCurrentFillValves(),
      channelNames: names,//this.makeCurrentChannelNames(),
      zoneControllers: getZoneControllers(this.zone),
      gwType:this.zoneInfo?.gatewayType||1800,
      gwId:this.zoneInfo?.gatewayId,
      channelInfo: info,
      heatStages: h,
      coolStages: c,
      virtual:virt,
      gwType:this.zoneInfo?.gatewayType||1800,
    }
//     cl(this.current.channelInfo[0].channelName)
//     cl(this.current.channelNames[0])
//     cl(this.current)
  }
  
  makeCurrentRest=async(parms)=>{
    this.makeCurrent(parms.body.unit)
    await this.mySetState("makeCurrentRest",{current:this.current})
//     cl(this.state.current.channelInfo[0].channelName)
//     cl("make current done")
  }
  
  getSettingsRest=async(parms)=>{
//     cl(this.state)
//     cl(parms)
    return({controls:this.page.controls,values:this.state.values})
//     return "settings"
  }
  
  setValuesRest=async(parms)=>{
//     cl("set Values")
//     cl(parms)
//     this.props.parms.onChange({cmd: "savePage", data:{savePage: true}})
    globs.events.publish("savePageEnable",true)
    this.mySetState("setValuesRest",{values:parms.values})
  }

  showPid=(parms)=>{
    let co=parms.pr.cont
    if(co.pid<0){return}
//     cl(parms)
    if(co.type=="channelType"){co.pid=508}
//     cl(this.zoneInfo)
    let dpName=getDatapointName(co.pid,this.zoneInfo.gatewayType||1800)
//     cl(dpName)
    let str=`Pid is ${co.pid}\n${dpName}`
//     cl(str)
    return str

  }
  
  parentRest=(parms)=>{
//     cl(parms)
    let cmds={makeCurrent:this.makeCurrentRest,
      getSettings:this.getSettingsRest,
      setValues:this.setValuesRest,
      pid:this.showPid,
    }
    if(cmds[parms.uri]){return cmds[parms.uri](parms)}
  }
  
//   getConnected=(z)=>{
// //     cl(globs.zonesInfo.info)
//     cl(globs.zonesInfo.sz2z[globs.userData.session.siteId][z])
//     return globs.zonesInfo.sz2z[globs.userData.session.siteId][z].connected
//     
//   }

  getZuci=()=>{
//     cl(this.props)
    let zuci = this.props.match.params.zuci;
//     cl(zuci)
    let parts = zuci.split('-');
    this.zone = parts[0]*1;// site zone index
    this.unit = parts[1]*1;
    this.chan = parts[2]*1;
    // if (this.chan === 0) this.chan = 192;
    this.indx = parts[3]*1;
//     cl(globs.zonesInfo.sz2z[globs.userData.session.siteId][this.zone])
//     cl(globs.zonesInfo)
//     cl(globs.userData)
//     cl(this.zone)
//     cl(globs.zonesInfo.sz2z)
//     cl(globs.userData.session.siteId)
//     cl(this.zone)
    this.connected=true||globs.zonesInfo.sz2z[globs.userData.session.siteId][this.zone].connected
  }
  
  getControllerType=()=>{// from zuci info
    let virtVers={link4:"L4",modbus:"MB",idoser:"ID",expLink4:"L4",pearl:"PL"}
    let tab=pInd[1800].config_controllers
    let id=tab[0] + pi[1800].config_controllers["igrowVersion"]
//     cl(this.zoneInfo)
    var contVers,type
    if((this.zoneInfo||{}).virtual){
      type=virtVers[this.zoneInfo.virtualType]
//       cl(contVers)
    }else{
      contVers=getZValue(this.zone, 240+this.unit, id)||""
      type="L4"
      if(contVers){
        if(contVers.indexOf("MB")>0) {type="MB"}
        if(contVers.indexOf("ID")>0) {type="ID"}
      }
    }
//     cl(type)
    this.contType=type
    this.virtType=this.zoneInfo?.virtualType
//     cl(this.contType)
  }

  makeControlDict=()=>{
    // cl(this.page.controls);
    this.page.contDict = {};
    this.page.controls.forEach((c, i)=>{
      // cl(c.name);
      this.page.contDict[c.name] = i;
    });
    // cl(this.page.contDict);
  }
  
  getChannelName=()=>{
    
  }
  
  setPageTitle=(title)=>{
//     console.trace()
//     cl(title)
//     console.trace()
//     cl(title)
//     cl(this.props)
//     cl(this.state)
//     cl(this.chan)
//     cl(this.unit)
    let ex=(this.unit)?`E${this.unit}-`:""
    let chan=this.chan
//     cl(this.zoneInfo)
    if((chan>=12)&&(this.zoneInfo?.gatewayType!=1900)){chan-=4}
    let name=(this.current.channelNames||[])[this.chan]||"No Name"
    if(this.chan<40){ title=`${ex}Channel ${chan+1} ${name}` }
    if(this.connected){
      if(!this.saveOK){title=`${title} (READ ONLY)`}
    }else{
      title=`${title} (Offline)`
    }
    document.title=title
    this.page.title=title
//     cl(title)
    this.mySetState("loc4",{pageTitle: title,current:this.current,values:this.values})
  }
  
  doPageOverrides=()=>{
    let ov=this.props.parms.overrides
//     cl(ov)
//     cl(this.page)
    this.page.controls.forEach((co,i)=>{
      let thisOv=co.customProps.override
      if(thisOv){
//         cl(thisOv)
        let pid=ov[thisOv]
//         cl(pid)
        if(pid){co.pid=pid}// override the set pid
      }
    })
  }

  loadFuiPage=()=>{
//     cl("loadFuiPage")
//     cl("load fui page")
//     console.trace()
//     console.trace()
//     cl("load fui")
    // cl(globs)
//     cl(this.props)
    this.getZoneInfo()
    let pageType = this.props.match.params.pageType;
//     cl(`Load Fui Page: ${pageType}`)
    this.getZuci();
    this.getControllerType();
//     globs.events.publish("setMainBarTitle", this.page.title)
    

//     cl(parts)
//     cl(zuci)
//     cl(this.indx)
//     cl(globs.fuiPages);
//     cl(pageType)
//     cl((globs.fuiPages||{})[pageType])
//     cl(pageType)
//     cl(globs.fuiPages[pageType])
//     cl(globs.fuiPages)
    if ((!globs.fuiPages)||(globs.fuiPages[pageType] === undefined)) {
      makeFuiPage(pageType);
    }
//     cl(globs.fuiPages)
//     cl(pageType)
//     cl(this.page)
    this.page = Object.assign({}, globs.fuiPages[pageType]);
//     cl(this.page)
    if(this.props.parms.overrides){this.doPageOverrides()}
//     cl(this.page)
    
//     cl(this.page);
    this.makeControlDict();
//     cl("call make")
//     cl(this.unit)
//     cl(this.zoneInfo)
    this.makeCurrent(this.unit);
//     this.getControlValues();
//     this.page.contDict["Zone Name"] = "now"
//     cl(this.page.contDict["Zone Name"])
//     cl(this?.props)
//     cl(this?.props?.parms)
//     cl(this?.props?.parms?.onChange)
//     cl(this.page)
    if(this?.props?.parms?.onChange){
//       cl("make bread")
      this.makeFuiBreadcrumbs()
//       cl("bread done")
    }
    this.setPageTitle(this.page.title)// sets state with page title
//     cl("fui page loaded")
  }

  showTitle=()=>{
//     cl(this.state.title)
    return(
        <h1>{this.state.title}</h1>
    );
  }

  onTextChange=(e)=>{
    let indx = e.target.id.split('-')[1]*1;
    // this.values = this.state.values.slice(0);
    this.values[indx] = e.target.value;
    cl("set state")
    this.mySetState("loc5",{values: this.values})
  }

  // getZValue=(z, c, i)=>{
  //   let d = null;
  //   try{
  //     let zone = dbVals.z[z];
  //     try{
  //       let chan = zone[c];
  //       try{
  //         return chan[i];
  //       }catch{}
  //     }catch{}
  //   }catch{}
  // }
  //
  // putZValue=(z, c, i, d)=>{
  //   cl("put: "+z+"-"+c+"-"+i+"-"+d)
  //   try{
  //     let zone = dbVals.z[z];
  //     try{
  //       let chan = zone[c];
  //       try{
  //         cl(chan[i])
  //         chan[i] = d;
  //       }catch{}
  //     }catch{}
  //   }catch{}
  // }
  //
  
/*this should use getParmInfo in /usa/utils/utils*/
  getDBParms=(z, u, c, idx, pid, type, k)=>{//i,
//     cl([z, u, c, idx, pid, type, k])
//     if(pid==5201){cl([z, u, c, idx, pid, type, k])}
//     cl(globs.userData.session.siteId)
    var z1, c1, i1;
    switch(type){
      case 0:
        z1 = z;
        c1 = 240 + u; // zone wide
//         i1 = pid;
        i1 = pid + idx * k;// type 0 can be indexed, too
//         if(pid==5201){cl(i1)}
        
        break;
      case 1:// snap chan data
        z1 = z;
        c1 = 40 * u + c; // zone wide
        i1 = pid;
        break;
      case 2:// snap ecph
        z1 = z;
        // c1 = 192 + 8 * u + idx; // zone wide
        c1 = c + 8 * u// + idx; // zone wide
        i1 = pid;
        break;
      case 3:// conf ecph sensors - idx is tank
        z1 = z;
        // c1 = 192 + 8 * u + idx; // zone wide
        c1 = c + 8 * u; // + idx; // zone wide
        i1 = pid + idx * k;
        break;
      case 4:// zone wide, let setpoints
        z1 = z;
        c1 = 255; // zone wide
        i1 = pid + idx * k;
        break;
      case 5:// sitewide
        z1 = 255;
        c1 = 0;
        i1 = pid;
        break;
      case 6:// zone type
        z1 = z;
        c1 = 255; // zone wide
        i1 = pid;
        break;
      case 7:// controller settings
        z1 = z;
        c1 = 240 + u; // zone wide
        i1 = pid;
        break;
      default:
        break;
    }
    return [z1, c1, i1];

  }

  // putControlValue=(z, u, c, idx, pid, type, k, val)=>{
  //   let [z1, c1, i1] = this.getDBParms(z, u, c, idx, pid, type, k);
  //   let d = putZValue(z1, c1, i1, val);
  // }

  // getControlValue=(z, u, c, idx, pid, type, k)=>{//i,
  //   // cl([i, z, u, c, idx, pid, type, k])
  //   // var z1, c1, i1;
  //   // switch(type){
  //   //   case 0:
  //   //     z1 = z;
  //   //     c1 = 240 + u; // zone wide
  //   //     i1 = pid;
  //   //     break;
  //   //   case 1:// snap chan data
  //   //     z1 = z;
  //   //     c1 = 40 * u + c; // zone wide
  //   //     i1 = pid;
  //   //     break;
  //   //   case 2:// snap ecph
  //   //     z1 = z;
  //   //     c1 = 192 + 8 * u + idx; // zone wide
  //   //     i1 = pid;
  //   //     break;
  //   //   case 3:// conf ecph sensors - idx is tank
  //   //     z1 = z;
  //   //     c1 = 192 + 8 * u + idx; // zone wide
  //   //     i1 = pid;
  //   //     break;
  //   //   case 4:// zone wide, let setpoints
  //   //     z1 = z;
  //   //     c1 = 255; // zone wide
  //   //     i1 = pid;
  //   //     break;
  //   //   case 5:// sitewide
  //   //     z1 = 255;
  //   //     c1 = 0;
  //   //     i1 = pid;
  //   //     break;
  //   //   case 6:// zone type
  //   //     z1 = z;
  //   //     c1 = 255; // zone wide
  //   //     i1 = pid;
  //   //     break;
  //   //   case 7:// controller settings
  //   //     z1 = z;
  //   //     c1 = 240 + u; // zone wide
  //   //     i1 = pid;
  //   //     break;
  //   //   default:
  //   //     break;
  //   // }
  //   // cl([z1, c1, i1])
  //   // cl(240 + "0");
  //   // cl(240 + u)
  //   // cl(typeof(u));
  //   let [z1, c1, i1] = this.getDBParms(z, u, c, idx, pid, type, k);
  //   // cl([z1, c1, i1])
  //
  //   let d = getZValue(z1, c1, i1);
  //   cl([z1, c1, i1, d])
  //   // cl(d);
  //   return d;
  // }

  getControlValues=()=>{// I don't think this works, and I don't know why it's here'
    /*use the z, u, c, i, pid, k, and tableType to get the
    current value*/
//     cl(this.state)
    // cl(this.page);
//     cl("get control vals")
    let values = this.state.values.slice(0);
    cl(values)
//     cl(values[5]?.value)
    this.page.controls.forEach((c, i)=>{
      cl(c)
      let val = this.getDBParmValue(this.zone, this.unit,
          this.chan, this.getControlIndex(c), c.pid, c.tableType, c.k);
//       if(i==5){cl(val)}
//       cl([this.zone, this.unit, this.chan, this.getControlIndex(c), c.pid, c.tableType, c.k])
//       values[i] = val;
//       if(i==0){cl(val)}
//       cl(this.zone,this.unit,this.chan)
//       cl([this.getControlIndex(c), c.pid, c.tableType, c.k])
      if((c.pid!=-1)&&(val||(val==0))){
        val=13
        if((values[i]||{}).type){values[i].value=val}else{values[i]=val}
      }
      // cl(c);
    });
    // cl("set")
//     cl(values)
    if (this.mounted){
//       cl("set state")
//       cl(values)
//       cl(values[5]?.value)
      this.mySetState("loc6",{values: values})
    }else{
      this.state.values = values;
    }

  }

  // values=[1, 2, 3, 4, 5];

  showOneText=(c, i)=>{
    // cl(i)
    return(
        <div>
          {c.title}<br/>
          <input
              type="text"
              value={this.state.values[i]}
              onChange={this.onTextChange}
              id={"t-" + i}
          />
        </div>
    );

  }

  setValue=(i, v)=>{// v has the type and value
    /* now, each of the values in the array is an
    object that includes the type*/
//     cl(this.values[i])

//     cl(v)
//     cl(v.value)
//     cl(v.value.overrides)
    if(v?.value?.overrides){v.overrides=v.value.overrides}
//     cl(v)
    // let values = this.state.values.slice(0);
    // let vO =
    this.values[i] = v;
//     cl(this.values[i].value)
//     cl(v)
//     cl(this.values)
    // cl(val)
    // cl(this.values)
    // this.mySetState("loc7",{values: this.values});
    // cl(this.values)
  }

  getChannelTypePids=(chTypeId)=>{// the pid of the eq subtype
    // cl(chTypeId)
    let modes = ["", "", "irrigation_mode", "",
      "light_mode", "", "pump_type", "", "vent_type", "", "", "", ""];// was vent_mode
    let z0 = this.zone;
    let u0 = this.unit;
    let c0 = this.chan;
    let i0 = this.indx;
    let tab = "channels_configuration";
    let base = pInd[1800][tab][0]
    let type = pInd[1800][tab][1]
    let coef = pInd[1800][tab][2]
    let ofs = pi[1800][tab]["channelType"];// ids for each column
    let pid1 = base + ofs;
    let modeType = Math.floor(chTypeId / 10)
    let dbp1 = this.getDBParms(z0, u0, c0, i0, pid1, type, coef)
    var dbp2 = null;
    if ((modes[modeType]||"") !== ""){// has mode
      tab = "config_channels_configuration";
      base = pInd[1800][tab][0]
      ofs = pi[1800][tab][modes[modeType]]
//       cl(modes)
//       cl(modeType)
//       cl(tab)
//       cl([base,ofs])
      let pid2 = base + ofs;
//       cl(pid2)
      dbp2 = this.getDBParms(z0, u0, c0, i0, pid2, type, coef)
      // cl(dbp2)
    }
    return [dbp1, dbp2]
    // cl(dbp1)
  }

  secondType=(type)=>{
//     cl(type)
    let seconds={}
      seconds[constant.EQ_VENT]=constant.EQ_2ND_VENT
      seconds[constant.EQ_CURT]=constant.EQ_2ND_CURT
      seconds[constant.EQ_MIXV]=constant.EQ_2ND_MIXV
      seconds[constant.EQ_MZONE2]=constant.EQ_2ND_MZONE2
      seconds[constant.EQ_GEN_PID]=constant.EQ_2ND_GEN_PID
//       cl(seconds)
//       cl(type)
//       cl(seconds[type])
    return seconds[type]||constant.EQ_NONE
  }
// proportional:
// EQ_VENT = 8
// EQ_CURT = 9
// EQ_MIXV = 10
// EQ_MZONE2 = 11
// EQ_GEN_PID = 12
// 
// EQ_2ND_VENT = 19
// EQ_2ND_CURT = 20
// EQ_2ND_MIXV = 21
// EQ_2ND_MZONE2 = 22
// EQ_2ND_GEN_PID = 23
// EQ_2ND_CRAVO = 24

  setSecondChannel=(arr,type)=>{
//     cl("set second channel")
//     cl(type)
//     cl(this.chanTypeOrig)
//     cl([arr,type])
//     cl(arr[1])
//     cl(this.chanTypeOrig)
    let orig=this.secondType(this.chanTypeOrig)
    let now=this.secondType(type)
//     cl([orig,now])
    if(now!=orig){
      let x=arr[0]
//       cl(x)
      if(!now){now=constant.EQ_NONE}
      if (putZValue(x.z,x.c+1,x.i,now)){
        arr.push({z:x.z, c:x.c+1, i:x.i, t:x.t, d:now})
      }
      
//       cl(arr)
//       cl("publish")
//       globs.events.publish("readWidgetData")// re-read if widget displayed
    }
//      cl(arr[0])
//      cl(arr[1])
  }
  
  sendArrayComplete=(r)=>{
    cl(r)
    globs.events.publish("saveOK",true)
  }
  
  addDefaultEquipmentData=(arr,z,c,channelType)=>{
//     cl("addDefaultEquipmentData")
    let gwType=this.zoneInfo.gatewayType||1800
//     cl(gwType)
//     cl("add default data")
    let types=["none","onoff","irr","co2","light","mzon","pump","fval","vent",
      "curt","mixv","pmz","gpid","","","","","","varout","vent2"/*19*/,
      "","","","","","","","","mixva",
    ]
// pi[1800].config_channels_configuration["activeCool_cool2"] = 1
    let ty=types[channelType]
//     cl(ty)
    let defs=deq[ty]
//     cl(defs)
    let base=pb[gwType].config_channels_configuration
    let now=getTimeI()
    Object.keys(defs).forEach(k=>{
//       cl(k)
//       cl(gwType,pi[gwType],k)
      let pid=base+pi[gwType].config_channels_configuration[k]
//       cl(base,gwType,pi[gwType])
      if(isNaN(pid)){
//         cl(gwType)
//         cl(pi[gwType])
//         cl(pi[gwType].config_channels_configuration)
//         cl(pi[gwType].config_channels_configuration[k])
//         cl(`got NaN: ${k}`)
      }else{
        let d=defs[k]
        arr.push({z:z, c:c, i:pid, t:now, d:d, f:2})
      }
    })
//     this.addDefaultOverrides(arr,z,c)
//     cl(arr)
  }
  
  initChannelTypeData=(arr,z,c)=>{
//     cl(c)
    let now=Math.floor(getTime())
//     for(let i=1;i<=21;i++){// the Celano Reversion
    for(let i=1;i<=20;i++){
      let ind=pInd[1800].channels_configuration[0]+i// PID_BASE_CONF_CHANNELS+i
      var data=0
      if(![506,507,508,509].includes(ind)){// isAnalog,chname , chType
//         cl(ind)
//         let data=(ind==509)?"{}":0//chData
        if (ind==505){data=1}// used
        arr.push({z:z, c:c, i:ind, t:now, d:data, f:2})
      }
    }
  }

  saveChannelTypeToDb=(c, vo, force,localOnly)=>{
/*this needs to save both the channelType, and
the channelData type
the page is going to have to get this information
too, when it is loaded
actually, that is being done, in getChannelType, in
Channel.js */
//     cl("saving channel type")
//     cl(c)
//     cl(vo)
    // cl(this.props)
//     cl("saveChannelTypeToDb")
    this.pidToDef={}// should be done with the defaults by this point
    let type = this.page.type//this.props.match.params.pageType;
//     cl(type)
    // cl(chTypes[type])
//     cl(chTypes)

//     cl(type)
    if(type.slice(-5)=="_1900"){
      type=type.substring(0,type.length-5)// remove the _1900
    }
    
    let chTypeId = chTypes[type].id;
//     cl(chTypeId)
    
    let channelType = Math.floor(chTypeId / 10);
    let channelMode = chTypeId % 10;
//     this.channelSecond=this.secondType(channelType)
//     cl([channelType, channelMode, this.channelSecond])
    let chInfo = this.getChannelTypePids(chTypeId);// returns [z,c,i]
//     cl(chInfo)
    let arr = []
//     cl("save chan type")
//     cl(channelType)
//     cl(force)
    if (force||putZValue(...chInfo[0], channelType)){
      
      let [z, c, i] = chInfo[0];
//       cl([z,c,i])
      arr = [({z: z, c: c, i: i, f:2,
        t: Math.floor(getTime()), d: channelType})];
//       cl(chTypeId)
      if(chTypeId!=121){this.setSecondChannel(arr, channelType)}// analog pid
      this.addDefaultEquipmentData(arr,z,c,channelType)
      this.initChannelTypeData(arr,z,c)
    }
    if (chInfo[1] !== null){
//       cl(chInfo)
      if (force||putZValue(...chInfo[1], channelMode)){
        let [z, c, i] = chInfo[1];
        arr.push(({z: z, c: c, i: i,
          t: Math.floor(getTime()), d: channelMode}));
      }
    }
//     cl(arr)
    if (arr.length > 0){
//       cl("send")
//       cl(arr)
//       cl(arr[9])
      let zi=this.zoneInfo
      let arr2=[]
      arr.forEach(a=>{
        if(putZValue(a.z,a.c,a.i,a.d)){arr2.push(a)}
      })
//       cl(arr2)
//       cl("Save Channel Type to DB")
      sendArray(arr,zi.virtual,zi.gatewayType,zi.controllerId,localOnly)
        .then(this.sendArrayComplete);
//       cl(dbVals.z[3][0])
//       cl(dbVals.z[3][1])
//       cl(dbVals.z[3][2])
    }
    // cl(arr)
  }

  localType=(index, vo)=>{
    // cl(index)
    // cl(vo)
    // this.mySetState("loc8",{local: vo.value})
    // this.getControlValues();
    // cl(this.state.values)
  }
  
  selectUnit=async(index,vo)=>{
//     cl(index,vo)
//     cl(vo.value)
    let zuci=this.props.match.params.zuci.split("-")
    zuci[1]=vo.value.unit
    this.unit=vo.value.unit
    let url = "/usa/c18/fui/" + vo.value.chType + "/" +
        zuci.join("-");
    await this.mySetState("selectUnit",{title: vo.value.title})
    history.replace(url)
  }

  zoneGroup=(index,vo)=>{
    this.zoneGroupValue=vo.value
//     cl(index)
//     cl(vo)
    this.values[index].value=vo.value
//     cl(this.values)
  }
  
  addDefaultOverrides=()=>{// array of 9 starts with chan_config, timedEnabled
    let pid=getParamId("configuration_channels","timedEnabled")
//     cl(pid)
    let defs=[0,0,0,0,0,"","","",""]
    for(let i=0;i<9;i++){
      this.pidToDef[pid+i]=defs[i]
    }
    
//     let now=getTimeI()
//     for(let i=0;i<9;i++){
//       arr.push({z:z, c:c, i:pid, t:now, d:defs[i], f:2})
//     }
  }
  
  makeEqDefaults=(pageType)=>{
//     cl("makeEqDefaults")
    if(pageType.indexOf("_1900")>0){
      pageType=pageType.substring(0,pageType.length-5)
    }
//     cl(pageType)
//     cl("make equip defaults")
    let types=["none","onoff","irr","co2","light","mzon","pump","fval","vent",
      "curt","mixv","pmz","gpid","","","","","","varout","vent2"/*19*/,
      "","","","","","","","","mixva",
    ]
    let chTypeId = chTypes[pageType].id;
    let channelType = Math.floor(chTypeId / 10);
//     cl(channelType)
    let defType=types[channelType]
    let defs=deq[defType]
//     cl(defType)
    this.pidToDef={}
    Object.keys(defs).forEach(de=>{
//       cl(de, defs[de])
      let pid=getParamId("configuration_channel_data",de)
      this.pidToDef[pid]=defs[de]
//       cl(pid)
    })
//     cl(this.pidToDef)
    this.addDefaultOverrides()
//     cl(this.pidToDef)
    
  }
  
  channelType=(index, vo)=>{
/*this is handling the change in the channelType
select. */
//     cl(vo)
    // return
//     cl(index,vo)
//     cl(this.values[index])
//     cl(this.values[1])
//     this.setValue(index, vo)
//     cl("channelType")
    let pageType = vo.value.chType;
    this.makeEqDefaults(pageType)
//     cl(pageType)
    let url = "/usa/c18/fui/" + vo.value.chType + "/" +
//     let url = "/fui/live/" + vo.value.chType + "/" +
        this.props.match.params.zuci;
    // cl(url);
//     cl("set state")
    this.mySetState("loc9",{title: vo.value.title})
    //   let zuci = this.props.match.params.zuci;
    //   let url = "/fui/live/" + t + "/" + zuci;
    //   // history.pop();
//     cl(this.page)
    this.page = Object.assign({}, globs.fuiPages[pageType]);// loads the new page
//     cl(this.page)
//     cl(this.page.controls)
    this.setPageTitle(this.page.title)
    if(this.page.controls === undefined){
      cl("clearing page.controls");
      this.page.controls = [];
    }else{
      this.saveZoneGroupsToDB();
    }
    // cl(this.page.controls)
//     cl("LiveFui channelType set history url")
    history.replace(url)
//     cl("LiveFui channelType Done")
//     cl("new url")

  }

  // setValue=(vo)=>{
  //   cl(vo);
  // }

  setArray=(i, v)=>{
//     cl(v.value)
//     cl([i,v])
//   setValue=(i, v)=>{
//     cl(v)
    // let v = this.values[vo.index];// = v;
    // v.value = vo.value;
//     cl(v)
    this.setValue(i, v)
    // cl(vo);
  }

  showWaterDays=(z,c)=>{
    let dayPids=[184,182,186,187,185,181,183,
                 191,189,193,194,192,188,190]
    let days=[]
    for(let i=0;i<14;i++){
      days.push(dbVals.z[z][c][600+dayPids[i]])
    }
//     cl(days)
  }

  saveArrayToDb=(c, val, force,localOnly)=>{
//     cl("save array")
//     cl(c, val, force)
//       cl(dbVals.z[0][4][969])
//     cl(this.state)
//     cl(val.value.overrides.c)
//     cl(val)
//     cl(c)
    let chan=(val?.overrides?.c)?val.overrides.c:this.chan
//     cl(chan)
//     cl(this.chan)
    let [z1, c1, i1] = this.getDBParms(this.zone,
        this.unit, +chan, this.getControlIndex(c), c.pid,
        c.tableType, c.k)
//     cl([z1, c1, i1])
    this.showWaterDays(z1,c1)
//     cl(val.value)
    if(Array.isArray(val.value)){
//       cl(val.value)
      let arr = [];
      val.value.forEach((v, i)=>{
        if(force||putZValue(z1, c1, i1 + i, v)){
  //         cl([z1, c1, i1 + i, v])
          arr.push({z: z1, c: c1, i: i1 + i, f:2,
            t: Math.floor(getTime()), d: v})
        }
      })
//       cl(arr)
//       sendArray(arr);
//       cl("send")
//       cl(dbVals.z[0][4][969])
//       cl(this.zoneInfo.virtual)
      let zi=this.zoneInfo
//       cl("Send Array Type to DB")
//       cl(arr)
      if(arr.length){sendArray(arr,zi.virtual,zi.gateway,zi.controllerIdType,localOnly)
        .then(this.sendArrayComplete)}
      this.showWaterDays(z1,c1)
//       cl(dbVals.z[0][4][969])
      
    }
  }

  saveScalarToDb=(c, val, force,localOnly)=>{
//     cl(dbVals.z[0][4][969])
//     cl(this.zone)
//     cl(this.chan)
//     cl("save")
//     cl(c)
//     cl(val)
    let [z1, c1, i1] = this.getDBParms(this.zone,
        this.unit, this.chan, this.getControlIndex(c), c.pid,
        c.tableType, c.k)
//     cl(c.tableType)
    if(i1==5221){cl([z1,c1,i1,val.value])}
//     cl("try1")
    if(force||putZValue(z1, c1, i1, val.value)){
//       cl("try2")
      let arr = [({z: z1, c: c1, i: i1, f:2,
        t: Math.floor(getTime()), d: val.value})];
//         cl("send")
//         cl(arr)
//       cl(dbVals.z[0][4][969])
      let zi=this.zoneInfo
//       cl(this.zoneInfo)
      sendArray(arr,zi.virtual,zi.gatewayType,zi.controllerId,localOnly)
        .then(this.sendArrayComplete);
      
//       sendArray(arr);
    }
//     cl("past")

  }
  
//   saveSecondChannel=()=>{
//     cl("save Second Channel")
//     cl(this.channelSecond)
//   }

  loadZoneGroup=async(zg)=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/zoneGroups", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body:{} })
    cl(res)
    let acctGroups=res.data.groups
    let groups=acctGroups[globs.userData.session.siteId]
    let group=groups[this.zoneGroupValue].zones
    return group
  }

  saveZoneGroupsToDB=async()=>{
// this.zone identifies the zone to write to
//     cl("zonegroups")
    if(this.zoneGroupValue==-1){return this.saveToDB()}
//     cl("zonegroups")
    let group=await this.loadZoneGroup(this.zoneGroupValue)
    let selZones=globs.zonesInfo.info.filter(z=>{
      return group.includes(z.zoneId)})
    this.curZone=this.zone
//     cl("zonegroups")
    selZones.forEach(z=>{
      this.zone=z.siteZoneIndex
      this.saveToDB()
    })
    this.zone=this.curZone
    globs.events.publish("saveOK",true)
//     cl("zonegroups")
//     cl(group)
//     cl(selZones)
  }

  saveToDB=(localOnly)=>{
    let pa=this.props.parms
//     cl("save to db")
//     cl(this.page.controls)

    this.page.controls.forEach((c, i)=>{
      let key=`${pa.gatewayType}-${c.pid}-${c.type}`
      let force=pa.conflictOverrides?.includes(key)
//       if(force){
//         cl(c)
//       }
//       cl([i,this.state.values[i]])
      if(!this.state.values[i]){return}
//       cl([i,this.state.values[i]])
      switch (this.state.values[i].type){
        case "array":
//           cl(this.state.values[i])
          this.saveArrayToDb(c, this.state.values[i],force,localOnly)// 'force' was true 20220512
          break;
        case "scalar":
//           cl("save scalar")
          this.saveScalarToDb(c, this.state.values[i],force,localOnly)
          break;
        case "channelType":
//           cl("save channel type")
          this.saveChannelTypeToDb(c, this.state.values[i],force,localOnly)
//           cl("save channel type")
//           console.trace()
          break;
        default:
          break;
      }
      // switch
    });
    if(localOnly){
//       cl("return")
      let ret=getLazyArray()
//       cl(ret)
      return ret
    }
  }
  
  newContext=(vals)=>{
//     cl("**********************************************************")
//     cl("newContext")
//     cl(vals)
//     cl("new2 cont")
//     cl(this.props.match.params.pageType)
//     globs.events.publish("newContext",{level:"config",siteId:site,zoneId:zone,pageType:parts[4],zuci:parts[5]})
    if(globs.fuiPages){this.loadFuiPage()}
//     cl("LiveFui newContext Done")
  }

  localSave=()=>{
    cl("**********************************************************")
    cl("local save")
    let res=this.saveToDB(true)
//     cl(res)
    globs.events.publish("saveOK",true)
    return res
  }
  
  afterSave=()=>{
//     cl("**********************************************************")
//     cl("LiveFui processing saveOK, afterSave")
//     cl("afterSave")
//     cl(this.props)
//disabled on 20230330. loadFuiPage was temporarily loading the *new*
// page, then it would show the old, then new, when eqType was changed
// I don't know why this loadFuiPage was here
//     this.loadFuiPage()
//     cl("call make")
    this.makeCurrent(this.unit)
//     this.current.tankNames=this.makeCurrentMixTanks(),
//     cl(this.current)
    this.mySetState("loc10",{
      title: this.page.title,
      template: this.page.template,
      current: this.current,
    });
//     cl(this.state)
//     cl("update")
//     cl("LiveFui afterSave Publishing updateFui")
    globs.events.publish("updateFui","")
//     cl("LiveFui saveOK Done")
  }

  saveCancel=(index, vo)=>{
//     cl(vo)
    // cl(vo.value)
    if(vo.value === "save"){
//       cl("save")
      this.saveZoneGroupsToDB();
//       cl("save")
//       cl(this.state.saveFunctions)
      this.state.saveFunctions.forEach(sf=>{sf()})// I don't think this is used!'
//       history.goBack();
    }else{
//       history.goBack();
    }
    // cl(vo);
  }

  mixTank=(index, vo)=>{
// http://ngsg.link4cloud.com:3104/usa/c18/fui/unit_Mixing_Tank_Calibration/3-0-192-0    
    this.values[index]={type: "mixTank", value: vo.value.tank}
//     cl(this.values[0])
//     cl(this.values)
//     cl(vo)
    this.chan=192+(+vo.value.tank.substr(4))
    let parts=this.props.match.url.split('/')
    let pageType = parts[4]//"unit_Mixing_Tanks"//vo.value.chType;
//     cl(this.props.match.url)
//     cl(parts)
    let url=`/usa/c18/fui/${pageType}/${this.zone}-${this.unit}-${this.chan}-${this.indx}`
//     let url = "/fui/live/" + vo.value.chType + "/" +
//         this.props.match.params.zuci;
    // cl(url);
//     cl("set state")
//     this.mySetState("loc11",{title: vo.value.title})
    //   let zuci = this.props.match.params.zuci;
    //   let url = "/fui/live/" + t + "/" + zuci;
    //   // history.pop();
    this.page = Object.assign({}, globs.fuiPages[pageType]);
    cl(this.page.title)
    this.setPageTitle(this.page.title)
//     globs.events.publish("setMainBarTitle", this.page.title)
    if(this.page.controls === undefined){
	  cl("clearing page.controls");
      this.page.controls = [];
    }
//     cl(this.page.controls)
    this.props.parms.onChange({cmd:"savePage",data:{savePage:true}})//,saveEnable:false
    history.replace(url)
  }

  setScalar=(index, value)=>{
    // cl(this.state)
    // cl(value)
    // let v = this.values[index];// = v;
    // v.value = vo.value;
    this.setValue(index, value)
    // this.setValue(vo.index, vo.value)
  }

  onChange=async(index, value)=>{
//     cl("**********************************************************")
//     cl(`Live Fui onChange index: ${index}`)
//     cl(index,value)
    cl.oc(this,index,value)
//     cl(value)
//     cl("onchange")
//     cl(index,value)
//     cl(value.noChange)
//     cl(this.props)
    let isChannelType=(value.value.type=="channelType")
    if((!isChannelType)&&(!value.noChange)){globs.events.publish("savePageEnable",true)}
//     if(!value.noChange){globs.events.publish("savePageEnable",true)}
//     cl(value.value.type)
    if(value.value.type=="mixTank"){
//       cl("check")
//       cl(this.props.parms.pageModified)
      if(this.props.parms.pageModified){
        let res=await this.props.parms.getPopup({text:"Are you sure you want to replace this View?", buttons:["Cancel","Yes"]})
        if(res=="Yes"){
          this.saveZoneGroupsToDB();
          this.state.saveFunctions.forEach(sf=>{sf()})
          cl("save it")
        }
        globs.events.publish("savePageEnable",false)
      }
    }
//     cl("pub")
//     this.props.parms.onChange({cmd: "savePage", data:{saveEnable: true}})// turn on save button
//     cl(index)
//     cl(value)
//     cl([index,value])
    /*OK, so value is an *object*, with a value attribute,
    and anything else that it needs. only the value
    will be stored in the db
    value is:
    {value: "something", other: "any other data"}
    basically, this object has to have a value and a type
    and anything else is OK*/
    // cl(value)
    /*this is changing. It can still be used with two
    arguments, but *should* be called with just the
    valueObject, with "index" as a property*/
    // if (valueObject !== undefined){
    //   valueObject.index = index;
    // } else {
    //   valueObject = index;
    // }

    // cl(valueObject)
//     cl(index, value)
    // cl(this.values)
    // let val = this.values[index]
//     cl(this.values)
//     cl(value)
//     cl(this.values[0])
//     cl(index)
//     cl(this.values)
    value.type = this.values[index].type// uses existing type

    // val.value = value;
//     cl(value)
    var cmds = {
      scalar: this.setScalar,
      array: this.setArray,
      channelType: this.channelType,
      zoneGroup: this.zoneGroup,
      selectUnit: this.selectUnit,
      local: this.localType,
      saveCancel: this.saveCancel,
      mixTank: this.mixTank,
    }
//     cl(value)
    cmds [value.type](index, value);
//     cl(this.values[4])
//     cl("set state")
//     cl(this.values[0])
//     cl(this.values)
    this.mySetState("loc12",{values: this.values});
  }

  // onChange=(index, valueObject)=>{
  //   // if (arguments.length == 2){
  //   //   valueObject.index = index;
  //   // }
  //   // cl(valueObject)
  //   var cmds = {
  //     scalar: this.setScalar,
  //     array: this.setArray,
  //     channelType: this.channelType,
  //     saveCancel: this.saveCancel,
  //   }
  //   cmds [valueObject.type](valueObject);
  // }

  // onChange=(e, t)=>{
  //   cl(e, t);
  //   let zuci = this.props.match.params.zuci;
  //   let url = "/fui/live/" + t + "/" + zuci;
  //   // history.pop();
  //   history.replace(url)
  //   this.page.title = e;
  //   // cl(this.props.match.params.pageType)
  // }

  getArrayValue=(index, vo)=>{
//     console.trace()
//     cl(index,vo);
    let c = this.page.controls[index]
    if(!c){return }
//     cl(c)
    let val = [];
    let chan=(vo?.overrides?.c)?vo.overrides.c:this.chan
//     cl(chan)
//     cl(this.chan)
//     cl(this.unit)
    for (let i = 0 ; i < vo.count ; i++){
      let v=this.getDBParmValue(this.zone,this.unit, +chan, 
        this.getControlIndex(c), c.pid + i, c.tableType, c.k)
//       cl(v)
      if((typeof(v)=="string")&&(v.length==0)){v=-1}
      val.push(v);
    }
//     cl(val)
//     cl(vo.overrides)
//     if(index==5){cl(val)}
    
    this.setValue(index, {type: vo.type, overrides: vo.overrides, value: val});
//     cl(val)
    // this.mySetState("loc13",{values: val})
  }

  getControlIndex=(c)=>{
/* if the control has customProps.index, then return that
otherwise, the page index*/
// cl(c);
    try{
      let indx = c.customProps.index;
      return (indx === undefined) ? this.indx : indx;
    }catch{}
  }
  
  getTankValue=(index, vo)=>{
//     cl(vo)
//     cl("get tank")
    let val={type: "mixTank", value: `tank${this.chan-192}`}
    this.values[index]=val
    this.setValue(index, val)
  }

  getScalarValue=(index, vo)=>{
    /*this sets the *initial* value. when the control
    uses onChange, it may put more information there.
    */
    // cl(vo);
    let c = this.page.controls[index]
// 	cl(this.page.controls);
// 	cl(index);
//     cl(index,c)
    // cl(this.chan)
	try{
		let val = this.getDBParmValue(this.zone,
			this.unit, this.chan, this.getControlIndex(c), c.pid,
			c.tableType, c.k);
		// cl(val)
		this.setValue(index, {type: vo.type, value: val});
		// cl(val)
	}catch{
		cl(`missing control in this.page.controls, index: ${index}`);
	}
  }

  nop=(index, vo)=>{
    this.setValue(index, {type: vo.type, value: undefined});
  }

  getValue=(index, valueDescription)=>{
//     cl([index, valueDescription])
    // cl(valueDescription)
    /* this should actually put the correct value in
    state.values*/
//   getControlValue=(i, z, u, c, idx, pid, type, k)=>{

    // cl(valueDescription)
    // if (valueDescription === undefined){
    //   valueDescription = index;
    // }else{
    // valueDescription.index = index;
    // }
    // cl(valueDescription)
    // valueDescription.index = index;
    // cl(index)
//     cl(valueDescription)
    var cmds={
      scalar: this.getScalarValue,
      array: this.getArrayValue,
      local: this.nop,
      saveCancel: this.nop,
      channelType: this.nop,
      zoneGroup: this.nop,
      selectUnit: this.nop,
      mixTank: this.getTankValue,
    }
    cmds[valueDescription.type](index, valueDescription);
    // switch(valueDescription.type){
    //   case "scalar":
    //     // cl(this.props)
    //     // cl(this.state)
    //     let c = this.page.controls[index]
    //     // cl(c);
    //     let val = this.getControlValue(index, this.zone,
    //       this.unit, this.chan, this.indx, c.pid,
    //       c.tableType, c.k);
    //     // cl(val)
    //     this.setValue(index, val);
    //     break;
    //   default:
    //     break;
    // }
//     cl("set state")
//     cl(this.values[5]?.value)
//     cl(this.values)
    this.mySetState("loc14",{values: this.values});
  }

  showChannelType=(c, i)=>{
//     cl(c,i)
    // cl("chan")
//     cl(this.props.match.params.pageType)
//     cl(this.props.parms)
//     cl(this.state)
    return (
        <ChannelType key={i} cont={c} ind={i}
                     type={this.page.type/*props.match.params.pageType*/}
                     current={this.state.current}
                     getValue={this.getValue}
                     zuci={this.props.match.params.zuci}
                     contType={this.contType}
                     virtType={this.virtType}
                     getPopup={this.props.parms.getPopup}
                     saveOK={this.saveOK&&this.connected}
                     rest={this.rest}
                     onChange={this.onChange}/>
    );
  }

  showOneControl=(c, i)=>{
//     cl(c)
//     cl(i)
    let pa=this.props.parms
//     cl(pa.getPopup)
    var conflict=false
    var commonValues
    if(pa.commonControls){
      let key=`${pa.gatewayType}-${c.pid}-${c.type}`
//       cl(pa.commonControls)
//       cl(key)
      if(pa.commonControls.includes(key)){
        commonValues=pa.commonValues[key]

//         cl(pa.commonValues[key])
        if((!pa.conflictOverrides.includes(key))&&
          commonValues.conflict){conflict=true}
      }else{
        return null
      }
    }

//     cl(c)
//     cl(i)
//     cl(this.state.values[5])
//     cl(this.state.current.channelInfo[0].channelName)
//     cl(this.props)
//     cl(this.state)
//     cl(globs.privsInfo.info)
//     cl("show")
//     cl(i,c)
    /* use zuci, and the information in the control
    to get the parameter and put it in a text control*/
//     cl(c)
    if(c.type === "channelType") return this.showChannelType(c, i);
    // cl(c.type)
    // let controls ={"inputMapping": FUISelect};
    let Cont = controls[c.type];
    if ((Cont !== null) && (Cont !== undefined)){
      // cl("here")
//       cl(this.state.values)
//       cl(this.state.values[i])
      
      let val = ((this.state.values[i] === undefined) ||
          (this.state.values[i].value === undefined))
          ? 0 : this.state.values[i].value;
//       cl(val)
      // cl(this.state.values[i])
//       cl(val)
//       cl("returning control");
//       cl(this.props.match.params.pageType)
//       cl([c.type,this.state.current])
//       cl(val)
//           cl(val)
//           cl(this.state.current.channelInfo[0].channelName)
//           cl(val)
      let control=(
        <Cont key={i} cont={c} ind={i}
              value={val}
              type={this.props.match.params.pageType}
              custom={c.customProps}
              templateProps={c.templateProps}
              current={this.state.current}
              getValue={this.getValue}
              title={c.title}
              zuci={this.props.match.params.zuci}
              onChange={this.onChange}
              breadCrumbs={this.makeFuiBreadcrumbs}
              saveOK={this.saveOK&&this.connected}
              saves={this.state.saveFunctions}
              pageModified={pa.pageModified}
              getPopup={pa.getPopup}
              rest={this.rest}
              conflict={conflict}
              commonValues={commonValues}
              />
      )
      if(conflict){
//         cl(conflict)
        return commonValues.conflict(control,i)
      }else{
        return control
      }
    }
    return (
        <div key={i}>{this.showOneText(c, i)}</div>
    );
  }
  
  showControls=()=>{
    var makeSect=(i)=>{
      let msConts=[]
      let c=contArr[i]
      let sectCust=c.customProps
//       cl(`make sect ${i} of ${sectCust.controlCount}`)
      let cnt=sectCust.controlCount
      let section=[]
      for(let j=0;j<cnt;j++){
        c=contArr[i+j+1]
//         cl(c.type)
        if(c){
          if(c?.type=="section"){
            var conts2
             [i,conts2]=makeSect(i+j+1)
            i=i-j-1
            section=section.concat(conts2)
          }else{
            section.push(this.showOneControl(c,i+j+1))
          }
        }
      }
      let style=(sectCust.float)?{float:sectCust.float}:{}
        style.marginLeft=sectCust.leftMargin
        style.width=sectCust.width
        if(sectCust.box){
          style.borderStyle="solid"
          style.borderWidth=1
          style.borderRadius=10
          style.padding=20
        }
      msConts.push(
        <div key={i+"sect"} id={`sect${i}`}
        style={style}
        >{section}</div>
      )
      if(sectCust.clear){
        msConts.push(<div key={i+"clear"} className="clearfloat"></div>)
      }
//       cl(`sect of ${sectCust.controlCount} done at control ${i+cnt}`)
//       cl(i+cnt, msConts)
      return [i+cnt,msConts]//i+cnt
    }

    let contArr=this.page.controls
    if(!contArr){return}
    let conts=[]
    for(let i=0;i< contArr.length;i++){
      var conts2
      let c=contArr[i]
      if(c.type=="section"){
        [i,conts2]=makeSect(i)
//         cl(conts2.length)
        conts=conts.concat(conts2)
//         conts.push(conts2)
      }else{
        conts.push(this.showOneControl(c,i))
      }
    }
    return(
      <div>
      {conts}
      </div>
    )
  }

  lookupPropValue=(split)=>{
    let name = split[1];
    // cl(name);
    for (let i = 0 ; i < this.htoProps.length ; i++){
      let hp = this.htoProps[i];
      if (name in hp){
        // cl(typeof(hp[name]));
        if(typeof(hp[name]) === "object"){
          if(split.length == 2) return hp[name];
          // cl(hp[name]);
          // cl(hp[name].title);
          return hp[name][split[2]];
          // cl("found it");
          // return "object";
        } else{
          return hp[name];
        }
      }
    };
  }

  lookupMethod=(name)=>{
    cl(name);
    if (name in Methods){
      return Methods[name];
    } else {
      return name;
    }
  }

  getPropValue=(prop)=>{
    if (typeof(prop) === "string"){
      if (prop === "object"){
        return "nothing";
      }
      let split = prop.split(".");
      // cl(prop)
      if ((split.length >= 2) && (split[0] === "p")){
        return this.lookupPropValue(split);
      }
      if ((split.length == 2) && (split[0] === "m")){
        return this.lookupMethod(split[1]);
      }
    }
    return prop;
  }
  /* there's a logical inconsistency here. subObject is called
  when we render, but it's doing a constructor job.
  if these object are going to have any life, they can't be
  copied and recreated in the render process. */

  subObject=(props)=>{
    Object.keys(props).forEach(k=>{
      if (props[k] === "object") {
        // cl("object");
        props[k] = {
          render:r=>{
            let rc = (this.state.rc === undefined) ? 0 : this.state.rc;
            cl("set state")
            this.mySetState("loc15",{rc: rc + 1});
            // cl("render: " + rc.toString());
          },
        };
      }
    });
  }

  getPropValues=(props)=>{
/* convert 'p.some' values to the value of the property
there are no property definitions in style
but there are *uses*
also, "self" refers to the current element
*/
    Object.keys(props).forEach(p=>{
      if (typeof(props[p]) == "object"){
        let props2 = Object.assign({}, props[p]);
        this.getPropValues(props2);
        props[p] = props2;
      }else{
        try{
          props[p] = this.getPropValue(props[p]);
        }catch{cl(p)}
      }
    });
    // cl(props)
  }

  validTags = {
    div: 1,
    p: 1,
    i: 1,
    b: 1,
    li: 1,
    ul: 1,
    img: 1,
    span: 1,
  };

  createControl=(hto)=>{
    cl("create control");
    if (hto[0].substring(0, 2) !== "c.") {
      throw "Invalid Tag";
      return;
    }
    let cn = hto[0].substring(2);
    let cont = this.page.controls[this.page.contDict[cn]];
    cont.templateProps = hto[1];
    // cl(cont)
    return this.showOneControl(cont, hto[1].key);

  }

//   createSelfElement=(tag, props, children)=>{
// /* create a React Element, and if props refers to "self",
// then include a reference to the Element itself*/
//     cl(props);
//     let el = React.createElement(tag, props, children);
//     Object.keys(props).forEach(p=>{
//       cl(p);
//       if (props[p] === "self"){
//         el.self2 = "here we are";
//         props[p] = el;
//       }
//     });
//     cl(props);
//     return el;
//   }

  createElement=(hto)=>{
    // cl([tag, props, chil])
    // cl(hto[0])

    try{
      hto[2] = this.getPropValue(hto[2]);// substitutes values
      // cl(hto);
      if (hto[0] === "img"){
        return React.createElement(hto[0], hto[1], null);
      }else{
        if (hto[0] in this.validTags){
          return React.createElement(hto[0], hto[1], hto[2]);
          // return this.createSelfElement(hto[0], hto[1], hto[2]);
        }else{
          // cl("handling control");
          // cl(hto)
          // hto[0] = this.getPropValue(hto[0]);
          // cl(p2);
          return this.createControl(hto)
        }
      }
    }catch{// if all else fails
      React.createElement("p", {}, []);
    }
  }

  showProps=(props)=>{
    // props.forEach(p=>{
    //   cl(p);
    // });
    Object.keys(props).forEach(p=>{
      if(typeof(props[p]) == "object") {
        this.showProps(props[p]);
      }else{
        cl(p);
      }
    })
  }

  // htoDepth = 0;
  htoProps = [];

  procTemplate=(hto)=>{
/* this will also do controls
Have to handle a control like a template, or hto
*/
    if (hto[0].substring(0, 2) !== "t.") return hto;
    let temp = globs.templates[hto[0].substring(2)].slice(0);
    let props = Object.assign({}, temp[1]);// current props
    let chil = temp[2].slice(0); // Object.assign({}, temp[2]);// current props
    Object.keys(hto[1]).forEach(p=>{
      props[p] = hto[1][p];
    });
    return [temp[0], props, chil];

    // let split = hto[0].split(".");
    // switch (split[0]){
    //   case "t":
    //     // if (hto[0].substring(0, 2) !== "t.") return hto;
    //     let temp = globs.templates[hto[0].substring(2)].slice(0);
    //     let props = Object.assign({}, temp[1]);// current props
    //     let chil = temp[2].slice(0); // Object.assign({}, temp[2]);// current props
    //     Object.keys(hto[1]).forEach(p=>{
    //       props[p] = hto[1][p];
    //     });
    //     return [temp[0], props, chil];
    //   // case "c":
    //   // // return this.showOneControl(c, i);
    //   //   cl(split);
    //   //   cl(this.page)
    //   //   return hto;
    //   default:
    //     return hto;
    //
    // }
  }
  // htoProps = [];

  getHto=(hto)=>{// hto is [tag, [props], [children] or text]
/* i is an index if this is one of multiple children
lets say that props is *not* optional, and that
an img has no children - undefined.
The handling of a template has to change, so that it
can have props
Now, an img has empty children, []
I think we'll build the template-checking
into createElement
to handle a template, rather than a regular hto:
add the template props to the props for the enclosing
tag, and then just process normally
*/
    // var tag, props, chil;
    // if (typeof hto === "string"){
    //   cl(this.htoDepth -= 1);
    //   let sp = hto.split('.');
    //   switch (sp[0]){
    //   case 't':
    //     let ch = globs.templates[sp[1]];
    //     return this.getHto(ch, i);
    //   default:
    //     return;
    //   }
    // }
    // cl(this.htoDepth += 1);
    // this.showProps(hto[1]);
    // cl(hto)
    // this.subObject(hto[1]);
    hto[0] = this.getPropValue(hto[0]);
    hto = this.procTemplate(hto);
    // cl(hto);
    let props = Object.assign({}, hto[1]);
    this.subObject(props);// replace "object" with {}
    this.htoProps.push(props);
    let hto2 = hto[2].slice(0);// have to work with a copy
    try{// fails if hto[2] is a string
      hto2.forEach((c, i)=>{
        c[1].key = i;// props
        hto2[i] = this.getHto(c);// child -> object
      })
    }catch{}
    // let props = Object.assign({}, hto[1]);
    this.getPropValues(props);
    // this.htoProps.push(props);
    // cl(props);
    this.htoProps.pop();
    return this.createElement([hto[0], props, hto2]);
  }

  showTemplate=()=>{
    // cl("showing template");
    // cl(this.page.template);
    // cl(globs.templates[this.page.template]);
    // cl(this.page);

    return this.getHto (this.template);
  }

  barClick = async(e)=>{
    cl(e.currentTarget.id);
    let pageType = this.props.match.params.pageType ; // "zone_Fallback";
    switch (e.currentTarget.id){
      case "home":
          let dash=await getHomeDashboard()
          if(dash){history.push(`/usa/dash/${dash}`)}
//         history.push("/usa/dash/vz4sci_m-iHC9nQZ")
        break;
      case "back":
        history.goBack()
        break;
      case "edit":
        history.push("/fui/" + pageType + "/zuci/" + this.props.match.params.zuci); //  + "/0-0-0-0"
        break;
      default:
        break;
    }
  }

  updateValues=()=>{
//     cl((this.state.values[5]||{}).value)
    
    this.getZuci();
    // cl("update values")
    // cl(this.values)
    let realValues = ["scalar", "array"];
// 	cl(this.values);
    this.values.forEach((v, i)=>{
//       cl(v)
//       cl(v.type)
      if (realValues.includes(v.type)){
        // cl(v);
        if(v.type=="scalar"){
          this.getScalarValue(i, {type: v.type})
        }else{
          this.getArrayValue(i,{type:v.type,count:v.value.length})
        }
      }

    });
//     cl((this.state.values[5]||{}).value)
    // cl(this.values)
  }

  checkUrl=()=>{// this is checking if the *props* version of the url has changed
//     cl([this.url,this.props.match.url])
    if (this.url === undefined){
      this.url = this.props.match.url;
    }
    if (this.url !== this.props.match.url){
      this.updateValues();
      this.url = this.props.match.url;
    }
  }

  showTempContols=()=>{
    if (this.page.template === undefined){
      return this.showControls();
    }else{
      return this.showTemplate();
    }
  }
  
  showMainBar=(title)=>{
    if((this?.props?.parms?.mode)&&(this.props.parms.mode=="c18"))
    {
      return null
    }else{
      return(
        <MainBar home back edit editColor={"inherit"}
                  menu click={this.barClick} title={title} />
      )
    }
  }

  render(){// called any time any control changes
//     cl(((dbVals?.z[0]||{})[0]||{})[508]||-33)
    if(dbVals.z[0]){
//       cl(dbVals.z[0][4][508])
//       cl(dbVals.z[0][5][508])
    }
//     cl(`Render Live Fui`)
//     cl(this.state.values[5]?.value)
//     cl(this.props)
    this.checkUrl()// recalc values if url changes
    this.mounted = true;
	// cl(this.page);
    if(this.state.loaded){
//             {this.showTitle()}
      return(
          <div className={this.props.match?.params.pageType}>
            {this.showMainBar(this.state.pageTitle)}
            {/*this.showControls()*/}
            {this.showTempContols()}
          </div>
      );
    }else{
      return(
          <div id="content-area">
            {this.showMainBar("FUI Page")}
            {this.state.loadMsg}
          </div>
      );

    }
  }

}

/*
These are all the kinds of FUI pages, just about 60 of 'em':
zone_Stages
zone_Fallback
zone_Output
zone_History
zone_Units
zone_Irrigation
zone_Lighting
zone_Alarms
zone_Smartcool
zone_H-C_Demand
zone_Setpoints
zone_SP_Drive_to_Avg
zone_SP_Influence_Factors
zone_SP_Retractable_Greenhouse
zone_Hum_DeHum
zone_Aux_Controls
zone_Pump_Schedule
zone_Sensors
unit_Input_Mapping
unit_Analog_Temp_Mapping
unit_Irrigation_Sensor_Mapping
unit_Vent_Position_Mapping
unit_Mixing_Tanks
unit_Generic_Mapping
unit_Network_Sensors
unit_Accumulator
unit_Input_Calibration
unit_Analog_Temp_Calibration
unit_Soil_Moisture_Calibration
unit_Vent_Position_Calibration
unit_Mixing_Tank_Calibration
unit_Generic_Calibration
unit_Input_Multipliers
unit_Miscellaneous
channel_Irrigation_Scheduled
channel_Irrigation_Accumulated_Light
channel_Irrigation_Cycle
channel_Irrigation_Trigger
channel_Irrigation_Soil_Trigger
channel_Irrigation_VPD
channel_CO2
channel_Light_Supplemental
channel_Light_Scheduled
channel_Light_Cyclic
channel_Light_DLI
channel_Microzone
channel_Supply_Pump
channel_Peristaltic_Batch_Pump
channel_Peristaltic_Recirculating_Pump
channel_Peristaltic_Balance_Pump
channel_Fill_Valve
channel_Vent_Roof
channel_Vent_Retractable_Roof
channel_Vent_Side_Wall
channel_Curtain
channel_Mix_Valve
channel_Proportional_Microzone
channel_PID
channel_Variable_Out

*/

export default LiveFui ;
