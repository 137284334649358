import React from 'react';
// import C18Duration00 from '../../usa/components/C18Duration00'
// import {pInd} from '../../components/utils/paramIds'
import {getParamId,getParamId2} from '../../usa/utils/utils';
import {dbVals,putZValue,sendArray} from '../../components/utils/http'
import {HSVtoRGB} from '../../usa/components/C18utils'
// import FUISelect from './FUISelect';
// import {proportionals} from '../utilsFui'
import {cl, globs,constant, getTimeI} from '../../components/utils/utils';

class VentStages extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    this.state={
//       stages:[0,0,0,0,0,0,0]
    }
    this.loadInfo()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
  }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
  }
  
  savePageEvent=()=>{
//     cl("save page")
    this.getSaveStages("save")
//     this.saveHumDeHumData()
  }
  
//   saveVentStages=()=>{
//     let now=getTimeI()
//     let z=this.zuci[0]
//     let c=this.zuci[2]
//     let stages=[...Array(pr.current.coolStages+1).keys()].map(k=>{
//       let pid=getParamId("configuration_channel_data",pIds[ofs+k])
//       return({
//         c:c,// zone wide
//         d:v,//st.hdhSettings[k][i],
//         f:1,
//         i:pid,
//         t:now,
//         z:z,
//       })
// 
//       return dbVals.z[z][c][getParamId("configuration_channel_data",pIds[ofs+k])]
//     })
//     
//     Object.keys(this.chans).forEach(k=>{
//       let ch=dbVals.z[z][k]
//       fields.forEach((f,i)=>{
//         let pid=getParamId("configuration_channels",f)
//         let v=st.hdhSettings[k][i]
// //           if((typeof v=="string")&&(v.length==0)){v=null}
//         cl(v)
// //           if(v!=null){v=+v}
// //           if(v.length==0)
//         if (putZValue(z,k,pid,v)){
//           hdhDBSettings.push({
//             c:k,// zone wide
//             d:v,//st.hdhSettings[k][i],
//             f:1,
//             i:pid,
//             t:now,
//             z:z,
//           })
//         }
//       })
//     })
//     this.saveGlobalHdh(z,hdhDBSettings)
//     cl(hdhDBSettings)
//     sendArray(hdhDBSettings,false).then(e=>{globs.events.publish("saveOK",true)})
//     
//   }
  
  getSaveStages=(getSave)=>{
    cl(this.props)
    let gwType=this.props.current.gwType
    let pIds=(gwType==1900)?
    ["CD_normal_onoff","CD_cool1_onoff","CD_cool2_onoff","CD_cool3_onoff",
      "CD_cool4_onoff","CD_cool5_onoff","CD_cool6_onoff",
      "activeCool_normal","activeCool_cool1","activeCool_cool2","activeCool_cool3",
      "activeCool_cool4","activeCool_cool5","activeCool_cool6"]:
    ["CD_normal_vent","cool_1","cool_2","cool_3","cool_4","cool_5","cool_6",
      "activeCool_normal","activeCool_cool1","activeCool_cool2","activeCool_cool3",
      "activeCool_cool4","activeCool_cool5","activeCool_cool6"]
      cl(pIds)
    let st=this.state
    let pr=this.props
    let z=this.zuci[0]
    let c=this.zuci[2]
    let ofs=(pr.custom.active)?7:0
    let virtual=pr.current.virtual
//     cl(pr.current)
//     cl(virtual)
    if(getSave=="get"){
      return [...Array(pr.current.coolStages+1).keys()].map(k=>{
//         cl(pIds[ofs+k])
//         cl(getParamId("configuration_channel_data",pIds[ofs+k]))
        return +((((dbVals?.z||{})[z]||{})[c]||{})
        [getParamId2(gwType,"configuration_channel_data",pIds[ofs+k])])||0
      })
    }else{
      let now=getTimeI()
      let stages=[...Array(pr.current.coolStages+1).keys()].map(k=>{
        let pid=getParamId2(gwType,"configuration_channel_data",pIds[ofs+k])
        return({
          c:c,// zone wide
          d:+st.stages[k],//st.hdhSettings[k][i],
          f:1,
          i:pid,
          t:now,
          z:z,
        })
        return dbVals.z[z][c][getParamId("configuration_channel_data",pIds[ofs+k])]
      })
//       cl(stages)
      sendArray(stages,virtual).then(e=>{globs.events.publish("saveOK",true)})
    }
  }

  loadInfo=()=>{
    this.zuci=this.props.zuci.split("-")
    let pr=this.props
//     cl(pr)
//     let stages
//       
//     let z=this.zuci[0]
//     let c=this.zuci[2]
//     let ofs=(pr.custom.active)?7:0
//     let stages=[...Array(pr.current.coolStages+1).keys()].map(k=>{
//       return dbVals.z[z][c][getParamId("configuration_channel_data",pIds[ofs+k])]
//     })
// //     cl(stages)
//     this.state.stages=stages
    this.state.stages=this.getSaveStages("get")
    
    
//     this.hums=[constant.EQ_VENT, constant.EQ_CURT, constant.EQ_ONOFF, constant.EQ_PUMP, constant.EQ_HID, constant.EQ_CO2, constant.EQ_MZONE, constant.EQ_MZONE2, constant.EQ_MIXV, constant.EQ_MIXV_A]
//     this.dehumInfo={
//       cycleOn:"Humidity Override On Time",
//       cycleOff:"Humidity Override Off Time",
//       dhltTemp:"Dehumidify Low Outside Temperature Threshold",
//       heatBoost:"Dehumidify Heat Boost",
//     }
//     let pr=this.props
//     this.zuci=pr.zuci.split("-")
//     let ci=pr.current.channelInfo
//     this.chans={}
//     ci.forEach((ch,i)=>{ 
//       if(this.hums.includes(ch.channelType) ){this.chans[i]=ch}
//     })
//     let hdhSettings=this.getSaveHDH("get")
//     let z=this.zuci[0]
//     let vals={}
//     Object.keys(this.dehumInfo).forEach(k=>{
//       vals[k]=dbVals.z[z][255][getParamId("configuration_zone_settings",this.dehumInfo[k])]
//     })
//     vals.hdhSettings=hdhSettings
//     cl(hdhSettings)
//     Object.assign(this.state,vals)
// //     this.state.hdhSettings=hdhSettings
  }
  
//http://ngsg.link4cloud.com:3104/usa/c18/fui/HumDeHum/1-0-240-0
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    let st=this.state
    globs.events.publish("savePageEnable",true)
    switch(type){
      case "upd":
        let stages=st.stages.slice(0)
        stages[vals.ind]=vals.val
        this.setState({stages:stages})
    }
//     let st=this.state
//     globs.events.publish("savePageEnable",true)
//     switch(type){
//       case "upd":
//         this.setState(vals)
//         break
//       case "hum":
//         cl(vals)
//         let hs=Object.assign({},st.hdhSettings)
//         let v=vals.val
//         cl(vals)
//         hs[vals.ch][vals.humStage]=(v.length>0)?+v:v
//         this.setState({hdhSettings:hs})
//         break
//       default:
//         break
//     }
    
  }
  
  makeColor=(h1,h2,frac,s,v)=>{
    var d2h=(dec)=>{
      return `00${dec.toString(16)}`.slice(-2)
    }
    let h=h1+frac*(h2-h1)
    let rgb=HSVtoRGB(h,s,v)
    return `#${d2h(rgb.r)}${d2h(rgb.g)}${d2h(rgb.b)}`
  }
  
  showStages=()=>{
    let st=this.state
    let pr=this.props
    let unit=<span style={{cursor:"default",marginLeft:-55}}>%</span>
    let h1=1
    let h2=0.6
    let stageSelects=st.stages.map((stv,i)=>{
//       cl(stv)
      let frac=i/6
      let bgColor=this.makeColor(h1,h2,frac,0.4,1)
      return(
        <td key={i}>
        <input type="number" style={{width:85,backgroundColor:bgColor}}
          min="0"
          max="100"
          step="1"
          value={stv}
          onChange={e=>this.onChange("upd",{ind:i,val:e.target.value})}
        />{unit}
        </td>
      )
    })
    return(
      <table><tbody>
      <tr>
      <th>Normal</th>
      {[...Array(pr.current.coolStages).keys()].map(i=>{
        return(
          <th key={i}>{`Cool ${i+1}`}</th>
        )
      })}
      </tr>
      <tr>
      {stageSelects}
      </tr>
      </tbody></table>
    )
  }
  
  render(){
    let st=this.state
    let unit=<span style={{cursor:"default",marginLeft:-60}}>&deg;{st.tempUnit}</span>
    
    return(
      <div>
      <div className="clearfloat"/><br/>
      {this.showStages()}
      <div className="clearfloat"/><br/>
      </div>
    )
  }
}

export default VentStages ;
