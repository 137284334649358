import React from 'react';
import {cl} from '../../components/utils/utils'


class C18Select01 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
//     cl(this.constructor.name)
//     cl(this)

  }
  
  render(){
    let pa=this.props.parms
//     cl(pa)
    return(
      <>
        <label htmlFor="priority">{pa.label}</label>
        <span className="custom-select">
          <select id="priority"
            value={pa[pa.valueName]}
            onChange={e=>{
              let val={}
              val[pa.valueName]=e.currentTarget.value
              pa.onChange("upd",val)}}
          >
          {pa.opts.map((pr,i)=>{
            return(
              <option key={i} value={pr.v}>{pr.t}</option>
            )
          })}
          </select>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </span>
      </>
    )
  }
}
      
export default C18Select01;
