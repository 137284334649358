import React from 'react';
import Graph01 from '../../visualization/components/Graph01.js'
import C18Input00 from './C18Input00'
import C18Select00 from './C18Select00'
import C18Button00 from './C18Button00'
import C18TabsHeader00 from './C18TabsHeader00'
import C18SiteZoneView00 from './C18SiteZoneView00'
import GrowJournalWidgetEntry01 from '../../visualization/components/GrowJournalWidgetEntry01'
import Select from 'react-select'
import { DateRangePicker } from 'react-date-range';
import {dbVals,getZoneControllers} from '../../components/utils/http'
import {wsTrans,sensorIds,chanPosIds,doGetPostBasic,setSensorIdNames,getChannelsInfo,
  initSensorIds} from '../utils/utils'
import {cl, globs,constant,getTime,dateToShortMonth,dateToDisplayDate,az,getRandomString} from '../../components/utils/utils';
import {loadSitesInfo,loadSiteData,loadSensorsInfo,getSensorsZone,loadZonesInfo,getZoneInfo,
  getSiteIndex,loadPrivsInfo,privs,
  getZoneIndex,loadPresetsInfo,getSiteName,getZoneName,
  getZoneId,loadTagsInfo,getChannelType, loadSummaryPresetsInfo,acctFeature} from './C18utils'
import '../../rdr_styles.css'
import '../../rdr_default.css'
import C18Bar from './C18Bar'
import C18HeatMap from './C18HeatMap'
import C18Calendar from './C18Calendar'
import C18ScatterPlot from './C18ScatterPlot'
import C18MuiSlider from './C18MuiSlider'



class C18Summary extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    this.notifies={}
    let now=getTime()
//     cl(now)
    this.colorArr=[
    "#009fb9", 
    "#df568b", "#9b8610", "#348bed", "#dc5e59", "#679525", 
     "#00a086", 
    "#9478e0", 
      "#c3732e", "#009d52", "#0098df", "#c862bc"]
//     this.mainSvgDiv=React.createRef();
//     this.selectSvgDiv=React.createRef();
//     this.chartElements=[],
    let toDate=new Date()// now
    var fromDate
    (fromDate=new Date()).setDate((toDate.getDate()-7))
//     cl(fromDate)
    let title="Account Summary"
    let p=props.parms
//     cl(p)
    p.onChange({cmd:"pageTitle",data:{pageTitle:title}})
    this.state={
      pageTitle: title,
      presetLevel:"zone",
      saveViewTime: "relative",
      saveViewName: "",
      currentPreset:null,
      chartElements:[
        {value:"inT",label:"Inside Temperature"},
      ],
      showTimePeriod: "none",
      timePeriod: {
        startDate: fromDate,
        endDate: toDate,
        key: 'selection',
      },
      saveMsg:"Save",
      gjEntry:null,
      site:p.site,
      zone:p.zone,
      showEventOptions: -1,
      showAggregateOptions: -1,
      className: 'filled',
      historySummary: {from: now, to: now},
    }
    let level=this.getPresetLevel()
    this.subscribe_createGrowJournalEvent=globs.events.subscribe("createGrowJournal",this.createGrowJournal)
    this.loadInfo()
    if(this.props.notify){this.props.notify({id: "newSensor", func: this.onNewSensor})}
    this.dark=((globs.device?.deviceTheme||"").toLowerCase().indexOf("dark")>=0)?1:0
  }

  componentWillUnmount=()=>{
    cl("component unmounted")
    this.mounted=false
    clearInterval(this.saveInt)
    this.saveCurrentPreset()
    this.subscribe_createGrowJournalEvent.remove()
    if(this.props.notify){this.props.notify({id: "newSensor", func: this.onNewSensor, unMount: true})}
  }

  onNewSensor=(sensor)=>{
//     cl(sensor)
    this.handleNewSensor(sensor.data)
  }
  
  setBreadCrumbs=async()=>{
// the breadcrumbs can be /graphing, /sites/xxx/graph, /sites/xxx/zones/xxx/graph, /sites/xxx/zones/xxx/sensor/xxx
// props can contain site: zone: sensor: equip:
    await loadSitesInfo()
    await loadZonesInfo()
    let siteName=getSiteName(this.props.parms.site)
    let zoneName=getZoneName(this.props.parms.zone)
    let presetName=globs.summaryPresetsInfo.info[this.getPresetIndex(this.props.parms.presetId)]?.name||"New Summary"
    let p=this.props.parms
    if(p){
//       cl(this.props)
      let bc=[
        {t:"Sites", url:"/usa/c18/sites"},
      ]
      if(p.site){
        bc.push(
          {t:siteName, url:`/usa/c18/sites/${p.site}`},
        )
      }
      if(p.zone){
        bc.push(
          {t:zoneName, url:`/usa/c18/sites/${p.site}/zones/${p.zone}`},
        )
      }
      if(["viewSummary","editSummary"].includes(p.pageType)){
        bc.push({t:"Summary", url:`/usa/c18/reports/summaryList`})
        if(p.pageType=="viewSummary"){
          bc.push({t:presetName, url:`/usa/c18/reports/viewSummary/${p.presetId}`})
        }else{
          bc.push({t:presetName, url:`/usa/c18/reports/editSummary/${p.presetId}`})
        }
      }
      p.onChange(
        {
          cmd: "breadcrumbs",
          data: {breadcrumbs:bc},
        },
      )
    }
  }
  
  componentDidMount=()=>{
    window.onbeforeunload=x=>{
      this.saveCurrentPreset()
//       x.preventDefault()
    }
    this.mounted=true
  }
  
  mySetState=(source,vals)=>{
    if(this.mounted){
      this.setState(vals)
    }else{
      Object.assign(this.state,vals)
    }
  }
  
  formatTime=(ts)=>{
    let da=new Date(ts*1000)
    let mm=da.getMonth()// 0 based
    let mmm=dateToShortMonth(da)
    let dd=da.getDate()
    let yyyy=da.getFullYear()
    let HR=da.getHours()
    let hr=1+(HR+11)%12
    let ap=(HR>=12)?"PM":"AM"
    let mn=da.getMinutes()
    return `${yyyy} ${mmm} ${dd} ${hr}:${az(mn,2)}${ap}`
  }
  
  getPresetLevel=()=>{
    var level="account"
    if(this.state.site){level="site"}
    if(this.state.zone){level="zone"}
    return level
  }
  
  makeCurrentPreset=()=>{
//     cl("make current")
/* the preset format should allow for readings from different zones and sites*/
// { 
// "_id" : ObjectId("6124e9ab6a4c77b12b4fd113"), 
// "presetId" : "KnZEXee9OtlbX8af", 
// "level" : "zone", 
// "site" : "0sna8jVYIh0xw6oF", 
// "zone" : "0SuR7ufdLEkMlTlh", 
// "name" : "Current", 
// "time" : "l24", 
//   "sensors" : [ { "v" : "inT", "z" : "0SuR7ufdLEkMlTlh" } ], 
// "sensors" : [ "inT" ], 
// "mod" : false, 
// "fromTime" : 1630016259.7604475, 
// "toTime" : 1630150524.7604475, 
// "fromSelect" : 1630099582, 
// "toSelect" : 1630131962, 
// "accountId" : "a036uzDCxohZ7ovD" }
/* we don't have a Current preset for this Level, Site, Zone*/
//     cl(this.props)
//     var site,zone
//     var level="account"
    var now=getTime()
    var fromTime=now-86400
    let level=this.getPresetLevel()
    let zone=(level=="zone")?this.state.zone:globs.zonesInfo.info[0].zoneId
    let cp={
      presetId:getRandomString(16),
      level:level,
      site:this.state.site,
      zone:this.state.zone,
      name:"Current",
      sensors:[{v:"inT",z:zone}],
      mod:false,
      fromTime:fromTime,
      fromSelect:fromTime+(now-fromTime)/4,
      toTime:now,
      toSelect:now-(now-fromTime)/4,
      events: [{v:"grj",z:zone}],
      interval: "Daily",
      interval_size: 1,
      aggregates: ["Max", "Min", "Avg", "Count"],
      vizType: "Summary",
      selectedTs: null,
      showTimeSlider: false,
    }
    cl(cp)
    return cp
  }
  
  getCurrentPreset=()=>{// find a preset for this level and user
// { "_id" : ObjectId("612a3c753b3f691738ab756b"), 
//   "presetId" : "fGnvc1wUTmr8IMuJ", 
//   "level" : "zone", 
//   "site" : "0sna8jVYIh0xw6oF", 
//   "zone" : "0SuR7ufdLEkMlTlh", 
//   "name" : "Aug 24", 
//   "time" : "re", 
//   "sensors" : [ { "v" : "inT", "z" : "0SuR7ufdLEkMlTlh" } ], 
//   "mod" : false, 
//   "fromTime" : 1629788400, 
//   "toTime" : 1629874800, 
//   "fromSelect" : 1629795524.6474445, 
//   "toSelect" : 1629864831.6474445, 
//   "accountId" : "a036uzDCxohZ7ovD", 
//   "t" : "Aug 17", 
//   "v" : "eYtLIZwMe3gU8mbI" }
    
    let p=this.findCurrentPreset(this.state.presetLevel, this.state.site, this.state.zone,
      globs.userData.session.userId
    )
//     cl(p)
    if(p){
      if((p.fromSelect>p.toTime)||(p.fromSelect<p.fromTime)){p.fromSelect=p.fromTime}
      if((p.toSelect>p.toTime)||(p.toSelect<p.fromTime)){p.toSelect=p.toTime}
      if(p.toSelect-p.fromSelect<100){p.toSelect=p.fromSelect+100}
      return p
    }else{
      return this.makeCurrentPreset()
    }
  }
  
  getPresetIndex=(presetId)=>{
    let gspi=globs.summaryPresetsInfo.info
    for(let i=0;i<gspi.length;i++){
      if(gspi[i].presetId==presetId){ return i }
    }
    return -1// not found
  }
  
  savePresetLocal=(pr)=>{// if presetId is found, then update, else insert
//     cl(pr)
    if(!pr){return}
    if((pr.t=="custom")&&(pr.name=="custom")){return}
    cl("save preset local")
    let gspi=globs.summaryPresetsInfo.info
    let i=this.getPresetIndex(pr.presetId)
    if(i>=0){
      Object.assign(gspi[i],pr)
    }else{
//       cl("push")
      gspi.push(Object.assign({},pr))
    }
  }
  
  loadPreset=(presetId)=>{
    let i=this.getPresetIndex(presetId)
    var cpx
    if(i>=0){// if found
      let gspi=globs.summaryPresetsInfo.info
      let pr=Object.assign({},gspi[i])
//       cl(pr)
      let name=pr.name
//       delete pr.name
//       delete pr.presetId
      cpx=Object.assign({},this.state.currentPreset)
      Object.assign(cpx,pr)
//       cl(cpx.t)
//       cl(cpx)
      if(cpx.time=="re"){
        let diff=getTime()-cpx.toTime
        cpx.fromTime+=diff
        cpx.fromSelect+=diff
        cpx.toTime+=diff
        cpx.toSelect+=diff
      }
//       cl(cpx)
      this.makeSensors(cpx)
      let pageTitle=`${name} Summary`
//       cl(cpx.sensors)
//       cl(this.state.presetName)
//       cl(name)
      this.mySetState("a",{
        preset: presetId, 
        presetName: name, 
        saveViewName: name,
        saveViewTime:(cpx.time=="re")?"relative":"absolute", 
        currentPreset:cpx,
        pageTitle:pageTitle})

      this.props.parms.onChange({cmd:"pageTitle",data:{pageTitle:pageTitle}})

      this.doNotify({type:"newTimePeriod", 
        vals:{
          sensors: this.sensors,
          fromTime:cpx.fromTime,
          toTime:cpx.toTime,
          fromSelect:cpx.fromSelect,
          toSelect:cpx.toSelect,
          dataMarkers:cpx.dataMarkers}})
    }else{
//       cl("no preset")
      this.mySetState("b",{preset: presetId, presetName: "", saveViewName: ""})
    }
//     cl(cpx.t)
    return cpx
//     cl("load preset done")
  }
  
  handleNewSensor=(sensorId)=>{// we have a sensorId
    let site=globs.userData.session.siteId
    let zone=this.props.parms.zone||getZoneId(site,0)
    let sensors=[{v:sensorId,z:zone,}]
    let cp={
      time:"l24",
      site:site,
      zone:zone,
      mod:false,
      level:"none",
      name:"custom",
      dataMarkers:false,
      t:"custom",
      sensors:sensors,
      userId:globs.userData.session.userId,
    }
    this.makeTimePeriod(cp)// handles 'l24',etc.
    let diff=cp.toTime-cp.fromTime
    cp.fromSelect=cp.fromTime+diff/4
    cp.toSelect=cp.toTime-diff/4
    this.makeSensors(cp)
    this.doNotify({type:"newSensors", vals:this.sensors})// send to Graph01
  }
  
  setChannelInfo=(zoneId)=>{
//     cl(zoneId)
//     let site=globs.userData.session.siteId
//     let zoneId=this.props.parms.zone||getZoneId(site,0)
    let zone=globs.zonesInfo.info[getZoneIndex(zoneId)]// ||cp.zone is a hack until we can     
//     cl(zone)
//     cl(zoneId)
    let res=getChannelsInfo(zone.siteZoneIndex,this.zoneControllers)// gets for all channels, including expansion controller
//     cl(res)
    let ci={}
    res.forEach(ch=>{
//       cl(ch)
      if(ch.type<constant.EQ_2ND_VENT){
        let u=Math.floor(ch.index/40)
        let id=`e${u}c`+(`00${ch.index}`).slice(-2)
        ci[id]=ch
      }
    })
    Object.keys(ci).forEach(k=>{
      let c=ci[k]
//       cl([k, c])
      this.sensorOptions.push({
        value:k,label:c.name
      })
    })
    
  }
  
  loadInfo=async()=>{
/*load presets: for this level (zone), get all presets for this user, and for public
 save new presets as level and userId, unless having "save public" privileges
time: the standard ones: lw, ly, etc, also re: relative, ab: absolute: use the from to times*/
//     cl("loadinfo")
//     cl(this.props)
/*time periods:
today
yesterday
this week
last week 
this month
last month 
this year
last year
last hour
last 24 hours
last 7 days
last 31 days
last 365 days
to, ye, tw, lw, tm, lm, ty, ly, l1, l24, l7, l31, l365
preset:
  fromSelect
  fromTime
  level
  mod
  name
presetId
sensors
  site
  time
  toSelect
  toTime
  userId
v
  zone



 */      

    await loadSitesInfo()
    await loadZonesInfo()
    // await loadPresetsInfo()
    cl("load summary presets")
    await loadSummaryPresetsInfo()
    await loadPrivsInfo()
    await loadTagsInfo()
    let writePriv=(privs("account",0,constant.AREA_PRIVS_WRITE)!=0)
    let defaultZone=globs.zonesInfo.info.filter(z=>{return z.inNet})[0]
    if(!defaultZone){
      defaultZone=globs.zonesInfo.info[0]
    }
    var cp
    let p=Object.assign({},this.props.parms)
    cl(p)
    let pr=globs.summaryPresetsInfo.info.filter(pr=>{return pr.presetId==p.presetId})[0]
    cl(pr)
    if((!this.state.site)&&(!pr)){
      pr={site:defaultZone.siteId, zone:defaultZone.zoneId}

    }
    cl(this.state)
    cl(pr)
    p.site=this.state.site||pr.site// if pr has a site, and state doesn't'
    p.zone=this.state.zone||pr.zone// zoneId
//     cl(p)
//     cl(this.state)
    await loadSiteData(p.site)
    let zInd=getZoneInfo(p.zone).siteZoneIndex
    this.zoneControllers=getZoneControllers(zInd)
    await this.makeSensorOptions(p.zone,zInd)
//     await setSensorIdNames(this.state.zone)
//     await this.setSensorOptionNames(this.state.zone)

    this.setChannelInfo(p.zone)
//     cl(p.zone)
    if(p.pageType=="editSummary"){
      p.site=globs.userData.session.siteId
      let gzi=globs.zonesInfo.info
      for(let i=0;i<gzi.length;i++){
        if(gzi[i].siteId==p.site){
          p.zone=gzi[i].zoneId
          p.sensorId="inT"
          p.eventId="grj"
          break
        }
      }
    }
    
//     cl(p)
    if((!p.presetId)&&(!p.sensors)&&(p.sensorId)){// this was blocking the Current preset
      p.sensors=[p.sensorId]
      p.period="l24"
      p.site=globs.userData.session.siteId
      p.zone=p.zone||getZoneId(p.site,0)
      p.events=[p.eventId]
    }// creating new graph
    
    if(p.sensors){// custom graph in url
      cl(p)
      this.state.presetLevel="none"
      let sensors=p.sensors.map(s=>{
        return({v:s,z:p.zone,})
      })
      let events=p.events.map(s=>{
        return({v:s,z:p.zone})
      })
      cp={
        time:p.period,
        site:p.site,
        zone:p.zone,
        mod:false,
        level:"none",
        name:"custom",
        dataMarkers:false,
        t:"custom",
        sensors:sensors,
        userId:globs.userData.session.userId,
        interval:"Daily",
        interval_size:1,
        aggregates: ["Max", "Min", "Avg", "Count"],
        vizType: "Summary",
        selectedTs: null,
        showTimeSlider: false,
      }
      this.makeTimePeriod(cp)// handles 'l24',etc.
      let diff=cp.toTime-cp.fromTime
      cp.fromSelect=cp.fromTime+diff/4
      cp.toSelect=cp.toTime-diff/4

    }else{
//       cl(this.props)
      this.state.presetLevel="account"// assumes not mounted
      if(p.site){
        if(p.zone){
          this.state.presetLevel="zone"
        }else{
          this.state.presetLevel="site"
        }
      }
      
      if(["viewSummary","editSummary"].includes(p.pageType)){//this.props.parms.pageType=="viewGraph"){
//         cl("view graph")
//         cl(p.presetId)
        if(p.presetId){
          cl("loading preset")
          cp=this.loadPreset(p.presetId)
        }else{
          cl("making preset")
          cp=this.makeCurrentPreset()
        }
//         cp=globs.presetsInfo.info[getPresetIndex(p.presetId)]
//         cl(cp)
      }else{
//         cl("get current")
        cl("getting preset")
        cp=this.getCurrentPreset()
      }
    }
//     cl(cp)
    this.makeSensors(cp)
//     cl(cp)
    let cmd={from:cp.fromTime, to:cp.toTime}
//     cl(cmd)
    let hist = await this.getHistorySummary(cp)
    this.mySetState("c",{loaded:true, /*presets:presets, */currentPreset:cp,site:p.site,zone:p.zone,
      writePriv:writePriv, historySummary: hist
    })
    this.setBreadCrumbs()
  }
  
  fixSensorId=(s)=>{
//     cl(s)
    if(!["e0","e1","e2","e3"].includes(s.v.substring(0,2))){
      s.v=`e0${s.v}`
    }
//     cl(s.v)
    s.v2=s.v
    let str=s.v.substring(2,4)
//     cl(str)
    if(["ec","ph"].includes(str)){
      s.ofs=+s.v.substring(4)
      s.v2=s.v.substring(0,4)
    }
  }
  
  makeSensors=(cp)=>{// should be done whenever the sensor list changes?
//     console.trace()
//     cl("make graph")
//     cl(dbVals)
//     let cp=this.state.currentPreset
//     cl(this.sensors)
    this.sensors=[]
//     cl(sensorIds)
//     cl(cp)
    if(!cp){return}
//     cl(cp.sensors[0]?.options?.color)
    cl(cp)
    cp.sensors.forEach((s,i)=>{// "c00" is the "sensor" for channel 0
//       cl(s)
      if(!s.v){// filling in zone info if *not* there
        let tmp=s
        s={
          v:tmp,
          z:cp.zone,
        }
      }
      this.fixSensorId(s)
      var sensor
      if((s.v2.substring(2,3)=="c")&&(!isNaN(s.v2.substring(4,6)))){
        sensor=chanPosIds(+s.v2.substr(3))
        let so=(this.sensorOptions.filter(so=>{return so.value==s.v2}))[0]
        if(so.label){sensor.name=so.label}
      }else{
        sensor=sensorIds[s.v2]
      }
      let zone=globs.zonesInfo.info[getZoneIndex(s.z||cp.zone)]// ||cp.zone is a hack until we can creat multi-zone graphs
//       cl(zone)
      var unit
//       cl(sensor)
      if(!sensor){return}
      if(sensor && sensor.type=="addedSensor"){
        unit=sensor.unit().t
      }else{
        unit=sensor.unit(zone.siteZoneIndex).t
      }

      if(zone){
//         cl(sensor)
        this.sensors.push({
          s:zone.siteId,
          z:zone.siteZoneIndex,
          c:sensor.ch+(s.ofs||0),
          i:sensor.id,
          id:s.v,//+((s.ofs!=undefined)?s.ofs:""),//+s.ofs,
          name:`${zone.zoneName} - ${sensor.name}`,
          shortName: sensor.name,
          options:s.options,
          type:sensor.type,
          mult:sensor.mult,
          unit:unit||"",//sensor.unit(zone.siteZoneIndex),
          color: this.colorArr[i],
          min: 0, 
          max: 30, 
          yShow: true,
        })
      }
    })

    
  }

  findCurrentPreset=(level,siteId,zoneId,userId)=>{
    var match
    let gspi=globs.summaryPresetsInfo.info
    for(let i=0;i<gspi.length;i++){
      let pr=gspi[i]
      switch(level){
        case "account":
          match=((level==pr.level)&&(pr.name=="Current"))
          break
        case "site":
          match=((level==pr.level)&&(pr.site==siteId)&&(pr.name=="Current"))
          break
        case "zone":
          match=((level==pr.level)&&(pr.zone==zoneId)&&(pr.name=="Current"))
          break
      }
      match=match&&(pr.userId==globs.userData.session.userId)
      if(match){return pr}
    }
  }
  
  saveCurrentPreset=async()=>{// has to also save to local memory - called when we're leaving the page
//     cl("save current preset")
//     cl(this.props.parms)
//     cl(this.state.currentPreset)
    let cp=Object.assign({},this.state.currentPreset)
//     let pa=this.props.parms
    let st=this.state
    cp.level=this.getPresetLevel()
    let pr=this.findCurrentPreset(cp.level,st.site,st.zone,globs.userData.session.userId)
    cp.presetId=(pr)?pr.presetId:getRandomString(16)
    cp.name="Current"
    cp.userId=globs.userData.session.userId
//     cp.presetId=||getRandomString(16)
//     cl(cp.presetId)
    
    this.savePresetLocal(this.state.currentPreset)
//     cl(cp)
    let hist=await wsTrans("usa", {cmd: "cRest", uri: "/s/summaryPresets", method: "update", 
      sessionId: globs.userData.session.sessionId, body: cp})
  }
  
  saveView=async()=>{
/*how do we know if this is a new preset, or an updated one?
Select a current preset: fill in presetName field, and saveViewName
*/
    let st=this.state
    cl(this.state)
    if(this.state.saveViewName){
      
//       let gpi=globs.presetsInfo.info
      let pr=Object.assign({},this.state.currentPreset)
      let saveIt=true
      if(this.state.presetName!=this.state.saveViewName){// create a new preset
        pr.presetId=getRandomString(16)// save as a new preset
        pr.name=this.state.saveViewName
        pr.userId=globs.userData.session.userId
        cl(pr)
      }else{// overwrite an existing preset
        let res=await this.props.parms.getPopup({text:"Are you sure you want to replace this View?", buttons:["Cancel","Yes"]})
        saveIt=(res=="Yes")
      }
      if(saveIt){
//         pr.userId=globs.userData.session.userId
        pr.ts=Math.round(getTime())
  //       cl(pr.sensors[0])
        pr.time=(this.state.saveViewTime=="absolute")?"ab":"re"
        pr.site=st.site
        this.savePresetLocal(pr)
//         cl(pr)
        globs.events.publish("viewsUpdated",pr.presetId)
        wsTrans("usa", {cmd: "cRest", uri: "/s/summaryPresets", method: "update", 
          sessionId: globs.userData.session.sessionId, body:pr})
        this.mySetState("d",{presetName:this.state.saveViewName,saveMsg:"Saving",preset:pr.presetId, className: "outlined"})
        this.saveInt=setInterval(()=>this.mySetState("ClearSave",{saveMsg:"Save", className: "filled"}),3000 )
      }
    }
  }
  
  deleteViewLocal=(presetId)=>{
    let index=this.getPresetIndex(presetId)
    if(index>=0){
      cl("slice")
      globs.summaryPresetsInfo.info.splice(index,1)
    }
    cl(globs.summaryPresetsInfo.info)
  }
  
  deleteView=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this view?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      if(this.state.preset){
        cl("deleteView view")
        this.deleteViewLocal(this.state.preset)
        globs.events.publish("viewsUpdated")
        wsTrans("usa", {cmd: "cRest", uri: "/s/summaryPresets", method: "delete", 
          sessionId: globs.userData.session.sessionId, body:{presetId:this.state.preset}})
      }
    }
  }

  aggregateOptions=[
    {value:"Max",label:"Max"},//23
    {value:"Min",label:"Min"},//27
    {value:"Avg",label:"Average"},//27
    {value:"Count",label:"Count"},//27
  ]

  sensorOptions=[
    {value:"inT",label:"Inside Temperature"},//23
    {value:"inH",label:"Inside Humidity"},//27
    {value:"inL",label:"Inside Light"},//32
    {value:"inC",label:"Inside CO2"},//31
    {value:"ouT",label:"Outside Temperature"},//24
    {value:"ouH",label:"Outside Humidity"},//28
    {value:"ouL",label:"Outside Light"},//33
    {value:"bpT",label:"Black Plate Temp"},//80
    
    {value:"oWs",label:"Wind Speed"},//80// new
    {value:"oWd",label:"Wind Direction"},//80
    {value:"dPr",label:"Differential Pressure"},//80
    {value:"bPr",label:"Barometric Pressure"},//80
    {value:"ran",label:"Rain"},//80
    {value:"sno",label:"Snow"},//80
    {value:"vpd",label:"VPD"},//80

    {value:"stT",label:"Temperature Stage"},//6
    {value:"stH",label:"Humidity Stage"},//7
    {value:"spH",label:"Heat Setpoint"},//8
    {value:"spC",label:"Cool Setpoint"},//9
    {value:"spU",label:"Humidify Setpoint"},//10
    {value:"spD",label:"DeHum Setpoint"},//11
    {value:"alH",label:"High Alarm"},//19
    {value:"alL",label:"Low Alarm"},//18
    {value:"c00",label:"Channel 1 Position"},//104+channel snap offset *and* channel
    {value:"c01",label:"Channel 2 Position"},
    {value:"c02",label:"Channel 3 Position"},
    {value:"c03",label:"Channel 4 Position"},
    {value:"c04",label:"Channel 5 Position"},
    {value:"c05",label:"Channel 6 Position"},
    {value:"c06",label:"Channel 7 Position"},
    {value:"c07",label:"Channel 8 Position"},
    {value:"c08",label:"Channel 9 Position"},
    {value:"c09",label:"Channel 10 Position"},
    {value:"c10",label:"Channel 11 Position"},
    {value:"c11",label:"Channel 12 Position"},
    {value:"at0",label:"Analog Temperature 1"},
    {value:"at1",label:"Analog Temperature 2"},
    {value:"at2",label:"Analog Temperature 3"},
    {value:"at3",label:"Analog Temperature 4"},
    {value:"at4",label:"Analog Temperature 5"},
    {value:"vp0",label:"Vent Position 1"},
    {value:"vp1",label:"Vent Position 2"},
    {value:"vp2",label:"Vent Position 3"},
    {value:"vp3",label:"Vent Position 4"},
    {value:"vp4",label:"Vent Position 5"},
    {value:"sm0",label:"Soil Moisture 1"},
    {value:"sm1",label:"Soil Moisture 2"},
    {value:"sm2",label:"Soil Moisture 3"},
    {value:"sm3",label:"Soil Moisture 4"},
    {value:"sm4",label:"Soil Moisture 5"},
    {value:"ec0", label:"EC Tank 1"},//203 + offset + tank
    {value:"ph0", label:"pH Tank 1"},//218
    {value:"tp0", label:"Temp Tank 1"},//233
    {value:"ec1", label:"EC Tank 2"},
    {value:"ph1", label:"pH Tank 2"},
    {value:"tp1", label:"Temp Tank 2"},
    {value:"ec2", label:"EC Tank 3"},
    {value:"ph2", label:"pH Tank 3"},
    {value:"tp2", label:"Temp Tank 3"},
    {value:"ec3", label:"EC Tank 4"},
    {value:"ph3", label:"pH Tank 4"},
    {value:"tp3", label:"Temp Tank 4"},
    {value:"ec4", label:"EC Tank 5"},
    {value:"ph4", label:"pH Tank 5"},
    {value:"tp4", label:"Temp Tank 5"},
    {value:"ec5", label:"EC Tank 6"},
    {value:"ph5", label:"pH Tank 6"},
    {value:"tp5", label:"Temp Tank 6"},
    {value:"ec6", label:"EC Tank 7"},
    {value:"ph6", label:"pH Tank 7"},
    {value:"tp6", label:"Temp Tank 7"},
    {value:"ec7", label:"EC Tank 8"},
    {value:"ph7", label:"pH Tank 8"},
    {value:"tp7", label:"Temp Tank 8"},
  ]
  
  makeSensorOptions=async(zone,zInd)=>{
    cl(zone,zInd)
//     cl(zone)
//     let zInd=getZoneInfo(zone).siteZoneIndex
    initSensorIds(zInd)
//     cl(Object.keys(sensorIds))
//     cl(zone)
    if(zone.zone=="allZones"){}else{
      await setSensorIdNames(zone)
    }
//     cl(sensorIds)
    this.sensorOptions=[]
    Object.keys(sensorIds).forEach(k=>{
      let s=sensorIds[k]
//       cl([k,s])
      this.sensorOptions.push({
        value:k,
        label:s.name
      })
    })
    this.sensorOptions.sort((a,b)=>{
      if(a.value>b.value){return 1}
      if(a.value<b.value){return -1}
      return 0
    })
  }
  
  showTitle=()=>{
    cl(this.state)
//     let level=this.getPresetLevel()
    
    return(
      <>
        <h2>{this.state.pageTitle}</h2>
        <div className="clearfloat"></div>
      </>
    )
  }
  
  downloadCsv=async()=>{
//from=1630399532&to=1630421614}&v0=inT&z0=0SuR7ufdLEkMlTlh    
//     cl(this.sensors)
    let cp=this.state.currentPreset
    let da0=new Date()
    let tzo=da0.getTimezoneOffset()
    cl(tzo)
    let query=`session=${globs.userData.session.sessionId}&type=summary&from=${Math.floor(cp.fromTime)}&to=${Math.floor(cp.toTime)}&tzo=${tzo}&interval=${cp.interval}&interval_size=${cp.interval_size}&aggregates=${cp.aggregates}`
    // cp.showAggregateOptions.forEach((a)=>{
    //   query+=`&a${a}`
    // })
    this.sensors.forEach((s,i)=>{
      query+=`&s${i}=${s.s}&z${i}=${s.z}&c${i}=${s.c}&i${i}=${s.i}`
    })
    cl(query)
    let da=dateToDisplayDate(da0,"yyyy_mm_dd_hh_mm_ss",tzo)
    cl(da)
    let url=`${constant.expressUrl}/usa/csv/download_${da}.csv?${query}`
    cl(url)
    window.location.href=url
  }
  
  toggleShowTimePeriod=()=>{
//     cl(this.state.showTimePeriod)
    let show=(this.state.showTimePeriod=="none")?"block":"none"
    this.mySetState("e",{showTimePeriod:show})
  }
  
  makeTimePeriod=(cp)=>{
    let h1=Math.floor((new Date()).getTime()/1000)
    let h0=h1-24*3600
    switch(cp.time){
      case "l24":
        cp.fromTime=h0
        cp.toTime=h1
        break
    }
    
  }

  showAggregates=()=>{
      return(
        <div className="time-period-container">
          <div className="title">Select Functions</div>
          <C18Button00 className="material-icons"
            onClick={()=>{this.onChange("aggregateOptions",{type:"OK",showAggregateOptions:!this.state.showAggregateOptions})}}>
              settings
          </C18Button00>
        </div>
      )
  }

  showVizOptions=()=>{
    let cp=Object.assign({},this.state.currentPreset)
    let types
    if (acctFeature("advancedDataViz")) {
      types=[
        ["Summary", "Summary"],
        ["Bar", "Bar"],
        ["Heat Map", "HeatMap"],
        ["Calendar", "Calendar"],
        ["Scatter Plot", "ScatterPlot"],
      ]
    } else {
      types=[
        ["Summary", "Summary"],
      ]
    }

      return(
        <div className="time-period-container">
          <div className="title">Visualization Type</div>
          <div className="custom-select">
            <C18Select00 
              id="visualization-type"
              onChange={(e)=>this.onChange("vizType", e.currentTarget.value)}
              value={cp.vizType}
            >
              {types.map((t,i)=>{
                return(
                  <option key={i} value={t[1]}>{t[0]}</option>
                )
              })}
            </C18Select00>
            <span className="material-icons down-arrow">
              keyboard_arrow_down
            </span>
          </div>
        </div>
      )
  }

  showTimeSliderToggle=()=>{
    let cp=Object.assign({},this.state.currentPreset)
    return(
        <>
          <div className="float-left-box data-markers-box">
            <label>Show time slider</label><input type="checkbox" 
            checked={this.state.currentPreset.showTimeSlider||false}
            onChange={e=>{this.onChange("showTimeSlider",{showTimeSlider:e.currentTarget.checked})}}/>
          </div>
          <div className="clearfloat"></div>
        </>
    )
  }

  showInterval=()=>{
    let cp=Object.assign({},this.state.currentPreset)
    cl(cp)
    let intervals=[
      ["Hourly", "Hourly"],
      ["Daily", "Daily"],
      ["Weekly", "Weekly"],
      ["Monthly", "Monthly"],
    ]

      return(
        <div className="time-period-container">
          <div className="title">Data Interval</div>
          <div className="custom-select">
            <C18Select00 
              id="report-interval"
              onChange={(e)=>this.onChange("interval", e.currentTarget.value)}
              value={cp.interval}
            >
              {intervals.map((t,i)=>{
                return(
                  <option key={i} value={t[1]}>{t[0]}</option>
                )
              })}
            </C18Select00>
            <span className="material-icons down-arrow">
              keyboard_arrow_down
            </span>
          </div>
        </div>
      )
  }
  
  showTimePeriod=()=>{
//     cl(this.state.timePeriod)
//     const selectionRange = {
//       startDate: new Date(),
//       endDate: new Date(),
//       key: 'selection',
//     }
    
//         <DateRangePicker
//           ranges={[this.state.timePeriod]}
//           onChange={o=>{this.onChange("timePeriod",o)}}
//           inputRanges={[]}
//         />
//     cl(this.state.timePeriod)
//     cl(this.state.currentPreset)
    let cp=Object.assign({},this.state.currentPreset)
//     this.makeTimePeriod(cp)
//     cl(cp)
//     cl(this.state.currentPreset)
//     cl(this.state.currentPreset.fromTime)
//     cl(cp.fromTime)
    
    let tp={
      startDate: new Date(1000*cp.fromTime),
      endDate: new Date(1000*cp.toTime),
      key:'selection',
    }
//     cl(tp.startDate)
//     cl(startDate)
    let startDateText=this.formatTime(cp.fromTime)
    let endDateText=this.formatTime(cp.toTime)
//     cl(cp.fromTime)
//     cl(startDateText)
//     cl(startDateText)
//     cl(tp)
    return (
      <div className="time-period-container">
      <div>
        <div className="title">Custom Period</div>
        <C18Button00 type="button" className="icon" onClick={this.toggleShowTimePeriod}>
        {`${startDateText} to ${endDateText}`}
          
          <span className="material-icons">
            keyboard_arrow_down
            </span>
        </C18Button00>
      </div>
      <div style={{position: "relative"}} onClick={e=>{e.preventDefault(); e.stopPropagation()}}>
        <div style={{position: "absolute", display: this.state.showTimePeriod}}>
          <DateRangePicker
            ranges={[tp]}
            onChange={o=>{this.onChange("timePeriod",o)}}
            inputRanges={[]}
//             onClick={this.onDateClick}
          />
        </div>
      </div>
      </div>
      
    )
  }
  
  getSensorName=(id)=>{// this is done in the render routine
    let so=this.sensorOptions
//     cl(id,so)
    for(let i=0;i<so.length;i++){
      if(so[i].value==id){return so[i].label}
    }
  }
  
  makeChartElements=()=>{
//     cl(this.props)
//     cl(this.state.zone)
    let ce=[]
//     cl(this.state.currentPreset.sensors)
    this.state.currentPreset.sensors.forEach(s=>{
//       cl(s)
      if(s.z==this.state.zone){
//         cl("push")
        if(s.v){
          ce.push({value:s.v, label:this.getSensorName(s.v)})
        }else{
          ce.push({value:s, label:this.getSensorName(s)})
        }
      }
    })
//     cl(ce)
    return ce
    
  }
  
  showChartElements=()=>{
//     let options = [
//       { value: 'chocolate', label: 'Chocolate' },
//       { value: 'strawberry', label: 'Strawberry' },
//       { value: 'chocolate2', label: 'Chocolate' },
//       { value: 'strawberry2', label: 'Strawberry' },
//       { value: 'chocolate3', label: 'Chocolate' },
//       { value: 'strawberry3', label: 'Strawberry' },
//       { value: 'chocolate4', label: 'Chocolate' },
//       { value: 'strawberry4', label: 'Strawberry' },
//       { value: 'chocolate5', label: 'Chocolate' },
//       { value: 'strawberry5', label: 'Strawberry' },
//       { value: 'vanilla', label: 'Vanilla' }
//     ]
// //     let {selectedOption}=this.state//"vanilla"
// //     let {selectedOption}=this.state//"vanilla"
// //     cl(selectedOption)
//     let selectedOption=[
//       { value: 'chocolate4', label: 'Chocolate' },
//       { value: 'strawberry4', label: 'Strawberry' },
//     ]

//         <C18Button00 type="button" className="icon">Inside Temperature
//           <span className="material-icons">
//             keyboard_arrow_down
//           </span>
//         </C18Button00>
//     this.makeChartElements()
//     cl(this.state)
    cl(this.sensorOptions)
    return(
      <div className="chart-elements-container">
        <div className="title">Chart Elements</div>
        <Select
          value={this.makeChartElements()}
          onChange={o=>{this.onChange("chartElements",o)}}
          options={this.sensorOptions}
          isMulti={true}
        />        
      </div>
    )
  }
  
//     loadGJ=async(cmd)=>{
// //       cl(cmd)
      
//       // loading gj should be possible without any sensors selected
//       cmd.sensors.forEach(s=>{// each sensor has site and siteZoneIndex
// //         cl(s)
//         sites.push(globs.zonesInfo.sz2z[s.s][s.z].siteId)
//         zones.push(globs.zonesInfo.sz2z[s.s][s.z].zoneId)
//       })
//       cl(sites)
//       cl(zones)// array of zoneIds-
//       let query={dispTime:{$gt:cmd.from,$lte:cmd.to},original:true}
//       // only check sites if level is site
//       query["$or"]=[{$and:[{siteId:{$in:sites}},{level: "site"}]},{zoneId:{$in:zones}}]
// //       cl(query)
//       let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "retrieve2", 
//         sessionId: globs.userData.session.sessionId, body: query})
// //       cl(res)
// //       let gj={}
// //       res.data.forEach(r=>{gj[r.zoneId]=r})
//       return res.data
//   }

    loadCTE=async(cmd)=>{
      // cl(cmd)
      let gateways = ["4"]
      let sites = ["d1fIue45CWvP@Nik"]
      // TODO more ways to specify which controller we want events from?
      // load controller events
      cmd.sensors.forEach(s=>{// each sensor has gatewayId and siteId for querying
        gateways.push(globs.zonesInfo.sz2z[s.s][s.z].gatewayId)
        sites.push(globs.zonesInfo.sz2z[s.s][s.z].siteId)
      })
      // cl(gateways)
      // cl(sites)
      let now=getTime()
      let rtdEvents=await wsTrans("usa", {cmd: "cRest", uri: "/s/rtdEvents", 
        method: "retrieve", sessionId: globs.userData.session.sessionId, 
        body: {s:{$in: sites},from:0,to:now}})
      let events = []
      rtdEvents.data.forEach(ev=>{
        let event = {}
        if (ev.d) {
          Object.assign(event, JSON.parse(ev.d))
          // process event text for line breaks?
          // cl(event.eventText)
          // if (event.eventText) event.eventText = event.eventText.replaceAll("\n", "</br>")
          event.dispTime = ev.t
          // cl(event)
          events.push(event)
        }
      })
      // cl(events)
      return events
    }    

    loadGJ2=async(cmd)=>{
      // cl(cmd)
      let sites=["d1fIue45CWvP@Nik"]
      let zones=[]
      // loading gj should be possible without any sensors selected???
      // cmd.events.forEach(s=>{// each sensor has site and siteZoneIndex
      //   cl(s)
      //   // check if s.z is an index or a zone id
      //   zones.push(globs.zonesInfo.sz2z[s.s][s.z].zoneId)
      // })
      // get zones from sensors as well
      cmd.sensors.forEach(s=>{// each sensor has site and siteZoneIndex
        // cl(s)
        // check if s.z is an index or a zone id
        sites.push(globs.zonesInfo.sz2z[s.s][s.z].siteId)
        zones.push(globs.zonesInfo.sz2z[s.s][s.z].zoneId)
      })
      // cl(zones)// array of zoneIds-
      let query={dispTime:{$gt:cmd.from,$lte:cmd.to},original:true}
      // only check sites if level is site
      query["$or"]=[{$and:[{siteId:{$in:sites}},{level: "site"}]},{zoneId:{$in:zones}}]
      // let query={zoneId:{$in:zones},dispTime:{$gt:cmd.from,$lte:cmd.to},original:true}
      // cl(query)
      let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "retrieve2", 
        sessionId: globs.userData.session.sessionId, body: query})
      // cl(res)
//       let gj={}
//       res.data.forEach(r=>{gj[r.zoneId]=r})
      return res.data
  }

  // pass mouse event so we know where to put the newly created grow journal
  createGrowJournal=async(cmd)=>{
    cl("make grow journal")
    let pos = cmd.pos
    cmd = cmd.cmd
    // make grow journal entry with data from selected sensor/time, then bring it up for view/edit
    // cl(pos)
    cl(cmd)
    // determine whether equipment
    let level = "zone"
    let tags = []
    let da=Math.floor(getTime())//new Date().getTime()/1000
    let isEq = new RegExp("e[0-9]+c[0-9]+").test(cmd.id)
    // cl(isEq)
    if (isEq) {
      let zInfo=globs.zonesInfo.info[cmd.zInd]
      // use ts from datapoint
      let pageType = getChannelType(zInfo.gatewayType, cmd.zInd, cmd.c)
      // cl(pageType)
      if (pageType) {
        let unit = Math.floor(cmd.c/40)
        let zuci = `${cmd.zInd}-${unit}-${cmd.c}-0`
        // cl([unit, zuci, `${pageType}-${zuci}`])
        tags.push(`${pageType}-${zuci}`)
        level = "config"
      }
    }
    if (cmd.id == "cte") level = "site"
    // find tag if equipment
    // level is config if equipment
    let gjEntry={
      threadId: getRandomString(16),
      growJournalId: getRandomString(16),
      // level:p.level,
      level: level, // zone if sensor, channel if eq, site if doser
      siteId: cmd.s,
      zoneId: globs.zonesInfo.sz2z[cmd.s][cmd.zInd]?.zoneId || "",
      userId: globs.userData.session.userId,
      body: "",//this.state.note,
      subject: "",//this.state.gjSubject,
      modTime: da,//this.state.modTime,//Math.floor(this.state.dispTime.getTime()/1000),
      dispTime: cmd.t/1000,//this.state.dispTime,//Math.floor(this.state.modTime.getTime()/1000),
      tags: tags,
      images: [],
      original: true,
    }
    // cl(gjEntry)
    // this.setState({images:[],tags:[],note:""})
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "create", 
      sessionId: globs.userData.session.sessionId,
      body: gjEntry})
    // cl(res)
    if (res.result == "ok") {
      let res2=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "retrieve2", 
        sessionId: globs.userData.session.sessionId, body: {growJournalId: gjEntry.growJournalId}})
      // cl(res2.data)
      let data = res2.data[0]
      // cl(data)
      if (data) {
        // refresh event timeline?
        data.left=pos.left
        data.top=pos.top
        this.setState({gjEntry:data, gjEdit: true})
      }
    } else {
      cl("error making gj entry")
    }
  }
  
  fixAddedSensorUnits=(cmd,hist)=>{
//     cl(cmd)
//     cl(hist)
    if(!hist.data){return}
    cmd.sensors.forEach((s,i)=>{
//       cl(s)
      if(s.type=="addedSensor"){
        let key=`${s.s}-${s.z}-${s.c}-${s.i}`
        hist.data.sensors[key].forEach((d,i)=>{
          d.d=d.d/s.mult
        })
      }
    })
  }
  
  cleanZeroData=(hist)=>{
//     cl(hist)
    let sensors=hist.data.sensors
    let totDif=1
    let cntDif=0
    Object.keys(sensors).forEach(k=>{
      let se=sensors[k]
      for(let i=1;i<se.length-1;i++){
        let dif=Math.abs(se[i].d-se[i-1].d)
        let avgDif=totDif/cntDif
        if((se[i].d==0)||(dif/avgDif>10)){
          se[i].d=((se[i+1]?.d||0)+(se[i-1]?.d||0))/2
          i+=1
        }else{
          totDif+=dif
          cntDif+=1
        }
      }
//       cl(k)
    })
    
  }

  getInterval=(interval)=>{
    cl(interval)
    switch (interval) {
      case "Hourly":
        return 60 * 60
      case "Daily":
        return 60 * 60 * 24
      case "Weekly":
        return 60 * 60 * 24 * 7
      case "Monthly":
        return 60 * 60 * 24 * 31
    }

  }

  makeCommand=(cp)=>{
    // let cp=Object.assign({},this.state.currentPreset)
    cp.fromSelect=cp.fromSelect||cp.fromTime
    cp.toSelect=cp.toSelect||cp.toTime
    cl(cp)
    let cmd={
      from: cp.fromSelect, 
      to: cp.toSelect, 
      interval: cp.interval, 
      interval_size: cp.interval_size, 
      aggregates: cp.aggregates, 
      type: "arr",}
    let sensors=[]
//     cl(this.sensors)
    this.sensors.forEach(s=>{
      sensors.push(Object.assign({},s,{yShow: true}))
    })
    sensors.forEach(s=>{
      s.mode="default"
      if(s.options){
        s.color=s.options.color
        s.min=s.options.min 
        s.max=s.options.max
        s.mode=s.options.mode
      }
    })
    cmd.sensors = []
    sensors.forEach(s=>{
      cl(s)
      cmd.sensors.push({s:s.s, z:s.z, c:s.c, i:s.i,type:s.type,
        unit:s.unit,mult:s.mult, name:s.shortName, id:s.id})
    })
    cmd.type = "report"
    return cmd
  }

  getHistorySummary=async(cp)=>{
    let th=this.state.historySummary
    // make command
    let cmd = this.makeCommand(cp)
    cl(cmd)
    return new Promise(async(r,e)=>{
      let hist=await wsTrans("usa", {cmd: "cRest", uri: "/s/historySummary", method: "retrieve", 
        sessionId: globs.userData.session.sessionId, body: cmd})
      
//       cl("got")
      this.fixAddedSensorUnits(cmd,hist)
      this.cleanZeroData(hist)
      th.data=hist.data
      if(th.res){th.res.forEach(re=>{re(th.data)})}// activate the list of responses
      delete th.res
//       cl(th.data)
      th.from=cmd.from
      th.to=cmd.to
      // cl("got data")
      th.pending = false
      r(th.data)
//       cl(th.from)
    })
  }
  
  notify=(note)=>{// register a notify function from child component, Graph01
//     cl(note)
    if(!(note.id in this.notifies)){this.notifies[note.id]=[]}
    this.notifies[note.id].push(note.func)
//     cl(this.notifies)
  }
  
  doNotify=(o)=>{
    if(o.type in this.notifies){
      this.notifies[o.type].forEach(f=>{
        f(o.vals)
      })
    }
    // this.onChange("getHistorySummary")
  }

  getElements=(vals)=>{
    let ce=[]
    vals.forEach(v=>{
      ce.push({v:v.value,z:this.state.zone}/*v.value*/)
    })
//     cl(ce)
    return{sensors:ce}
    
  }
    
  onChange=async(type, vals)=>{
//     cl([type,vals])
    var cp
    var v
    var hist
    if(vals){v=vals.vals} 
    var sensorUnits={inT:"\u00B0F",inH:"%"}
//     let v=vals.vals
    switch(type){
      case "graph":
        switch(vals.type){
          case "selectChange":
            this.doNotify(vals)
            cp=Object.assign({},this.state.currentPreset,{fromSelect:v.fromVal, toSelect:v.toVal})
            this.mySetState("selectChange",{currentPreset:cp})
            break
          default:
            break
        }
          break
      case "chartElements":
        let newSensors=this.getElements(vals).sensors
        var oldSensors=[]
        this.state.currentPreset.sensors.forEach(s=>{
          if(s.z!=this.state.zone){oldSensors.push(s)}
        })
        cp=Object.assign({},this.state.currentPreset,{sensors:oldSensors.concat(newSensors)})
        this.makeSensors(cp)
        this.doNotify({type:"newSensors", vals:this.sensors})// notify other graph of change
        hist = await this.getHistorySummary(cp)
        this.mySetState("i",{currentPreset:cp, historySummary: hist})
        break
      case "chartAggregates":
        var newAggr = []
        cp=Object.assign({},this.state.currentPreset)
        let ag = cp.aggregates.find(e=>e == vals.id)
        if (ag == null) {
          newAggr.push(vals.id)
        }
        var oldAggr=[]
        if (cp.aggregates && cp.aggregates.length) {
          cp.aggregates.forEach(s=>{
            // don't include vals.id
            // cl([s.z, this.state.zone])
            if(s != vals.id){oldAggr.push(s)}
            // if(s.v != vals.id){oldEvents.push(s)}
          })
        }
        cl(oldAggr)
        cl(newAggr)
        cp=Object.assign({},this.state.currentPreset,{aggregates:oldAggr.concat(newAggr)})
//         cl(cp)
        this.doNotify({type:"newAggregates", vals:cp.aggregates})// notify other graph of change
        hist = await this.getHistorySummary(cp)
        this.mySetState("i",{currentPreset:cp, historySummary: hist})
        break
      case "dateRange":
        cl("dateRange",vals)
        break
      case "timePeriod":
//         cl("time period")
        let fromTime=Math.floor(vals.selection.startDate.getTime()/1000)
        let toTime=Math.floor(vals.selection.endDate.getTime()/1000)
        
        if(toTime<=fromTime){toTime=fromTime+3600}
        let cp2=Object.assign({},this.state.currentPreset,{fromTime: fromTime, toTime: toTime})
        cp2.fromSelect=cp2.fromTime
        cp2.toSelect=cp2.toTime
//         cl(cp2.toTime-cp2.fromTime)
//         cl(cp2.toSelect-cp2.fromSelect)
//         cl(cp2)
//         cl(fromTime,toTime)
        this.doNotify({type:"newTimePeriod", vals:{fromTime:fromTime,toTime:toTime,
          dataMarkers:this.state.currentPreset.dataMarkers,
        }})
        // cl(this.state)
        hist = await this.getHistorySummary(cp2)
        this.mySetState("set Time Period",{currentPreset:cp2, historySummary: hist})
//         cl(cp2.fromTime)
//         cl(this.state.currentPreset.fromTime)
//         cl(this.state.currentPreset)
        break
      case "interval":
        cl(type,vals)
        cp=Object.assign({},this.state.currentPreset,{interval: vals})
        this.doNotify({type:"interval", vals: vals})// notify other graph of change
        hist = await this.getHistorySummary(cp)
        this.mySetState("set Interval",{currentPreset:cp, historySummary: hist})
        break
      case "vizType":
        cl(type,vals)
        cp=Object.assign({},this.state.currentPreset,{vizType: vals})
        // this.doNotify({type:"interval", vals: vals})// notify other graph of change
        // hist = await this.getHistorySummary(cp)
        // this.mySetState("set Interval",{currentPreset:cp, historySummary: hist})
        this.mySetState("set vizType",{currentPreset:cp})
        break
      case "timeSlider":
        cl(type,vals)
        cp=Object.assign({},this.state.currentPreset,{selectedTs: vals.timeVal})
        cl(cp)
        this.mySetState("set timeSlider",{currentPreset:cp})
        break
      case "showTimeSlider":
        cl(type,vals)
        cp=Object.assign({},this.state.currentPreset,{showTimeSlider: vals.showTimeSlider})
        if (!cp.selectedTs && this.state.historySummary?.report) {
          cp=Object.assign({},cp,{selectedTs: this.state.historySummary?.report[0].ts})
        }
        cl(cp)
        this.mySetState("set showTimeSlider",{currentPreset:cp})
        break
      case "szv":
        cl(type,vals)
        switch(vals.cmd) {
          case "deleteView":
            this.deleteView()
            break
          case "newPreset":
//             cl(vals)
            this.loadPreset(vals.vals.preset)
//             cl("loaded preset")
//             cl(this.state.currentPreset)
            break
          case "newSiteZone":
            cl(vals)
            await loadSiteData(vals.site)
//             await this.makeSensorOptions(vals.zone)
            let zi=globs.zonesInfo.info.filter(
              z=>{return z.zoneId==vals.zone})[0]
            cl(zi)
            await this.makeSensorOptions(vals.zone,zi?.siteZoneIndex)

            this.setChannelInfo(vals.zone)

            // cl("is this being called?")
            cl(vals)

            this.mySetState("newSiteZone",{site:vals.site,zone:vals.zone})
            cl(this.state)
            break
        }
//         cl([type,vals])
        break
      case "saveViewTime":
      case "saveViewName":
        this.mySetState("l",vals)
        break
//         this.mySetState("m",vals)
//         break
      case "saveView":
        this.saveView()
        break
      case "downloadCsv":
        this.downloadCsv()
        break
      case "gjClose":
        this.setState({gjEntry:null, gjEdit: false})
        break
      case "mainDiv":
        if(this.state.showTimePeriod=="block"){this.toggleShowTimePeriod()}
//         cl("close")
        break
      case "aggregateOptions":
        cp=Object.assign({},this.state.currentPreset)
        // cl(vals)
        switch(vals.type){// saving options
          case "OK":
            // let events=cp.events?.slice(0) || {}
//             cl(sensors)
            // let s=this.state
            // set event shown  based
//             cl(sensors[0].options.showGrid)
            // events.options={
              // showGrowJournal:s.eventOptionsShowGrowJournal,
            // }
//             cl(sensors[0].options.showGrid)
//             cl(sensors)
            // Object.assign(vals,{currentPreset:cp})
          case "Cancel":
          default:
            this.mySetState("aggr Opts",vals)
            break
        }
        break
      // case "gjEntry":
      //   // cl(vals)
      //   let entries
      //   switch (vals.cmd) {
      //     case "deleteEntry":
      //       // cl("delete entry")
      //       entries = this.events.find(e => e.id == "grj").data
      //       if (entries && entries.length) {
      //         entries = entries.slice(0)
      //         // cl(entries)
      //         this.deleteGJEntry(entries, vals)
      //         this.setState({gjEntry:null, gjEdit: false})
      //       }
      //       break
      //     case "addGJEntry":// when an entry is edited
      //       // cl("add entry")
      //       // cl(JSON.stringify(this.events))
      //       // sometimes events doesn't have data - when is it given data
      //       entries = this.events.find(e => e.id == "grj").data
      //       if (entries && entries.length) {
      //         entries = entries.slice(0)
      //         // cl(entries)
      //         this.updateGJEntry(entries,vals.gjEntry)
      //         this.events.find(e => e.id == "grj").data = entries
      //         let entry = Object.assign(vals.gjEntry, {top: this.state.gjEntry.top, left: this.state.gjEntry.left})
      //         this.setState({gjEntry:entry})
      //         // cl(entry)
      //         this.doNotify({type:"newEvents", vals:this.events})// send to Graph01
      //       }
      //       break
      //     case "image":
      //       this.props.parms.onChange({cmd:"showImage", vals: vals})
      //       break
      //   }
      default:
        break
    }
  }

  updateGJEntry=(entries,entry)=>{
    for(let i=0;i<entries.length;i++){
      if(entries[i].data.growJournalId==entry.growJournalId){
        entries[i].data=entry
        break
      }
    }
  }

  deleteGJEntry=(entries,vals) => {
    var query
    switch(vals.type){
      case "main":
        query={threadId:vals.id}
        for(let i=0;i<entries.length;i++){
          if(entries[i].data.threadId==vals.id){
            entries.splice(i,1)
          }
        }
        break
      case "reply":
        query={growJournalId:vals.id}
        break
    }
    cl(query)
    wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "delete", 
      sessionId: globs.userData.session.sessionId,
      body: query})
  } 
  
  showDownloadCsv=()=>{
//     cl("download csv")
    return(
      <div className="float-left-box bottom-margin">
        <C18Button00 id="downloadCsv" className="icon"
        onClick={e=>this.onChange("downloadCsv")}>
          Download CSV
          <span className="material-icons">
          file_download
          </span>
        </C18Button00>
      </div>
    )
  }
  
//   onChange=()=>{
//     cl("change")
//   }
//   
  showSaveThisView=()=>{
    return(
      <div className="float-right-box">
        <h3>Save this view</h3>
        <C18Input00 id="graphingPreset" type="text" placeholder="Name" 
          value={this.state.saveViewName}
          onChange={e=>this.onChange("saveViewName",{saveViewName:e.currentTarget.value})}
        />
        
        <C18Input00 type="radio" id="time-absolute" name="timeselect" value="absolute"
          checked={this.state.saveViewTime=="absolute"}
          onChange={e=>this.onChange("saveViewTime",{saveViewTime:e.target.value})}
        ></C18Input00>
        <label htmlFor="time-absolute">Absolute Time</label>

        <C18Input00 type="radio" id="time-relative" name="timeselect" value="relative"
          checked={this.state.saveViewTime=="relative"}
          onChange={e=>this.onChange("saveViewTime",{saveViewTime:e.target.value})}
        ></C18Input00>
        <label htmlFor="time-relative">Relative Time</label>

        <div className="spacer"></div>
        <C18Button00 type="button" className={this.state.className} onClick={o=>{this.onChange("saveView")}}>{this.state.saveMsg}</C18Button00>
      </div>
    )
  }
  
  onDateClick=(e)=>{
    cl(e)
    e.preventDefault()
    e.stopPropagation()
  }
  
  onClick=(type)=>{
//     cl(type)
//     cl(this.state.showTimePeriod)
//     cl("on click")
    switch(type){
      case "content":
        if(this.state.showTimePeriod=="block"){
//           cl("content time period block")
//           this.mySetState("n",{showTimePeriod:"none", currentPreset:Object.assign({},this.state.currentPreset)})
          
        }
        
        break
      default:
        break
    }
  }

  showGJEntry=()=>{
    if(this.state.gjEntry){
      let gj=this.state.gjEntry
      // cl(gj)
      let backgroundColor = (this.dark)?"#000000":"#FFFFFF"
      return(
        <div style={{position:"absolute",left:gj.left,top:gj.top, width:gj.top,backgroundColor:backgroundColor,
          borderRadius:10, boxShadow:`10px 10px 10px ${this.dark ? "#202020" : "#888888"}`, 
          padding:10
        }}>
        <div style={{float:"right",backgroundColor:(this.dark) ? "#202020" : "#EEEEEE",cursor:"pointer", padding: "5px 10px"}}
        onClick={e=>this.onChange("gjClose",{})}>x</div>
          <GrowJournalWidgetEntry01 
            key={gj.growJournalId} 
            parms={{
              entry: gj,
              level: "zone",//p.level,
              avatars: this.avatars,
              getPopup: this.props.parms.getPopup,
              editing: this.state.gjEdit || false
            }}
            onChange={vals=>this.onChange("gjEntry", vals)}
            />
        </div>
      )
      //               getPopup:p.getPopup,
      //               myTag:`${p.pageType}-${p.zuci}`
      //               onChange: e=>this.onChange("gjEntry01",e)
    }else{return null}
  }

  
  showGridCheckbox=()=>{
    return(
        <div style={{float:"left", display:"inline-block"}}>
          <input type="checkbox" 
          checked={this.state.axisOptionsShowGrid||false}
          onChange={(e)=>this.onChange("axisOptions",{axisOptionsShowGrid:e.currentTarget.checked})}
          />
          <label style={{display:"inline-block",marginLeft:10}}>Show Grid</label>
        </div>
    )
  }

  showReport=(data)=> {
    let banding=(globs.usersInfo.uiInfo||{}).tableBanding||true
    let rows = []
    let cpx=Object.assign({},this.state.currentPreset)
    let body
    data.forEach((d, i)=>{
      cl(d)
      let tableBand=(((i+1)%2)&&banding)?"tableBand":null
      body = (d.body) ? d.body : ""
      var rowStyle,onClick
      rows.push(
        <tr key={i} style={rowStyle} className={tableBand} onClick={onClick}>
          <td>{d.interval}</td>
          <td>{getSiteName(d.siteId)}</td>
          <td>{getZoneName(getZoneId(d.siteId, d.zoneId))}</td>
          <td>{d.time}</td>
          <td>{d.sensorName}</td>
          {Object.entries(d.aggregates).map(([k, v] ,i)=>{
            return(
              <td key={i}>{v}{(d.aggregates != "Count") ? d.unit : ""}</td>
            )
          })}
        </tr>
      )
    })
    // TODO ADD TIMEZONE TO TIME
    return(
      <div className="table-container">
        { rows.length ?
        <table className="list report"><tbody>
          <tr>
            <th><h3>Interval</h3></th>
            <th><h3>Site</h3></th>
            <th><h3>Zone</h3></th>
            <th><h3>Time</h3></th>
            <th><h3>Sensor</h3></th>
            {cpx.aggregates.map((a,i)=>{
              return(
                <th key={i}><h3>{a}</h3></th>
              )
            })}
          </tr>
          {rows}
        </tbody></table>
        :
        <h3 style={{textAlign: "center"}}>
        No data found for this report
        </h3>
        }
      </div>
    )

  }

  showAggregateCheckbox=()=>{
    let cpx=Object.assign({},this.state.currentPreset)
    cl(cpx)
    return (
      <>
      {
        this.aggregateOptions.map((s, i)=>{
          let aggr = cpx.aggregates.find(e=>e == s.value)
          return (
            <div key={i}>
              <input type="checkbox" 
              checked={aggr!=null}
              onChange={(e)=>{this.onChange("chartAggregates",{id: s.value})}}
              />
              <label style={{display:"inline-block",marginLeft:10}}>Show {s.label}</label>
            </div>
          )
        })
      }
      </>
    )
  }

  showAggregateOptions=()=>{
    let opt=this.state.showAggregateOptions
//     cl(this.sensors)
//     cl(this.state)
    return(
      <div className="popup graph-axis-popup" style={{display:(opt>=0)?"block":"none"}}>
        <h2>Aggregate Options</h2>
        {this.showAggregateCheckbox()}
        <div className="float-left-box">
        <br /><br />
          <div className="alignright ok-cancel">
            <button type="button" className="filled left-margin"
              onClick={()=>{this.onChange("aggregateOptions",{type:"OK",showAggregateOptions:-1})}}
            >OK</button>
          </div>
        </div>
      </div>
    )
  }

//   showPaging=()=> {
//     let first=this.state.skip+1
//     let last=first+this.state.limit-1
//     let count=this.state.count
//     if(last>count){last=count}
// //     cl([first,last,count])
//     return (
//       <div className="report-paging paging">
//         <button type="button" className="back-to-beginning" aria-label="first page"
//           onClick={e=>this.onClick("begin")}>&#171;</button>
//         <button type="button" className="back" aria-label="previous page"
//           onClick={e=>this.onClick("back")}>&#8249;</button>
//         <span className="page-range">{`${first}-${last} of ${count}`}</span> 
//         <button type="button" className="forward" aria-label="next page"
//           onClick={e=>this.onClick("forward")}>&#8250;</button>
//         <button type="button" className="forward-to-end" aria-label="last page"
//           onClick={e=>this.onClick("end")}>&#187;</button>
//       </div>
//     )
//   }

  showSummaryType=(data)=>{
    let type = this.state.currentPreset.vizType
    switch (type) {
      case "Summary":
        return (
          <div className="reports-and-graphs">
            {this.showReport(data)}
          </div>
          )
      case "Bar":
        return (
          <div>
            { data[0] ?
              <C18Bar data={data} />
            :
              <h3 style={{textAlign: "center"}}>
              No data found for this report
              </h3>
            }
          </div>
        )
      case "HeatMap":
        return <C18HeatMap data={[]} />
      case "Calendar":
        return <C18Calendar data={[]} />
      case "ScatterPlot":
        return <C18ScatterPlot data={[]} />
      default:
        return (
          <div className="reports-and-graphs">
            {this.showReport(data)}
          <div className="clearfloat"></div>
          </div>
        )
    }
  }
  
  // swap between different modes of summary (report, data visualization)
  showSummary=()=>{
    let cpx=Object.assign({},this.state.currentPreset)
    let data = this.state.historySummary?.report || []

    let startDateText=this.formatTime(cpx.fromTime)
    let endDateText=this.formatTime(cpx.toTime)
    let title

    // apply time slider filter to data
    if (cpx.showTimeSlider && cpx.selectedTs) {
      cl(cpx.selectedTs)
      data = data.filter((d)=>d.ts == cpx.selectedTs)
      title = `${cpx.interval} - ${this.formatTime(cpx.selectedTs)}`
    } else {
      title = `${cpx.interval} - ${startDateText} to ${endDateText}`
    }
    // add sensor names and units to data
    data.forEach((d)=>{
      let sensor = this.sensors.find((s)=>s.s == d.siteId && s.z == d.zoneId && s.id == d.sensorId)
      if (cpx.showTimeSlider && cpx.selectedTs) {
        d.fullSensorName = sensor.name
      } else {
        d.fullSensorName = `${sensor.name}\n${d.time}`
      }
      d.unit = sensor.unit
      Object.entries(d.aggregates).map(([k, v] ,i)=>{
        switch (k) {
          // round to tenths
          case "Max":
          case "Min":
          case "Avg":
            d.aggregates[k] = Math.round(v * 10) / 10
        }
      })
    })
    return (
      <div className="reports-and-graphs">
        <h3>{title}</h3>
        {this.showSummaryType(data)}
        <div className="clearfloat"></div>
      </div>
    )
  }

  showTimeSlider=()=>{
    let cp=Object.assign({},this.state.currentPreset)
    if (cp.showTimeSlider) {
      let data = this.state.historySummary?.report || []
      let seen = []
      let marks = []
      data.forEach((r)=>{
        if (!seen.includes(r.ts)) {
          seen.push(r.ts)
          marks.push(
          {
            value: r.ts,
            time: r.time
          })
        }
      })
      cl(marks)
      if (marks.length > 1) {
        return (
          <C18MuiSlider
            label="TimeSlider"
            step={null}
            marks={marks} // array of obj with int val and str label
            onChange={(e, v)=>{
              this.onChange("timeSlider",{timeVal:v})
            }}
          />
        )
      }
    }
  }

  showSelectedSensors=()=>{
    let sensors = this.sensors.map((s)=>{return s.name})
    return (
      <div style={{display:"inline-block"}}>
          <h4>
            Selected Sensors: {sensors.join(", ")}
          </h4>
      </div>
    )
  }

  showHistoryButton=()=> {
    return (
      <C18Button00 className="icon"
        onClick={e=>this.onChange("getHistorySummary")}>
          Get History Test
      </C18Button00>
    )
  }
  
  render(){
    let p=this.props.parms
    let cpx=Object.assign({},this.state.currentPreset)
    let tabs=[
//       {v:"schedule",t:"Schedule"},
//       {v:"reports",t:"Reports"},
      {v:"graphs",t:"Graphs"},
    ]
    if(this.state.loaded){
      cl(p.pageType)
      let view=(p.pageType=="viewSummary")
//       let edit=(p.pageType=="editGraph")
//       cl(this.state.currentPreset.dataMarkers)
//       this.makeSensors()
//           <div id="content-area" onClick={o=>{this.onClick("content")}}>
//           </div>
      return(
        <div id="quickChart" onClick={e=>this.onChange("mainDiv",{})}>
                <div className="clearfloat"/>
                {!view&&
                  <>
                    {!false&&// was edit
                      <>
                        <C18SiteZoneView00 parms={{
                          level:this.getPresetLevel(),
                          site:this.state.site,
                          zone:this.state.zone,
                          pageType:p.pageType,
                          view:null,
                          getPopup:p.getPopup,
                          onChange:e=>{this.onChange("szv",e)}
                        }}/>
                        <br/><br/>
                      </>
                    }
                    {this.showTimePeriod()}
                    {(this.state.zone||(p.pageType=="editGraph"))&&this.showChartElements()}
                    {this.showInterval()}
                    {this.showAggregates()}
                    {this.showVizOptions()}
                    {this.showTimeSliderToggle()}
                  </>
                }
                <div className="clearfloat"/>
                {this.showSelectedSensors()}
                <div className="clearfloat"/>
                {this.showSummary()}
                <div className="clearfloat"/>
                {this.showTimeSlider()}
                <div className="clearfloat"/>
                {this.showDownloadCsv()}
                <div className="clearfloat"/>
                {((!view)&&this.state.writePriv) && this.showSaveThisView()}
                <div className="clearfloat"/>
                {this.showAggregateOptions()}
                {this.showGJEntry()}
        </div>
      )
    }else{
      return <div>loading. . .</div>
    }
  }
}

export default C18Summary ;

