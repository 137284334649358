import React from 'react';
import C18SidebarGrowJournalHeader00 from './C18SidebarGrowJournalHeader00';
import GrowJournalReply00 from './GrowJournalReply00';
import C18Anchor00 from './C18Anchor00';
import GrowJournalWidgetEntry01 from '../../visualization/components/GrowJournalWidgetEntry01'
import {loadSitesInfo,loadZonesInfo,getZoneIndex,loadUsersInfo,loadTagsInfo,
  loadGJ,acctFeature,loadGJ2} from './C18utils'
import {wsTrans} from '../../usa/utils/utils'
import {cl,globs,getTime} from '../../components/utils/utils';
import config from '../../components/utils/config'  

class C18SidebarGrowJournal00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      loaded:false,
      gjSkip:0,
      gjLimit:5,
      gjLastDisp: Math.floor(getTime())+3*3600,
      gjLastId: "",
      gjMore: true,
      filter:{},
      entries:[],
    }
    this.loadInfo()
    this.subscribe_newContextEvent=globs.events.subscribe("newContext",this.newContext)
    this.updateInterval=setInterval(this.checkGJUpdated,30000)
    
    
//     this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
//     this.props.parms.onChange("feed",{type:"savePage", data:{savePage:true}}) 
  }
  
  componentDidMount=()=>{
    this.mounted=true
  }
  componentWillUnmount=()=>{
    this.subscribe_newContextEvent.remove()
    clearInterval(this.updateInterval)
    this.mounted=false
  }
  
  getLastMod=async()=>{// get last dispTime for Grow Journal
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/lastTableMod", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body:{tab:"growJournal",field:"dispTime"}})
//     cl(r.data[0].dispTime)
    return r.data[0]?.dispTime
  }
  
  checkGJUpdated=async()=>{
    // cl("check gj updated")
    let lm=await this.getLastMod()
    let p=this.props.parms
    // cl(lm)
    // reload all currently loaded entries
    if(lm>this.state.lastMod){
      // let entries=await loadGJ(this.state.gjSkip,this.state.gjLimit,this.state.filter,
      //   this.props.parms)
      let v = {lastMod: lm}
      let entries=await loadGJ2(this.state.entries.length,this.state.filter,p, Math.floor(getTime())+3*3600, "")
      v.entries = entries
      // set lastdisp and lastid to second to last item (this is so we know there is more to load)
      let lastEntry = entries[this.state.gjLimit - 2]
      v.gjLastDisp = lastEntry.dispTime
      v.gjLastId = lastEntry._id
      this.mySetState(v)
    }
  }
  
  newContext=async(vals)=>{// page has changed
//     cl(vals)
    var p
    if(vals.level=="config"){
      p={level:vals.level,siteId:vals.siteId,zoneId:vals.zoneId,mode:"fui",pageType:vals.pageType,zuci:vals.zuci}
    }else{
      p={level:vals.level,siteId:vals.siteId,zoneId:vals.zoneId}
    }
    // let entries=await loadGJ(this.state.gjSkip,this.state.gjLimit,this.state.filter,p)
    if (this.mounted) {
      let entries=await loadGJ2(this.state.gjLimit,this.state.filter,p, this.state.gjLastDisp, this.state.gjLastId)
      let v = {loaded:true,sgjLevel:p.level,sgjMode:p.mode,sgjSite:p.siteId,
          sgjZone:p.zoneId,pageType:vals.pageType,zuci:vals.zuci}
//       cl(entries)
//       cl(entries.length)
      if (entries.length == this.state.gjLimit) {
        // set lastdisp and lastid to second to last item (this is so we know there is more to load)
        let lastEntry = entries[this.state.gjLimit - 2]
        v.gjLastDisp = lastEntry.dispTime
        v.gjLastId = lastEntry._id
        v.gjMore = true
      } else {
        v.gjMore = false
      }
      entries = this.state.entries.concat(entries.slice(0, this.state.gjLimit - 1))
      v.entries = entries
//       cl(entries)
      this.mySetState(v)
    }
  }
  
//   getTimePeriod=()=>{
//     let end=Math.floor(getTime())+3*3600
//     let begin=end-60*86400
//     return [begin,end]
//   }
//   
//   loadGJ=async(skip,limit,parms)=>{
// //     cl(this.props)
// //     cl(this.state)
//     let p=parms//this.props.parms
// //     cl(p)
//     if(!p){return[]}
//     var tag,tags
//     let method="retrievePaging"// used for account, site, and zone levels
//     if(p.pageType){// pageType is only on fui pages
//       tag=`${p.pageType}-${p.zuci}`
// //       tags={$elemMatch:{$eq:tag}}
//       method="retrievePagingTags"
//     }
// //     cl(method)
//     let [begin,end]=this.getTimePeriod()
//     var query
//     if(p.level=="account"){
//       query={
//         level:p.level,
//         original: true,
//         dispTime: {$gt: begin, $lte: end},
//       }
//     }else{// for site and zone levels
//       query={
//         level:p.level,
//         original: true,
//         zoneId: p.zoneId, 
//         siteId: p.siteId,
//         dispTime: {$gt: begin, $lte: end},
//         tag:tag,
//       }
//       if(p.level=="site"){
//         delete query.level
//         delete query.siteId
//         delete query.zoneId
//         query["$or"]=[{level:"account"},{level:"site",siteId:p.siteId},{level:"zone",siteId:p.siteId}]
//       }
//       if(p.level=="zone"){
//         delete query.level
//         delete query.zoneId
//         query["$or"]=[{level:"zone",zoneId:p.zoneId},{level:"config",zoneId:p.zoneId},{level:"site",siteId:p.siteId}]
//       }
//       if(p.level=="config"){
//         delete query.level
//       }
//     }
//     cl(query)
// //     cl(method)
//     let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: method, 
//       sessionId: globs.userData.session.sessionId,
//       body: query, page:{skip:skip,limit:limit,sort:{dispTime:-1}}})
//     let entries=r.data
//     entries.forEach(e=>{
//       e.t=e.dispTime; 
//       e.u=e.userId; 
//       e.ty="GJ"
//     })
//     if((p.level!="account")&&(p.level!="site")){
//       for(let i=entries.length-1;i>=0;i--){
//         if(entries[i].level=="account"){delete entries.splice(i,1)}
//       }
//     }
//     return entries
//   }
  
  mySetState=(vals)=>{
    if(this.mounted){
      this.setState(vals)
    }else{
      Object.assign(this.state,vals)
    }
  }
  
  loadInfo=async()=>{
    await loadTagsInfo()
    await loadUsersInfo()
    let p=this.props.parms
//     cl(p)
    let lastMod=await this.getLastMod()
    // let entries=await loadGJ(this.state.gjSkip,this.state.gjLimit,this.state.filter,p)
    let entries=await loadGJ2(this.state.gjLimit,this.state.filter,p, this.state.gjLastDisp, this.state.gjLastId)
    let v = {loaded:true,sgjLevel:p.level,sgjMode:p.mode,sgjSite:p.siteId,
      sgjZone:p.zoneId,pageType:p.pageType,zuci:p.zuci,lastMod:lastMod}
//     cl(entries)
//     cl(entries.length)
    if (entries.length == this.state.gjLimit) {
      // set lastdisp and lastid to second to last item (this is so we know there is more to load)
      let lastEntry = entries[this.state.gjLimit - 2]
//       cl(lastEntry)
      v.gjLastDisp = lastEntry.dispTime
      v.gjLastId = lastEntry._id
      v.gjMore = true
    } else {
      v.gjMore = false
    }
    entries = this.state.entries.concat(entries.slice(0, this.state.gjLimit - 1))
    v.entries = entries
//     cl(v)
    this.mySetState(v)
  }
  
  deleteEntry=(vals)=>{
    var query
    let entries=this.state.entries.slice(0)
    switch(vals.type){
      case "main":
        query={threadId:vals.id}
        for(let i=0;i<entries.length;i++){
          if(entries[i].threadId==vals.id){
            entries.splice(i,1)
          }
        }
        break
      case "reply":
        query={growJournalId:vals.id}
        break
    }
    wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "delete", 
      sessionId: globs.userData.session.sessionId,
      body: query})
    
    this.setState({entries:entries})
//     cl(entries)
//     cl(vals)
  }
  
  updateEntry=(entries,entry)=>{
    for(let i=0;i<entries.length;i++){
      if(entries[i].growJournalId==entry.growJournalId){
        entries[i]=entry
        break
      }
    }
//     return entries
//     cl(entries)
//     cl(entry)
  }
  
  onChange=async(type,vals)=>{
//     cl(type,vals)
    var entries
    let st=this.state
    let pa=this.props.parms
    switch(type){
//       case "gjEntry01":
//         cl(vals)
//         break
      case "header":
        cl(vals)
        switch(vals.cmd){
          case "addGJEntry":// need to hide edit panel
            entries=this.state.entries.slice(0)// add an entry
            let e=vals.gjEntry
            e.t=e.dispTime; 
            e.u=e.userId; 
            e.ty="GJ"
            entries.unshift(vals.gjEntry)
            this.setState({entries:entries})
            break
          case "filter":
//             cl("do filter")
            delete vals.cmd
            cl(vals.filter)
            // vals.entries=await loadGJ(st.gjSkip,st.gjLimit,vals.filter,pa)
            vals.entries=await loadGJ2(this.state.gjLimit,vals.filter,pa, Math.floor(getTime())+3*3600, "")
            let v = {}
            cl(vals.entries)
            cl(vals.entries.length)
            if (vals.entries.length == this.state.gjLimit) {
              // set lastdisp and lastid to second to last item (this is so we know there is more to load)
              let lastEntry = vals.entries[this.state.gjLimit - 2]
              v.gjLastDisp = lastEntry.dispTime
              v.gjLastId = lastEntry._id
              v.gjMore = true
            } else {
              v.gjMore = false
            }
            vals.entries = vals.entries.slice(0, this.state.gjLimit - 1)
            v.entries = vals.entries
            v.filter = vals.filter
            cl(vals.entries)
            this.setState(v)
            break
          default:
            break
        }
        break
      case "gjEntry":
//         cl(vals)
        switch(vals.cmd){
          case "deleteEntry":
//             cl("delete entry")
//             cl(vals)
            this.deleteEntry(vals)
            break
          case "image":
            this.props.parms.onChange("feed",{type:"image",path:vals.path})
//             cl(vals)
            break
          case "addGJEntry":// when an entry is edited
            entries=this.state.entries.slice(0)// add an entry
            this.updateEntry(entries,vals.gjEntry)
//             cl(vals)
            this.setState({entries:entries})
            break
          case "tagClick":
            if(st.filter.tags?.length && vals.filter.tags.some((t) => st.filter.tags.includes(t))){
              vals.filter={}
            }
            // vals.entries=await loadGJ(st.gjSkip,st.gjLimit,vals.filter,pa)
            // start from beginning
            vals.entries=await loadGJ2(this.state.gjLimit,vals.filter,pa, Math.floor(getTime())+3*3600, "")
            let v = {}
            cl(vals.entries)
            cl(vals.entries.length)
            if (vals.entries.length == this.state.gjLimit) {
              // set lastdisp and lastid to second to last item (this is so we know there is more to load)
              let lastEntry = vals.entries[this.state.gjLimit - 2]
              v.gjLastDisp = lastEntry.dispTime
              v.gjLastId = lastEntry._id
              v.gjMore = true
            } else {
              v.gjMore = false
            }
            vals.entries = vals.entries.slice(0, this.state.gjLimit - 1)
            v.entries = vals.entries
            v.filter = vals.filter
            this.setState(v)
            break
        }
        break
      case "loadMore":
        cl("load more")
        vals.e.preventDefault()
        // entries=await loadGJ(this.state.gjSkip,this.state.gjLimit+5,this.state.filter,this.props.parms)
        entries=await loadGJ2(this.state.gjLimit,this.state.filter,this.props.parms, this.state.gjLastDisp, this.state.gjLastId)
        let v = {}
        cl(entries)
        cl(entries.length)
        if (entries.length == this.state.gjLimit) {
          // set lastdisp and lastid to second to last item (this is so we know there is more to load)
          let lastEntry = entries[this.state.gjLimit - 2]
          v.gjLastDisp = lastEntry.dispTime
          v.gjLastId = lastEntry._id
          v.gjMore = true
        } else {
          v.gjMore = false
        }
        entries = this.state.entries.concat(entries.slice(0, this.state.gjLimit - 1))
        v.entries = entries
        cl(entries)
        this.setState(v)
        break
      default:
        cl("default")
        this.props.parms.onChange("feed",vals)
        break
    }
//     cl(type,vals)
  }
  
  showGrowJournalEntry=(i,e)=>{
//     cl(e)
//     cl(this.props)
    let p=this.props.parms
//     cl(p)
    var myTag
    if(p.pageType){myTag=`${p.pageType}-${p.zuci}`}
//     cl(e)
    return (
      <div id="/sidebar/growJournalEntry" key={e.growJournalId}>
        <GrowJournalWidgetEntry01 
          key={e.growJournalId} 
          parms={{
            entry: e,
            level: p.level,
            key:i,
            avatars: this.avatars,
            getPopup:p.getPopup,
            myTag:myTag,
            siteId:p.siteId,
            zoneId:p.zoneId,
//             onChange: e=>this.onChange("gjEntry01",e)
          }}
          onChange={vals=>this.onChange("gjEntry", vals)}
          />
      </div>
    )
  }
  
  showEntries=()=>{
//     cl(this.state.entries)
    return this.state.entries.map((e,i)=>{
      return this.showGrowJournalEntry(i,e)
    })
  }
  
  showGJInfo=()=>{
    let s=this.state
    switch(s.sgjLevel){
      case "account":
        return(<p>{`level:${s.sgjLevel}`}</p>)
      case "site":
        return(<p>{`level:${s.sgjLevel}, site:${s.sgjSite},`}</p>)
      case "zone":
        return(<p>{`level:${s.sgjLevel}, zone:${s.sgjZone}`}</p>)
      case "config":
        return(<p>{`level:${s.sgjLevel}, site:${s.sgjSite}, zone:${s.sgjZone}, pageType:${s.pageType}, zuci:${s.zuci}`}</p>)
    }
  }
  
  render(){
//     cl(this.props)
    let p=this.props.parms
//     cl(p)
//     cl(this.state)
    if(this.state.loaded){
      return(
        <div id="/sidebar/growJournal">
        <C18SidebarGrowJournalHeader00
          parms={{
            level:this.state.sgjLevel,
            siteId:p.siteId,
            zoneId:p.zoneId,
            mode:this.props.parms.mode,// mode, zuci, pageType are just for fui pages
            zuci:this.props.parms.zuci,
            pageType:this.props.parms.pageType,
            getPopup:p.getPopup,
            onChange:e=>this.onChange("header",e)
          }}
        />
        {(acctFeature("showGjId"))&&this.showGJInfo()}
        {this.showEntries()}
        {this.state.gjMore&&
          <div className="center">
            <C18Anchor00 to="" className="colored-link-text large-text load-more"
            onClick={e=>this.onChange("loadMore",{e:e})}>Load More</C18Anchor00>
          </div>
        }
        </div>
      )
    }else{
      return <div>loading. . .</div>
    }
  }
}
      
export default C18SidebarGrowJournal00;
